import Vue from 'vue'
import dayjs from 'dayjs'

Vue.filter('dateFormat', function (value, format = 'YYYY-MM-DD') {
  if (value) return dayjs(new Date(value)).format(format)
  else return null
})

Vue.filter('moneyFormat', function (value) {
  return `￥${value.toFixed(2)}元`
})

Vue.filter('mobileFormat', function (value) {
  if (value?.length === 11) {
    return value
    // return value.substring(0, 3) + ' ' + value.substring(3, 7) + ' ' + value.substring(7, 11)
  } else {
    return value
  }
})

Vue.filter('noticeDateFormat', function (value) {
  if (!value) {
    return
  }
  if (dayjs(value).format('YYYY-MM-DD') === dayjs(new Date()).format('YYYY-MM-DD')) {
    return value.substring(11, 16)
  } else {
    return dayjs(value).format('YYYY-MM-DD')
  }
})

// 格式化数字三位加一逗号 (为0显示)
Vue.filter('numberFormat', value => {
  if (value === 0) {
    return 0
  }
  if (!value) {
    return
  }
  // 将数字转换成字符串
  let numberStr = value.toString()
  // 使用正则表达式在逗号前插入逗号
  numberStr = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return numberStr
})

Vue.filter('dictLabelByValue', (value, dictOptions) => {
  let arr = dictOptions.filter(item => item.dictValue == value)
  if (arr.length > 0) {
    return arr[0].dictLabel
  } else {
    return value
  }
})
