<template>
  <div>
    <form-table
      ref="baseForm"
      module="productCertificate"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      :isExpand="true"
      :moreSearch="true"
      :isCardDisabled="false"
      isNeedOperate
      expandKey="productList"
      :tableExpandOptions="tableExpandOptions"
      @clickBtn="onSelect"
      @changeBtnOLd="changeBtnOLd"
      @changeBtnNew="changeBtnNew"
    >
      <template slot="custom-title">
        <van-row style="font-size: 12px; color: red">
          <div>
            <van-icon name="info-o" color="red" size="14" />
            请注意，药监局网站公示信息变更后，最新产品注册证（盖章版）会在约1个月后上传。
          </div>
        </van-row>
      </template>
      <template slot="table-btn" slot-scope="data">
        <!--        <van-popover v-model="showPopover" trigger="click" placement="top">-->
        <!--          <div style="padding: 10px 10px" v-if="data.row.lastValidPeriod != null">-->
        <!--            <div style="width: 300px; min-height: 30px; font-size: 12px; color: #323233; display: flex">-->
        <!--              <div style="min-width: 60px">文件名：</div>-->
        <!--              <div>{{ lastValidInfo.name }}</div>-->
        <!--            </div>-->
        <!--            <div style="height: 30px; line-height: 30px; font-size: 12px; color: #323233">-->
        <!--              注册证失效日期：{{ lastValidInfo.certExpireDate }}-->
        <!--            </div>-->
        <!--            <div style="height: 30px; line-height: 30px; font-size: 12px; color: #323233">-->
        <!--              上传时间：{{ lastValidInfo.updateTime }}-->
        <!--            </div>-->
        <!--            <div style="height: 30px; line-height: 30px; font-size: 12px; color: #0b41cd">-->
        <!--              <span @click="showFile">预览</span>-->
        <!--              <span style="margin-left: 10px" @click="downloadLastFile">下载</span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div style="height: 30px; line-height: 30px; font-size: 12px; color: #323233; padding: 10px 10px" v-else>-->
        <!--            暂无上个有效期的信息-->
        <!--          </div>-->
        <!--          <template #reference>-->
        <!--            <span style="color: #ced9f5; margin: 0 0.4rem">|</span>-->
        <!--            <span-->
        <!--              :style="data.row.lastValidPeriod != null ? { color: '#0b41cd' } : { color: '#8c8c8c' }"-->
        <!--              @click="lastValidity(data.row)"-->
        <!--              >上个有效期</span-->
        <!--            >-->
        <!--          </template>-->
        <!--        </van-popover>-->
        <span style="color: #ced9f5; margin: 0 5px">|</span>
        <span
          :style="data.row.lastValidPeriod != null ? { color: '#0b41cd' } : { color: '#8c8c8c' }"
          @click="lastValidity(data.row)"
          >上个有效期</span
        >
        <span style="color: #ced9f5; margin: 0 5px">|</span>
        <span style="color: #0b41cd" @click="showMore(data.row, data.index)">{{
          data.row.isShow ? '收起更多' : '展开更多'
        }}</span>
      </template>
    </form-table>

    <van-dialog v-model="show" title="上个有效期的注册证" :showConfirmButton="false" :closeOnClickOverlay="true">
      <van-row style="margin-top: 0px">
        <van-col span="24">
          <div style="padding: 10px 10px" v-if="Object.keys(lastValidInfo).length > 0">
            <div style="width: 300px; min-height: 60px; font-size: 12px; color: #323233; display: flex">
              <div style="min-width: 60px; font-weight: bold">文件名：</div>
              <div>{{ lastValidInfo.name }}</div>
            </div>
            <div style="width: 300px; min-height: 60px; font-size: 12px; color: #323233; display: flex">
              <div style="min-width: 60px; font-weight: bold">失效日期：</div>
              <div>{{ lastValidInfo.certExpireDate }}</div>
            </div>
            <div style="width: 300px; min-height: 60px; font-size: 12px; color: #323233; display: flex">
              <div style="min-width: 60px; font-weight: bold">上传时间：</div>
              <div>{{ lastValidInfo.updateTime }}</div>
            </div>
            <div style="height: 30px; text-align: center; margin-bottom: 10px">
              <van-button type="default" style="width: 70px" @click="showFile">预览</van-button>
              <van-button type="default" style="width: 70px; margin-left: 10px" @click="downloadLastFile"
                >下载</van-button
              >
            </div>
          </div>
          <div style="height: 30px; line-height: 30px; font-size: 12px; color: #323233; padding: 10px 10px" v-else>
            暂无上个有效期的信息
          </div>
        </van-col>
      </van-row>
    </van-dialog>
  </div>
</template>

<script>
import { getDicts } from '@/api/dict/data'
import { delProductCertificate, lastValidPeriod } from '@/api/file'
import { previewFile } from '@/api/formTable'
import { download } from '@/utils/request'

export default {
  name: 'ProductCertificate',
  data() {
    return {
      btnData: [{ text: '下载注册证' }, { text: '下载产品信息' }],
      searchFormData: [
        { props: 'name', label: '文件名', type: 'input' },
        { props: 'certNo', label: '注册证号', type: 'input' },
        { props: 'registerBy', label: '注册人', type: 'input' },
        { props: 'productNo', label: '产品编号', type: 'input', isMore: true },
        { props: 'productCnName', label: '产品中文名称', type: 'input', isMore: true },
        {
          props: 'productLine',
          label: '产品线',
          type: 'DictSelect',
          dictCode: 'sys_product_line',
          multiple: false,
          isMore: true
        },
        { props: 'udiDi', label: 'UDI-DI', type: 'input', isMore: true },
        { props: 'consumablesClassCode', label: '医保耗材分类编码', type: 'input', isMore: true }
      ],
      tableOptions: [
        {
          prop: 'name',
          label: '文件名',
          type: 'fileName'
        },
        {
          prop: 'certNo',
          label: '注册证号'
        },
        {
          prop: 'approveDate',
          label: '注册证批准日期'
        },
        {
          prop: 'certExpireDate',
          label: '注册证失效日期'
        },
        {
          prop: 'registerBy',
          label: '注册人'
        },
        {
          prop: 'updateTime',
          label: '上传时间'
        }
      ],
      tableExpandOptions: [
        {
          prop: 'productNumber',
          label: '产品编号'
        },
        {
          prop: 'productNameZh',
          label: '产品中文名称'
        },
        {
          prop: 'dwhBl',
          label: '产品线',
          dictCode: 'sys_product_line',
          options: []
        },
        {
          prop: 'eanUpc',
          label: 'UDI-DI'
        },
        {
          prop: 'consumablesClassCode',
          label: '医保耗材分类编码'
        }
      ],
      headerTitle: '产品注册证',
      queryUrl: '/bff-dp-mobile/register-cert/dealer-list',
      showPopover: false,
      lastValidInfo: {},
      show: false
    }
  },
  created() {
    getDicts('sys_product_line').then(({ data }) => {
      this.searchFormData[3].options = data
      this.tableExpandOptions[2].options = data
    })
  },
  methods: {
    handleDelete(obj) {
      let params = {
        id: obj.regCertDocumentId,
        fileId: obj.fileId
      }
      this.$dialog
        .alert({
          message: `是否确认删除该文件？删除后，经销商将看不到此文件`,
          showCancelButton: true
        })
        .then(() => {
          delProductCertificate(params).then(res => {
            if (res.code === 200) {
              this.$toast.success('删除成功')
              this.$refs.baseForm.getList()
            } else {
              this.$toast.fail(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    lastValidity(obj) {
      if (obj.lastValidPeriod == null) {
        return false
      }
      let params = {
        id: obj.regCertDocumentId
      }
      lastValidPeriod(params).then(res => {
        this.lastValidInfo = { ...res.data }
        this.show = true
      })
    },
    downloadLastFile() {
      const fileIds = this.lastValidInfo.fileId
      download('/file/multi-download', {}, null, {
        method: 'POST',
        params: {
          regCertFlag: true,
          watermarkFlag: true,
          fileIds
        }
      })
    },
    onSelect(text, queryParams, list) {
      if (this.$refs.baseForm.productCertificateOpr === '下载注册证') {
        this.downloadFile(list)
      } else if (this.$refs.baseForm.productCertificateOpr === '下载产品信息') {
        const selectedFileIdList = [...list].map(item => {
          return item.regCertDocumentId
        })
        const requestParams = {
          ...queryParams,
          regCertDocumentIdList: selectedFileIdList.join(',')
        }
        this.download(
          '/bff-dp-mobile/register-cert/dealer-cert-product-export',
          {},
          `产品信息_${new Date().getTime()}.xlsx`,
          {
            method: 'post',
            params: requestParams
          }
        )
      }
    },
    changeBtnOLd() {
      this.btnData = [{ text: '下载注册证' }]
    },
    changeBtnNew() {
      this.btnData = [{ text: '下载注册证' }, { text: '下载产品信息' }]
    },
    downloadFile(list) {
      if (list.length === 0) {
        this.$toast('请先勾选要操作的记录')
        return false
      }
      const selectedList = [...list]
      const selectedFileIdList = selectedList.map(item => {
        return item.fileId
      })
      const fileIds = selectedFileIdList.join(',')
      download('/file/multi-download', {}, null, {
        method: 'POST',
        params: {
          regCertFlag: true,
          watermarkFlag: true,
          fileIds
        }
      })
    },
    showFile() {
      let params = {
        regCertFlag: true,
        fileId: this.lastValidInfo.fileId,
        watermarkFlag: true
      }
      previewFile(params, this.lastValidInfo.name)
    },
    showMore(obj, index) {
      obj.isShow = !obj.isShow
      this.$refs['baseForm'].showOrHide(obj, index)
    }
  }
}
</script>

<style lang="less" scoped></style>
