export const queryUrl = '/bff-dp-mobile/performance-result/dealer-data-list'
export const tableOptions = [
  {
    type: 'fileName',
    hideFileIcon: true
  },
  {
    prop: 'uploadTime',
    label: '上传时间'
  }
]

export const searchFormData = [
  {
    props: 'performanceYear',
    label: '年份',
    type: 'date',
    valueFormat: 'yyyy',
    dateFormat: 'yyyy',
    dateType: 'year'
  },
  {
    props: 'performancePeriod',
    label: '周期',
    type: 'DictSelect',
    options: [],
    multiple: false
  }
]
