<template>
  <div class="login">
    <div class="login-container">
      <img class="login-logo" src="../../assets/logo/logo.png" />
      <!--      <img class="title" src="../../assets/images/login-name.png" />-->
      <div class="content" style="font-size: 24px; color: #0b41cd">
        <van-icon
          name="info-o"
          color="red"
          size="36"
          style="font-weight: bolder; position: relative; top: 3px; right: 5px"
        />系统提示!
      </div>
      <div class="content" style="margin-top: 30px" v-if="errorCode == '403002'">该激活链接已过期，</div>
      <div class="content" v-if="errorCode == '403002'">请联系管理员重新发送激活邮件。</div>
      <div class="content" style="margin-top: 30px" v-if="errorCode == '403012'">
        请使用Edge或Chrome浏览器访问CEP系统。
      </div>
    </div>
    <!--    <video autoplay muted loop playsinline disablePictureInPicture>-->
    <!--      <source src="../../assets/videos/login.mp4" type="video/mp4" />-->
    <!--    </video>-->
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  data() {
    return {
      errorCode: null
    }
  },
  created() {
    this.errorCode = this.$route.query.code
  }
}
</script>

<style lang="less" scoped>
.login {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.login-form {
  margin: 0 auto;
  position: relative;
  top: 20vh;
  z-index: 100;
  border-radius: 6px;
  width: 100%;
  padding: 25px 0 5px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  width: 160px;
  margin-bottom: 50px;
}
.login-container {
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 327px;
  height: 347px;
  border: 0.5px solid #d0d0d0;
  //box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  .login-logo {
    width: 120px;
    margin: 48px auto;
  }
  .title {
    width: 300px;
    margin-bottom: 20px;
  }
  .content {
    font-size: 16px;
    font-weight: bold;
  }
}
video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>
