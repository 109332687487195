export const queryUrl = '/bff-dp-mobile/shared-document/dealer/list'
export const moduleType = 'shareDocument'
export const tableOptions = [
  {
    prop: 'fileName',
    label: '文件名',
    type: 'fileName',
    rightTop: {
      left: {
        prop: 'year',
        label: '年份'
      }
    }
  },
  {
    prop: 'departCode',
    label: '部门',
    dictCode: 'cc_shared_document_department',
    options: []
  },
  {
    prop: 'fileType',
    label: '文件类型',
    dictCode: 'cc_shared_document_file_type',
    options: []
  },
  {
    prop: 'createdDate',
    label: '上传时间'
  }
]

export const searchFormData = [
  { props: 'fileName', label: '文件名', type: 'input' },
  {
    props: 'year',
    label: '年份',
    type: 'date',
    valueFormat: 'yyyy',
    dateFormat: 'yyyy',
    dateType: 'year'
  },
  {
    props: 'department',
    label: '部门',
    type: 'DictSelect',
    dictCode: 'cc_shared_document_department',
    multiple: false
  },
  {
    props: 'fileType',
    label: '文件类型',
    type: 'DictSelect',
    dictCode: 'cc_shared_document_file_type',
    multiple: false
  }
]
