<template>
  <div class="home list-bg" :class="{ noHeight: noCepRole }">
    <div class="noCepRole" v-if="noCepRole">
      <img
        style="width: 70px; height: 70px; margin-bottom: 20px"
        :src="require('@/assets/images/noRole-icon1.png')"
        fit="fill"
      />
      <p class="dis1">您当前没有CEP系统权限，如有需要请联系贵司管理员</p>
    </div>
    <Header />
    <!--    <Banner />-->
    <div class="space" />

    <Chart index="1" v-has-permission="['dashboard:sap:data']" />
    <div class="space" v-has-permission="['dashboard:sap:data']" />

    <ChartPCR index="2" v-has-permission="['dashboard:rcr:data']" />
    <div class="space" v-has-permission="['dashboard:rcr:data']" />

    <!--    <TabCardKpi v-has-permission="['kpi:score']" />-->
    <!--    <div class="space" v-has-permission="['kpi:score']" />-->

    <Instrument v-has-permission="['dashboard:instrument:data']" />
    <div class="space" v-has-permission="['dashboard:instrument:data']" />

    <TabCardCib v-has-permission="['dashboard:cib:price']" />
    <div class="space" v-has-permission="['dashboard:cib:price']" />

    <!--    <Credit v-has-permission="['dashboard:notice']" />-->
    <!--    <div class="space" v-has-permission="['dashboard:notice']" />-->

    <ChannelData v-has-permission="['dashboard:inventory:data']" />
    <div class="space" v-has-permission="['dashboard:inventory:data']" />

    <Auth v-has-permission="['dashboard:loa:data']" />
    <div class="space" v-has-permission="['dashboard:loa:data']" />

    <div
      style="
        width: 50px;
        height: 50px;
        border-radius: 8px;
        color: #fff;
        position: fixed;
        right: 2px;
        top: 45%;
        background: #fff;
        z-index: 999;
        box-shadow: 0px 0px 10px 0px rgba(11, 65, 205, 0.15);
      "
      @click="showPopoverNovice = !showPopoverNovice"
      v-has-permission="['dashboard:notice:urgent']"
    >
      <div style="width: 50px; height: 35px; display: flex; justify-content: center; align-items: center">
        <img style="width: 35px; height: 35px" :src="require('@/assets/images/Notification.svg')" fit="fill" />
      </div>
      <div style="width: 50px; height: 15px; color: #262626; text-align: center; font-size: 10px">重要提醒</div>
    </div>
    <van-popup
      v-model="showPopoverNovice"
      class="popup-box"
      closeable
      round
      get-container="body"
      position="bottom"
      @close="showPopoverNovice = false"
    >
      <Reminder />
    </van-popup>

    <div
      style="
        width: 50px;
        height: 50px;
        border-radius: 8px;
        color: #fff;
        position: fixed;
        right: 2px;
        top: calc(45% + 60px);
        background: #fff;
        z-index: 999;
        box-shadow: 0px 0px 10px 0px rgba(11, 65, 205, 0.15);
      "
      @click="showPopoverTask = !showPopoverTask"
      v-has-permission="['dashboard:task-todo']"
    >
      <div style="width: 50px; height: 35px; display: flex; justify-content: center; align-items: center">
        <img style="width: 35px; height: 35px" :src="require('@/assets/images/Task.svg')" fit="fill" />
      </div>
      <div style="width: 50px; height: 15px; color: #262626; text-align: center; font-size: 10px">待办任务</div>
    </div>
    <van-popup
      v-model="showPopoverTask"
      class="popup-box"
      closeable
      round
      get-container="body"
      position="bottom"
      @close="showPopoverTask = false"
    >
      <Task />
    </van-popup>

    <!--    <Notice v-has-permission="['dashboard:notice']" />-->
    <!-- 二级路由坑 -->
    <!--    <div id="myChart" style="width: 100vw; height: 400px"></div>-->
  </div>
</template>

<script>
import Header from '@/views/home/components/Header.vue'
import Chart from '@/views/home/components/Chart.vue'
import ChartPCR from '@/views/home/components/ChartPCR.vue'
import Banner from '@/views/home/components/Banner.vue'
import TabCardKpi from '@/views/home/components/TabCardKpi.vue'
import TabCardCib from '@/views/home/components/TabCardCib.vue'
import Auth from '@/views/home/components/Auth.vue'
import Notice from '@/views/home/components/Notice.vue'
import Reminder from '@/views/home/components/Reminder.vue'
import Task from '@/views/home/components/Task.vue'
import Credit from '@/views/home/components/Credit.vue'
import ChannelData from '@/views/home/components/ChannelData.vue'
import Instrument from '@/views/home/components/Instrument.vue'
export default {
  name: 'home',
  data() {
    return {
      value: '',
      banner: [], //轮播图
      channel: [], //居家-志趣数据
      brandList: [], // 品牌制造商数据
      newGoodsList: [], // 周一周四新品首发数据
      hotGoodsList: [], // 人气推荐
      topicList: [], // 专题精选
      categoryList: [], //产品列表
      showPopoverNovice: false,
      showPopoverTask: false
    }
  },

  components: {
    Notice,
    Auth,
    TabCardCib,
    TabCardKpi,
    Banner,
    Chart,
    Header,
    ChartPCR,
    Reminder,
    Task,
    Credit,
    ChannelData,
    Instrument
  },
  created() {},
  computed: {
    isNeedChangeDealer() {
      return this.$store.state.user.isNeedChangeDealer
    },
    noCepRole() {
      return this.$store.state.user.noRoutes
    }
  },
  methods: {
    getContainer() {
      return document.querySelector('.van-popover__wrapper')
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  padding: 0 16px 100px;
  box-sizing: border-box;
}
.list-bg {
  min-height: 100vh;
  background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
}
.space {
  height: 12px;
}
.popup-box {
  z-index: 1000;
}
.noCepRole {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1111;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
}
.noHeight {
  height: 0px;
  overflow: hidden;
  padding: 0;
  min-height: unset;
}
</style>
