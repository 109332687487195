<template>
  <div>
    <div class="list-bg" />
    <van-nav-bar :title="title" right-text="保存" left-arrow @click-left="goToBack">
      <template #right>
        <span class="header-btn" :class="loading ? 'btn-loading' : ''" @click="onSubmit">保存</span>
      </template>
    </van-nav-bar>
    <van-form validate-first ref="form">
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.roleName"
        name="roleName"
        required
        readonly
        label="账号类型"
        placeholder="请输入账号类型"
        :rules="[{ required: true, message: '账号类型不能为空' }]"
      />
      <van-field
        class="text-hide1"
        :maxlength="defaultMaxLength"
        input-align="right"
        :required="form.processFlag == 'add'"
        clickable
        readonly
        name="platformRoleNames"
        :value="form.platformRoleNames"
        label="角色"
        placeholder="请点击选角色"
        @click="showRolePicker"
        :rules="[{ required: form.processFlag == 'add', message: '请选择角色' }]"
      >
      </van-field>
      <van-collapse v-model="activeNames" v-if="form.roleTreeList.length > 0">
        <van-collapse-item title="角色划分展示" name="1" class="role">
          <template v-for="(item, i) in roleTypeList">
            <div v-if="item.checkedRole.length > 0" :key="i" class="border-bottom">
              <h3 class="title">{{ item.systemName }}</h3>
              <p
                class="role-list"
                v-for="(role, j) in item.roleList"
                :key="j"
                style="padding-left: 5px; line-height: 1.25"
              >
                <span v-if="item.checkedRole.includes(role.roleId)"
                  ><span style="color: #000">{{ role.roleName }}</span>
                  <span class="dis" v-if="role.description">({{ role.description }})</span></span
                >
              </p>
            </div>
          </template>
        </van-collapse-item>
      </van-collapse>

      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.userName"
        name="userName"
        required
        label="用户名"
        placeholder="-"
        readonly
      />
      <!-- <van-cell is-link v-if="!userId"  @click=";(choseShowDealerContact = true), (choseDealerContact = contactInfo)">
        <template #title>
          <span class="custom-title" style="color:#0b41cd">绑定已有联络人</span>
        </template>
      </van-cell> -->
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.nickName"
        name="nickName"
        required
        label="姓名"
        :readonly="isManage == 'true' || isCompanyManager == 'true'"
        placeholder="姓名"
        :rules="[{ required: true, message: '请输入姓名' }]"
        @input="handleInput"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.phonenumber"
        name="phonenumber"
        label="手机"
        required
        :readonly="isManage == 'true' || isCompanyManager == 'true'"
        placeholder="请输入手机号"
        :rules="[{ pattern: phonePattern, message: '请输入正确手机号' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.email"
        name="email"
        required
        :readonly="isManage == 'true' || isCompanyManager == 'true'"
        label="邮箱"
        placeholder="请输入邮箱"
        rows="1"
        autosize
        type="textarea"
        :rules="[{ pattern: emailPattern, message: '请输入正确邮箱' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.position"
        name="position"
        required
        :readonly="isManage == 'true' || isCompanyManager == 'true'"
        label="职位"
        placeholder="请输入职位"
        :rules="[{ required: true, message: '职位不能为空' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        name="status"
        label="状态"
        input-align="right"
        required
        v-if="title == '编辑账号'"
      >
        <template #input>
          <van-switch
            size="20"
            :value="form.status"
            @input="changeStatus"
            :disabled="
              form.activationStatus != '0' ||
              isManage == 'true' ||
              isCompanyManager == 'true' ||
              form.nickName === loginUsername
            "
          />
        </template>
      </van-field>

      <van-dialog v-model="showPickerRoleFlag" class="detail-dialog" @confirm="onConfirmRole">
        <van-tree-select :items="columnsRole" :active-id.sync="activeIds" :main-active-index.sync="activeIndex" />
      </van-dialog>
      <div style="margin: 16px"></div>
    </van-form>
  </div>
</template>

<script>
import {
  listRole,
  getUserId,
  getDealerUser,
  addDealerUser,
  updateDealerUser,
  changeUserStatus,
  checkBinding,
  getRoleTypeList,
  checkDealerUserRoleWarning
} from '@/api/userCenter/dealer'
import { listContacts } from '@/api/userCenter/dealerContact'
import _ from 'lodash'

export default {
  name: 'DealerAccountInfo',
  data() {
    return {
      activeNames: [1],
      activeIds: [],
      activeIndex: '0',
      checkedRole: [],
      phonePattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      emailPattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      columnsRole: [],
      showPickerRoleFlag: false,
      form: {
        userName: '',
        nickName: '',
        phonenumber: '',
        email: '',
        status: true,
        roleTreeList: [],
        platformRoleNames: '',
        roleName: '',
        position: ''
      },
      title: '',
      roleLabel: '',
      userId: undefined,
      loading: false,
      roleIdClone: [],
      parentRole: [4],
      beforeEditInfo: {},
      roleTypeList: [],
      roleListLabel: [],
      choseDealerContact: {},
      contactInfo: {},
      dalerContactList: [],
      dalerContactListName: [],
      hasDealerContact: false,
      loadingDalerContactList: false,
      choseShowDealerContact: false,
      isDAoverdue: false
    }
  },
  computed: {
    isManage() {
      return decodeURIComponent(_.get(this.$route, 'query.isManage', false))
    },
    isCompanyManager() {
      return decodeURIComponent(_.get(this.$route, 'query.isCompanyManager', false))
    },
    loginUsername() {
      return this.$store.state.user.name
    }
  },
  async created() {
    this.loading = true
    //da合同是否过期
    this.isDAoverdue = this.$store.state.user.userInfo.isDealerExpire
    if (this.$route.params.dealerId != 'new') {
      this.title = '编辑账号'
      this.userId = this.$route.params.dealerId
      await this.getUserById()
    } else {
      this.title = '新增账号'
      this.form.roleName = '经销商子账号'
      this.form.subAccountFlag = '1'
      this.getUserId()
    }
    await this.getRoleTypeListFun()
  },
  methods: {
    handleInput(value) {
      this.form.nickName = value.replace(/\s+/g, '')
    },
    setPlatformRoleNames(treeList) {
      let platformRoleNames = []
      treeList.map(item => {
        item.roleList.map(role => {
          if (item.checkedRole.includes(role.roleId)) {
            platformRoleNames.push(role.roleName)
          }
        })
      })
      this.form.platformRoleNames = platformRoleNames.join(',')
      console.log(this.form.platformRoleNames)
    },
    onConfirmRole() {
      this.roleTypeList.map(item => {
        if (item.roleList.length > 0) {
          item.checkedRole = []
          item.roleList.map(role => {
            if (this.activeIds.includes(role.roleId)) {
              item.checkedRole = [...item.checkedRole, role.roleId]
            }
          })
        }
      })
      this.form.roleTreeList = this.roleTypeList
      this.setPlatformRoleNames(this.roleTypeList)
    },
    async getRoleList() {
      const data = await listRole()
      this.columnsRole = data.data
    },
    async getRoleTypeListFun() {
      console.log(this.form)
      let _isManage = this.form.subAccountFlag == '1' ? false : true
      console.log('_isManage', _isManage)
      await getRoleTypeList(_isManage === true ? 1 : 0).then(response => {
        this.roleTypeList = response.data
        this.roleTypeList = this.roleTypeList.map(item => {
          return {
            ...item,
            checkedRole: item.checkedRole || []
          }
        })
        this.form.roleTreeList.map(item => {
          this.roleTypeList.map(role => {
            if (role.systemName == item.systemName) {
              role.checkedRole = item.checkedRole
            }
          })
        })
        this.setPlatformRoleNames(this.roleTypeList)
        this.columnsRole = _.cloneDeep(this.roleTypeList).map(item => {
          return {
            text: item.systemName,
            children: item.roleList.map(item => {
              return {
                text: item.roleName,
                id: item.roleId,
                disabled: item.roleId == 3 || (item.isAdmin && this.isCompanyManager == 'true')
              }
            })
          }
        })
      })
    },
    getUserId() {
      getUserId().then(res => {
        this.loading = false
        this.$set(this.form, 'userName', res.data)
      })
    },
    async getUserById() {
      await getDealerUser(this.userId).then(res => {
        this.loading = false
        const data = res.data.sysUser
        this.form = data
        this.form.status = this.form.status == '0' ? (this.form.status = true) : (this.form.status = false)

        res.data.roleList.map(item => {
          this.roleTypeList.map(role => {
            if (role.systemName == item.systemName) {
              role.checkedRole = item.checkedRole
            }
          })
        })

        this.beforeEditInfo = JSON.parse(JSON.stringify(this.form))
      })
    },
    getTextValues(a, b) {
      var c = []
      b.forEach(function (roleId) {
        var obj = a.find(function (item) {
          return item.roleId === roleId
        })
        if (obj) {
          c.push(obj.roleName)
        }
      })
      return c
    },
    showRolePicker() {
      // if (this.title == '编辑账号') {
      //   return false
      // }
      this.activeIds = this.form.roleTreeList
        .map(item => {
          return item.checkedRole.map(role => {
            return role
          })
        })
        .flat()
      this.showPickerRoleFlag = true
    },
    onConfirmRoleId() {
      this.form.roleId = _.cloneDeep(this.roleIdClone)
      this.roleLabel = this.columnsRole
        .filter(item => this.form.roleId.includes(item.roleId))
        .map(filteredItem => filteredItem.roleName)
        .join('，')
      this.showPickerRoleFlag = false
    },
    goToBack() {
      this.$router.go(-1)
    },
    goToBackWithRefresh() {
      this.$router.push({
        name: 'UserCenter',
        params: {
          page: 'Account'
        }
      })
    },
    changeStatus(val) {
      let param = {
        userId: this.userId,
        dealerId: this.form.org.orgId
      }
      checkBinding(param).then(res => {
        let msgStr = null
        let text = val ? '启用' : '停用'
        let status = val ? '0' : '1'
        if (res.data.isBindFlag) {
          if (text === '停用') {
            msgStr =
              '该经销商账号已绑定过经销商联络人，如果停用该账号，会将已绑定的联络人进行解绑操作，请确认是否继续停用该账号？'
          } else {
            msgStr = '确认要' + text + ' ' + this.form.nickName + ' 这个账号？'
          }
        } else {
          msgStr = '确认要' + text + ' ' + this.form.nickName + ' 这个账号？'
        }
        this.$dialog
          .confirm({
            message: msgStr
          })
          .then(() => {
            changeUserStatus(this.userId, status).then(({ code, msg }) => {
              if (code != 200) {
                this.$toast.fail({
                  message: msg,
                  className: 'fail'
                })
                return
              }
              this.$toast.success('操作成功')
              this.form.status = val
            })
          })
          .catch(() => {})
      })
    },
    async onSubmit() {
      await this.$refs.form.validate().then(async () => {
        if (this.loading) {
          return
        }
        this.loading = true
        let requestParams = {
          userAccount: this.form.userName,
          phoneNumber: this.form.phonenumber,
          name: this.form.nickName,
          position: this.form.position,
          email: this.form.email,
          roleList: this.roleTypeList,
          userId: this.userId,
          contactId: this.contactInfo.contactId,
          contactGroups: this.contactInfo.contactGroups || []
        }
        if (this.$route.params.dealerId != 'new') {
          let disText = ''
          await checkDealerUserRoleWarning([
            {
              ...requestParams,
              roleTreeList: this.roleTypeList,
              processFlag: 'update'
            }
          ]).then(res => {
            disText = res.data
          })
          if (this.beforeEditInfo.email != this.form.email) {
            //修改邮箱时的特殊处理
            let param = {
              userId: this.userId,
              dealerId: this.form.org.orgId
            }
            checkBinding(param).then(res => {
              if (res.data.isBindFlag) {
                this.$dialog
                  .confirm({
                    message:
                      '当前账号已经绑定联络人，联络人的邮箱与当前账号邮箱不同, 请确认是否允许使用当前邮箱进行覆盖？'
                  })
                  .then(() => {
                    this.$dialog
                      .confirm({ message: disText + '是否保存更改?' })
                      .then(() => {
                        updateDealerUser(requestParams)
                          .then(({ code, msg }) => {
                            if (code != 200) {
                              this.$toast.fail({
                                message: msg,
                                className: 'fail'
                              })
                              return
                            }
                            this.$toast.success('保存成功')
                            this.goToBackWithRefresh()
                          })
                          .finally(() => {
                            this.loading = false
                          })
                      })
                      .catch(() => {
                        this.goToBackWithRefresh()
                        this.loading = false
                      })
                  })
                  .catch(() => {})
              } else {
                this.$dialog
                  .confirm({ message: disText + '是否保存更改?' })
                  .then(() => {
                    updateDealerUser(requestParams)
                      .then(({ code, msg }) => {
                        if (code != 200) {
                          this.$toast.fail({
                            message: msg,
                            className: 'fail'
                          })
                          return
                        }
                        this.$toast.success('保存成功')
                        this.goToBackWithRefresh()
                      })
                      .finally(() => {
                        this.loading = false
                      })
                  })
                  .catch(() => {
                    this.goToBackWithRefresh()
                    this.loading = false
                  })
              }
            })
          } else {
            this.$dialog
              .confirm({ message: disText + '是否保存更改?' })
              .then(() => {
                updateDealerUser(requestParams)
                  .then(({ code, msg }) => {
                    if (code != 200) {
                      this.$toast.fail({
                        message: msg,
                        className: 'fail'
                      })
                      return
                    }
                    this.$toast.success('保存成功')
                    this.goToBackWithRefresh()
                  })
                  .finally(() => {
                    this.loading = false
                  })
              })
              .catch(() => {
                this.goToBackWithRefresh()
                this.loading = false
              })
          }
        } else {
          addDealerUser(requestParams)
            .then(({ code, msg }) => {
              if (code != 200) {
                this.$toast.fail(msg)
                return
              }
              this.$toast.success('新建成功')
              this.goToBackWithRefresh()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
      // .catch(() => {
      //   // this.$toast.fail('提交失败')
      // })
    }
  }
}
</script>

<style lang="less" scoped>
.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}
/deep/.van-cell {
  border-bottom: 1px solid #ebedf0;
}
/deep/ .van-form {
  background: #fff;
}
/deep/ .van-toast--fail {
  width: 300px;
}
/deep/ .van-nav-bar__title {
  font-weight: bold !important;
}
/deep/ .van-field__label {
  color: #000;
}
.header-btn {
  color: #0b41cd;
}
.li-wrapper {
  width: 100%;
  margin-left: 130px;
  position: relative;
}
.system-name {
  font-size: 20px;
  font-weight: bold;
  color: #0b41cd;
  position: absolute;
  right: calc(100% + 5px);
  top: 50%;
  transform: translateY(-50%);
}
::v-deep {
  .detail-dialog {
    // padding-top: 20px;
  }
  .text-hide1 {
    /*单行文本溢出*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .border-bottom {
    border-bottom: 1px solid #0b41cd52;
    padding-bottom: 3px;
    margin-bottom: 3px;
  }
  .border-bottom:last-child {
    border-bottom: none;
  }
  .title {
    font-size: 16px;
    color: #0b41cd;
  }
  .role {
    font-size: 12px;
  }
  .role {
    .van-collapse-item__content {
      background: linear-gradient(180deg, rgba(233, 242, 253, 0.3) 0%, #e9f2fd 100%);
    }
  }
}
.dis {
  font-size: 12px;
  color: gray;
}
</style>
