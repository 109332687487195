<template>
  <div class="box">
    <div class="history-hot" v-if="isShowHistory">
      <h4>历史记录</h4>
      <van-icon name="delete" class="delete-icon" @click="clearFn" />
      <van-tag
        plain
        type="primary"
        v-for="(item, index) in historyKeywordList"
        :key="index"
        v-if="item"
        @click="goSearch(item)"
        >{{ item }}</van-tag
      >
    </div>
    <div class="hot-box">
      <h4>热门搜索</h4>
      <van-tag
        plain
        :type="item.is_hot ? 'danger' : 'primary'"
        v-for="(item, index) in hotKeywordList"
        :key="index"
        v-if="item.keyword"
        @click="goSearch(item.keyword)"
        >{{ item.keyword }}</van-tag
      >
    </div>
  </div>
</template>

<script>
import { Clearhistory } from '@/https/http'
export default {
  name: 'history-hot',
  data() {
    return {
      isShowHistory: 1
    }
  },
  props: ['historyKeywordList', 'hotKeywordList'],
  methods: {
    clearFn() {
      Clearhistory().then(res => {
        console.log(res)
        this.isShowHistory = 0
      })
    },
    goSearch(value) {
      this.$emit('goSearch', value)
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  font-size: 16px;

  span {
    margin-right: 3px;
  }
  .history-hot {
    margin-bottom: 10px;
    position: relative;
    .delete-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
</style>
