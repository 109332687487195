<template>
  <div class="list-bg">
    <van-nav-bar title="待办日历" left-arrow @click-left="goBack"></van-nav-bar>
    <van-row style="padding: 10px 10px">
      <van-col span="24">
        <vue-event-calendar :events="events" @month-changed="monthChange" @day-changed="dayChange">
          <template slot-scope="props">
            <div v-for="event in props.showEvents" :key="event.noticeId" class="event-item" @click="goDetail(event)">
              <div style="font-size: 16px; color: #262626; font-weight: bold">{{ event.title }}</div>
              <div style="font-size: 14px; color: #8c8c8c">{{ event.date }}</div>
            </div>
          </template>
        </vue-event-calendar>
      </van-col>
    </van-row>
    <van-row style="padding: 10px 10px">
      <van-col span="24">
        <div style="background: rgba(114, 144, 223, 1); min-height: 100px; padding: 10px 10px; border-radius: 8px">
          <div style="font-size: 22px; text-align: center; color: #ffffff; margin-bottom: 10px">{{ title }}</div>
          <div
            v-for="(item, index) in dayEvents"
            :key="index"
            style="background: #ffffff; border-radius: 8px; margin-bottom: 10px"
            @click="goDetail(item)"
          >
            <div style="padding: 5px 10px 5px 20px">
              <div style="font-size: 16px; color: #262626; font-weight: bold">{{ item.title }}</div>
              <div style="font-size: 14px; color: #8c8c8c">{{ item.date }}</div>
            </div>
          </div>
          <div
            style="font-size: 16px; text-align: center; color: #ffffff; padding-top: 10px"
            v-if="dayEvents.length === 0"
          >
            暂无数据
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { getCalendarData, getCalendarEvent } from '@/api/notice'
import dayjs from 'dayjs'

export default {
  name: 'Calendar',
  data() {
    return {
      events: [],
      startDate: null,
      endDate: null,
      dayEvents: [],
      title: '待办通知',
      initFlag: false
    }
  },
  async created() {
    this.initFlag = true
    this.$EventCalendar.toDate(dayjs(new Date()).format('YYYY/MM/DD'))
    this.startDate = dayjs(new Date()).startOf('month').format('YYYY-MM-DD')
    this.endDate = dayjs(new Date()).endOf('month').format('YYYY-MM-DD')
    await this.onLoad()
    this.getEvents()
  },
  methods: {
    onLoad() {
      let params = {
        startDate: this.startDate,
        endDate: this.endDate
      }
      this.events = []
      this.$loading.show()
      getCalendarData(params)
        .then(res => {
          this.$loading.hide()
          res.data.map(item => {
            item.noticeRemindList.map(event => {
              let obj = {
                date: dayjs(item.calendarDate).format('YYYY/MM/DD'),
                title: event.noticeTitle,
                noticeId: event.noticeReceiveId
              }
              this.events.push(obj)
            })
          })
        })
        .catch(err => {
          this.$loading.hide()
          console.log(err)
        })
    },
    getEvents() {
      getCalendarEvent({})
        .then(res => {
          res.data.map(item => {
            let obj = {
              date: dayjs(item.dueDate).format('YYYY/MM/DD'),
              title: item.noticeTitle,
              noticeId: item.noticeReceiveId
            }
            this.dayEvents.push(obj)
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    goBack() {
      this.$router.go(-1)
    },
    monthChange(month) {
      console.log(month)
      let dateStr = month.replace('年', '-').replace('月', '-')
      this.startDate = dayjs(dateStr).startOf('month').format('YYYY-MM-DD')
      this.endDate = dayjs(dateStr).endOf('month').format('YYYY-MM-DD')
      this.initFlag = false
      this.onLoad()
    },
    dayChange(day) {
      console.log(day)
      // this.startDate = dayjs(day.date).format('YYYY-MM-DD')
      // this.endDate = dayjs(day.date).format('YYYY-MM-DD')
      // this.onLoad()
      this.dayEvents = day.events
      if (this.initFlag) {
        this.title = '待办通知'
      } else {
        this.title = dayjs(day.date).format('YYYY-MM-DD') + '通知'
      }
      this.initFlag = false
    },
    goDetail(obj) {
      this.$router.push({
        path: '/notice/detail',
        query: { noticeId: obj.noticeId, from: 'NOTICE' }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.list-bg {
  min-height: 100vh;
  padding-bottom: 50px;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}

/deep/.cal-wrapper {
  font-size: 14px !important;
}

/deep/.cal-header {
  font-size: 14px !important;
}

/deep/.cal-body {
  font-size: 14px !important;
}

/deep/.weeks {
  font-size: 14px !important;
}

/deep/.dates {
  font-size: 14px !important;
}

/deep/.date-num {
  font-size: 14px !important;
}

/deep/.__vev_calendar-wrapper .events-wrapper {
  display: none;
}

/deep/.__vev_calendar-wrapper .events-wrapper .event-item .title {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
