<template>
  <div>
    <div class="list-bg" />
    <van-nav-bar title="学员信息" right-text="保存" left-arrow @click-left="goToBack">
      <template #right>
        <span class="header-btn" :class="loading ? 'btn-loading' : ''" @click="save">保存</span>
      </template>
    </van-nav-bar>
    <van-form @submit="onSubmit" ref="form">
      <van-row class="field-box">
        <van-field
          :maxlength="20"
          input-align="right"
          v-model="form.trainerName"
          name="trainerName"
          label="员工姓名"
          placeholder="请输入员工姓名"
          required
          :readonly="form.status == 0"
          :rules="[{ required: true, message: '员工姓名不能为空' }]"
        />
        <van-field
          :maxlength="defaultMaxLength"
          input-align="right"
          v-model="form.mobilePhone"
          name="mobilePhone"
          label="手机"
          placeholder="请输入手机号"
          required
          :rules="[{ required: true, message: '请输入正确的手机号码', pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/ }]"
        />
        <van-field
          :maxlength="defaultMaxLength"
          input-align="right"
          v-model="form.email"
          name="email"
          label="邮箱"
          placeholder="请输入邮箱"
          rows="1"
          autosize
          type="textarea"
          required
          :rules="[
            {
              required: true,
              message: '请输入正确的邮箱地址',
              pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            }
          ]"
        />
        <van-field
          :maxlength="defaultMaxLength"
          input-align="right"
          readonly
          required
          clickable
          rows="1"
          autosize
          type="textarea"
          name="functionList"
          :value="functionListLabel"
          label="员工职能"
          placeholder="请选择员工职能"
          @click="showPickerFunction = true"
          :rules="[{ required: true, message: '请选员工职能' }]"
        />
        <van-popup v-model="showPickerFunction" position="bottom">
          <van-picker
            show-toolbar
            :columns="sys_trainer_function"
            value-key="name"
            item-height="56px"
            @confirm="onConfirmFunctionList"
            @cancel="showPickerFunction = false"
          >
            <template #option="option">
              <div style="width: 100%">
                <van-checkbox-group v-model="functionListClone" style="display: inline-block; margin-left: 130px">
                  <van-checkbox :name="option.dictValue">{{ option.dictLabel }}</van-checkbox>
                </van-checkbox-group>
              </div>
            </template>
          </van-picker>
        </van-popup>
        <!-- <van-field :maxlength="defaultMaxLength"
            input-align="right"
            readonly
            clickable
            name="gender"
            :value="genderLabel"
            label="性别"
            placeholder="点击选择"
            required
            @click="showPickerGender = true"
            :rules="[{ required: true, message: '请选择性别' }]"
          />
          <van-popup v-model="showPickerGender" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictLabel"
              :columns="sys_user_sex"
              @confirm="onConfirmGender"
              @cancel="showPickerGender = false"
            />
          </van-popup>
          <van-field :maxlength="defaultMaxLength"
            input-align="right"
            v-model="form.age"
            name="age"
            label="年龄"
            placeholder="请输入年龄"
            required
            :rules="[{ required: true, message: '年龄不能为空' }]"
          />
          <van-field :maxlength="defaultMaxLength"
            input-align="right"
            readonly
            clickable
            name="entryTime"
            :value="form.entryTime"
            label="入职时间"
            placeholder="选择日期"
            required
            @click="showCalendar = true"
            :rules="[{ required: true, message: '请选择入职时间' }]"
          />
          <van-calendar v-model="showCalendar" @confirm="onConfirmDate" :min-date="minDate" /> -->
      </van-row>
      <!-- <van-row class="field-box">

          <van-field :maxlength="defaultMaxLength"
            input-align="right"
            v-model="form.wechatNickname"
            name="wechatNickname"
            label="微信昵称"
            placeholder="请输入微信昵称"
            required
            :rules="[{ required: true, message: '微信昵称不能为空' }]"
          />

          <van-field :maxlength="defaultMaxLength"
            input-align="right"
            v-model="form.telephone"
            name="telephone"
            label="座机"
            placeholder="请输入座机"
          />
        </van-row> -->
      <van-row class="field-box">
        <van-field
          :maxlength="defaultMaxLength"
          input-align="right"
          v-model="form.trainingAccount"
          name="trainingAccount"
          label="培训账号"
          placeholder="-"
          readonly
        />
        <van-field
          :maxlength="defaultMaxLength"
          input-align="right"
          v-model="form.rejectionReason"
          name="rejectionReason"
          label="驳回原因"
          placeholder="-"
          readonly
          rows="1"
          autosize
          type="textarea"
        />
        <van-field
          :maxlength="defaultMaxLength"
          input-align="right"
          v-model="form.employeeMemo"
          name="employeeMemo"
          label="员工信息备注"
          placeholder="请输入员工信息备注"
          rows="1"
          autosize
          type="textarea"
        />
      </van-row>
      <van-row class="field-box">
        <!-- <van-field :maxlength="defaultMaxLength"
            input-align="right"
            readonly
            clickable
            name="education"
            :value="educationLabel"
            label="最高学历"
            placeholder="请选择最高学历"
            required
            @click="showPickerEducation = true"
            :rules="[{ required: true, message: '请选择最高学历' }]"
          />
          <van-popup v-model="showPickerEducation" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictLabel"
              :columns="sys_trainer_education"
              @confirm="onConfirmEducation"
              @cancel="showPickerEducation = false"
            />
          </van-popup>
          <van-field :maxlength="defaultMaxLength"
            input-align="right"
            v-model="form.major"
            name="major"
            label="专业"
            placeholder="请输入专业"
            required
            :rules="[{ required: true, message: '请填写用户名' }]"
          /> -->
        <van-cell title="培训联络人姓名" :value="form.trainingContactName" />
        <van-cell title="培训联络人手机" :value="form.trainingContactPhone | mobileFormat" />
        <van-cell title="培训联络人邮箱" :value="form.trainingContactEmail" />
        <!-- <van-field :maxlength="defaultMaxLength"
            input-align="right"
            v-model=""
            name="trainingContactName"
            label="培训联络人姓名"
            readonly
          /> -->
        <!-- <van-field :maxlength="defaultMaxLength"
            input-align="right"
            v-model="form.trainingContactPhone"
            name="trainingContact"
            label="培训联络人手机"
            readonly
          />
          <van-field :maxlength="defaultMaxLength"
            input-align="right"
            v-model="form.trainingContactEmail"
            name="trainingContactEmail"
            label="培训联络人邮箱"
            readonly
          /> -->
      </van-row>
    </van-form>
  </div>
</template>

<script>
import { getDicts } from '@/api/dict/data'
import { addTrainers, updateTrainers, getTrainContactInformation } from '@/api/userCenter/students'
import _ from 'lodash'
export default {
  name: 'StudentsDetail',
  data() {
    return {
      formDicts: [
        { key: 'trainerName', type: 'input', name: '员工姓名' },
        { key: 'mobilePhone', type: 'input', name: '手机' },
        { key: 'email', type: 'input', name: '邮箱' },
        { key: 'functionList', type: 'select', name: '员工职能' },
        { key: 'employeeMemo', type: 'input', name: '员工信息备注' }
      ],
      form: {
        gender: '',
        education: '',
        functionList: []
      },
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2010, 0, 31),
      showCalendar: false,
      showPickerGender: false,
      showPickerEducation: false,
      showPickerFunction: false,
      sys_trainer_function: [],
      gender: '',
      education: '',
      functionListClone: [],
      loading: false
    }
  },
  computed: {
    functionListLabel: function () {
      return this.sys_trainer_function
        .filter(itemA => this.form.functionList.includes(itemA.dictValue.toString()))
        .map(filteredItem => filteredItem.dictLabel)
        .join('，')
    }
  },
  created() {
    if (this.$route.query.trainerId) {
      this.form = this.$route.query
      this.formClone = _.cloneDeep(this.form)
      this.functionListClone = _.cloneDeep(this.form.functionList)
    } else {
      this.form = { ...this.form, ...this.$route.query }
    }

    getDicts('sys_trainer_function').then(({ data }) => {
      this.sys_trainer_function = data
    })
  },
  methods: {
    // getTrainingContact() {
    //   this.loading = true
    //   const dealerId = { dealerId: this.form.dealerId }
    //   getTrainContactInformation(dealerId)
    //     .then(response => {
    //       if (response.code === 200) {
    //         this.form = { ...this.form, ...response.data }
    //       }
    //       this.loading = false
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       this.loading = false
    //     })
    // },
    save() {
      this.$refs.form.submit()
    },
    onSubmit(values) {
      // if (!this.form.trainerId && !this.form.trainingContactId) {
      //   this.$toast.fail('无培训联络人信息')
      //   return
      // }
      // this.$dialog
      //   .alert({
      //     title: '法规信息提醒',
      //     message: `收集学员信息均经过学员本人同意，收集学员信息均经过学员本人同意。`,
      //     showCancelButton: true
      //   })
      //   .then(() => {
      if (this.loading) {
        return
      }
      this.loading = true
      console.log('submit', values, this.form)
      const trainerId = this.$route.query.trainerId
      if (trainerId) {
        this.compareForm()
        updateTrainers(this.form)
          .then(response => {
            if (response.code === 200) {
              this.$toast.success('修改成功')
              this.goToBackWithRefresh()
            } else {
              this.$toast.fail(response.msg)
            }
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else {
        addTrainers({ ...this.form, dealerId: this.$route.query.dealerId })
          .then(response => {
            if (response.code === 200) {
              this.$toast.success('新增成功')
              this.goToBackWithRefresh()
            } else {
              this.$toast.fail(response.msg)
            }
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
      // })
      // .catch(() => {})
    },
    compareForm() {
      this.form.trainerLog = []
      const formOld = this.formClone
      const formNew = this.form
      this.formDicts.forEach(ele => {
        if (ele.type === 'input') {
          if (formOld[ele.key] !== formNew[ele.key]) {
            this.form.trainerLog.push({
              changeColumn: ele.key,
              oldValue: formOld[ele.key],
              newValue: formNew[ele.key]
            })
          }
        } else if (ele.type === 'select') {
          if (formOld[ele.key].length !== formNew[ele.key].length) {
            this.form.trainerLog.push({
              changeColumn: ele.key,
              oldValue: formOld[ele.key]?.join(','),
              newValue: formNew[ele.key]?.join(',')
            })
          } else if (formOld[ele.key].sort().toString() !== formNew[ele.key].sort().toString()) {
            this.form.trainerLog.push({
              changeColumn: ele.key,
              oldValue: formOld[ele.key]?.join(','),
              newValue: formNew[ele.key]?.join(',')
            })
          }
        }
      })
      console.log(this.form)
    },
    goToBackWithRefresh() {
      this.$router.push({
        name: 'UserCenter',
        params: {
          page: 'ST'
        }
      })
    },
    goToBack() {
      this.$router.go(-1)
    },
    onConfirmDate(date) {
      let year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate() // 获取当前日(1-31)
      if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0
      this.form.entryTime = `${year}-${month}-${strDate}`
      this.showCalendar = false
    },
    onConfirmGender(row) {
      console.log(row)
      this.gender = row.dictLabel
      this.form.gender = row.dictValue
      this.showPickerGender = false
    },
    onConfirmEducation(row) {
      console.log(row)
      this.education = row.dictLabel
      this.form.education = row.dictValue
      this.showPickerEducation = false
    },
    onConfirmFunctionList() {
      this.form.functionList = _.cloneDeep(this.functionListClone)
      console.log(this.form)
      this.showPickerFunction = false
    }
  }
}
</script>

<style lang="less" scoped>
.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}
.header {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  .head-arrow {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    left: 16px;
  }
  .head-title {
    font-weight: 600;
  }
  .head-btn {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    right: 16px;
    font-size: 16px;
    color: #0b41cd;
  }
}
.field-box {
  background-color: #fff;
  margin-bottom: 8px;
}
/deep/.van-cell {
  border-bottom: 1px solid #ebedf0;
}
/deep/.van-cell .van-cell__title {
  flex: none;
  width: 100px;
  color: #323233;
  margin-right: 12px;
}
/deep/.van-cell .van-cell__value {
  color: #323233;
}
/deep/.van-field__error-message {
  text-align: right;
}
/deep/.van-field__label {
  width: 100px;
}
// /deep/ .van-toast--fail {
//   width: 300px;
// }
.header-btn {
  color: #0b41cd;
}
</style>
