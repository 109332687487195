import request from '@/utils/request'

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/user/dict/data/type/' + dictType,
    method: 'get'
  })
}
// 根据参数键名查询参数值
export function getConfigKey(configKey) {
  return request({
    url: '/user/config/configKey/' + configKey,
    method: 'get'
  })
}
