<template>
  <div class="list-bg">
    <van-tabs v-model="active" class="tab-new" @change="tabChange">
      <!--      <van-tab title="公告" name="ANNOUNCEMENT">-->
      <!--        <van-row style="height: 50px; line-height: 50px; text-align: right; padding-right: 20px">-->
      <!--          <van-col span="24" style="color: #0b41cd; font-size: 14px" @click="filterData"-->
      <!--            ><span>筛选</span><van-icon name="filter-o" color="#0B41CD"-->
      <!--          /></van-col>-->
      <!--        </van-row>-->
      <!--        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
      <!--          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">-->
      <!--            <van-cell v-for="item in list" :key="item.id" @click="showDetail(item.noticeId)">-->
      <!--              <van-row style="height: 50px; border-bottom: 1px solid #dddddd; padding: 0px 0px 15px 0px">-->
      <!--                <van-col span="2">-->
      <!--                  <div v-if="item.readStatus === 'N'"><i class="dotClass" style="background-color: red"></i></div>-->
      <!--                  <div v-if="item.urgentFlag === 'Y'"><van-icon name="info-o" color="red" /></div>-->
      <!--                </van-col>-->
      <!--                <van-col span="22">-->
      <!--                  <div style="display: flex; height: 30px">-->
      <!--                    <div class="notice-type">-->
      <!--                      {{ noticeTypeLabel(item.noticeType) }}-->
      <!--                    </div>-->
      <!--                    <div style="flex-grow: 1; color: #595959; text-align: right">-->
      <!--                      {{ item.sendTime | parseTime }}-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                  <div class="notice-title">-->
      <!--                    {{ item.noticeTitle }}-->
      <!--                    <img :src="require('@/assets/images/attachment.svg')" v-if="item.attachmentFlag === 'Y'" />-->
      <!--                  </div>-->
      <!--                </van-col>-->
      <!--              </van-row>-->
      <!--            </van-cell>-->
      <!--          </van-list>-->
      <!--        </van-pull-refresh>-->
      <!--      </van-tab>-->
      <van-tab title="通知" name="NOTICE">
        <van-row style="height: 50px; line-height: 50px; padding-right: 20px">
          <van-col span="12" style="color: #969799; font-size: 14px; text-align: left; padding-left: 20px">
            <span>共{{ total }}条数据</span>
          </van-col>
          <van-col span="12" style="color: #0b41cd; font-size: 14px; text-align: right" @click="filterData">
            <span>筛选</span><van-icon name="filter-o" color="#0B41CD" />
          </van-col>
        </van-row>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <van-cell v-for="item in list" :key="item.id" @click="showDetail(item.noticeReceiveId)">
              <van-row style="border-bottom: 1px solid #dddddd; padding: 0px 0px 10px 0px">
                <van-col span="2">
                  <div v-if="item.readStatus === 'N'"><i class="dotClass" style="background-color: red"></i></div>
                  <div v-if="item.urgentFlag === 'Y'"><van-icon name="info-o" color="red" /></div>
                </van-col>
                <van-col span="22">
                  <div class="notice-title">
                    {{ item.noticeTitle }}
                    <img :src="require('@/assets/images/attachment.svg')" v-if="item.attachmentFlag === 'Y'" />
                  </div>
                  <div style="display: flex; height: 30px; line-height: 30px">
                    <div class="notice-type">
                      {{ noticeTypeLabel(item.noticeType) }}
                    </div>
                    <div style="flex-grow: 1; color: #8c8c8c; text-align: right; font-size: 12px">
                      {{ item.sendTime | noticeDateFormat }}
                    </div>
                  </div>
                </van-col>
              </van-row>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
    <van-popup v-model="show" closeable round position="bottom" style="min-height: 450px">
      <van-row style="padding: 15px 10px 10px 10px; text-align: center">
        <van-col span="24">
          <div style="font-size: 16px; font-weight: bold">筛选</div>
        </van-col>
      </van-row>
      <van-row style="padding: 5px 10px">
        <van-col span="24">
          <div style="font-size: 14px; color: #595959">通知类型</div>
        </van-col>
      </van-row>
      <van-row gutter="20" style="padding: 5px 10px; border-bottom: 1px solid #dddddd">
        <van-col v-for="(item, index) in options" :key="index" style="padding-left: 5px; padding-right: 5px">
          <div :class="item.checked ? 'divActive' : 'divNormal'" @click="divClick(index)">{{ item.dictLabel }}</div>
        </van-col>
      </van-row>
      <van-row style="padding: 5px 10px; margin-top: 10px">
        <van-col span="24">
          <div style="font-size: 14px; color: #595959">发布时间</div>
        </van-col>
      </van-row>
      <van-row gutter="20" style="padding: 5px 10px; border-bottom: 1px solid #dddddd">
        <van-col span="6" v-for="(item, index) in options2" :key="index">
          <div :class="item.checked ? 'divActive' : 'divNormal'" @click="divClick2(item, index)">{{ item.label }}</div>
        </van-col>
      </van-row>
      <van-row gutter="20" style="padding: 5px 10px; border-bottom: 1px solid #dddddd">
        <van-col span="11">
          <van-field
            :maxlength="defaultMaxLength"
            readonly
            clickable
            name="datetimePicker"
            v-model="sendStartTime"
            placeholder="选择起始时间"
            input-align="center"
            @click="showPicker = true"
          />
        </van-col>
        <van-col span="2"><div style="font-size: 18px; height: 38px; line-height: 38px">-</div></van-col>
        <van-col span="11">
          <van-field
            :maxlength="defaultMaxLength"
            readonly
            clickable
            name="datetimePicker"
            v-model="sendEndTime"
            placeholder="选择终止时间"
            input-align="center"
            @click="showPicker2 = true"
          />
        </van-col>
      </van-row>
      <van-row style="padding: 5px 10px; border-bottom: 1px solid #dddddd">
        <van-col span="24">
          <van-checkbox v-model="readStatus" shape="square" icon-size="18px" style="font-size: 14px; color: #595959"
            >只显示未读通知</van-checkbox
          >
        </van-col>
      </van-row>
      <van-row gutter="20" style="padding: 5px 10px; text-align: center">
        <van-button type="default" style="width: 80px" @click="show = false">取消</van-button>
        <van-button type="default" style="width: 80px; margin-left: 10px" @click="reset">重置</van-button>
        <van-button type="info" style="width: 80px; margin-left: 10px" @click="filterSearch">确定</van-button>
      </van-row>
    </van-popup>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        title="选择日期"
        v-model="currentDate"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showPicker2" position="bottom">
      <van-datetime-picker
        type="date"
        title="选择日期"
        v-model="currentDate2"
        @confirm="onConfirm2"
        @cancel="showPicker2 = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { receiveNoticeList } from '@/api/notice'
import { getDicts } from '@/api/dict/data'
import dayjs from 'dayjs'

export default {
  name: 'Notice',
  data() {
    return {
      active: 'NOTICE',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      timer: null,
      noticeList: [],
      options: [],
      options2: [
        {
          value: 1,
          label: '近一个月',
          checked: false
        },
        {
          value: 2,
          label: '近半年',
          checked: false
        },
        {
          value: 3,
          label: '近一年',
          checked: false
        },
        {
          value: 4,
          label: '近两年',
          checked: false
        }
      ],
      show: false,
      sendStartTime: null,
      sendEndTime: null,
      showPicker: false,
      showPicker2: false,
      notice_type: [],
      readStatus: undefined,
      currentDate: new Date(),
      currentDate2: new Date()
    }
  },
  filters: {
    parseTime(time) {
      return dayjs(time).format('YYYY-MM-DD')
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromPath = from.path
      if (from.path === '/notice/detail') {
        vm.active = from.query.from
      } else {
        vm.active = 'NOTICE'
      }
    })
  },
  created() {
    if (this.$route.query?.readStatus) {
      this.readStatus = true
    }
    getDicts('notice_type').then(({ data }) => {
      this.notice_type = data
      this.options = data
      this.options.map(item => {
        this.$set(item, 'checked', false)
      })
    })
    // this.getList()
  },
  methods: {
    onLoad() {
      this.loading = true
      // 设置定时器做节流处理，避免滚动时频繁触发加载事件
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.queryParams.pageNum++
          this.getList()
          // 清除定时器，释放内存
          this.timer = null
        }, 1000)
      }
    },
    getList() {
      let arr = []
      this.options.forEach(item => {
        if (item.checked) {
          arr.push(item.dictValue)
        }
      })
      this.options2.forEach(item => {
        if (item.checked) {
          if (item.value === 1) {
            this.sendStartTime = dayjs(new Date()).subtract(1, 'month').format('YYYY-MM-DD')
            this.sendEndTime = dayjs(new Date()).format('YYYY-MM-DD')
          } else if (item.value === 2) {
            this.sendStartTime = dayjs(new Date()).subtract(6, 'month').format('YYYY-MM-DD')
            this.sendEndTime = dayjs(new Date()).format('YYYY-MM-DD')
          } else if (item.value === 3) {
            this.sendStartTime = dayjs(new Date()).subtract(1, 'year').format('YYYY-MM-DD')
            this.sendEndTime = dayjs(new Date()).format('YYYY-MM-DD')
          } else if (item.value === 4) {
            this.sendStartTime = dayjs(new Date()).subtract(2, 'year').format('YYYY-MM-DD')
            this.sendEndTime = dayjs(new Date()).format('YYYY-MM-DD')
          }
        }
      })
      let params = {
        ...this.queryParams,
        stationType: this.active,
        noticeTypeStr: arr.length > 0 ? arr.join(',') : '',
        sendStartTime: this.sendStartTime,
        sendEndTime: this.sendEndTime,
        readStatus: this.readStatus ? 'N' : undefined
      }
      receiveNoticeList(params)
        .then(res => {
          this.loading = false
          this.refreshing = false
          this.total = res.total
          this.list = [
            ...this.list,
            ...res.rows.map(item => {
              return {
                ...item
              }
            })
          ]
          if (this.list.length >= this.total) {
            this.finished = true
          }
        })
        .catch(err => {
          this.loading = false
          this.refreshing = false
          console.log(err)
        })
    },
    noticeTypeLabel(data) {
      return this.notice_type.filter(itemA => data?.toString() === itemA.dictValue.toString())[0]?.dictLabel
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams = {
        pageNum: 0,
        pageSize: 10
      }
      this.total = 0
      this.list = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    tabChange() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams = {
        pageNum: 0,
        pageSize: 10
      }
      this.total = 0
      this.list = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    onCancel() {},
    filterData() {
      this.show = true
    },
    divClick(index) {
      this.options[index].checked = !this.options[index].checked
    },
    divClick2(obj, index) {
      this.options2[index].checked = !this.options2[index].checked
      let boo = this.options2[index].checked
      this.options2.forEach(item => {
        if (obj.value !== item.value && boo) {
          item.checked = false
        }
      })
    },
    onConfirm(val) {
      this.sendStartTime = dayjs(val).format('YYYY-MM-DD')
      this.showPicker = false
    },
    onConfirm2(val) {
      this.sendEndTime = dayjs(val).format('YYYY-MM-DD')
      this.showPicker2 = false
    },
    showDetail(noticeId) {
      this.$router.push({
        path: '/notice/detail',
        query: { noticeId: noticeId, from: this.active === 'ANNOUNCEMENT' ? 'ANNOUNCEMENT' : 'NOTICE' }
      })
    },
    reset() {
      this.sendStartTime = null
      this.sendEndTime = null
      this.options.forEach(item => {
        item.checked = false
      })
      this.options2.forEach(item => {
        item.checked = false
      })
      this.readStatus = undefined
    },
    filterSearch() {
      this.show = false
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams = {
        pageNum: 0,
        pageSize: 10
      }
      this.total = 0
      this.list = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    }
  }
}
</script>

<style lang="less" scoped>
.list-bg {
  min-height: 100vh;
  padding-bottom: 50px;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}

.dotClass {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: block;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.notice-type {
  //background-color: #ced9f5;
  color: #8c8c8c;
  font-size: 12px;
  padding: 0px 0px;
  border-radius: 4px;
  min-width: 50px;
}

.notice-title {
  //padding: 0px 10px;
  font-size: 16px;
  //overflow: hidden;
  //text-overflow: ellipsis;
  //white-space: nowrap;
  color: #262626;
  font-weight: bold;
}

.divNormal {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #8c8c8c;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  padding: 0 5px;
}

.divActive {
  border-radius: 4px;
  background-color: #0b41cd;
  border: 1px solid #0b41cd;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  padding: 0 5px;
}

/deep/.van-tabs__nav {
  //background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
  background-color: rgba(255, 0, 0, 0);
}

/deep/.van-popup {
  padding-bottom: 20px;
}

/deep/.van-tabs__nav {
  background: transparent;
}
/deep/.van-tabs--line .van-tabs__wrap {
  width: 30%;
  padding-top: 17px;
}
/deep/.van-tab__text--ellipsis {
  line-height: 1.75;
}
/deep/.van-tab {
  font-size: 20px;
  &.van-tab--active {
    font-size: 24px;
    span {
      font-weight: bold;
    }
  }
}
</style>
