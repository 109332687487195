<template>
  <div>
    <router-view v-if="$route.path === '/dataCenter/instrument/changeLog'"></router-view>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
      :is-need-operate="true"
      :more-column="false"
      :more-search="false"
      :isCardDisabled="false"
      :isCheckbox="false"
      :hide-btn-with-empty-data="true"
      @on-search="handleData"
    >
      <template #column-item="scope">
        <div>
          <van-row class="item">
            <van-col :span="20">
              <div class="name">
                <van-icon name="info-o" color="red" size="14" v-if="scope.row['isExpire']" />
                {{ scope.row['equipmentNum'] }}<br />{{ scope.row['instrLocName'] }}
              </div>
            </van-col>
            <van-col :span="4" class="year">
              <div>{{ scope.row['instrLocKey'] }}</div>
            </van-col>
          </van-row>
        </div>
      </template>
      <template #column-item-form="scope">
        <template v-if="scope.item.prop === 'moduleStatusSupplement'">
          <van-cell :title="scope.item.label" label-class="titleClass" style="font-size: 12px">
            <span>{{ scope.row[scope.item.prop] || '-' }}</span>
            <van-popover v-model="scope.row.showPopover" trigger="click" theme="dark">
              <div class="instrumentPopover">
                <span style="font-size: 12px">{{ scope.row.moduleStatusDescription }}</span>
              </div>
              <template #reference>
                <van-icon name="info-o" color="red" size="14" v-if="scope.row.moduleStatusDescription" />
              </template>
            </van-popover>
          </van-cell>
        </template>
        <template v-else>
          <van-cell
            :title="scope.item.label"
            :value="scope.row[scope.item.prop]"
            label-class="titleClass"
            style="font-size: 12px"
          ></van-cell>
        </template>
      </template>
      <template slot="table-btn" slot-scope="data">
        <!--        <span @click="setMoreColumn(data.row)">{{ data.row.isShowMoreColumn ? '收起' : '展示' }}更多字段</span>-->
        <!--        <span style="padding: 0 5px">|</span>-->
        <span @click="viewChangeLog(data.row)">变更日志</span>
      </template>
    </form-table>
  </div>
</template>

<script>
import { getDicts } from '@/api/dict/data'
import { getChangeLogVad } from '@/api/dataCenter/customerService/instrument'
import { queryUrl, searchFormData, tableOptions } from './config'

export default {
  name: 'VadInstrumentList',
  data() {
    return {
      btnData: [{ text: '导出' }],
      queryUrl: queryUrl,
      searchFormData: searchFormData,
      tableOptions: tableOptions,
      headerTitle: '授权服务仪器',
      changeLogOptions: [
        {
          key: 'instrLocKey',
          label: '终端代码'
        },
        {
          key: 'instrLocName',
          label: '终端名称'
        },
        {
          key: 'serialNumber',
          label: '仪器序列号'
        },
        {
          key: 'moduleStatus',
          label: '仪器使用状态'
        }
      ]
    }
  },
  created() {
    getDicts('sys_instrument_type').then(({ data }) => {
      this.searchFormData[2].options = data
    })
  },
  methods: {
    onSelect(_, searchParams, selectedData) {
      const selectedFileIdList = [...selectedData].map(item => {
        return item.equipmentNum
      })
      const requestParams = {
        ...searchParams,
        ids: selectedFileIdList.join(','),
        pageNum: undefined,
        pageSize: undefined
      }
      this.download('/bff-dp-mobile/instrument-vad/data/export', {}, `授权服务仪器_${new Date().getTime()}.xlsx`, {
        method: 'get',
        params: requestParams
      })
    },
    handleData(data) {
      data.forEach(item => {
        this.$set(item, 'showPopover', false)
      })
    },
    viewChangeLog(data) {
      if (!data.isHaveLog) {
        this.$toast.fail('暂无变更日志')
        return false
      }
      let params = {
        equipmentNum: data.equipmentNum
      }
      getChangeLogVad(params)
        .then(res => {
          if (!res.data) {
            this.$toast.fail('暂无变更日志')
            return false
          } else {
            let obj = res.data
            let arr = []
            this.changeLogOptions.forEach(item => {
              if (obj[item.key] !== data[item.key]) {
                arr.push({
                  fieldName: item.label,
                  beforeChangeValue: obj[item.key],
                  afterChangeValue: data[item.key],
                  updateTime: obj.updateTime
                })
              }
            })
            if (obj.oldMasterEngineer1 !== obj.masterEngineer1) {
              arr.push({
                fieldName: 'Primary Engineer<br/>Roche User Login',
                beforeChangeValue: obj.oldMasterEngineer1,
                afterChangeValue: obj.masterEngineer1,
                updateTime: obj.updateTime
              })
            }
            if (obj.oldMasterEngineer2 !== obj.masterEngineer2) {
              arr.push({
                fieldName: 'Backup Engineer<br/>Roche User Login',
                beforeChangeValue: obj.oldMasterEngineer2,
                afterChangeValue: obj.masterEngineer2,
                updateTime: obj.updateTime
              })
            }
            if (obj.oldApplicationEngineer1 !== obj.applicationEngineer1) {
              arr.push({
                fieldName: 'Application Specialist<br/>Roche User Login',
                beforeChangeValue: obj.oldApplicationEngineer1,
                afterChangeValue: obj.applicationEngineer1,
                updateTime: obj.updateTime
              })
            }
            if (obj.oldApplicationEngineer2 !== obj.applicationEngineer2) {
              arr.push({
                fieldName: 'Application Specialist Backup<br/>Roche User Login',
                beforeChangeValue: obj.oldApplicationEngineer2,
                afterChangeValue: obj.applicationEngineer2,
                updateTime: obj.updateTime
              })
            }
            this.$router.push({
              path: '/dataCenter/customerService/instrument/changeLog',
              query: { data: JSON.stringify(arr) }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    setMoreColumn(data) {
      const isShowMoreColumn = data.isShowMoreColumn ? !data.isShowMoreColumn : true
      this.$set(data, 'isShowMoreColumn', isShowMoreColumn)
    }
  }
}
</script>

<style lang="css" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    color: #0b41cd;
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .year {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(20px) translateY(10px);
  }
}

/deep/ .titleClass {
  color: #8c8c8c !important;
  font-size: 12px;
}

.instrumentPopover {
  line-height: 20px !important;
  padding: 5px 10px !important;
}
</style>
