<template>
  <div>
    <van-dialog v-model="cibDetailShow" class="detail-dialog">
      <p class="margin-bottom">公司负责人变更详情</p>

      <div class="sub-title">
        <span class="label gray">审批状态:</span>
        <span class="content">
          {{ state || '-' }}
        </span>
      </div>
      <div class="sub-title" v-if="rejectionReason">
        <span class="label gray">审批备注:</span>
        <span class="content">
          {{ rejectionReason || '-' }}
        </span>
      </div>
      <div class="sub-title" v-if="comment">
        <span class="label gray">撤销备注:</span>
        <span class="content">
          {{ comment || '-' }}
        </span>
      </div>
      <div class="sub-title before">
        <span class="label gray">原有姓名:</span>
        <span class="content">
          {{ form.beforeNickName }}
        </span>
      </div>
      <div class="sub-title after">
        <span class="label gray">变更姓名:</span>
        <span class="content" :class="{ org: form.beforeNickName != form.nickName }">
          {{ form.nickName }}
        </span>
      </div>
      <div class="sub-title before">
        <span class="label gray">原有手机:</span>
        <span class="content">
          {{ form.beforePhoneNumber }}
        </span>
      </div>
      <div class="sub-title after">
        <span class="label gray">变更手机:</span>
        <span class="content" :class="{ org: form.phoneNumber != form.beforePhoneNumber }">
          {{ form.phoneNumber }}
        </span>
      </div>
      <div class="sub-title before">
        <span class="label gray">原有邮箱:</span>
        <span class="content">
          {{ form.beforeEmail }}
        </span>
      </div>
      <div class="sub-title after">
        <span class="label gray">变更邮箱:</span>
        <span class="content" :class="{ org: form.email != form.beforeEmail }">
          {{ form.email }}
        </span>
      </div>
      <div class="sub-title before">
        <span class="label gray">原有职位:</span>
        <span class="content">
          {{ form.beforePosition }}
        </span>
      </div>
      <div class="sub-title after">
        <span class="label gray">变更职位:</span>
        <span class="content" :class="{ org: form.position != form.beforePosition }">
          {{ form.position }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">申请描述:</span>
        <span class="content">
          {{ form.appealDescription || '-' }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">上传附件:</span>
        <span
          class="content"
          style="color: rgb(12, 65, 205)"
          v-if="form.fileId"
          @click="showDetail(form.fileId, form.fileName)"
        >
          {{ form.fileName }}
        </span>
        <span class="content" v-else>-</span>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { previewFile } from '@/api/formTable'
import { getDicts } from '@/api/dict/data'
import { getAppealDetailByTaskIdForManagerInfo } from '@/api/userCenter/dealer'
export default {
  name: 'Details',
  dicts: [],
  components: {},
  data() {
    return {
      form: {},
      cibDetailShow: false,
      rejectionReason: '',
      state: '',
      comment: '',
      appealDescription: ''
    }
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    init(detailsInfo) {
      this.cibDetailShow = true
      this.form = detailsInfo
      this.state = ''
      this.rejectionReason = ''
      this.comment = ''
      this.getDetail(detailsInfo)
    },
    async getDetail(row) {
      getAppealDetailByTaskIdForManagerInfo(row.appealTaskId, row.processInstanceId)
        .then(res => {
          if (res.code == 200 && res.data) {
            this.state = res.data.statusName
            this.rejectionReason = res.data.rejectionReason
            this.comment = res.data.comment
            this.form = res.data
          }
        })
        .catch(error => {})
    },
    showDetail(fileId, fileName) {
      if (fileId === null || fileId === '' || fileName === null || fileName === '') {
        return
      }
      let params = {
        fileId: fileId,
        watermarkFlag: true
      }
      previewFile(params, fileName)
    }
  }
}
</script>
<style scoped lang="less">
.sub-title {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: 14px;
  padding: 10px 5px;
  color: rgba(20, 20, 20, 1);
  .label {
    padding-right: 10px;
    &.gray {
      color: #8c8c8c;
      font-size: 12px;
    }
    &.red {
      color: red;
      font-size: 12px;
    }
  }
  .content {
    flex: 1;
    display: flex;
    padding-right: 0;
    align-items: flex-start;
    text-align: right;
    justify-content: flex-end;
    > .text {
      flex: 1;
      color: rgba(9, 52, 164, 1);
      display: flex;
      justify-content: flex-end;
    }
  }
}
.detail-dialog {
  padding: 20px 15px 0;
  .van-row {
    width: 80%;
    margin: 0 auto 10px;
    border-bottom: 1px solid rgba(235, 237, 240, 1);
    &:last-child {
      margin-bottom: 0;
    }
    .van-cell {
      padding: 0 10px;
    }
  }
}
.margin-bottom {
  margin-bottom: 10px;
}
.before {
  background: #f5f5f5;
}
.after {
  background: #f0f4fd;
}
.line {
  width: 80%;
  background: #000;
  height: 1px;
}
::v-deep {
  .van-dialog__content {
    max-height: 70vh;
    overflow: scroll;
  }
}
.org {
  color: #ff7016;
}
</style>
