export const queryUrl = '/bff-dp-mobile/instrument-vad/data/list'

export const searchFormData = [
  {
    props: 'contractNumber',
    label: '合同号',
    type: 'input',
    isMore: true
  },
  {
    props: 'equipmentNum',
    label: '设备号',
    type: 'input'
  },
  {
    props: 'materialNumber',
    label: '物料号',
    type: 'input',
    isMore: true
  },
  {
    props: 'materialDesc',
    label: '物料名称',
    type: 'input'
  },
  {
    props: 'instrLocKey',
    label: '终端代码',
    type: 'input',
    isMore: true
  },
  {
    props: 'instrLocName',
    label: '终端名称',
    type: 'input'
  },
  {
    props: 'provinceNameZh',
    label: '终端省份',
    type: 'input',
    isMore: true
  },
  {
    props: 'iBaseCate',
    label: '仪器类型',
    type: 'DictSelect',
    dictCode: 'sys_instrument_type',
    isMore: true
  },
  {
    props: 'serialNumber',
    label: '仪器序列号',
    type: 'input'
  },
  {
    props: 'contractMaxEndYear',
    label: '服务合同到期年份',
    type: 'date',
    placeholder: '请选择年份',
    valueFormat: 'yyyy',
    dateFormat: 'yyyy',
    dateType: 'year',
    isMore: true
  },
  {
    props: 'masterEngineer1',
    label: 'Primary Engineer Roche User Login',
    type: 'input'
  },
  {
    props: 'masterEngineer2',
    label: 'Backup Engineer Roche User Login',
    type: 'input'
  },
  {
    props: 'applicationEngineer1',
    label: 'Application Specialist Roche User Login',
    type: 'input'
  },
  {
    props: 'applicationEngineer2',
    label: 'Application Specialist Backup Roche User Login',
    type: 'input'
  }
]

export const tableOptions = [
  {
    type: 'fileName',
    hideFileIcon: true
  },
  {
    prop: 'contractNumber',
    label: '合同号',
    width: 150,
    isMore: true,
    sortable: true
  },
  {
    prop: 'equipmentNum',
    label: '设备号',
    sortable: true
  },
  {
    prop: 'instrumentAgeSort',
    label: '机龄',
    width: 150,
    isMore: true,
    sortable: true
  },
  {
    prop: 'materialNumber',
    label: '物料号',
    isMore: true,
    sortable: true
  },
  {
    prop: 'materialDesc',
    label: '物料名称',
    width: 200,
    sortable: true
  },
  {
    prop: 'instrLocKey',
    label: '终端代码',
    width: 150,
    isMore: true,
    sortable: true
  },
  {
    prop: 'instrLocName',
    label: '终端名称',
    width: 200,
    sortable: true
  },
  {
    prop: 'provinceNameZh',
    label: '终端省份',
    width: 120,
    isMore: true,
    sortable: true
  },
  {
    prop: 'iBaseCate',
    label: '仪器类型',
    isMore: true,
    sortable: true
  },
  {
    prop: 'serialNumber',
    label: '仪器序列号',
    sortable: true
  },
  {
    prop: 'moduleStatus',
    label: '仪器使用状态',
    // dictCode: 'module_status',
    isMore: true,
    sortable: true
  },
  {
    prop: 'moduleStatusSupplement',
    label: '状态补充说明',
    isMore: true,
    sortable: true
  },
  {
    prop: 'salesTeam',
    label: '业务部门',
    isMore: true,
    sortable: true
  },
  {
    prop: 'businessLine',
    label: '产品线',
    isMore: true,
    sortable: true
  },
  {
    prop: 'firstInstallDate',
    label: '仪器模块首次启用时间',
    width: 180,
    isMore: true,
    sortable: true
  },
  {
    prop: 'contractMaxEndDate',
    label: '服务合同到期日',
    isMore: true,
    sortable: true
  },
  {
    prop: 'movementType',
    label: '最终处置方案',
    isMore: true,
    sortable: true
  },
  {
    prop: 'movementStatus',
    label: '处置方案状态',
    isMore: true,
    sortable: true
  },
  {
    prop: 'masterEngineer1',
    label: 'Primary Engineer Roche User Login',
    sortable: true
  },
  {
    prop: 'feedbackMasterEngineer1',
    label: '更新责任人(工程师 Site A)',
    width: 180,
    sortable: true
  },
  {
    prop: 'masterEngineer2',
    label: 'Backup Engineer Roche User Login',
    sortable: true
  },
  {
    prop: 'feedbackMasterEngineer2',
    label: '更新责任人(工程师 Site B)',
    width: 180,
    sortable: true
  },
  {
    prop: 'applicationEngineer1',
    label: 'Application Specialist Roche User Login',
    sortable: true
  },
  {
    prop: 'feedbackApplicationEngineer1',
    label: '更新责任人(应用 Site A)',
    width: 180,
    sortable: true
  },
  {
    prop: 'applicationEngineer2',
    label: 'Application Specialist Backup Roche User Login',
    sortable: true
  },
  {
    prop: 'feedbackApplicationEngineer2',
    label: '更新责任人(应用 Site B)',
    width: 180,
    sortable: true
  }
]
