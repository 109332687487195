<template>
  <div>
    <van-popover
      class="popover"
      placement="bottom-end"
      v-model="showPopover"
      trigger="click"
      :actions="actions"
      @select="onSelect"
    >
      <template #reference>
        <van-icon name="ellipsis" />
      </template>
    </van-popover>
    <form-table
      ref="baseForm"
      :hasHeader="false"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :query-permission="['system:notice:query']"
      :isCheckbox="false"
      :hasIcon="false"
      :moreSearch="false"
      :more-column="true"
      :isNeedOperate="true"
      :isCardDisabled="false"
    >
      <template #column-item="scope">
        <div class="item">
          <div class="name">
            <span> {{ scope.row.processDefinitionName }}({{ scope.row.processInstanceId }})</span>
          </div>
          <div class="state" v-html="statusHtml(scope.row.result)"></div>
        </div>
      </template>
      <template slot="table-btn" slot-scope="data">
        <span type="text" @click="checkDetail(data.row)">查看详情</span>|
        <span :class="disabledStopTask(data.row) ? 'gray' : ' '" @click="stopTask(data.row)">撤销</span>
      </template>
    </form-table>
    <Details ref="detailComp"></Details>

    <van-dialog
      v-model="showFlag"
      title="是否确认撤销任务"
      cancelButtonColor="#8c8c8c"
      confirmButtonColor="#ee0a24"
      :showCancelButton="true"
      :closeOnClickOverlay="false"
      :beforeClose="onBeforeClose"
      @confirm="editConfirm"
    >
      <van-form validate-first @submit="onSubmit" ref="editForm">
        <van-row style="height: 80px; margin-top: 20px">
          <van-col span="24">
            <van-field
              :maxlength="100"
              input-align="right"
              v-model="editForm.comment"
              name="distributorName"
              label="备注"
              placeholder="请输入"
            />
          </van-col>
        </van-row>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import Details from '../details.vue'
import { stopTask } from '@/api/task/index'
import { tableOptions, queryUrl, searchFormData, headerTitle } from './config'
import { getDicts } from '@/api/dict/data'
export default {
  name: 'DemoList',
  components: { Details },
  data() {
    return {
      searchFormData: searchFormData,
      tableOptions: tableOptions,
      headerTitle: headerTitle,
      queryUrl: queryUrl,
      process_instance_status: {},
      canNotStopTask: ['CIB_CONF_CHECK', 'CHANNEL_LINK_COLLECT', 'CHANNEL_DATA_COLLECT'],
      showFlag: false,
      editForm: {
        processInstanceId: '',
        comment: '',
        category: ''
      },
      showPopover: false,
      actions: [{ text: '导出' }]
    }
  },
  created() {
    getDicts('process_result_status').then(res => {
      if (res.code === 200 && res.data.length > 0) {
        let statusMap = {}
        res.data.map(item => {
          statusMap[item.dictValue] = { text: item.dictLabel, color: item.remark }
        })
        this.process_instance_status = statusMap
      }
    })
  },
  methods: {
    disabledStopTask(row) {
      let disabled = false
      //任务已被操作
      if (row.revokeFlag == 1) {
        disabled = true
        return disabled
      }

      //完成状态或是类型不允许，disable为ture
      if (row.status == '2' || this.canNotStopTask.includes(row.category)) {
        disabled = true
      } else {
        disabled = false
      }
      return disabled
    },
    statusHtml(status) {
      let currentStatus = this.process_instance_status[status] || { text: '未知', color: '' }
      let html = `<span style="background:${currentStatus.color};display:inline-block;width:7px;height:7px;margin-right: 5px;border-radius:50%;"></span>${currentStatus.text}`
      return html
    },
    checkDetail(detailsInfo) {
      this.$refs.detailComp.init(detailsInfo)
    },
    stopTask(row) {
      if (this.disabledStopTask(row)) {
        return
      }
      this.editForm = {
        processInstanceId: '',
        comment: '',
        category: ''
      }
      this.showFlag = true
      this.editForm.processInstanceId = row.processInstanceId
      this.editForm.category = row.category
    },
    onBeforeClose(action, done) {
      if (action === 'confirm') {
        return done(false)
      } else {
        return done()
      }
    },
    editConfirm() {
      this.onSubmit()
    },
    onSubmit() {
      this.$refs.editForm
        .validate()
        .then(() => {
          stopTask(this.editForm)
            .then(res => {
              this.$toast.success('撤销成功')
              this.onRefresh()
              this.showFlag = false
            })
            .catch(err => {
              this.showFlag = false
              console.log(err)
            })
        })
        .catch(() => {})
    },
    onRefresh() {
      this.$refs.baseForm.onRefresh()
    },
    onSelect(data) {
      const requestParams = this.$refs.baseForm.queryParams
      if (data.text === '导出') {
        this.download('/bff-dp-mobile/task-flow/my-process-export', {}, `已发任务_${new Date().getTime()}.xlsx`, {
          method: 'get',
          params: requestParams
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.gray {
  color: #aeaeae;
}
.popover {
  color: #0b41cd;
  font-size: 16px;
  position: absolute;
  height: 44px;
  right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
</style>
