<template>
  <div class="main-container">
    <div class="list-bg" />
    <van-row class="header" type="flex" justify="space-between" v-if="hasHeader">
      <van-col class="head-arrow" @click="goToBack"><van-icon name="arrow-left" color="#0b41cd" /></van-col>
      <van-col class="head-title">{{ headerTitle }}</van-col>
      <van-col
        v-if="hideBtnWithEmptyData ? btnData.length < 2 && total > 0 : btnData.length < 2"
        class="head-btn"
        @click="onSelect(headBtnText)"
        >{{ headBtnText }}</van-col
      >
      <van-col v-else-if="btnData.length > 1">
        <van-popover
          class="popover"
          placement="bottom-end"
          v-model="showPopover"
          trigger="click"
          :actions="btnData"
          @select="onSelect"
        >
          <template #reference>
            <van-icon name="ellipsis" />
          </template>
        </van-popover>
      </van-col>
    </van-row>
    <van-row class="title" type="flex" justify="space-between">
      <van-col class="total">
        <span>共 {{ total }} 条数据</span>
      </van-col>
      <van-col class="filter" @click="filterData"
        ><span>筛选</span><van-icon name="filter-o" color="#0B41CD"
      /></van-col>
    </van-row>
    <van-row class="name"> <slot name="custom-title" /></van-row>
    <van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <van-collapse v-model="activeNames" class="collapse">
            <van-checkbox-group v-model="checkedList">
              <van-collapse-item
                :name="isCardDisabled ? 'index' : ind"
                v-for="(i, ind) in tableData"
                :key="ind"
                :is-link="!isCardDisabled"
                :disabled="isCardDisabled"
                :class="rowStyle ? rowClassName(i) : ''"
              >
                <template #title>
                  <div v-for="(item, index) in tableOptions" :key="index">
                    <van-row
                      v-if="item.type === 'fileName'"
                      type="flex"
                      align="center"
                      class="card"
                      :class="{ height: item.height == 'auto' }"
                    >
                      <van-col span="4" style="color: coral" v-if="!item.hideFileIcon">
                        <img
                          class="icon"
                          :src="require('@/assets/images/pdf.svg')"
                          v-if="(i[item.prop] && getFileType(i[item.prop]) === '.PDF') || forcePDF == true"
                        />
                        <img
                          class="icon"
                          :src="require('@/assets/images/excel.svg')"
                          v-else-if="
                            i[item.prop] &&
                            (getFileType(i[item.prop]) === '.XLS' || getFileType(i[item.prop]) === '.XLSX')
                          "
                        />
                        <img
                          class="icon"
                          :src="require('@/assets/images/word.svg')"
                          v-else-if="
                            i[item.prop] &&
                            (getFileType(i[item.prop]) === '.DOC' || getFileType(i[item.prop]) === '.DOCX')
                          "
                        />
                        <img
                          class="icon"
                          :src="require('@/assets/images/png.svg')"
                          v-else-if="i[item.prop] && getFileType(i[item.prop]) === '.PNG'"
                        />
                        <img
                          class="icon"
                          :src="require('@/assets/images/jpg.svg')"
                          v-else-if="
                            i[item.prop] &&
                            (getFileType(i[item.prop]) === '.JPG' || getFileType(i[item.prop]) === '.JPEG')
                          "
                        />
                        <img class="icon" :src="require('@/assets/images/file.svg')" v-else />
                        <!-- <van-icon name="manager" size="1rem" /> -->
                      </van-col>
                      <van-col
                        span="20"
                        class="card-text file-name"
                        @click="clickFileName(i, item)"
                        v-if="!item.cardTitleRight"
                      >
                        <slot name="column-item" :row="i" :item="item" :index="index">
                          <van-row class="name">{{ i[item.prop] }} </van-row>
                        </slot>
                      </van-col>
                      <van-col v-if="item.rightTop || item.rightBottom" :span="7" class="card-text card-right">
                        <!-- <van-row>
                          <span>{{ i[item.titleRight] }}</span>
                        </van-row> -->
                        <van-row v-if="item.rightTop" class="right-top">
                          <span v-if="item.rightTop.left" class="left">{{ i[item.rightTop.left.prop] }}</span>
                          <span v-if="item.rightTop.right && i[item.rightTop.right.prop]" class="right"
                            >-{{ getTableValue(i[item.rightTop.right.prop], item.rightTop.right) }}
                          </span>
                        </van-row>
                        <van-row v-if="item.rightBottom" class="right-bottom">
                          <span class="left">{{ getTableValue(i[item.rightBottom.prop], item.rightBottom) }} </span>
                        </van-row>
                      </van-col>
                      <van-col v-if="item.cardTitleRight" :span="24" class="card-text card-title-right">
                        <van-row class="name" style="position: relative; left: 20px">
                          <span style="color: #8c8c8c">{{ i[item.cardTitleRight] }}</span>
                        </van-row>
                      </van-col>
                      <van-col
                        :span="isCardDisabled && isShowCheckbox ? 2 : ''"
                        class="collapse-checkbox"
                        @click.stop
                        v-show="isShowCheckbox"
                        :style="setStyle()"
                      >
                        <van-checkbox :name="ind" @click.stop />
                      </van-col>
                    </van-row>
                  </div>
                </template>
                <div class="collapse-content">
                  <van-row class="divider">
                    <van-col span="24" v-for="(item, index) in tableOptions" :key="index">
                      <van-cell v-if="item.type == 'file'" :title="item.label">
                        <span style="color: #0b41cd" @click="clickFileName(i, item, item.fileId)">{{
                          getTableValue(i[item.prop], item)
                        }}</span>
                      </van-cell>
                      <van-cell
                        v-else-if="
                          item.type !== 'fileName' &&
                          ((item.isMore && i['isShowMoreColumn']) || !item.isMore) &&
                          !item.isEdit
                        "
                        :title="item.label"
                        :value="getTableValue(i[item.prop], item)"
                      >
                      </van-cell>
                      <div
                        v-else-if="
                          item.type !== 'fileName' &&
                          ((item.isMore && i['isShowMoreColumn']) || !item.isMore) &&
                          item.isEdit
                        "
                      >
                        <template>
                          <slot name="column-item-form" :row="i" :item="item" :index="ind"></slot>
                        </template>
                      </div>
                    </van-col>
                  </van-row>
                  <div class="expandList" v-if="isExpand && i['isShow']">
                    <van-row class="expandDivider" v-for="(obj, rowIndex) in i[expandKey]" :key="rowIndex">
                      <van-col span="24" v-for="(expand, colIndex) in tableExpandOptions" :key="colIndex"
                        ><van-cell :title="expand.label" :value="getTableValue(obj[expand.prop], expand)"
                      /></van-col>
                    </van-row>
                  </div>
                  <van-row v-if="isNeedOperate" class="foot-btn">
                    <span v-for="(item, index) in tableOptions" :key="index">
                      <span
                        v-if="
                          (item.type === 'fileName' &&
                            i[item.prop] &&
                            (getFileType(i[item.prop]) === '.PDF' ||
                              getFileType(i[item.prop]) === '.PNG' ||
                              getFileType(i[item.prop]) === '.JPG')) ||
                          (item.type === 'fileName' && forcePDF == true)
                        "
                        @click="showDetail(i, item)"
                      >
                        预览
                      </span>
                    </span>
                    <slot name="table-btn" :row="i" :index="ind" />
                  </van-row>
                </div>
              </van-collapse-item>
            </van-checkbox-group>
          </van-collapse>
        </van-list>
      </van-pull-refresh>
    </van-row>
    <van-popup v-model="show" class="popup-box" closeable round get-container="body" position="bottom" @close="cancel">
      <van-cell-group class="group-box">
        <van-col span="24" v-for="(item, index) in queryOptions" :key="index" class="form-item">
          <div v-show="(item.isMore && showMore) || !item.isMore">
            <van-field
              v-if="item.type === 'input'"
              v-model="queryParams[item.props]"
              :label="item.label"
              :placeholder="`${item.placeholder || '请输入'}`"
              :disabled="item.disableRule ? item.disableRule(queryParams) : item.disabled"
            />
            <van-field
              v-if="item.type === 'DictSelect'"
              readonly
              clickable
              :name="item.props"
              :value="pickerParams[item.props]"
              :label="item.label"
              placeholder="点击选择"
              @click="openPicker(item)"
              :disabled="item.disableRule ? item.disableRule(queryParams) : item.disabled"
            />
            <van-popup v-model="showPicker" position="bottom" get-container="body">
              <van-picker
                show-toolbar
                value-key="dictLabel"
                :columns="options"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
            <van-field
              v-if="item.type === 'date'"
              readonly
              clickable
              :name="item.props"
              :value="queryParams[item.props]"
              :label="item.label"
              placeholder="点击选择"
              @click="openDatePicker(item)"
              :disabled="item.disableRule ? item.disableRule(queryParams) : item.disabled"
            />
            <van-popup v-model="showDatePicker" position="bottom" get-container="body">
              <van-picker
                title="请选择年份"
                show-toolbar
                :columns="columns"
                :default-index="select"
                @confirm="yearConfirm"
                @cancel="cancela"
              />
            </van-popup>
            <van-field
              v-if="item.type === 'dateTime'"
              readonly
              clickable
              :name="item.props"
              :value="queryParams[item.props]"
              :label="item.label"
              placeholder="点击选择"
              @click="openDateTimePicker(item)"
              :disabled="item.disableRule ? item.disableRule(queryParams) : item.disabled"
            />
            <van-popup v-model="showDateTimePicker" position="bottom" get-container="body">
              <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择年月日"
                @confirm="timeConfirm"
                @cancel="showDateTimePicker = false"
              />
            </van-popup>
            <van-field v-if="item.type === 'radio'" :label="item.label">
              <template #input>
                <van-radio-group :value="queryParams[item.props]" direction="horizontal">
                  <van-radio
                    v-for="ele in item.options"
                    :name="ele.value"
                    :key="ele.value"
                    @click="() => handleChange(ele.value, item.props)"
                    >{{ ele.label }}</van-radio
                  >
                </van-radio-group>
              </template>
            </van-field>
          </div>
        </van-col>
      </van-cell-group>
      <van-row class="popup-foot-btn" gutter="20">
        <van-button v-if="moreSearch" class="btn-120" type="default" @click="showMore = !showMore">{{
          showMore ? '收起搜索' : '展开搜索'
        }}</van-button>
        <van-button class="btn margin-lf-10" type="default" @click="reset">重置</van-button>
        <van-button class="btn margin-lf-10" type="info" @click="handleQuery">确定</van-button>
      </van-row>
    </van-popup>
  </div>
</template>

<script>
import { getListApi, getListApiCommon, previewFile } from '@/api/formTable'
import { getDicts } from '@/api/dict/data'
import { dataCenter } from '@/utils/dataCenter'
export default {
  name: 'FormTable',
  props: {
    hasHeader: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    isCheckbox: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    moreSearch: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    forcePDF: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    moreColumn: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    headerTitle: {
      type: String,
      default: ''
    },
    queryUrl: {
      type: String,
      default: ''
    },
    queryOptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    tableOptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    btnData: {
      type: Array,
      default: function () {
        return []
      }
    },
    isNeedOperate: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    isCardDisabled: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    queryData: {
      type: Object,
      default: function () {
        return { pageNum: 1, pageSize: 10 }
      }
    },
    isExpand: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    expandKey: String,
    tableExpandOptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    hideBtnWithEmptyData: {
      type: Boolean,
      default: false
    },
    module: {
      type: String,
      default: function () {
        return ''
      }
    },
    refreshMethod: {
      type: Boolean,
      default: false
    },
    initSearchParams: {
      type: Object,
      default: function () {
        return {}
      }
    },
    specialQuery: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    rowStyle: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data() {
    return {
      checkedList: [],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      pickerParams: {},
      selectedItem: {},
      functionLabel: [],
      showPicker: false,
      showDatePicker: false,
      showDateTimePicker: false,
      sys_trainer_function: [],
      seatchValue: '',
      showPopover: false,
      show: false,
      popoverText: '',
      refreshing: false,
      finished: false,
      total: 0,
      loading: false,
      activeNames: ['index'],
      tableData: [],
      options: [],
      dateValue: '',
      select: null,
      columns: [],
      isShowMoreColumn: false,
      showMore: false,
      extraParams: {},
      isShowCheckbox: false,
      headBtnText: this.btnData[0]?.text || '',
      queryParams: {},
      productCertificateOpr: ''
    }
  },
  computed: {
    setPageSize() {
      return this.queryParams.pageNum * this.queryParams.pageSize
    },
    rowClassName() {
      return obj => {
        if (obj.data_confirm_status === '待确认') {
          return 'warning-row'
        } else if (obj.data_confirm_status === '已失效' || obj.data_confirm_status === '当前无授权') {
          return 'expire-row'
        }
        return ''
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0)
    })
  },
  watch: {
    queryOptions: {
      handler: function () {
        // console.log(this.queryOptions)
        // if (this.queryOptions.length > 0) {
        //   this.queryOptions.forEach(item=>{
        //     this.queryParams[item.props] = ""
        //   })
        //   this.queryParams.pageNum = 1
        //   this.queryParams.pageSize = 10
        //   console.log(this.queryParams)
        // }
      },
      immediate: true
    },
    queryData: {
      handler(newData) {
        newData.pageNum = 1
        newData.pageSize = 10
        this.queryParams = newData
      },
      immediate: true
    },
    isCardDisabled: {
      handler(newData) {
        if (newData) {
          this.activeNames = ['index']
        } else {
          this.activeNames = []
        }
      }
    },
    btnData: {
      handler(newData) {
        if (newData && newData.length === 1) {
          if (this.module === 'productCertificate') {
            this.headBtnText = '完成'
          } else {
            this.headBtnText = newData[0].text
          }
        } else {
          this.headBtnText = ''
        }
      },
      deep: true
    }
  },
  created() {
    if (this.$route.query?.id) {
      this.extraParams.id = this.$route.query?.id
    }
    if (this.$route.query?.channelType) {
      this.extraParams.channelType = this.$route.query?.channelType
    }
    if (this.$route.query?.overviewFlag) {
      this.extraParams.overviewFlag = this.$route.query?.overviewFlag
    }
    if (this.$route.query?.productLine) {
      this.extraParams.productLine = this.$route.query?.productLine
      this.pickerParams.productLine = this.$route.query?.productLine
    }
    this.getList()
    this.getOptions()
  },
  methods: {
    setStyle() {
      const style = this.isCardDisabled
        ? { display: 'flex', justifyContent: 'end' }
        : { position: 'absolute', right: '14px', bottom: '14px' }
      return style
    },
    getOptions() {
      this.queryOptions.forEach(item => {
        if (item.dictCode) {
          getDicts(item.dictCode).then(({ data }) => {
            item.options = data
            //queryParams带参回显
            if (this.queryParams[item.props]) {
              const result = data.find(element => {
                return this.queryParams[item.props] === element.dictValue
              })
              this.pickerParams[item.props] = result.dictLabel
            }
            if (item?.showRule) {
              item.options = item.options
                .map(op => {
                  // item.show = item?.showRule(item)
                  return {
                    ...op,
                    show: item?.showRule(op)
                  }
                })
                .filter(item => item.show)
            }
          })
        }
        if (Object.hasOwn(item, 'defaultValue')) {
          this.queryParams[item.props] = item.defaultValue
        }
      })
      this.tableOptions.forEach(item => {
        if (item.dictCode && (!item.options || item.options?.length < 1)) {
          getDicts(item.dictCode).then(({ data }) => {
            item.options = data
          })
        }
      })
    },
    formatDates(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    //获取默认最新时间
    getCurrentYear() {
      var nowTime = new Date()
      let year = nowTime.getFullYear()
      let month = nowTime.getMonth()
      let day = nowTime.getDate()
      var dateValue = ''
      dateValue = this.formatDates(new Date(year, month, day))
      this.dateValue = dateValue.slice(0, 4)
    },
    //年选择器
    yearConfirm(value) {
      const data = this.selectedItem
      this.queryParams[data.props] = value.toString()
      this.showDatePicker = false
    },
    timeConfirm(value) {
      const data = this.selectedItem
      this.queryParams[data.props] = formatDate(value)
      this.showDateTimePicker = false
      function formatDate(date) {
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
      }
    },
    //年数据
    getYearData() {
      var nowTime = new Date(this.dateValue)
      let year = nowTime.getFullYear()
      let month = nowTime.getMonth()
      let day = nowTime.getDate()
      for (let i = 1901; i < 2099; i++) {
        this.columns.push(i)
      }

      var years = this.formatDates(new Date(year, month, day))
      var Year = years.slice(0, 4)

      this.select = this.columns.indexOf(Number(Year))
    },
    getTableValue(value, item) {
      if (!item.dictCode) {
        if (item.render) {
          return item.render(value)
        } else {
          return value || '-'
        }
      } else {
        if (value && item.options && item.options.length > 0) {
          const val = item.options.find(ele => ele.dictValue == value)
          //月份补0
          if (item.prop === 'month') {
            if (val && val.dictValue < 10) {
              return '0' + val.dictLabel
            }
          }
          return val?.dictLabel || '-'
        } else {
          return value || '-'
        }
      }
    },
    //点击取消按钮时触发的事件
    cancela() {
      this.showDatePicker = false
    },
    openDatePicker(item) {
      this.getCurrentYear()
      this.getYearData()
      this.showDatePicker = true
      // this.options = item.options
      this.selectedItem = item
    },
    openDateTimePicker(item) {
      this.showDateTimePicker = true
      // this.options = item.options
      this.selectedItem = item
    },
    openPicker(item) {
      if (item.disableRule && item.disableRule(this.queryParams)) {
        return
      }
      this.showPicker = true
      this.options = item.options
      this.selectedItem = item
    },
    getList(type) {
      if (!this.queryUrl) {
        return
      }
      this.loading = true
      let commonParam = {
        ...this.queryParams,
        pageNum: undefined,
        pageSize: undefined
      }
      const params = {
        ...this.queryParams,
        ...this.extraParams,
        ...this.initSearchParams,
        fromDataColumnRequestMap: this.module === 'CommonModule' ? commonParam : undefined
      }
      if (this.module === 'CommonModule') {
        getListApiCommon(this.queryUrl, params)
          .then(response => {
            this.loading = false
            this.refreshing = false
            if (this.specialQuery) {
              this.total = response.data.taskList.total || 0
              if (type == 'load') {
                this.tableData = [
                  ...this.tableData,
                  ...response.data.taskList.rows.map(item => {
                    return {
                      ...item
                    }
                  })
                ]
              } else {
                this.tableData = response.data.taskList.rows || []
              }
              this.$emit('on-check', response.data)
            } else {
              this.total = response.total || 0
              if (type == 'load') {
                this.tableData = [
                  ...this.tableData,
                  ...response.rows.map(item => {
                    return {
                      ...item
                    }
                  })
                ]
              } else {
                this.tableData = response.rows || []
              }
            }
            this.$emit('on-search', this.tableData)
            //产品注册证特殊处理
            this.tableData.forEach(obj => {
              this.$set(obj, 'isShow', false)
            })
            if (this.tableData.length >= this.total) {
              this.finished = true
            }
          })
          .catch(error => {
            this.finished = true
            console.log(error)
          })
      } else {
        getListApi(this.queryUrl, params)
          .then(response => {
            this.loading = false
            this.refreshing = false
            if (this.specialQuery) {
              this.total = response.data.taskList.total || 0
              if (type == 'load') {
                this.tableData = [
                  ...this.tableData,
                  ...response.data.taskList.rows.map(item => {
                    return {
                      ...item
                    }
                  })
                ]
              } else {
                this.tableData = response.data.taskList.rows || []
              }
              this.$emit('on-check', response.data)
            } else {
              this.total = response.total || 0
              if (type == 'load') {
                this.tableData = [
                  ...this.tableData,
                  ...response.rows.map(item => {
                    return {
                      ...item
                    }
                  })
                ]
              } else {
                this.tableData = response.rows || []
              }
            }
            this.$emit('on-search', this.tableData)
            //产品注册证特殊处理
            this.tableData.forEach(obj => {
              this.$set(obj, 'isShow', false)
            })
            if (this.tableData.length >= this.total) {
              this.finished = true
            }
          })
          .catch(error => {
            this.finished = true
            console.log(error)
          })
      }
    },
    onLoad() {
      this.loading = true
      // 设置定时器做节流处理，避免滚动时频繁触发加载事件
      if (!this.timer) {
        // if (this.refreshing) {
        //   this.trainersList = []
        //   this.refreshing = false
        // }
        this.timer = setTimeout(() => {
          this.queryParams.pageNum++
          this.getList('load')
          // 清除定时器，释放内存
          this.timer = null
        }, 1000)
      }
    },
    goToBack() {
      if (this.$route.query.title) {
        this.$router.push({ name: 'DataCenter', params: { title: this.$route.query.title } })
      } else {
        this.$router.go(-1)
      }
    },
    filterData() {
      this.show = true
    },
    cancel() {
      this.show = false
      // this.reset()
    },
    reset() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10
      }
      this.queryOptions.forEach(item => {
        if (Object.hasOwn(item, 'defaultValue')) {
          this.queryParams[item.props] = item.defaultValue
        }
      })
      this.pickerParams = {}
    },
    onConfirm(value) {
      const data = this.selectedItem
      this.queryParams[data.props] = data.dictValueFormat === 'dictLabel' ? value.dictLabel : value.dictValue
      this.queryParams = data.customChange ? data.customChange(this.queryParams) : this.queryParams
      this.pickerParams[data.props] = value.dictLabel
      this.pickerParams = data.customChange ? data.customChange(this.pickerParams) : this.pickerParams
      this.showPicker = false
    },
    /** 确定按钮操作 */
    handleQuery() {
      this.extraParams = {}
      // 清除地址栏中参数
      const { path } = this.$route
      const query = { ...this.$route.query }
      this.queryParams.pageNum = 1
      delete query.id
      delete query.channelType
      this.$router.replace({ path, query }).catch(err => err)
      this.$emit('on-change', this.queryParams)
      this.getList()
      this.cancel()
    },
    onSelect(text) {
      if (this.isCheckbox) {
        if (text === '下载' || text.text === '下载注册证' || text.text === '下载产品信息') {
          this.isShowCheckbox = true
          this.headBtnText = '完成'
          if (this.module === 'productCertificate') {
            this.productCertificateOpr = text.text
            this.$emit('changeBtnOLd')
          }
        } else if (text === '完成') {
          const checkedCollapseItem = this.checkedList.map(item => {
            return this.tableData[item]
          })
          if (checkedCollapseItem.length > 0) {
            this.$emit('clickBtn', text, { ...this.queryParams, pageNum: 1, pageSize: this.setPageSize }, [
              ...checkedCollapseItem
            ])
          }
          this.checkedList = []
          this.headBtnText = '下载'
          this.isShowCheckbox = false
          if (this.module === 'productCertificate') {
            this.$emit('changeBtnNew')
          }
        }
      } else {
        if (text.text === '提交') {
          this.$emit('submitBtn', text, { ...this.queryParams }, this.tableData)
        } else if (text.text === '分销商清单') {
          this.$emit('dealerListBtn')
        } else {
          const checkedCollapseItem = this.checkedList.map(item => {
            // return this.tableData.find(ele => {
            //   return ele.fileId === item
            // })
            return this.tableData[item]
          })
          this.$emit('clickBtn', text, { ...this.queryParams, pageNum: 1, pageSize: this.setPageSize }, [
            ...checkedCollapseItem
          ])
        }
      }
      this.showPopover = false
    },
    // setMoreColumn(data) {
    //   const isShowMoreColumn = data.isShowMoreColumn ? !data.isShowMoreColumn : true
    //   this.$set(data, 'isShowMoreColumn', isShowMoreColumn)
    // },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      if (this.refreshMethod) {
        this.pickerParams = {}
        // this.activeNames = []
        this.$emit('on-refresh', this.tableData)
      } else {
        // 重新加载数据
        this.queryParams = {
          pageNum: 1,
          pageSize: 10
        }
        this.pickerParams = {}
        this.total = 0
        this.tableData = []
        // this.activeNames = []
        this.getList()
      }
    },
    clickFileName(obj, item, fileId) {
      if (!this.isCardDisabled) {
        return
      } else {
        if (
          obj[item.prop] &&
          (this.getFileType(obj[item.prop]) === '.XLSX' || this.getFileType(obj[item.prop]) === '.DOCX')
        ) {
          if (this.module === 'CommonModule') {
            dataCenter.downloadFile([obj], 'file_id')
          } else {
            dataCenter.downloadFile([obj], fileId)
          }
        } else {
          this.showDetail(obj, item, fileId)
        }
      }
    },
    handleChange(value, label) {
      this.queryParams[label] = value
      this.$forceUpdate()
    },
    showDetail(obj, item, fileId) {
      if (
        ((item.type === 'fileName' || item.type === 'file') &&
          obj[item.prop] &&
          (this.getFileType(obj[item.prop]) === '.PDF' ||
            this.getFileType(obj[item.prop]) === '.PNG' ||
            this.getFileType(obj[item.prop]) === '.JPG')) ||
        (item.type === 'fileName' && this.forcePDF == true)
      ) {
        let params = {
          fileId: obj[fileId] || obj.fileId || obj.file_id,
          watermarkFlag: true
        }
        if (this.module === 'productCertificate' || this.module === 'productDocument' ) {
          params.regCertFlag = true
        }
        let fileName = this.forcePDF ? `${obj[item.prop]}.pdf` : obj[item.prop]
        previewFile(params, fileName)
      } else {
        return
      }
    },
    getFileType(fileName) {
      if (!fileName.lastIndexOf('.')) {
        return fileType
      }
      const fileType = fileName.substr(fileName.lastIndexOf('.'), fileName.length - 1).toUpperCase()
      return fileType
    },
    showOrHide(val, index) {
      this.$set(this.tableData, index, val)
    }
  }
}
</script>

<style lang="less" scoped>
.van-cell {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.van-cell .van-cell__title {
  color: #8c8c8c;
}

.van-cell .van-cell__value {
  color: #262626;
}

.margin-lf-10 {
  margin-left: 10px;
}

.main-container {
  padding: 0 16px;
}

.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
}

.popover-action {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 128px;
  height: 44px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  .text {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.header {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;

  .popover {
    color: #0b41cd;
    font-size: 16px;
    position: absolute;
    height: 52px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
  }

  .head-arrow {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    left: 16px;
  }

  .head-title {
    font-weight: 600;
  }

  .head-btn {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    right: 16px;
    font-size: 16px;
    color: #0b41cd;
  }
}

.title {
  padding: 6px 0 12px;

  .total {
    font-size: 14px;
    line-height: 24px;
    color: #969799;
  }

  .filter {
    color: #0b41cd;
    font-size: 14px;
  }
}

.collapse {
  padding: 2px;

  .van-collapse-item {
    overflow: hidden;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);

    .card {
      height: 52px;

      .icon {
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-right: 10px;
      }

      .file-name {
        flex: 1;
      }

      .card-right {
        text-align: right;
        padding-left: 10px;
        font-size: 12px;

        // line-height: 14px;
        .right-top {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          // display: flex;
          // justify-content: end;
          .right {
            // margin-left: 2px;
          }
        }

        .right-bottom {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // color: #0b41cd;
        }
      }

      .card-title-right {
        text-align: right;
        padding-left: 10px;
        margin-top: -25px;
      }

      // .year {
      //   text-align: end;
      //   padding-left: 10px;
      // }
    }

    .collapse-checkbox {
      margin-left: 5px;
    }

    .name {
      color: #0b41cd;
      font-size: 12px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    .collapse-content {
      .line {
        border-bottom: 0.5px solid #ebedf0;
      }
    }

    .foot-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      color: #0b41cd;
      border-top: 1px solid #ebedf0;
      padding: 10px 16px;

      .vertical-line {
        margin: 0 8px;
      }
    }
  }
}

.popup-box {
  overflow-y: hidden;
  height: 450px;

  .van-cell {
    font-size: 14px;
    line-height: 20px;
  }

  .group-box {
    margin-top: 40px;
    height: 100%;
    overflow-y: scroll;
  }

  .popup-foot-btn {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    box-sizing: border-box;

    .btn {
      width: 80px;
    }

    .btn-120 {
      width: 120px;
    }
  }
}

.height {
  height: auto !important;
}

.buy-popup-box::after {
  content: '';
  border: 1px solid red;
  width: 100%;
  height: 500px;
  background: #eee;
}

.expandList {
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe) !important;
}

.expandDivider {
  border-bottom: 2px solid #d9ebfb;
}

.form-item:last-of-type {
  margin-bottom: 80px;
}

/deep/ .warning-row {
  background: #fff0f0 !important;
}

/deep/ .expire-row {
  background: #e6e6e6 !important;
}
</style>
