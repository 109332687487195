<template>
  <div class="app-container">
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      :module="module"
      :hide-btn-with-empty-data="true"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
    >
    </form-table>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { dataCenter } from '@/utils/dataCenter'
import { getCommonModuleData } from '@/api/file'
export default {
  name: 'Common_Module',
  data() {
    return {
      btnData: [{ text: '下载' }],
      module: 'CommonModule',
      // headerTitle: '通用模块',
      searchFormData: [],
      tableOptions: [],
      initData: [],
      queryUrl: null
    }
  },
  computed:{
    headerTitle() {
      return this.$route.query?.pageTitle || '通用模块'
    }
  },
  created() {
    this.getTableHeader()
  },
  watch: {
    '$route.query': {
      handler(val) {
        this.getTableHeader()
      },
      deep: true
    }
  },
  methods: {
    getTableHeader() {
      getCommonModuleData(this.$route.query?.type).then(res => {
        this.initData = res.data
        let searchList = this.initData.filter(item => item.isDealerQuery === '1')
        this.searchFormData = searchList.map(item => {
          return {
            props: item.columnName,
            label: item.columnBusinessName,
            type:
              item.htmlType === 'datetime'
                ? 'date'
                : item.htmlType === 'select'
                ? 'DictSelect'
                : item.columnName === 'file_name'
                ? 'input'
                : item.htmlType,
            dictCode: item.htmlType === 'select' ? item.dictType : undefined,
            valueFormat: item.htmlType === 'datetime' ? item.dictType : undefined,
            dateFormat: item.htmlType === 'datetime' ? item.dictType : undefined,
            dateType: item.dictType === 'yyyy' ? 'year' : 'date'
          }
        })

        let tableList = this.initData.filter(item => item.isDealerList === '1')
        this.tableOptions = tableList.map(item => {
          return {
            prop: item.columnName,
            label: item.columnBusinessName,
            type: item.columnName === 'file_name' ? 'fileName' : undefined,
            dictCode: item.htmlType === 'select' ? item.dictType : undefined,
            render:
              item.columnName == 'upload_time'
                ? val => {
                    return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
                  }
                : undefined,
          }
        })

        this.queryUrl = '/bff-dp-mobile/customer-form-show/dealer-data-list/' + this.$route.query?.type
        this.$nextTick(() => {
          this.$refs.baseForm.getOptions()
        })
      })
    },
    onSelect(text, queryParams, list) {
      this.download(list)
    },
    download(list) {
      dataCenter.downloadFile(list,'file_id')
    }
  }
}
</script>
