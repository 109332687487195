import request from '@/utils/request'

//查询仓库仪器关系
export function getWarehouseInstrument(query) {
  return request({
    url: '/bff-dp-mobile/instrument-data/warehouse-list',
    method: 'get',
    params: query
  })
}

//查询非Instrument物料号
export function getMaterialInstrument(query) {
  return request({
    url: '/bff-dp-mobile/instrument-data/material-list',
    method: 'get',
    params: query
  })
}

//上传仓库仪器关系
export function uploadWarehouseInstrument(data) {
  return request({
    url: '/bff-dp-mobile/instrument-data/import-warehouse',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

//上传非Instrument物料号
export function uploadMaterialInstrument(data) {
  return request({
    url: '/bff-dp-mobile/instrument-data/import-material',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

//查询变更日志
export function getChangeLog(query) {
  return request({
    url: '/bff-dp-mobile/instrument-data/data/log',
    method: 'get',
    params: query
  })
}

//查询经销商申诉时间段
export function getAppealDate() {
  return request({
    url: '/bff-dp-mobile/instrument-data/appeal-date',
    method: 'get'
  })
}

//仪器申诉详情
export function getInstrumentAppealDetail(query) {
  return request({
    url: `/bff-dp-mobile/instrument-data/appeal-detail`,
    method: 'get',
    params: query
  })
}
