<template>
  <div class="app-container">
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
      :forcePDF="true"
    >
    </form-table>
  </div>
</template>

<script>
import { dataCenter } from '@/utils/dataCenter'
import { getDicts } from '@/api/dict/data'
export default {
  name: 'CIB_Confirmation',
  dicts: ['sys_product_line'],
  data() {
    return {
      headerTitle: 'CIB确认函',
      btnData: [{ text: '下载' }],
      searchFormData: [
        { props: 'name', label: '文件名', type: 'input' },
        {
          props: 'year',
          label: '年份',
          width: '120px',
          type: 'date',
          valueFormat: 'yyyy',
          dateFormat: 'yyyy',
          dateType: 'year'
        },
        {
          props: 'productLineValue',
          label: '产品线',
          type: 'DictSelect',
          dictCode: 'sys_product_line',
          multiple: false
        }
      ],
      tableOptions: [
        {
          prop: 'dealerFileName',
          label: '文件名',
          type: 'fileName',
          rightTop: {
            left: {
              prop: 'year',
              label: '年份'
            }
          },
          rightBottom: { prop: 'productLineValue', label: '产品线', dictCode: 'sys_product_line', options: [] }
        },

        // {
        //   prop: 'year',
        //   label: '年份'
        // },
        {
          prop: 'createTime',
          label: '罗氏上传时间'
        },
        {
          prop: 'feedbackDate',
          label: '申诉截止日期'
        },
        {
          prop: 'returnFileName',
          label: 'CIB盖章文件',
          type: 'file',
          fileId: 'returnFileId'
        }
      ],
      queryUrl: '/bff-web/cib-confirmation/getListByDealer'
    }
  },
  created() {
    getDicts('sys_product_line').then(({ data }) => {
      this.searchFormData[2].options = data
      this.tableOptions[0].rightBottom.options = data
    })
  },
  methods: {
    onSelect(text, queryParams, list) {
      console.log(text, queryParams)
      this.daowload(list)
    },
    daowload(list) {
      dataCenter.downloadFile(list)
    }
  }
}
</script>
