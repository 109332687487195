<template>
  <div class="app-tab-bar">
    <!-- 下方导航 -->
    <van-tabbar
      v-model="active"
      active-color="#0B41CD"
      inactive-color="#8C8C8C"
      @change="onChange"
      shape="round"
      route
      safe-area-inset-bottom
      placeholder
      style="padding-bottom: 5px"
    >
      <van-tabbar-item to="/home" name="overview">
        <span>总览</span>
        <template #icon="props">
          <img v-show="props.active" :src="require('@/assets/images/tabbar1-1.svg')" />
          <img v-show="!props.active" :src="require('@/assets/images/tabbar1.svg')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/dataCenter" name="dataCenter">
        <span :style="active === 'dataCenter' ? { color: '#0B41CD' } : { color: '#8C8C8C' }">业务中心</span>
        <template #icon="props">
          <img v-show="props.active || active === 'dataCenter'" :src="require('@/assets/images/tabbar4-1.svg')" />
          <img v-show="!props.active && active !== 'dataCenter'" :src="require('@/assets/images/tabbar4.svg')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/notice" name="notice">
        <span>通知</span>
        <template #icon="props">
          <img v-show="props.active" :src="require('@/assets/images/tabbar2-1.svg')" />
          <img v-show="!props.active" :src="require('@/assets/images/tabbar2.svg')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/task" name="task">
        <span>任务中心</span>
        <template #icon="props">
          <img v-show="props.active" :src="require('@/assets/images/tabbar5-1.svg')" />
          <img v-show="!props.active" :src="require('@/assets/images/tabbar5.svg')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/userCenter" name="userCenter">
        <span>用户中心</span>
        <template #icon="props">
          <img v-show="props.active" :src="require('@/assets/images/tabbar3-1.svg')" />
          <img v-show="!props.active" :src="require('@/assets/images/tabbar3.svg')" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'app-tab-bar',
  data() {
    return {
      active: ''
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        this.setSelectedTab(to.path)
      },
      deep: true
    }
  },
  methods: {
    onChange(m) {
      this.active = m
    },
    setSelectedTab(path) {
      if (path.indexOf('/dataCenter') > -1) {
        this.active = 'dataCenter'
      } else {
        this.active = ''
      }
    }
  }
}
</script>

<style></style>
