import request from '@/utils/request'

// 查询用户列表
export function listUser(query) {
  return request({
    url: '/user/dealerUser/sub-dealer-list',
    method: 'get',
    params: query
  })
}

export function listDealer(query) {
  return request({
    url: '/user/dealer/list',
    method: 'get',
    params: query
  })
}

// 新增用户
export function addDealerUser(data) {
  return request({
    url: '/user/dealerUser/add-common-user',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateDealerUser(data) {
  return request({
    url: '/user/dealerUser/edit-common-user',
    method: 'post',
    data: data
  })
}

// 删除用户
export function delDealerUser(data) {
  return request({
    url: '/user/dealerUser/remove/',
    method: 'post',
    data: data
  })
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  const data = {
    userId,
    password
  }
  return request({
    url: '/user/dealerUser/resetPwd',
    method: 'put',
    data: data
  })
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status
  }
  return request({
    url: '/user/dealerUser/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: '/user/dealerUser/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/user/dealerUser/profile',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/user/dealerUser/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/user/dealerUser/profile/avatar',
    method: 'post',
    data: data
  })
}

// 查询授权角色
export function getAuthRole(userId) {
  return request({
    url: '/user/dealerUser/authRole/' + userId,
    method: 'get'
  })
}

// 保存授权角色
export function updateAuthRole(data) {
  return request({
    url: '/user/dealerUser/authRole',
    method: 'put',
    params: data
  })
}

// 查询部门下拉树结构
export function deptTreeSelect() {
  return request({
    url: '/user/dealerUser/deptTree',
    method: 'get'
  })
}

// 经销商--api
//经销商详情
export function getDealer(orgId) {
  return request({
    url: '/bff-dp-mobile/dealer/info/' + orgId,
    method: 'get'
  })
}
// 修改经销商
export function updateDealer(data) {
  return request({
    url: '/user/dealer/edit',
    method: 'post',
    data: data
  })
}
//经销商全量
export function listDealerAll() {
  return request({
    url: '/user/dealer/dealer-list',
    method: 'get'
  })
}
// 经销商--api
//经销商详情
export function getDealerDetail(orgId) {
  return request({
    url: '/bff-dp-mobile/dealer-info/info/' + orgId,
    method: 'get'
  })
}
// 修改经销商
export function updateDealerDetail(data) {
  return request({
    url: '/user/dealer-detail/edit',
    method: 'post',
    data: data
  })
}

//经销商全量1002,1003选择用的
export function listDealerAllForCMCL() {
  return request({
    url: '/auth/dealerCM/dealers',
    method: 'get'
  })
}

// 获取token
//localhost:30008/dealerCM/dealer/{orgId}
export function getTokenByOrgIdForCMCL(orgId) {
  return request({
    url: `/auth/dealerCM/dealer/${orgId}`,
    method: 'put'
  })
}

// 修改经销商
export function editDealer(data) {
  return request({
    url: '/bff-dp-mobile/dealer-info/edit',
    method: 'post',
    data: data
  })
}

//查看Gigya账号状态
export function queryAccountStatus(userId) {
  return request({
    url: '/user/dealerUser/' + userId + '/gigyaUserInfo',
    method: 'get'
  })
}

//重发Gigya账号激活邮件
export function sendActiveEmail(userId) {
  return request({
    url: '/user/dealerUser/' + userId + '/resendVerificationMail',
    method: 'put'
  })
}

//重置Gigya账号密码
export function resetGigyaPwd(userId) {
  return request({
    url: '/user/dealerUser/' + userId + '/resetPassword',
    method: 'post'
  })
}

// 查询角色
export function listRole() {
  return request({
    url: '/user/role/dealer-roles',
    method: 'get'
  })
}

//筛选联络人
export function getContactOptions(data) {
  return request({
    url: '/bff-dp-mobile/dealer-contacts/dealer-contacts-group-codes',
    method: 'post',
    data: data
  })
}

// 查询用户Id
export function getUserId() {
  return request({
    url: '/user/dealerUser/dealer-user-id',
    method: 'get'
  })
}

// 查询用户详细
export function getDealerUser(userId) {
  return request({
    url: '/user/dealerUser/info/' + userId,
    method: 'get'
  })
}

// 查询是否绑定过子账号
export function checkBinding(query) {
  return request({
    url: '/bff-dp-mobile/dealer-contact-user-mapping/check-bindings',
    method: 'get',
    params: query
  })
}

//获取公司信息
export function getDealerCompanyInfo() {
  return request({
    url: '/bff-dp-web/dealer-company-info/detail',
    method: 'get'
  })
}

export function updateDealerCompanyInfo(data) {
  return request({
    url: '/bff-dp-web/dealer-company-info/apply-change',
    method: 'put',
    data: data
  })
}

//批量修改经销商联络人
export function updateDealerContacts(data) {
  return request({
    url: '/bff-dp-web/dealer-contacts/dealer-contact-edit-dealer',
    method: 'post',
    data: data
  })
}

//获取角色树
export function getRoleTypeList(isManage) {
  return request({
    url: '/user/dealerUser/dealer-role-list?isManage=' + String(isManage),
    method: 'get'
  })
}

// 创建申诉经销商端申请变更公司信息
export function createAppealForCompanyInfo(data) {
  return request({
    url: '/bff-dp-mobile/dealer-company-info/appeal-create',
    method: 'post',
    data: data
  })
}

// 获取申诉公司信息
export function getAppealDetailByTaskIdForCompanyInfo(taskId, processInstanceId) {
  let params = ''

  if (processInstanceId) {
    params = `processId=${processInstanceId}`
  }
  if (taskId) {
    params = `taskId=${taskId}`
  }

  return request({
    url: `/bff-dp-mobile/dealer-company-info/appeal-detail?${params}`,
    method: 'get'
  })
}

export function UpdateDealerAccountList(data) {
  return request({
    url: '/user/dealerUser/dealer-account-batch-edit-dealer',
    method: 'post',
    data: data
  })
}

// 申请变更 公司负责人
export function createAppealForManager(data) {
  return request({
    url: '/user/dealerUser/company-user-appeal',
    method: 'post',
    data: data
  })
}

// 获取申诉公司信息
export function getAppealDetailByTaskIdForManagerInfo(taskId, processInstanceId) {
  let params = ''

  if (processInstanceId) {
    params = `processInstanceId=${processInstanceId}`
  }
  if (taskId) {
    params = `taskId=${taskId}`
  }

  return request({
    url: `/user/dealerUser/company-user-appeal-detail?${params}`,
    method: 'get'
  })
}

// 经销商--api
export function checkDealerUserRoleWarning(data) {
  return request({
    url: '/user/dealerUser/check-dealer-user-role-warning',
    method: 'post',
    data: data
  })
}
