<template>
  <div class="confirmation">
    <van-popup v-model="pickerOptions.display" position="bottom">
      <van-picker
        show-toolbar
        :default-index="pickerOptions.defaultIndex"
        :columns="pickerOptions.columns"
        @cancel="pickerOptions.display = false"
        @confirm="onPickerConfirm"
      />
    </van-popup>
    <van-nav-bar
      title="CIB确认函"
      left-arrow
      class="confirmation-nav"
      fixed
      placeholder
      safe-area-inset-top
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="filter-bar">
      <div class="filter-item" @click.prevent="() => onPickerFormat()">
        <span class="filter-label">年份：</span>
        <span class="filter-content">{{ filter.year || '全部' }}</span>
        <van-icon class="icon-drop" name="play" size="12" />
      </div>
    </div>
    <div class="file-cards">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-cell v-for="(file, idx) in fileDataList" :key="idx" class="files-group">
          <file-card
            :name="file.dealerFileName"
            :desc="file.year + '年'"
            @on-click="onCardClick(file)"
            :data="file"
          ></file-card>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>
<script>
import FileCard from './fileCard.vue'
import { getCIBConfirmationListByDealer } from '@/api/dataCenter/cib/confirmation'
export default {
  components: { FileCard },
  data() {
    return {
      filter: {
        year: ''
      },
      pagination: {
        total: 0,
        pageNum: 1,
        pageSize: 10
      },

      pickerOptions: {
        display: false,
        columns: []
      },
      fileDataList: [],
      loading: false,
      finished: false
    }
  },
  methods: {
    onPickerFormat() {
      let defaultIndex = 0
      const columns = ['全部']
      const defaultValue = this.filter.year || new Date().getFullYear()
      let start = new Date().getFullYear() - 10
      const end = new Date().getFullYear() + 10
      for (let i = start; i <= end; i++) {
        if (i === defaultValue) {
          defaultIndex = columns.length
        }
        columns.push(i)
      }
      this.pickerOptions = {
        columns,
        display: true,
        defaultIndex
      }
    },
    onPickerConfirm(v) {
      this.filter.year = v === '全部' ? '' : v
      this.pickerOptions.display = false
      this.pagination = {
        ...this.pagination,
        pageNum: 1
      }
      this.queryListData({
        ...this.filter,
        ...this.pagination
      }).then(({ rows, total }) => {
        this.fileDataList = rows
        this.pagination.total = total
        this.loading = false
        if (this.fileDataList.length >= total) {
          this.finished = true
        }
      })
    },
    onLoad() {
      this.queryListData({
        ...this.filter,
        ...this.pagination
      }).then(({ rows, total }) => {
        this.fileDataList = this.fileDataList.concat(rows)
        this.pagination.total = total
        this.loading = false
        if (this.fileDataList.length >= total) {
          this.finished = true
        }
      })
    },
    queryListData(params) {
      return getCIBConfirmationListByDealer(params).then(res => {
        if (res && res.code === 200) {
          return res
        }
        return {
          total: 0,
          data: []
        }
      })
    },
    onCardClick(raw) {
      this.$router.push({
        path: `/dataCenter/cib/confirmation/dear/detail/${raw.fileId}`,
        query: {
          filename: encodeURIComponent(raw.dealerFileName)
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.confirmation {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(296.57deg, #d9ebfb 0%, #d9ebfb 5.27%, #f2f2f7 35.59%, #fef9f7 65.39%, #fffefe 83.33%);
  .confirmation-nav {
    ::v-deep .van-nav-bar {
      background-color: #fff;
      .van-nav-bar__content {
        .van-nav-bar__left {
          .van-icon {
            color: rgba(38, 38, 38, 1);
          }
        }
      }
    }
  }
  .filter-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
    .filter-item {
      display: flex;
      font-size: 15px;
      align-items: center;
      &:last-child {
        align-self: flex-end;
      }
      .filter-label {
        color: #8c8c8c;
      }
      .filter-content {
        color: #595959;
      }
      .icon-drop {
        font-size: 12px;
        color: rgba(140, 140, 140, 1);
        transform: rotate(90deg);
        position: relative;
        top: -1px;
      }
    }
  }
  .file-cards {
    flex: 1;
    overflow-y: auto;
    .file-card {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}
</style>
