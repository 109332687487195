<template>
  <div class="app-container">
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
    >
    </form-table>
  </div>
</template>

<script>
import { dataCenter } from '@/utils/dataCenter'
import { getDicts } from '@/api/dict/data'
export default {
  name: 'Contract',
  dicts: ['sys_product_line'],
  data() {
    return {
      headerTitle: 'CIB合同',
      btnData: [{ text: '下载' }],
      searchFormData: [
        { props: 'fileName', label: '文件名', type: 'input' },
        {
          props: 'year',
          label: '年份',
          width: '120px',
          type: 'date',
          valueFormat: 'yyyy',
          dateFormat: 'yyyy',
          dateType: 'year'
        },
        {
          props: 'productLine',
          label: '产品线',
          type: 'DictSelect',
          dictCode: 'sys_product_line',
          multiple: false
        }
      ],
      tableOptions: [
        {
          prop: 'fileName',
          label: '文件名',
          type: 'fileName',
          rightTop: {
            left: {
              prop: 'year',
              label: '年份'
            }
          },
          rightBottom: { prop: 'productLineLabel', label: '产品线' }
        },
        // {
        //   prop: 'year',
        //   label: '年份'
        // },
        // {
        //   prop: 'productLineLabel',
        //   label: '产品线',
        //   dictCode: 'sys_product_line'
        // },
        {
          prop: 'createdDate',
          label: '上传时间'
        }
      ],
      queryUrl: '/bff-dp-mobile/document/model-one/dealer-cib-contract'
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    async setOptions() {
      const options = await getDicts('sys_product_line').then(res => {
        let list = []
        if (res.code === 200 && res.data.length > 0) {
          list = res.data
        }
        return list
      })
      this.tableOptions[0].rightBottom.options = options
    },
    onSelect(text, queryParams, list) {
      console.log(text, queryParams)
      this.daowload(list)
    },
    daowload(list) {
      dataCenter.downloadFile(list)
    }
  }
}
</script>
