<template>
  <!-- 数据列表渲染 -->
  <ul class="goods_list">
    <li v-for="item in goodsList" :key="item.id" @click="gotodetail(item.id)">
      <img v-lazy="item.list_pic_url" alt="" />
      <p>{{ item.name }}</p>
      <p>{{ item.retail_price | moneyFlrmat }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'products',
  props: ['goodsList'],
  data() {
    return {}
  },
  methods: {
    gotodetail(id_) {
      this.$router.push({ path: '/productDetail', query: { id: id_ } })
    }
  }
}
</script>

<style lang="less" scoped>
.goods_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  li {
    width: 48%;
    img {
      width: 100%;
    }
  }
}
</style>
