<template>
  <div class="wrapper">
    <div class="list-bg" />
    <van-row class="header" type="flex" justify="space-between">
      <van-col class="head-arrow" @click="goBack"><van-icon name="arrow-left" color="#0b41cd" /></van-col>
      <van-col class="head-title">编辑</van-col>
      <van-col class="head-btn" @click="doSave">完成</van-col>
    </van-row>
    <van-row class="item">
      <van-col :span="20">
        <span class="name">
          <span style="font-weight: bold">{{ form.end_customer_code }}</span>
          <br />
          <span style="font-weight: bold">{{ form.end_customer_name }}</span>
        </span>
      </van-col>
      <van-col :span="4" class="status">
        <span v-if="form.data_confirm_status === '待确认'" class="red-span">待确认</span>
        <span v-if="form.data_confirm_status === '已确认'" class="green-span">已确认</span>
        <span v-if="form.data_confirm_status === '已失效'" class="gray-span">已失效</span>
        <span v-if="form.data_confirm_status === '当前无授权'" class="gray-span">当前无授权</span>
        <span v-if="form.data_confirm_status === '经销商新增'" class="gray-span">经销商新增</span>
      </van-col>
    </van-row>
    <van-row class="item">
      <van-col :span="8">
        <span class="title">BA</span>
      </van-col>
      <van-col :span="16">
        <span class="label">{{ form.BA }}</span>
      </van-col>
    </van-row>
    <van-row class="item">
      <van-col :span="8">
        <span class="title">BL</span>
      </van-col>
      <van-col :span="16">
        <span class="label">{{ form.BL }}</span>
      </van-col>
    </van-row>
    <van-row class="item">
      <van-col :span="8">
        <span class="title">平台交易单配送商</span>
      </van-col>
      <van-col :span="16">
        <span class="label">{{ form.platform_order_distributor }}</span>
      </van-col>
    </van-row>
    <van-form validate-first ref="form">
      <van-field
        readonly
        clickable
        input-align="right"
        :value="form.distributor_level2"
        name="distributor_level2"
        label="二级分销商"
        placeholder="点击选择"
        @click="openPicker('distributor_level2')"
        :rules="[{ required: false, message: '二级分销商不能为空' }]"
      />
      <van-field
        readonly
        clickable
        :clearable="checkClear('distributor_level3')"
        input-align="right"
        :value="form.distributor_level3"
        name="distributor_level3"
        label="三级分销商"
        placeholder="点击选择"
        @click="openPicker('distributor_level3')"
        :rules="[{ required: false, message: '三级分销商不能为空' }]"
      />
      <van-field
        readonly
        clickable
        :clearable="checkClear('distributor_level4')"
        input-align="right"
        :value="form.distributor_level4"
        name="distributor_level4"
        label="四级分销商"
        placeholder="点击选择"
        @click="openPicker('distributor_level4')"
        :rules="[{ required: false, message: '四级分销商不能为空' }]"
      />
      <van-field
        readonly
        clickable
        :clearable="checkClear('distributor_level5')"
        input-align="right"
        :value="form.distributor_level5"
        name="distributor_level5"
        label="五级分销商"
        placeholder="点击选择"
        @click="openPicker('distributor_level5')"
        :rules="[{ required: false, message: '五级分销商不能为空' }]"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          value-key="value"
          :columns="distributorList"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        >
          <!--          <template #columns-top>-->
          <!--            <van-row>-->
          <!--              <van-col span="18">-->
          <!--                <van-field-->
          <!--                  :maxlength="100"-->
          <!--                  v-model="form.dealer_feedback"-->
          <!--                  name="position"-->
          <!--                  label=""-->
          <!--                  placeholder="请输入要新增的分销商"-->
          <!--                />-->
          <!--              </van-col>-->
          <!--              <van-col span="6">-->
          <!--                <van-button class="btn addUser" type="info">确定</van-button>-->
          <!--              </van-col>-->
          <!--            </van-row>-->
          <!--          </template>-->
        </van-picker>
      </van-popup>
      <van-field
        readonly
        clickable
        input-align="right"
        :value="form.reasons_difference"
        name="editLabel"
        label="差异原因"
        placeholder="点击选择"
        @click="showPicker2 = true"
        :rules="[{ required: false, message: '差异原因不能为空' }]"
      />
      <van-popup v-model="showPicker2" position="bottom">
        <van-picker
          show-toolbar
          value-key="dictLabel"
          :columns="reasonOptions"
          @confirm="onConfirm2"
          @cancel="showPicker2 = false"
        />
      </van-popup>
      <van-field
        clearable
        :maxlength="200"
        input-align="right"
        v-model="form.dealer_feedback"
        name="position"
        label="经销商反馈"
        placeholder="请输入"
        :rules="[{ required: false, message: '经销商反馈不能为空' }]"
      />
    </van-form>
  </div>
</template>

<script>
import { getDicts } from '@/api/dict/data'
import { getDistributor } from '@/api/dataCenter/chain'
import _ from 'lodash'

export default {
  name: 'ChannelChainEdit',
  props: {
    detail: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      distributorList: [],
      reasonOptions: [],
      showPicker: false,
      showPicker2: false,
      selectedProp: '',
      form: {
        distributor_level2: '',
        distributor_level3: '',
        distributor_level4: '',
        distributor_level5: '',
        reasons_difference: '',
        dealer_feedback: ''
      },
      beforeEditInfo: {}
    }
  },
  watch: {
    detail: {
      handler(value) {
        this.init(value)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    checkClear() {
      return val => {
        if (val === 'distributor_level3') {
          if (this.form.distributor_level4) {
            return false
          } else {
            return true
          }
        } else if (val === 'distributor_level4') {
          if (this.form.distributor_level5) {
            return false
          } else {
            return true
          }
        } else if (val === 'distributor_level5') {
          return true
        }
      }
    }
  },
  created() {
    getDicts('reason_difference').then(({ data }) => {
      this.reasonOptions = data
    })
    this.getDistributorInfo()
  },
  methods: {
    async getDistributorInfo() {
      let params = {
        pageNum: 1,
        pageSize: 10000
      }
      const { rows } = await getDistributor(params)
      this.distributorList = rows.map(item => {
        return {
          label: item.distributorName,
          value: item.distributorName
        }
      })
    },
    init(obj) {
      this.form = _.cloneDeep(obj)
    },
    openPicker(prop) {
      if (prop === 'distributor_level2') {
        this.selectedProp = prop
        this.showPicker = true
      } else if (prop === 'distributor_level3') {
        if (this.form.distributor_level2) {
          this.selectedProp = prop
          this.showPicker = true
        } else {
          this.selectedProp = prop
          this.showPicker = false
        }
      } else if (prop === 'distributor_level4') {
        if (this.form.distributor_level3) {
          this.selectedProp = prop
          this.showPicker = true
        } else {
          this.selectedProp = prop
          this.showPicker = false
        }
      } else if (prop === 'distributor_level5') {
        if (this.form.distributor_level4) {
          this.selectedProp = prop
          this.showPicker = true
        } else {
          this.selectedProp = prop
          this.showPicker = false
        }
      }
    },
    onConfirm(data) {
      this.form[this.selectedProp] = data.value
      this.showPicker = false
    },
    onConfirm2(data) {
      this.form.reasons_difference = data.dictLabel
      this.showPicker2 = false
    },
    goBack() {
      this.$emit('close')
    },
    doSave() {
      this.$refs.form
        .validate()
        .then(() => {
          this.$emit('submit', this.form)
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}
/deep/.van-cell {
  border-bottom: 1px solid #ebedf0;
}
/deep/ .van-form {
  background: #fff;
}
/deep/ .van-toast--fail {
  width: 300px;
}
/deep/ .van-nav-bar__title {
  font-weight: bold !important;
}
/deep/ .van-field__label {
  color: #000;
}
/deep/.van-field__error-message {
  text-align: right;
}
.header-btn {
  color: #0b41cd;
}

.wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}

.header {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;

  .popover {
    color: #0b41cd;
    font-size: 16px;
    position: absolute;
    height: 52px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
  }

  .head-arrow {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    left: 16px;
  }

  .head-title {
    font-weight: 600;
  }

  .head-btn {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    right: 16px;
    font-size: 16px;
    color: #0b41cd;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 10px 16px;
  .name {
    color: #595959;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .title {
    color: #8c8c8c;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(10px) translateY(0px);
  }
  .label {
    color: #595959;
    font-size: 14px;
    display: flex;
    justify-content: right;
    align-items: center;
    transform: translateX(10px) translateY(0px);
  }
}

.green-span {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding: 1px 5px;
  background-color: #cbede8;
  color: #21201f;
  border-radius: 24px 24px 24px 24px;
}

.red-span {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding: 1px 5px;
  background-color: #ffb4b1;
  color: #21201f;
  border-radius: 24px 24px 24px 24px;
}

.gray-span {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding: 1px 5px;
  background-color: #f5f5f5;
  color: #21201f;
  border-radius: 24px 24px 24px 24px;
}

/deep/ .addUser {
  height: 30px !important;
  position: relative !important;
  top: -10px !important;
  margin-left: 10px;
}
</style>
