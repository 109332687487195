<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      @clickBtn="onSelect"
    >
    </form-table>
  </div>
</template>

<script>
import { dataCenter } from '@/utils/dataCenter'
import { getDicts } from '@/api/dict/data'
import { delCibHistory, historyVersion, downloadHistory } from '@/api/file'
import { previewFile } from '@/api/formTable'

export default {
  name: 'CIB_Warning',
  data() {
    return {
      btnData: [{ text: '下载' }],
      searchFormData: [
        { props: 'name', label: '文件名', type: 'input' },
        { props: 'year', label: '年份', type: 'date' },
        { props: 'month', label: '月份', type: 'DictSelect', options: [] },
        { props: 'productLine', label: '产品线', type: 'DictSelect', options: [] }
      ],
      tableOptions: [
        {
          prop: 'name',
          label: '文件名',
          type: 'fileName',
          rightTop: {
            left: {
              prop: 'year',
              label: '年份'
            },
            right: {
              prop: 'month',
              label: '月份',
              dictCode: 'sys_month',
              options: []
            }
          },
          rightBottom: { prop: 'productLine', label: '产品线', dictCode: 'sys_product_line', options: [] }
        },
        // {
        //   prop: 'year',
        //   label: '年份'
        // },
        // {
        //   prop: 'month',
        //   label: '月份',
        //   dictCode: 'sys_month'
        // },
        // {
        //   prop: 'productLine',
        //   label: '产品线',
        //   dictCode: 'sys_product_line'
        // },
        {
          prop: 'createTime',
          label: '上传时间'
        }
      ],
      headerTitle: 'CIB预警',
      queryUrl: '/bff-dp-mobile/cib-document/dealer-list',
      showPopover: false,
      historyInfo: {}
    }
  },
  created() {
    getDicts('sys_product_line').then(({ data }) => {
      this.searchFormData[3].options = data
      this.tableOptions[0].rightBottom.options = data
    })

    getDicts('sys_month').then(({ data }) => {
      this.searchFormData[2].options = data
      this.tableOptions[0].rightTop.right.options = data
    })
  },
  methods: {
    handleDelete(obj) {
      let params = {
        id: obj.cibDocumentId,
        fileId: obj.fileId
      }
      this.$dialog
        .alert({
          message: `是否确认删除该文件？删除后，经销商将看不到此文件`,
          showCancelButton: true
        })
        .then(() => {
          delCibHistory(params).then(res => {
            if (res.code === 200) {
              this.$toast.success('删除成功')
              this.$refs.baseForm.getList()
            } else {
              this.$toast.fail(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    showHistory(obj) {
      let params = {
        id: obj.cibDocumentId,
        name: encodeURIComponent(obj.name)
      }
      historyVersion(params).then(res => {
        this.historyInfo = { ...res.data }
      })
    },
    downloadLastFile(obj) {
      downloadHistory(obj.fileId, true)
    },
    onSelect(text, queryParams, list) {
      this.download(list)
    },
    download(list) {
      dataCenter.downloadFile(list)
    },
    showDetail(obj) {
      let params = {
        fileId: obj.fileId,
        watermarkFlag: true
      }
      previewFile(params, obj.name)
    }
  }
}
</script>

<style></style>
