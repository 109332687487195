<template>
  <van-row class="main-container">
    <van-row class="header" :class="theme.headerClass">
      <van-row class="title">
        <van-col>绩效 > KPI绩效考核结果</van-col>
        <van-col class="date" v-if="kpiResult?.kpiList?.length > 0"
          >{{ kpiResult.year }}年 {{ kpiResult.periodValueLabel }}</van-col
        >
      </van-row>
      <van-row class="action">
        <van-col
          class="item"
          v-for="item in kpiResult.kpiList"
          :key="item.productLineName"
          :class="currentProduct.productLineName === item.productLineName ? 'active' : ''"
          @click="changeShowType(item)"
          >{{ item.productLineName | dictLabelByValue(productLineOptions) }}</van-col
        >
      </van-row>
    </van-row>
    <van-row class="desc">当前绩效考核评分</van-row>
    <van-row class="result">
      <van-col class="score" @click="goToDetail(currentProduct)">{{ currentProduct.totalScore }}</van-col>
      <van-col class="history-score">上季度评分：{{ currentProduct.lastQuarterTotalScore }}分</van-col>
    </van-row>
    <div class="bg-color" :class="theme.bgClass" />
    <van-image class="bg-img" :src="require('@/assets/images/home-kpi.png')" />
  </van-row>
</template>
<script>
import { getKpiResult } from '@/api/home'
import Empty from '@/views/home/components/Empty.vue'
import { getDicts } from '@/api/dict/data'
import get from 'lodash/get'
import find from 'lodash/find'

const THEME_CONFIG = {
  yellow: {
    background: require('@/assets/images/home-jixiao.png'),
    bgClass: 'bg-yellow',
    headerClass: 'header-yellow'
  },
  pink: {
    background: require('@/assets/images/home-jixiao.png'),
    bgClass: 'bg-pink',
    headerClass: 'header-pink'
  }
}

export default {
  name: 'TabCard',
  components: { Empty },
  props: {
    themeType: {
      type: String,
      default: 'yellow'
    }
  },
  data() {
    return {
      currentProduct: {},
      theme: THEME_CONFIG[this.themeType],
      currentTime: new Date().getFullYear() + '年 ' + new Date().getMonth() + '月',
      kpiResult: {},
      productLineOptions: [],
      periodOptions: []
    }
  },
  created() {
    this.getKpiResult()
    this.getDicts()
  },
  methods: {
    getDicts() {
      getDicts('sys_product_line').then(res => {
        this.productLineOptions = res.data
      })
      getDicts('period_value').then(res => {
        this.periodOptions = res.data
      })
    },
    changeShowType(currentProduct) {
      this.currentProduct = currentProduct
    },
    getKpiResult() {
      let params = {}
      getKpiResult(params)
        .then(res => {
          this.kpiResult = { ...res.data }
          this.currentProduct = this.kpiResult.kpiList[0]
        })
        .catch(err => {
          console.log(err)
        })
    },
    goToDetail(obj) {
      let title = ''
      if (obj.kpiAssessmentName) {
        title = obj.kpiAssessmentName.substr(0, obj.kpiAssessmentName.lastIndexOf('.'))
      } else {
        title = `${obj.year}_${obj.periodValue}_${obj.baValue}_${obj.productLineValue}`
      }
      this.$router.push({
        path: `/dataCenter/kpi/kpiScore/dear/detail/${obj.kpiAssessmentId}`,
        query: {
          title: encodeURIComponent(title),
          period: get(find(this.periodOptions, { dictValue: obj.periodValue }), 'dictLabel')
        }
      })
    }
  },
  mounted() {}
}
</script>

<style scoped lang="less">
.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  z-index: 1;
  overflow: hidden;
  .header {
    height: 75px;
    box-sizing: border-box;
    border-radius: 0 0 100px 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    padding: 15px 16px;
    position: relative;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        color: #595959;
        font-size: 12px;
        font-weight: 400;
        margin-left: auto;
      }
    }
    .action {
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      line-height: 1;
      max-width: 250px;
      overflow-x: auto;
      .item {
        padding: 0 12px;
        color: #595959;
        font-size: 14px;
        font-weight: 500;
        margin-right: 4px;
        border-radius: 6px 6px 0 0;
        background: #fff7f2;
        min-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
      }
      .active {
        color: #ff7d2a;
        padding: 0 12px;
        min-height: 30px;
      }
    }
  }
  .desc {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 400;
    margin-top: 27px;
    margin-left: 16px;
  }
  .result {
    padding-left: 16px;
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 40px;
    line-height: 1;
    .score {
      font-size: 32px;
      color: #262626;
      font-weight: 400;
      margin-right: 8px;
    }
    .history-score {
      background: #fce3d3;
      border-radius: 16px;
      height: 24px;
      padding: 0 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      color: #434343;
      font-weight: 400;
    }
  }
}
.bg-img {
  width: 120px;
  height: 120px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.bg-color {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
}
.header-yellow {
  background: linear-gradient(to right, rgba(252, 228, 212, 1), rgba(252, 228, 212, 0.33));
  border: 1px solid #ffe2cf;
}
.header-pink {
  background: linear-gradient(to right, rgba(253, 244, 212, 1), rgba(252, 244, 212, 0.55));
  border: 1px solid #ffe584;
}

.bg-yellow {
  background: linear-gradient(to top, rgba(255, 246, 239, 1) 15%, rgba(255, 240, 227, 0.5) 50%, rgba(255, 255, 255, 1));
}

.bg-pink {
  background: linear-gradient(to top, rgba(253, 249, 236, 1) 30%, rgba(255, 246, 218, 1) 50%, rgba(255, 255, 255, 1));
}
.empty-container {
  height: 130px;
}
</style>
