<template>
  <div class="main-container">
    <div class="list-bg" />
    <van-row class="header" type="flex" justify="space-between">
      <van-col class="head-arrow" @click="goBack"><van-icon name="arrow-left" color="#0b41cd" /></van-col>
      <van-col class="head-title">{{ headerTitle }}</van-col>
      <van-col>
        <van-popover
          class="popover"
          placement="bottom-end"
          v-model="showPopover"
          trigger="click"
          :actions="btnData"
          @select="onSelect"
        >
          <template #reference>
            <van-icon name="ellipsis" />
          </template>
        </van-popover>
      </van-col>
    </van-row>
    <van-row class="title" type="flex" justify="space-between">
      <van-col class="total">
        <span>共{{ total }}条数据</span>
      </van-col>
      <van-col class="filter" @click="filterData"
        ><span>筛选</span><van-icon name="filter-o" color="#0B41CD"
      /></van-col>
    </van-row>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-collapse v-model="activeNames" class="collapse">
          <van-collapse-item v-for="item in tableData" :key="item.id" name="index">
            <template #title>
              <van-row type="flex" align="center" class="card">
                <van-col span="3" style="color: coral"><van-icon name="manager" class="icon" /></van-col>
                <van-col span="21" class="card-text">
                  <van-row class="name">{{ item.distributorName }} </van-row>
                </van-col>
              </van-row>
            </template>
            <div class="collapse-content">
              <van-row class="divider">
                <van-col span="24"><van-cell title="更新人" :value="item.updateBy || '-'" /></van-col>
                <van-col span="24"><van-cell title="更新时间" :value="item.updateTime || '-'" /></van-col>
              </van-row>
              <van-row class="foot-btn" v-if="orgId != 1002 && orgId != 1003 && !isDealerExpire">
                <span @click="handleUpdate(item)">编辑</span>
                <span class="vertical-line">|</span>
                <span @click="handleDelete(item)">删除</span>
              </van-row>
            </div>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="show" class="popup-box" closeable round position="bottom" @close="cancel">
      <van-cell-group class="group-box">
        <van-field
          clearable
          :maxlength="100"
          input-align="right"
          v-model="queryParams.distributorName"
          name="distributorName"
          label="分销商名称"
          placeholder="请输入"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            value-key="label"
            :columns="distributorList"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
      </van-cell-group>
      <van-row class="popup-foot-btn" gutter="20">
        <van-button class="btn margin-lf-10" type="default" @click="reset">重置</van-button>
        <van-button class="btn margin-lf-10" type="info" @click="handleQuery">确定</van-button>
      </van-row>
    </van-popup>

    <van-dialog
      v-model="showFlag"
      :title="title"
      cancelButtonColor="#8c8c8c"
      confirmButtonColor="#ee0a24"
      :showCancelButton="true"
      :closeOnClickOverlay="false"
      :beforeClose="onBeforeClose"
      @confirm="editConfirm"
    >
      <van-form validate-first @submit="onSubmit" ref="editForm">
        <van-row style="height: 80px; margin-top: 20px">
          <van-col span="24">
            <van-field
              :maxlength="100"
              input-align="right"
              v-model="editForm.distributorName"
              name="distributorName"
              label="分销商名称"
              placeholder="请输入"
              :rules="[{ required: true, message: '分销商名称不能为空' }]"
            />
            <!--            <van-field-->
            <!--              readonly-->
            <!--              clickable-->
            <!--              required-->
            <!--              :value="editForm.distributorName"-->
            <!--              name="distributorName"-->
            <!--              label="分销商名称"-->
            <!--              placeholder="点击选择"-->
            <!--              @click="showPicker2 = true"-->
            <!--              :rules="[{ required: true, message: '分销商名称不能为空' }]"-->
            <!--            />-->
            <!--            <van-popup v-model="showPicker2" position="bottom" get-container="body">-->
            <!--              <van-picker-->
            <!--                show-toolbar-->
            <!--                value-key="dictLabel"-->
            <!--                :columns="distributorList"-->
            <!--                @confirm="onConfirm2"-->
            <!--                @cancel="showPicker2 = false"-->
            <!--              />-->
            <!--            </van-popup>-->
          </van-col>
        </van-row>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import { deleteDistributor, getDistributor, addDistributor, editDistributor } from '@/api/dataCenter/chain'
export default {
  name: 'ChainDealer',
  data() {
    return {
      headerTitle: '分销商清单',
      headBtnText: '下载',
      btnData: [{ text: '下载' }, { text: '新增' }],
      showPopover: false,
      loading: false,
      finished: false,
      refreshing: false,
      showPicker: false,
      activeNames: ['index'],
      show: false,
      distributorList: [],
      searchLabel: '',
      tableData: [],
      queryParams: {
        pageNum: 0,
        pageSize: 10,
        orderByColumn: 'updateTime',
        isAsc: 'desc'
      },
      total: 0,
      showFlag: false,
      title: '新增分销商',
      editForm: {
        distributorName: ''
      },
      showPicker2: false,
      selectedData: {}
    }
  },
  computed: {
    orgId() {
      return this.$store.state.user.userInfo.orgId
    },
    isDealerExpire() {
      return this.$store.state.user.userInfo.isDealerExpire
    }
  },
  created() {
    this.getDistributorInfo()
    if (this.orgId == 1002 || this.orgId == 1003 || this.isDealerExpire) {
      this.btnData = [{ text: '下载' }]
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async getDistributorInfo() {
      let params = {
        pageNum: 1,
        pageSize: 10000
      }
      const { rows } = await getDistributor(params)
      this.distributorList = rows.map(item => {
        return {
          label: item.distributorName,
          value: item.distributorName
        }
      })
    },
    onLoad() {
      this.loading = true
      // 设置定时器做节流处理，避免滚动时频繁触发加载事件
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.queryParams.pageNum++
          this.getList()
          // 清除定时器，释放内存
          this.timer = null
        }, 1000)
      }
    },
    getList() {
      this.loading = true
      getDistributor(this.queryParams)
        .then(response => {
          this.loading = false
          this.refreshing = false
          this.total = response.total || 0
          this.tableData = [
            ...this.tableData,
            ...response.rows.map(item => {
              return {
                ...item
              }
            })
          ]
          if (this.tableData.length >= this.total) {
            this.finished = true
          }
        })
        .catch(() => {
          this.loading = false
          this.refreshing = false
        })
    },
    handleUpdate(data) {
      this.selectedData = { ...data }
      this.title = '编辑分销商'
      this.editForm.distributorName = data.distributorName
      this.showFlag = true
    },
    handleDelete(data) {
      this.$dialog
        .alert({
          message: `是否确认删除该分销商？`,
          showCancelButton: true,
          cancelButtonColor: '#8c8c8c',
          confirmButtonColor: '#ee0a24'
        })
        .then(() => {
          let params = {
            id: data.distributorId
          }
          this.loading = true
          deleteDistributor(params).then(() => {
            this.$toast.success('删除成功')
            this.getDistributorInfo()
            this.onRefresh()
            this.loading = false
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    onSelect(data) {
      if (data.text === '下载') {
        this.download('/bff-dp-mobile/all-channel-data/dealer-export', {}, `分销商信息_${new Date().getTime()}.xlsx`, {
          method: 'get',
          params: { ...this.queryParams, pageNum: undefined, pageSize: undefined }
        })
      } else if (data.text === '新增') {
        this.title = '新增分销商'
        this.editForm.distributorName = ''
        this.showFlag = true
      }
    },
    onConfirm(data) {
      this.searchLabel = data.label
      this.queryParams.distributorName = data.label
      this.showPicker = false
    },
    onConfirm2(data) {
      this.editForm.distributorName = data.dictLabel
      this.showPicker2 = false
    },
    filterData() {
      this.show = true
    },
    cancel() {
      this.show = false
    },
    reset() {
      this.queryParams = {
        pageNum: 0,
        pageSize: 10,
        orderByColumn: 'updateTime',
        isAsc: 'desc'
      }
      this.searchLabel = ''
    },
    handleQuery() {
      this.onRefreshBySearch()
      this.cancel()
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams = {
        pageNum: 0,
        pageSize: 10,
        orderByColumn: 'updateTime',
        isAsc: 'desc'
      }
      this.total = 0
      this.tableData = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.getList()
    },
    onRefreshBySearch() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams = {
        ...this.queryParams,
        pageNum: 0,
        pageSize: 10,
        orderByColumn: 'updateTime',
        isAsc: 'desc'
      }
      this.total = 0
      this.tableData = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.getList()
    },
    onBeforeClose(action, done) {
      if (action === 'confirm') {
        return done(false)
      } else {
        return done()
      }
    },
    editConfirm() {
      this.onSubmit()
    },
    onSubmit() {
      this.$refs.editForm
        .validate()
        .then(() => {
          if (this.title === '新增分销商') {
            let params = {
              distributorName: this.editForm.distributorName
            }
            addDistributor(params)
              .then(res => {
                this.$toast.success('新增成功')
                this.getDistributorInfo()
                this.onRefresh()
                this.showFlag = false
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            let params = {
              distributorId: this.selectedData.distributorId,
              distributorName: this.editForm.distributorName
            }
            editDistributor(params)
              .then(res => {
                this.$toast.success('修改成功')
                this.getDistributorInfo()
                this.onRefresh()
                this.showFlag = false
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.main-container {
  padding: 0 16px;
}

.header {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;

  .popover {
    color: #0b41cd;
    font-size: 16px;
    position: absolute;
    height: 52px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
  }

  .head-arrow {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    left: 16px;
  }

  .head-title {
    font-weight: 600;
  }

  .head-btn {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    right: 16px;
    font-size: 16px;
    color: #0b41cd;
  }
}

.title {
  padding: 6px 0 12px;

  .total {
    font-size: 14px;
    line-height: 24px;
    color: #969799;
  }

  .filter {
    color: #0b41cd;
    font-size: 14px;
  }
}

.popup-box {
  overflow-y: hidden;
  height: 450px;

  .van-cell {
    font-size: 14px;
    line-height: 20px;
  }

  .group-box {
    margin-top: 40px;
    height: 100%;
    overflow-y: scroll;
  }

  .popup-foot-btn {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    box-sizing: border-box;

    .btn {
      width: 80px;
    }

    .btn-120 {
      width: 120px;
    }
  }
}

.margin-lf-10 {
  margin-left: 10px;
}

.divider {
  border-bottom: 0.5px solid #ebedf0;
}

.collapse {
  padding: 2px;
  .van-collapse-item {
    // overflow: hidden;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe) !important;
    /deep/.van-collapse-item__content {
      border-radius: 0 0 8px 8px;
    }
    .icon {
      margin-top: 5px;
      margin-left: 10px;
      font-size: 20px;
    }
    .card-text {
      color: #8c8c8c;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #0b41cd;
        font-size: 14px;
        font-weight: 600;
        // margin-bottom: 2px;
      }
      .email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
      .status {
        // margin-bottom: 2px;
        font-size: 12px;
        .table-status {
          display: flex;
          justify-content: right;
          align-items: center;
          .dotClass {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
      .phone {
        font-size: 12px;
        text-align: end;
      }
    }
    /deep/.van-cell {
      align-items: center;
      padding: 10px 8px;
    }
    .diy-cell-value {
      color: #969799;
      text-align: right;
    }

    .collapse-content {
      /deep/.van-cell {
        align-items: normal;
        font-size: 12px;
        line-height: 14px;
        padding-left: 0;
      }

      .table-mark {
        margin-left: 8px;
        padding: 0.2vw 0.8vw;
        border-radius: 12px;
      }
      .mark-del {
        background-color: #ebeef5;
      }
    }
    .foot-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 8px;
      color: #0b41cd;
      border-top: 0.5px solid #ebedf0;
      .vertical-line {
        margin: 0 8px;
      }
    }
    .disable-button {
      color: #a0cfff;
    }
  }
}
</style>
