import request from '@/utils/request'

// 查询列表个数
export function deleteTask(instanceIdList) {
  return request({
    url: `/bff-dp-mobile/flowable-instance/delete/${instanceIdList}`,
    method: 'delete'
  })
}

//取消
export function stopTask(data) {
  const category = data.category
  let url = '/bff-dp-mobile/task-flow/stop-process'

  switch (category) {
    case 'CIB_CONF_COMPLAINT':
      url = `/bff-dp-mobile/cib-confirmation/appeal-process-cancel`
      break
    case 'INSTRUMENT_COMPLAINT':
      url = `/bff-dp-mobile/instrument-data/cancel-appeal`
      data.processId = data.processInstanceId
      data.remark = data.comment
      break
    case 'KPI_RESULT_COMPLAINT':
      url = `/bff-dp-mobile/kpi-assessmentResult/cancelAppeal`
      break
    case 'CONTACT_CHANGE':
      url = `/user/dealerUser/company-user-appeal-cancel`
      break
    default:
      break
  }
  return request({
    url: url,
    method: 'post',
    data: data
  })
}

//查询自定义任务详情
export function getTaskDetailById(query) {
  return request({
    url: '/bff-dp-mobile/define-task/form/' + query.id,
    method: 'get'
  })
}

export function getTaskDetailByTaskId(query) {
  return request({
    url: '/bff-dp-mobile/define-task/task/' + query.id + '/' + query.taskId,
    method: 'get'
  })
}

//提交自定义任务信息
export function submitTaskDetail(data) {
  return request({
    url: '/bff-dp-mobile/task-flow/complete-task',
    method: 'post',
    data: data
  })
}
