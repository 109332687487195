<!-- 我的 -->
<template>
  <div class="user-box">
    <div class="list-bg" />
    <div class="head-title">业务中心</div>
    <van-list>
      <van-list>
        <van-collapse v-model="activeNames" class="collapse" accordion>
          <van-collapse-item :name="item.meta.title" v-for="(item, index) in userRouter" :key="index">
            <template #title>
              <van-row type="flex" align="center" class="card">
                <van-col class="icon-container"
                  ><van-image
                    width="24px"
                    height="24px"
                    :src="require('../../assets/icons/' + item.meta.icon + '.svg')"
                /></van-col>
                <van-row class="name">{{ item.meta.title }}</van-row>
                <van-col span="6" class="card-text"></van-col>
              </van-row>
            </template>
            <div class="collapse-content">
              <van-button
                class="menu-button"
                plain
                type="info"
                v-for="menuItem in item.children"
                :key="menuItem.meta.title"
                @click="goToPage(menuItem)"
                >{{ menuItem.meta.title }}</van-button
              >
            </div>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-list>
  </div>
</template>

<script>
import { getRouters } from '@/api/login'
import { cloneDeep } from 'lodash'

const DATA_CENTER_MENU = [
  {
    title: '授权',
    icon: 'menu-shouquan',
    children: [
      {
        title: 'LOA信息',
        url: '/dataCenter/daAndLoa/loa'
      }
    ]
  },
  {
    title: '业绩',
    icon: 'menu-jixiao',
    children: [
      {
        title: '经销商指标',
        url: '/dataCenter/bgi/dealerBGI'
      },
      {
        title: 'SAP Scorecard',
        url: '/dataCenter/performance/sap'
      },
      {
        title: 'RCR Scorecard',
        url: '/dataCenter/performance/rcr'
      }
    ]
  },
  {
    title: '绩效',
    icon: 'menu-cib',
    children: [
      {
        title: 'KPI绩效政策',
        url: '/dataCenter/cib/kpiPolicy'
      },
      {
        title: 'KPI绩效考核结果',
        url: '/dataCenter/kpi/kpiScore'
      }
    ]
  },
  {
    title: '激励',
    icon: 'menu-tongzhi',
    children: [
      {
        title: '激励政策',
        url: '/dataCenter/cib/policy'
      },
      {
        title: 'CIB框架',
        url: '/dataCenter/cib/framework'
      },
      {
        title: 'CIB预警',
        url: '/dataCenter/cib/warning'
      },
      {
        title: 'CIB确认函',
        url: '/dataCenter/cib/confirmation/index'
      }
    ]
  },
  {
    title: '渠道数据',
    icon: 'menu-qudao',
    children: [
      {
        title: '渠道数据承知',
        url: '/dataCenter/channel-data/inventory'
      },
      {
        title: '渠道数据反馈',
        url: '/dataCenter/channel-data/feedback'
      },
      {
        title: '全渠道链',
        url: '/dataCenter/channel-data/chain'
      }
    ]
  },
  {
    title: '仪器',
    icon: 'menu-yiqi',
    children: [
      {
        title: '仪器管理政策',
        url: '/dataCenter/instrument/management/policy'
      },
      {
        title: '仪器清单',
        url: '/dataCenter/instrument/list'
      }
    ]
  },
  {
    title: '合同',
    icon: 'menu-hetong',
    children: [
      {
        title: 'DA合同',
        url: '/dataCenter/daAndLoa/DAContract'
      },
      {
        title: 'CIB合同',
        url: '/dataCenter/DA/LOA/contract'
      }
    ]
  },
  {
    title: '信息共享中心',
    icon: 'menu-xitong',
    children: [
      {
        title: '产品注册证',
        url: '/dataCenter/productCertificate'
      },
      {
        title: '产品召回',
        url: '/dataCenter/tool/productRecalls'
      },
      {
        title: '上市及退市',
        url: '/dataCenter/tool/listedAndDelisting'
      },
      {
        title: 'RDSZ上市资料',
        url: '/dataCenter/productQualification/rdsz'
      },
      {
        title: '其他资讯',
        url: '/dataCenter/tool/otherConsultation'
      }
    ]
  }
]
export default {
  name: 'user',
  data() {
    return {
      activeNames: [1],
      AuthedMenu: [],
      menuList: [],
      routerData: [],
      userRouter: []
    }
  },
  created() {
    if (this.$route.params.title) {
      this.activeNames = this.$route.params.title
    }
    this.getRouters()
    // this.userRouter = this.$store.state.user.authRouters
  },
  methods: {
    goToPage(route) {
      if (route.path === '/commonModule/list') {
        let query = {
          ...JSON.parse(route.query),
          title: this.activeNames,
          pageTitle: route.meta.title
        }
        this.$router.push({ path: '/dataCenter' + route.path, query: query })
      } else {
        this.$router.push({ path: '/dataCenter' + route.path, query: { title: this.activeNames } })
      }
    },
    getRouters() {
      // this.AuthedMenu = this.$store.state.user.routes
      // this.filterMenus()
      getRouters().then(res => {
        this.userRouter = res.data
        const excludeRouterName = ['总览', '通知', '内部管理层报告', '任务流程中心', '用户中心', '系统管理']
        this.userRouter = this.userRouter.filter(item => !excludeRouterName.includes(item?.meta?.title) && !item.hidden)
        this.userRouter = this.userRouter.map(router => {
          router.children = router.children.filter(item => !item.hidden)
          return router
        })
        console.log(this.userRouter)
      })
    },
    filterMenus() {
      let dataCenterMenu = cloneDeep(DATA_CENTER_MENU)
      dataCenterMenu = dataCenterMenu
        .map(item => {
          item.children = item.children.filter(children => this.AuthedMenu.includes(children.title))
          return item
        })
        .filter(item => this.AuthedMenu.includes(item.title))
      this.menuList = dataCenterMenu
    }
  }
}
</script>
<style lang="less" scoped>
.user-box {
  min-height: 70vh;
  box-sizing: border-box;
  padding: 0 16px 50px;
}
.list-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 200vh;
  z-index: -1;
  background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
}
.collapse {
  .van-collapse-item {
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
    /deep/.van-collapse-item__content {
      padding-bottom: 0;
      border-radius: 0 0 8px 8px;
    }
    .collapse-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    /deep/.van-cell--clickable:active {
      background-color: transparent;
    }
  }
}

.menu-button {
  min-width: 48%;
  margin-bottom: 12px;
}

.menu-icon {
  color: #0b41cd;
  margin-right: 8px;
}
.name {
  color: #0b41cd;
  font-size: 14px;
  font-weight: 500;
}
.head-title {
  font-size: 24px;
  font-weight: 600;
  color: #0b41cd;
  padding-bottom: 27px;
  padding-top: 17px;
}

/deep/.van-collapse-item__title .van-cell__right-icon::before {
  color: #0b41cd;
  transform: rotate(0deg);
}

/deep/.van-collapse-item__title--expanded .van-cell__right-icon::before {
  transform: rotate(90deg);
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
</style>
