<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      :initSearchParams="initSearchParams"
      @clickBtn="onSelect"
      @submitBtn="handleSubmit"
      @on-search="handleSearchData"
      :query-permission="['system:notice:query']"
      :is-need-operate="true"
      :more-column="true"
      :more-search="true"
      :isCardDisabled="false"
      :isCheckbox="false"
      :hideBtnWithEmptyData="true"
      :refreshMethod="true"
      @on-refresh="refreshData"
    >
      <template slot="custom-title">
        <div class="contact" v-if="contactUser">
          <van-image
            width="15"
            height="15"
            :src="require('@/assets/images/dealer-tree-people.svg')"
            style="margin-right: 5px"
          />
          <span>罗氏区域联系人</span>
          <span style="font-weight: bold; padding-left: 10px; width: 60%" v-if="contactUser.indexOf('/') > -1">
            <div v-for="(item, index) in contactUser.split('/')" :key="index">
              {{ item }}
            </div>
          </span>
          <span style="font-weight: bold; padding-left: 10px; width: 60%" v-else>
            {{ contactUser }}
          </span>
        </div>
      </template>
      <template slot="table-btn" slot-scope="data">
        <span @click="setMoreColumn(data.row)" style="margin-top: 10px"
          >{{ data.row.isShowMoreColumn ? '收起' : '展示' }}更多字段</span
        >
      </template>
      <template #column-item="scope">
        <div>
          <van-row class="item">
            <van-col :span="20">
              <span class="name"
                ><span style="font-weight: bold">{{ scope.row['data_label'] }}</span
                ><br />
                {{ scope.row['roche_product_name'] }}</span
              >
            </van-col>
            <van-col :span="6" class="year">
              <div>{{ scope.row['year'] }}<span class="line">-</span>{{ scope.row['month'].padStart(2, '0') }}</div>
            </van-col>
          </van-row>
        </div>
      </template>
      <template #column-item-form="scope">
        <template
          v-if="
            scope.item.prop === 'dealer_reason_feedback' &&
            checkSubmitFlag &&
            orgId != 1002 &&
            orgId != 1003 &&
            !isDealerExpire
          "
        >
          <van-field
            readonly
            clickable
            :name="scope.item.prop"
            :value="scope.row.dealer_reason_feedback"
            :label="scope.item.label"
            placeholder="点击选择"
            input-align="right"
            label-class="titleClass"
            style="font-size: 12px"
            @click="openPicker(scope.row, scope.item, scope.index)"
          >
            <template #label>
              <span>{{ scope.item.label }}</span>
              <span style="color: #0b41cd">*</span>
            </template>
          </van-field>
          <van-popup v-model="showPicker" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              value-key="dictLabel"
              :columns="options"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
        </template>
        <template
          v-else-if="
            scope.item.prop === 'dealer_reason_description' &&
            checkSubmitFlag &&
            orgId != 1002 &&
            orgId != 1003 &&
            !isDealerExpire
          "
        >
          <van-field
            v-model="scope.row[scope.item.prop]"
            :label="scope.item.label"
            placeholder="请输入"
            :maxlength="200"
            input-align="right"
            label-class="titleClass"
            style="font-size: 12px"
            clearable
          />
        </template>
        <template v-else>
          <van-cell :title="scope.item.label" :value="scope.row[scope.item.prop]" style="font-size: 12px">
            <template #title v-if="scope.item.prop === 'dealer_reason_feedback'">
              <span>{{ scope.item.label }}</span>
              <span style="color: #0b41cd">*</span>
            </template>
          </van-cell>
        </template>
      </template>
    </form-table>
  </div>
</template>

<script>
import { getDicts } from '@/api/dict/data'
import { getContactUser, getTableHeader, submitFeedback, checkBeforeSubmit } from '@/api/dataCenter/channelData'
export default {
  name: 'FeedbackList',
  data() {
    return {
      contactUser: '',
      btnData: [],
      searchFormData: [
        // {
        //   props: 'year',
        //   label: '年份',
        //   type: 'date',
        //   placeholder: '请选择年份',
        //   valueFormat: 'yyyy',
        //   dateFormat: 'yyyy',
        //   dateType: 'year'
        // },
        // {
        //   props: 'month',
        //   label: '月份',
        //   type: 'DictSelect',
        //   placeholder: '请选择月份',
        //   dictCode: 'sys_month',
        //   multiple: false
        // },
        {
          props: 'dataLabel',
          label: '数据标签',
          type: 'DictSelect',
          dictCode: 'sys_channel_data_tag',
          multiple: false
        },
        {
          props: 'rocheProductCode',
          label: '产品编号',
          type: 'input',
          isMore: true
        },
        {
          props: 'rocheProductName',
          label: '产品名称',
          type: 'input'
        },
        {
          props: 'ba',
          label: '部门',
          type: 'DictSelect',
          dictCode: 'sys_channel_dealer_department',
          multiple: false,
          isMore: true
        },
        {
          props: 'productLine',
          label: '产品线',
          type: 'DictSelect',
          dictCode: 'sys_product_line',
          multiple: false,
          isMore: true
        },
        {
          props: 'terminalCode',
          label: '终端代码',
          type: 'input',
          isMore: true
        },
        {
          props: 'terminalName',
          label: '终端名称',
          type: 'input',
          isMore: true
        }
      ],
      tableOptions: [],
      headerTitle: '渠道数据反馈',
      queryUrl: '/bff-dp-mobile/channel-data/data/list',
      showPicker: false,
      options: [],
      reasonOptions1: [],
      reasonOptions2: [],
      selectedIndex: null,
      selectedItem: {},
      selectedData: {},
      checkSubmitFlag: false,
      initSearchParams: {
        pageSize: 300,
        orderByColumn: 'data_label',
        isAsc: 'asc'
      }
    }
  },
  created() {
    this.checkSubmit()
    this.getTableHeader()
    this.getContactUser()
    getDicts('channel_feedback_reason_type').then(({ data }) => {
      this.reasonOptions1 = data.filter(item => item.dictValue === '负销量')
      this.reasonOptions2 = data.filter(item => item.dictValue === '负库存')
    })
  },
  beforeDestroy() {
    if (this.checkSubmitFlag) {
      let filterData = this.$refs.baseForm.tableData.map(item => {
        return {
          id: item.id,
          dealerReasonFeedback: item.dealer_reason_feedback,
          dealerReasonDescription: item.dealer_reason_description,
          year: item.year,
          month: item.month
        }
      })
      let params = {
        detailsSubmitDtoList: filterData,
        status: '0',
        year: filterData[0].year,
        month: filterData[0].month
      }
      submitFeedback(params)
        .then(() => {})
        .catch(() => {})
    }
  },
  computed: {
    orgId() {
      return this.$store.state.user.userInfo.orgId
    },
    isDealerExpire() {
      return this.$store.state.user.userInfo.isDealerExpire
    }
  },
  methods: {
    checkSubmit() {
      checkBeforeSubmit()
        .then(res => {
          this.checkSubmitFlag = res.data
          if (this.checkSubmitFlag && this.orgId != 1002 && this.orgId != 1003 && !this.isDealerExpire) {
            this.btnData = [{ text: '导出' }, { text: '提交' }]
          } else {
            this.btnData = [{ text: '导出' }]
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getTableHeader() {
      getTableHeader(1).then(res => {
        let list = res.data.filter(
          item => !['month', 'data_label', 'roche_product_name', 'year'].includes(item.columnName)
        )
        list = list.map((item, index) => {
          return {
            ...item,
            prop: item.columnName,
            label: item.columnBusinessName,
            isMore: item.isMore == '1',
            isEdit: item.columnName === 'dealer_reason_feedback' || item.columnName === 'dealer_reason_description'
          }
        })
        this.tableOptions = [
          {
            type: 'fileName',
            hideFileIcon: true
          },
          ...list
        ]
      })
    },
    async handleSearchData(data) {
      await this.checkSubmit()
      data.forEach(item => {
        if (item.dealer_reason_feedback && item.dealer_reason_feedback !== '') {
          this.$set(item, 'isSubmit', false)
          this.$set(item, 'dataLabel', item.dealer_reason_feedback)
        } else {
          this.$set(item, 'isSubmit', true)
          this.$set(item, 'dataLabel', '')
        }
      })
    },
    onSelect(_, searchParams, selectedData) {
      const selectedFileIdList = [...selectedData].map(item => {
        return item.id
      })
      const requestParams = {
        ...searchParams,
        orderByColumn: 'data_label',
        isAsc: 'asc',
        ids: selectedFileIdList.join(',')
      }
      this.download('/bff-dp-mobile/channel-data/data/export', {}, `渠道数据反馈_${new Date().getTime()}.xlsx`, {
        method: 'get',
        params: requestParams
      })
    },
    setMoreColumn(data) {
      const isShowMoreColumn = data.isShowMoreColumn ? !data.isShowMoreColumn : true
      this.$set(data, 'isShowMoreColumn', isShowMoreColumn)
    },
    getContactUser() {
      getContactUser().then(res => {
        let name = ''
        res.data.map((item, index) => {
          name += item.contactName
          if (item.email) {
            name = name + '(' + item.email + ')'
          }
          if (res.data.length !== index + 1) {
            name += '/'
          }
        })
        this.contactUser = name
      })
    },
    openPicker(data, item, index) {
      this.showPicker = true
      if (data.data_label === '负销量') {
        this.options = this.reasonOptions1
      } else {
        this.options = this.reasonOptions2
      }
      this.selectedIndex = index
      this.selectedItem = item
      this.selectedData = data
    },
    onConfirm(value) {
      this.$set(this.selectedData, 'dealer_reason_feedback', value.dictLabel)
      this.$set(this.selectedData, 'dataLabel', value.dictLabel)
      this.$refs.baseForm.tableData.splice(this.selectedIndex, 1, this.selectedData)
      this.showPicker = false
    },
    handleSubmit(_, searchParams, tableData) {
      if (!this.checkSubmitFlag) {
        this.$toast.fail('不能提交反馈！')
        return false
      }
      let flag = 0
      tableData.forEach(item => {
        if (!item.dealer_reason_feedback || item.dealer_reason_feedback === '') {
          flag++
        }
      })
      if (flag > 0) {
        this.$toast.fail('请完善所有的反馈原因信息再提交！')
        return false
      }
      let filterData = tableData.map(item => {
        return {
          id: item.id,
          dealerReasonFeedback: item.dealer_reason_feedback,
          dealerReasonDescription: item.dealer_reason_description,
          year: item.year,
          month: item.month
        }
      })
      let params = {
        detailsSubmitDtoList: filterData,
        status: '1',
        year: filterData[0].year,
        month: filterData[0].month
      }
      this.$dialog
        .confirm({
          message: '确定提交，提交后当前数据不可修改。'
        })
        .then(() => {
          submitFeedback(params).then(({ code, msg }) => {
            if (code != 200) {
              this.$toast.fail({
                message: msg,
                className: 'fail'
              })
              return
            }
            this.$toast.success('提交成功')
            this.checkSubmit()
            this.$refs.baseForm.getList()
          })
        })
        .catch(() => {})
    },
    refreshData(data) {
      if (this.checkSubmitFlag) {
        let filterData = data.map(item => {
          return {
            id: item.id,
            dealerReasonFeedback: item.dealer_reason_feedback,
            dealerReasonDescription: item.dealer_reason_description,
            year: item.year,
            month: item.month
          }
        })
        let params = {
          detailsSubmitDtoList: filterData,
          status: '0',
          year: filterData[0].year,
          month: filterData[0].month
        }
        submitFeedback(params)
          .then(() => {
            this.$refs.baseForm.total = 0
            this.$refs.baseForm.tableData = []
            this.$refs.baseForm.getList()
          })
          .catch(() => {})
      } else {
        this.$refs.baseForm.total = 0
        this.$refs.baseForm.tableData = []
        this.$refs.baseForm.getList()
      }
    }
  }
}
</script>

<style lang="css" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    color: #0b41cd;
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .year {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(20px) translateY(6px);
  }
}
.line {
  //padding-left: 4px;
  //padding-right: 4px;
}
.contact {
  font-size: 14px;
  margin-left: auto;
  display: flex;
  //justify-content: flex-end;
  align-items: center;
  line-height: 14px;
  padding-bottom: 6px;
}

/deep/ .titleClass {
  color: #8c8c8c !important;
  font-size: 12px;
}
</style>
