<template>
  <div class="list-bg" :class="editMode ? 'edit' : ''">
    <van-popover
      v-if="getCheckPermi('delaer-btn')"
      class="popover"
      placement="bottom-end"
      v-model="showPopover"
      trigger="click"
      :actions="actions"
      @select="onSelect"
    >
      <template #reference>
        <van-icon name="ellipsis" />
      </template>
    </van-popover>
    <van-row v-else class="tab-right-btn" @click="onSelect()">
      <van-col class="text">导出</van-col>
      <!--      <van-col class="text">新增</van-col>-->
    </van-row>
    <van-row class="header" type="flex" justify="space-between">
      <van-col class="total">
        <span>共{{ total }}条数据</span>
      </van-col>
      <div>
        <van-col
          class="filter"
          style="margin-right: 5px"
          @click="changeEditMode"
          v-has-permission="['delaer-btn']"
          v-if="!editMode && !loading && !isDAoverdue"
        >
          <span>编辑</span><van-icon name="edit" color="#0B41CD" />
        </van-col>
        <van-col class="filter" @click="filterData"
          ><span>筛选</span><van-icon name="filter-o" color="#0B41CD"
        /></van-col>
      </div>
    </van-row>
    <van-notice-bar
      class="bar"
      v-if="editMode"
      left-icon="volume-o"
      text="可点击联络人字段进行编辑，完成后点击“完成”按钮"
    />
    <van-collapse v-model="activeNamesTitle" class="collapse dealerInfoDetail" v-if="!editMode">
      <van-collapse-item :is-link="false" name="1">
        <template #title>
          <h3 class="title" style="cursor: pointer; color: #000" slot="reference" @click="openInfo()">
            {{ dealerClone || dealerInfo.rocheDealerName }}
          </h3>
        </template>
        <template #value>
          <div class="theme-color">
            <span v-if="!isDAoverdue" @click.stop="openDealerInfoDetail()">{{
              dealerInfoDetail.isHaveAppeal ? '已提交申请' : '变更申请'
            }}</span>
          </div>
        </template>
        <div class="collapse-content">
          <div style="display: flex" v-if="!dealerInfoDetail.state">
            <img class="warning-icon" :src="require('@/assets/images/warning_triangle@2x.png')" />
            <span class="font-dis" style="">暂无相关公司信息，请提交申请至罗氏进行补足</span>
          </div>
          <template v-else>
            <van-row class="divider">
              <van-col class="label-wrapper">
                <van-icon name="phone-o" class="theme-color" />{{
                  (dealerInfoDetail.phonenumber || '暂无信息') | mobileFormat
                }}
              </van-col>
              <van-col class="label-wrapper">
                <van-icon name="envelop-o" class="theme-color" />{{ dealerInfoDetail.postCode || '暂无信息' }}
              </van-col>
            </van-row>
            <van-row class="text-hide1" style="word-break: break-all">
              <van-icon name="location-o" class="theme-color" />{{ dealerInfoDetail.address || '暂无信息' }}
            </van-row>
            <van-row class="text-hide1" style="word-break: break-all">
              <van-icon name="location-o" class="theme-color" /><span class="label theme-color">寄件</span
              >{{ dealerInfoDetail.mailingAddress || '暂无信息' }}
            </van-row>
          </template>
        </div>
      </van-collapse-item>
    </van-collapse>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="bg">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-collapse v-model="activeNames" class="collapse">
          <van-checkbox-group v-model="editChecked" shape="square">
            <van-collapse-item :name="i" v-for="(item, i) in accountList" :key="i">
              <template #title>
                <van-row type="flex" align="center" justify="space-between" class="card">
                  <div style="display: flex; align-items: center; width: 60%">
                    <van-col style="color: coral" v-if="editMode">
                      <van-checkbox
                        :name="item.userName"
                        @click.native.stop
                        :disabled="item.isDealerManager || item.isCompanyManager"
                      ></van-checkbox>
                    </van-col>
                    <van-col span="3" style="color: coral; margin: 0 2.5px">
                      <van-icon name="manager" class="icon" />
                    </van-col>
                    <van-col span="24" class="card-text">
                      <van-row class="name">{{ item.nickName || '-' }} </van-row>
                      <van-row class="email text-hide1" v-if="!editMode"
                        ><van-icon name="envelop-o" class="theme-color" />{{ item.email || '-' }}
                      </van-row>
                    </van-col>
                  </div>
                  <van-col span="8" class="card-text">
                    <van-row class="status">
                      <van-row class="diy-cell-value">
                        <span class="table-status" v-if="item.status == 0">
                          <i class="dotClass" style="background-color: springgreen"></i
                          ><span class="custom-title">启用 </span>
                        </span>
                        <span class="table-status" v-if="item.status == 1">
                          <i class="dotClass" style="background-color: red"></i><span class="custom-title">停用</span>
                        </span>
                      </van-row>
                    </van-row>
                    <van-row class="phone" v-if="!editMode">
                      <van-icon name="phone-o" class="theme-color" />{{ item.phonenumber || '-' | mobileFormat }}
                    </van-row>
                  </van-col>
                </van-row>
              </template>
              <div class="collapse-content">
                <van-row class="divider" @click="goToInfo(item)">
                  <van-col span="24" v-if="!editMode"
                    ><van-cell title="用户名" :value="item.userName || '-'"
                  /></van-col>
                  <van-col span="24" v-if="editMode"
                    ><van-cell title="手机" :value="item.phonenumber || '-'"
                  /></van-col>
                  <van-col span="24" v-if="editMode"><van-cell title="邮箱" :value="item.email || '-'" /></van-col>
                  <van-col span="24"><van-cell title="账号类型" :value="item.roleName || '-'" /></van-col>
                  <van-col span="24" class="role-wrapper">
                    <div class="role-wrapper-title">角色</div>
                    <div class="role-wrapper-content">
                      <template v-for="(list, i) in item.roleTreeList">
                        <div v-if="list.checkedRole.length > 0" :key="i" class="border-bottom">
                          <span class="title">{{ list.systemName }}：</span>
                          <template v-for="(role, j) in list.roleList">
                            <span class="role-list last" :key="j" v-if="list.checkedRole.includes(role.roleId)"
                              >{{ role.roleName }}<span class="child">，</span></span
                            >
                          </template>
                        </div>
                      </template>
                    </div>
                    <!-- <van-cell title="角色" :value="item.roleNames || '-'" /> -->
                  </van-col>
                  <van-col span="24"><van-cell title="变更人" :value="item.updateBy || '-'" /></van-col>
                  <van-col span="24"
                    ><van-cell title="变更日期" :value="parseTime(item.updateTime, '{y}-{m}-{d}') || '-'"
                  /></van-col>
                </van-row>
                <van-row class="foot-btn" v-has-permission="['delaer-btn']" v-if="!editMode">
                  <span v-if="item.isCompanyManager" @click="goToChange(item)">变更申请</span>
                  <span class="vertical-line" v-if="item.isCompanyManager">|</span>
                  <span @click="editSingle(item)">编辑</span>
                  <span class="vertical-line">|</span>
                  <span @click="delSingle(item)">删除</span>
                  <span class="vertical-line">|</span>
                  <van-popover
                    class="popover-foot"
                    placement="bottom"
                    v-model="item.showPopoverFoot"
                    trigger="click"
                    :actions="actionsFoot"
                    @select="onSelectFoot($event, item)"
                  >
                    <template #reference>
                      <van-icon name="ellipsis" />
                    </template>
                  </van-popover>
                </van-row>
              </div>
            </van-collapse-item>
          </van-checkbox-group>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
    <div v-if="editMode" class="edit-btn">
      <van-button class="btn" @click="handleEditCancel">取消</van-button>
      <van-button class="btn" style="color: red" @click="handleDeleteRow"
        >删除<span v-if="editChecked.length > 0">({{ editChecked.length }})</span></van-button
      >
      <van-button class="btn" @click="handleAdd">新增</van-button>
      <van-button class="btn btn-finish" @click="handleEditFinish">完成编辑</van-button>
    </div>
    <van-popup v-model="show" class="popup-box" closeable round position="bottom" @close="cancel">
      <van-cell-group class="group-box">
        <van-field :maxlength="defaultMaxLength" v-model="queryParams.userName" label="用户名" placeholder="请输入" />
        <van-field
          :maxlength="defaultMaxLength"
          readonly
          clickable
          name="function"
          :value="functionLabel"
          label="账号类型"
          placeholder="点击选择"
          @click="showPickerFunction = true"
        />
        <van-popup v-model="showPickerFunction" position="bottom">
          <van-picker
            show-toolbar
            value-key="dictLabel"
            :columns="sys_dealer_role"
            @confirm="onConfirmFunctionList"
            @cancel="showPickerFunction = false"
          />
        </van-popup>
        <van-field
          :maxlength="defaultMaxLength"
          readonly
          clickable
          name="function"
          :value="functionLabel2"
          label="角色"
          placeholder="点击选择"
          @click="showPickerFunction2 = true"
        />
        <van-dialog v-model="showPickerFunction2" class="detail-dialog" @confirm="onConfirmRole">
          <van-tree-select :items="roleOptions" :active-id.sync="activeIds" :main-active-index.sync="activeIndex" />
        </van-dialog>
        <!-- <van-popup v-model="showPickerFunction2" position="bottom">
          <van-picker
            show-toolbar
            value-key="roleName"
            :columns="roleList"
            @confirm="onConfirmFunctionList2"
            @cancel="showPickerFunction2 = false"
          />
        </van-popup> -->
        <van-field :maxlength="defaultMaxLength" v-model="queryParams.nickName" label="姓名" placeholder="请输入" />
        <van-field :maxlength="defaultMaxLength" v-model="queryParams.email" label="邮箱" placeholder="请输入" />
      </van-cell-group>
      <van-row class="popup-foot-btn" gutter="20">
        <van-button class="btn margin-lf-10" type="default" @click="reset">重置</van-button>
        <van-button class="btn margin-lf-10" type="info" @click="handleQuery">确定</van-button>
      </van-row>
    </van-popup>
    <info-detail
      v-if="showDetailPop"
      :dealerInfoDetail="info"
      @close="closeShowDetailPop"
      @success="dealerInfoDetail.isHaveAppeal = true"
    />
    <dealer-contact-info
      v-if="showContactDetailPop"
      :contactInfoDetail="contactInfoDetail"
      @close="closeshowContactDetailPop"
      @submit="changeItem"
      :hasCompanyManager="hasCompanyManager"
    ></dealer-contact-info>
    <manager-change
      v-if="showManageChangePop"
      :manageChangeInfo="manageChangeInfo"
      @close="closeshowManageChangePop"
    ></manager-change>

    <van-dialog v-model="showFlag" title="账号信息" :showConfirmButton="false" :closeOnClickOverlay="false">
      <van-row style="margin-top: 0px">
        <van-col span="24">
          <div style="padding: 10px 10px">
            <div style="width: 300px; min-height: 60px; font-size: 12px; color: #323233; display: flex">
              <div style="width: 140px; font-weight: bold">邮箱：</div>
              <div>{{ accountInfo.profile ? accountInfo.profile.email : '' }}</div>
            </div>
            <div style="width: 300px; min-height: 60px; font-size: 12px; color: #323233; display: flex">
              <div style="width: 140px; font-weight: bold">是否点击激活链接：</div>
              <div>
                {{ accountInfo.emails ? (accountInfo.emails.unverified.length === 0 ? '已点击' : '未点击') : '未点击' }}
              </div>
            </div>
            <div style="width: 300px; min-height: 60px; font-size: 12px; color: #323233; display: flex">
              <div style="width: 140px; font-weight: bold">是否同意承知：</div>
              <div>
                {{
                  accountInfo.isVerified
                    ? accountInfo.preferences && Object.keys(accountInfo.preferences).length > 0
                      ? accountInfo.preferences.terms.cepdp.isConsentGranted
                        ? '已同意'
                        : '未同意'
                      : '未同意'
                    : '未同意'
                }}
              </div>
            </div>
            <div style="height: 30px; text-align: center; margin-bottom: 10px">
              <van-button type="default" style="width: 70px" @click="close">关闭</van-button>
            </div>
          </div>
        </van-col>
      </van-row>
    </van-dialog>
  </div>
</template>

<script>
import InfoDetail from './info/infoDetail'
import DealerContactInfo from './info/accountDetail'
import ManagerChange from './info/managerChange'
import { getSelectedDealer } from '@/utils/auth'
import {
  listContacts,
  dealerChagne,
  delDealerContact,
  authAccount,
  getUnbindAccount,
  checkBeforeAuth,
  unbindAccount
} from '@/api/userCenter/dealerContact'
import {
  getDealerDetail,
  editDealer,
  delDealerUser,
  checkBinding,
  getDealerCompanyInfo,
  updateDealerContacts,
  listUser,
  queryAccountStatus,
  sendActiveEmail,
  resetGigyaPwd,
  UpdateDealerAccountList,
  listRole,
  getRoleTypeList,
  checkDealerUserRoleWarning
} from '@/api/userCenter/dealer'
import dayjs from 'dayjs'
import { getDicts } from '@/api/dict/data'
import { checkPermi } from '@/utils/permission'
import _ from 'lodash'

export default {
  name: 'Notice',
  components: {
    InfoDetail,
    DealerContactInfo,
    ManagerChange
  },
  data() {
    return {
      showFlag: false,
      deleteRow: [],
      editChecked: [],
      info: {},
      showDetailPop: false,
      showContactDetailPop: false,
      showManageChangePop: false,
      contactInfoDetail: {},
      manageChangeInfo: {},
      showPopover: false,
      actionsFoot: [{ text: '查看注册状态' }, { text: '重发激活邮件' }, { text: '重置密码' }],
      actions: [{ text: '导出' }],
      active: 'announcement',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      queryParams: {
        pageNum: 0,
        pageSize: 10,
        nickName: undefined,
        role: '',
        orgName: '',
        orgCode: '',
        orgType: 1,
        orderByColumn: 'updateTime',
        isAsc: 'desc',
        platformRoleId: []
      },
      extraParams: {},
      activeNamesTitle: ['1'],
      activeNames: [],
      dealerInfoDetail: {},
      showDealerInfoDetail: false,
      timer: null,
      accountList: [],
      show: false,
      startDate: null,
      endDate: null,
      showPicker: false,
      showPicker2: false,
      //数据字典
      sys_dealer_role: [],
      dealerInfoLoading: false,
      showPickerFunction: false,
      showPickerFunction2: false,
      functionLabel: '',
      functionLabel2: '',
      dealerClone: getSelectedDealer(),
      authShow: false,
      showPickerAccount: false,
      accountLabel: '',
      accountNickName: '',
      authInfo: {},
      selectRow: {},
      editMode: false,
      index: 1,
      isDAoverdue: false,
      accountInfo: {},
      hasCompanyManager: false,
      hasDealerManager: false,
      roleList: [],
      isDealerManager: false,
      roleTypeList: [],
      roleOptions: [],
      roleOptionsForManager: [],
      roleOptionsForDealer: [],
      roleOptionsForAll: [],
      systemNameList: [],
      activeIds: [],
      activeIndex: '0'
    }
  },
  filters: {
    contactGroupFilter(value, sys_dealer_role) {
      return sys_dealer_role
        .filter(itemA => value.includes(itemA.dictValue.toString()))
        .map(filteredItem => filteredItem.dictLabel)
        .join('，')
    }
  },
  watch: {
    editMode(val) {
      this.$emit('editAble', val)
      this.$store.commit('SET_EDITING', val)
    },
    showManageChangePop(newValue) {
      this.setBodyOverflow(newValue)
    }
  },
  computed: {
    dealerInfo() {
      return this.$store.state.user.baseInfo
    }
  },
  async created() {
    if (this.$route.query?.id) {
      this.extraParams.id = this.$route.query?.id
    }
    await getDicts('sys_dealer_role').then(({ data }) => {
      this.sys_dealer_role = data
    })
    this.sys_dealer_role = this.sys_dealer_role.filter(dict => dict.dictValue == '3' || dict.dictValue == '4')
    //da合同是否过期
    this.isDAoverdue = this.$store.state.user.userInfo.isDealerExpire
    this.isDealerManager = this.$store.state.user.userInfo.subAccountFlag == '0'
    console.log(this.isDealerManager, 'isDealerManager')
    // this.onLoad()
    this.getRoleList()
  },
  mounted() {
    this.getDealerDetail()
    this.getRoleTypeListFun()
    window.$vm = this
  },
  methods: {
    async getRoleTypeListFun() {
      await getRoleTypeList(1).then(response => {
        this.roleOptionsForManager = response.data.map(item => {
          return {
            text: item.systemName,
            children: item.roleList.map(role => {
              return {
                text: role.roleName,
                id: role.roleId
              }
            })
          }
        })
      })
      await getRoleTypeList(0).then(response => {
        this.roleOptionsForDealer = response.data.map(item => {
          return {
            text: item.systemName,
            children: item.roleList.map(role => {
              return {
                text: role.roleName,
                id: role.roleId
              }
            })
          }
        })
      })
      this.roleOptionsForAll = this.mergeAndDeduplicate(this.roleOptionsForManager, this.roleOptionsForDealer)
      this.roleOptions = this.roleOptionsForAll
    },
    mergeAndDeduplicate(array1, array2) {
      const mergedMap = new Map(array1.map(item => [item.text, { ...item }]))
      array2.forEach(item2 => {
        if (mergedMap.has(item2.text)) {
          if (mergedMap.get(item2.text).children && item2.children) {
            mergedMap.get(item2.text).children = this.mergeAndDeduplicate(
              mergedMap.get(item2.text).children,
              item2.children
            )
          } else if (item2.children) {
            mergedMap.set(item2.text, { ...mergedMap.get(item2.text), ...item2 })
          }
        } else {
          mergedMap.set(item2.text, { ...item2 })
        }
      })
      return Array.from(mergedMap.values())
    },
    onConfirmRole() {
      console.log(this.activeIds)
      console.log(this.roleOptions)
      this.functionLabel2 = this.extractTextByIds(this.roleOptions, this.activeIds)
    },
    extractTextByIds(array, ids) {
      // 用于存储找到的 text 值的数组
      const texts = []

      // 遍历数组中的每个对象
      array.forEach(item => {
        // 遍历当前对象的 children 数组
        item.children.forEach(child => {
          // 检查 child 的 id 是否在 ids 数组中
          if (ids.includes(child.id)) {
            // 如果是，将 child 的 text 添加到 texts 数组
            texts.push(child.text)
          }
        })
      })

      // 用逗号和空格将 texts 数组的元素连接起来
      return texts.join(', ')
    },
    setBodyOverflow(value) {
      if (value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },
    async getRoleList() {
      const data = await listRole()
      this.roleList = data.data
    },
    onSelectFoot(data, obj) {
      if (data.text === '查看注册状态') {
        this.getAccountStatus(obj)
      } else if (data.text === '重发激活邮件') {
        if (this.checkIsDAoverdue()) {
          return
        }
        if (obj.isDealerManager) {
          this.$toast.fail('经销商管理员不可操作！')
          return
        }
        if (obj.activationStatus != '1') {
          this.$toast.fail('账号已激活！')
          return
        }
        if (obj.isCompanyManager && !this.isDealerManager) {
          this.$toast.fail('公司负责人不可操作！')
          return
        }
        this.sendActiveEmail(obj)
      } else if (data.text === '重置密码') {
        if (this.checkIsDAoverdue()) {
          return
        }
        if (obj.isDealerManager) {
          this.$toast.fail('经销商管理员不可操作！')
          return
        }
        if (obj.isCompanyManager && !this.isDealerManager) {
          this.$toast.fail('公司负责人不可操作！')
          return
        }
        this.resetPwd(obj)
      }
    },
    sendActiveEmail(obj) {
      sendActiveEmail(obj.userId)
        .then(res => {
          console.log(res)
          this.$toast.success('操作成功')
        })
        .catch(err => {
          console.log(err)
        })
    },
    resetPwd(obj) {
      resetGigyaPwd(obj.userId)
        .then(res => {
          console.log(res)
          this.$toast.success('操作成功')
        })
        .catch(err => {
          console.log(err)
        })
    },
    checkIsDAoverdue() {
      if (this.isDAoverdue) {
        this.$toast.fail({
          message: '当前经销商DA已过期，无法操作！',
          className: 'fail'
        })
        return true
      } else {
        return false
      }
    },
    close() {
      this.accountInfo = {}
      this.showFlag = false
    },
    getAccountStatus(obj) {
      this.$loading.show()
      queryAccountStatus(obj.userId)
        .then(res => {
          this.$loading.hide()
          this.accountInfo = { ...res }
          this.showFlag = true
        })
        .catch(err => {
          this.$loading.hide()
          console.log(err)
        })
    },
    changeItem(obj) {
      this.accountList.filter(item => {
        if (item.userName == obj.userName) {
          item.roleTreeList = obj.roleTreeList
          item.nickName = obj.nickName
          item.email = obj.email
          item.phonenumber = obj.phonenumber
          item.roleName = obj.roleName
          item.subAccountFlag = obj.subAccountFlag
          item.platformRoleNames = obj.platformRoleNames
          item.processFlag = obj.processFlag
          item.status = obj.status
          item.position = obj.position
        }
      })
      this.showContactDetailPop = false
    },
    async changeDealer(callback) {
      let disText = ''
      await checkDealerUserRoleWarning([...this.accountList, ...this.deleteRow]).then(res => {
        disText = res.data
      })
      this.$dialog.confirm({
        title: '提示',
        message: disText + '是否保存更改?',
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        messageAlign: 'left',
        beforeClose: (action, done, e) => {
          if (action === 'confirm') {
            try {
              UpdateDealerAccountList([...this.accountList, ...this.deleteRow])
                .then(res => {
                  if (res.code === 200) {
                    done() // 关
                    this.$toast.success('修改成功')
                    if (typeof callback === 'function') {
                      this.handleEditCancel(callback)
                      callback()
                    } else {
                      this.handleEditCancel(callback)
                    }
                  } else {
                    this.$toast(res.msg)
                    done() // 关
                  }
                })
                .catch(() => {
                  done()
                })
            } catch (error) {
              done()
            } finally {
              // done()
            }
          } else {
            if (action == 'cancel') {
              if (callback) {
                if (typeof callback === 'function') {
                  this.handleEditCancel(callback)
                  callback()
                } else {
                  console.log('cancel')
                  this.handleEditCancel(callback)
                }
              }
            }
            done()
          }
        }
      })
    },
    async handleEditFinish() {
      let disText = ''
      await checkDealerUserRoleWarning([...this.accountList, ...this.deleteRow]).then(res => {
        disText = res.data
      })
      this.$dialog.confirm({
        title: '提示',
        message: disText + '是否保存更改?',
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        messageAlign: 'left',
        beforeClose: (action, done, e) => {
          if (action === 'confirm') {
            try {
              UpdateDealerAccountList([...this.accountList, ...this.deleteRow])
                .then(res => {
                  if (res.code === 200) {
                    done() // 关
                    this.$toast.success('修改成功')
                    this.handleEditCancel()
                  } else {
                    this.$toast(res.msg)
                    done() // 关
                  }
                })
                .catch(() => {
                  done()
                })
            } catch (error) {
              done()
            } finally {
              // done()
            }
          } else {
            done()
          }
        }
      })
    },
    handleAdd() {
      //数组添加一条为add的
      this.accountList.unshift({
        userName: 'Add' + this.index,
        roleTreeList: [],
        nickName: '',
        phonenumber: '',
        email: '',
        roleName: '经销商子账号',
        subAccountFlag: '1',
        platformRoleNames: '',
        status: 1,
        processFlag: 'add',
        position: ''
      })
      this.index++
    },
    handleEditCancel(link) {
      this.editMode = false
      console.log('editMode', this.editMode)
      this.$store.commit('SET_EDITING', false)
      if (typeof link === 'string') {
        console.log('跳转', link)
        console.log('store editing', this.$store.state.user.editing)
        this.$router.push({ path: link })
      }
      this.deleteRow = []
      this.queryParams.pageSize = 10
      this.onRefresh()
    },
    changeEditMode() {
      this.editMode = true
      this.$store.commit('SET_EDITING', true)
      this.queryParams = {
        pageNum: 0,
        pageSize: 9999,
        orgType: 1,
        orderByColumn: 'updateTime',
        platformRoleId: [],
        isAsc: 'desc'
      }
      this.total = 0
      this.accountList = []
      this.refreshing = true
      this.loading = true
      this.onLoad()
    },
    closeshowContactDetailPop() {
      this.showContactDetailPop = false
    },
    closeshowManageChangePop(type) {
      this.showManageChangePop = false
      if (type == 'init') {
        this.onRefresh()
      }
    },
    closeShowDetailPop(type) {
      this.showDetailPop = false
      if (type == 'init') {
        this.onRefresh()
      }
    },
    openDealerInfoDetail() {
      if (this.dealerInfoDetail.isHaveAppeal) {
        return
      }
      this.$toast.fail('请在电脑端进行申请')
      return
      this.showDetailPop = true
      this.info = _.cloneDeep(this.dealerInfoDetail)
    },
    getDealerDetail() {
      getDealerCompanyInfo().then(res => {
        if (res.data) {
          this.dealerInfoDetail = res.data
          this.dealerInfoDetail.dealerName = this.dealerInfo.rocheDealerName
        }
      })
    },
    parseTime(time) {
      if (time) {
        return dayjs(time).format('YYYY-MM-DD')
      } else return null
    },
    getCheckPermi(value) {
      return checkPermi([value])
    },
    openInfo() {
      this.dealerInfoLoading = true
      getDealerDetail(this.dealerInfo.rocheDealerId)
        .then(res => {
          this.dealerInfoLoading = false
          this.dealerInfoDetail = { ...res.data }
        })
        .catch(() => {
          this.dealerInfoLoading = false
        })
    },
    onSelect(data) {
      if (data.text === '新增') {
        this.goToAddSingle('new')
      }
      if (data.text === '导出') {
        let roleIds = []
        roleIds = [...this.activeIds, this.queryParams.roleId]
        this.download('/user/dealerUser/dealer-export', {}, `dealer_user_${new Date().getTime()}.xlsx`, {
          method: 'get',
          params: {
            ...this.queryParams,
            querySearchRoles: roleIds.filter(id => {
              if (id) {
                return id
              }
            }),
            pageNum: 1,
            pageSize: 999
          }
        })
      }
    },
    //新增修改
    goToAddSingle(dealerId) {
      if (this.checkIsDAoverdue()) {
        return
      }
      this.$router.push(`/userCenter/dealerAccountInfo/${dealerId}`)
    },
    editSingle(row) {
      // if (row.isCompanyManager) {
      //   this.$toast.fail('公司负责人不可编辑！')
      //   return
      // }
      // if (row.isDealerManager) {
      //   this.$toast.fail('经销商管理员不可编辑！')
      //   return
      // }
      if (this.checkIsDAoverdue()) {
        return
      }
      this.$router.push({
        path: `/userCenter/dealerAccountInfo/${row.userId}`,
        query: {
          isManage: row.isDealerManager,
          isCompanyManager: row.isCompanyManager
        }
      })
    },
    async delSingle(row) {
      if (this.checkIsDAoverdue()) {
        return
      }
      if (row.isCompanyManager) {
        this.$toast.fail('公司负责人不可删除！')
        return
      }
      if (row.isDealerManager) {
        this.$toast.fail('经销商管理员不可删除！')
        return
      }
      let requestParams = [
        {
          userId: row.userId,
          dealerId: row.org.orgId,
          roleTreeList: row.roleTreeList,
          processFlag: 'delete'
        }
      ]
      let disText = ''
      await checkDealerUserRoleWarning(requestParams).then(res => {
        disText = res.data
      })
      let param = {
        userId: row.userId,
        dealerId: row.org.orgId
      }
      checkBinding(param).then(res => {
        let msgStr = null
        if (res.data.isBindFlag) {
          msgStr =
            disText +
            '该经销商账号已绑定过经销商联络人，如果删除该账号，会将已绑定的联络人进行解绑操作，请确认是否继续删除该账号？'
        } else {
          msgStr = disText + '是否确认删除 ' + row.nickName + ' 这个账号？'
        }
        this.$dialog
          .confirm({
            message: msgStr
          })
          .then(() => {
            delDealerUser([row.userId]).then(({ code, msg }) => {
              if (code != 200) {
                this.$toast.fail({
                  message: msg,
                  className: 'fail'
                })
                return
              }
              this.$toast.success('删除成功')
              this.onRefresh()
            })
          })
          .catch(() => {})
      })
    },
    //新增\修改
    goToInfo(item) {
      if (!this.editMode) return
      this.showContactDetailPop = true
      this.contactInfoDetail = _.cloneDeep(item)
      // this.$set(this.contactInfoDetail, 'processFlag', 'edit')
    },
    //申请更改
    goToChange(item) {
      if (this.checkIsDAoverdue()) {
        return
      }
      if (item.isAppeal) {
        this.$toast.fail('目前有进行中的申请更改！')
        return
      }

      this.$toast.fail('请在电脑端进行申请')
      return
      this.showManageChangePop = true
      this.manageChangeInfo = _.cloneDeep(item)
    },
    //随机颜色
    getRandomRGBColor() {
      const r = Math.floor(Math.random() * 256)
      const g = Math.floor(Math.random() * 256)
      const b = Math.floor(Math.random() * 256)
      return `rgb(${r}, ${g}, ${b})`
    },
    onLoad() {
      let _this = this
      let queryParams = _this.queryParams
      _this.loading = true
      // 设置定时器做节流处理，避免滚动时频繁触发加载事件
      if (!_this.timer) {
        _this.timer = setTimeout(() => {
          _this.queryParams.pageNum++
          _this.getList(queryParams)
          // 清除定时器，释放内存
          _this.timer = null
        }, 1000)
      }
    },
    handleDeleteRow() {
      if (this.editChecked.length === 0) {
        this.$toast.fail('请选择要删除的数据')
        return
      }
      this.accountList.forEach(element => {
        if (this.editChecked.includes(element.userName)) {
          if (element.processFlag != 'add') {
            element.processFlag = 'delete'
            this.deleteRow.push(element)
          }
        }
      })
      this.accountList = this.accountList.filter(item => {
        return !this.editChecked.includes(item.userName)
      })
      this.editChecked = []
    },
    getList(queryParams) {
      this.editChecked = []
      this.queryParams = queryParams
      let roleIds = []
      roleIds = [...this.activeIds, this.queryParams.roleId]
      let requestParams = {
        ...this.extraParams,
        ...this.queryParams,
        roleId: null,
        querySearchRoles: roleIds.filter(id => {
          if (id) {
            return id
          }
        })
      }
      console.log(requestParams)
      listUser(requestParams)
        .then(res => {
          if (res.code !== 200) {
            this.loading = false
            this.refreshing = false
            this.finished = true
            return
          }
          this.loading = false
          this.refreshing = false
          this.total = res.total
          this.accountList = [
            ...this.accountList,
            ...res.rows.map(item => {
              return {
                ...item,
                showPopoverFoot: false
              }
            })
          ]
          this.setHasCompanyManager()
          this.setHasDealerManager()
          if (this.accountList.length >= this.total) {
            this.finished = true
          }
        })
        .catch(err => {
          this.loading = false
          this.refreshing = false
          console.log(err)
        })
    },
    setHasCompanyManager() {
      //拿到列表给每个用户设置是否是公司管理员，如果是只能编辑角色，并且负责人的角色不能编辑
      this.hasCompanyManager = false
      this.accountList.map(item => {
        if (item.hasCompanyRole) {
          item.isCompanyManager = true
          this.hasCompanyManager = true
        }
      })
    },
    setHasDealerManager() {
      //拿到列表给每个用户设置是否是公司管理员，如果是只能编辑角色，并且负责人的角色不能编辑
      let dealerManager = '3'
      this.hasDealerManager = false
      this.accountList.map(item => {
        item.roleTreeList.map(role => {
          if (role.checkedRole.includes(dealerManager)) {
            item.isDealerManager = true
            this.hasDealerManager = true
          }
        })
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams = {
        pageNum: 0,
        pageSize: 10,
        orderByColumn: 'updateTime',
        isAsc: 'desc'
      }
      this.total = 0
      this.accountList = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    onRefreshBySearch() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams.pageNum = 0
      this.total = 0
      this.accountList = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    tabChange() {
      this.editMode = false
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams = {
        pageNum: 0,
        pageSize: 10,
        orderByColumn: 'updateTime',
        isAsc: 'desc'
      }
      this.total = 0
      this.accountList = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    onConfirmFunctionList(data) {
      this.functionLabel = data.dictLabel
      this.queryParams.roleId = data.dictValue
      this.showPickerFunction = false
      this.functionLabel2 = ''
      this.activeIds = []
      this.queryParams.platformRoleId = []
      if (this.queryParams.roleId == 3) {
        this.roleOptions = this.roleOptionsForManager
      } else if (this.queryParams.roleId == 4) {
        this.roleOptions = this.roleOptionsForDealer
      } else if (!this.queryParams.roleId) {
        this.roleOptions = this.roleOptionsForAll
      }
    },
    onConfirmFunctionList2(data) {
      this.functionLabel2 = data.roleName
      this.queryParams.platformRoleId = data.roleId
      this.showPickerFunction2 = false
    },
    filterData() {
      this.show = true
    },
    cancel() {
      this.show = false
      // this.reset()
    },
    reset() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'updateTime',
        isAsc: 'desc',
        dealerId: this.dealerInfo.rocheDealerId
      }
      this.functionLabel = ''
    },
    /** 确定按钮操作 */
    handleQuery() {
      this.extraParams = {}
      const { path } = this.$route
      const query = { ...this.$route.query }
      delete query.id
      this.$router.replace({ path, query })
      this.onRefreshBySearch()
      this.show = false
    },
    getAccountList() {
      getUnbindAccount({}).then(res => {
        this.accountList = res.data
        this.accountList = res.data.map(item => {
          return {
            ...item,
            nameLabel: item.nickName + '（' + item.userName + '）'
          }
        })
      })
    },
    cancel2() {
      this.authShow = false
      this.$refs.authForm.resetValidation()
      this.reset2()
    },
    reset2() {
      this.authInfo = {}
      this.accountLabel = ''
      this.accountNickName = ''
    },
    onConfirmAccount(data) {
      console.log(data)
      this.accountLabel = data.nameLabel
      this.accountNickName = data.nickName
      this.authInfo.userId = data.userId
      this.showPickerAccount = false
    }
  }
}
</script>

<style lang="less" scoped>
@import 'index';
.list-bg {
  min-height: calc(100vh - 99px - 55px) !important;
  background: linear-gradient(268deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
  padding: 20px 16px;
}
.edit {
  height: calc(100vh - 99px - 55px - 60px);
  min-height: auto !important;
  overflow: scroll;
  overflow-x: hidden;
}
.edit-btn {
  position: fixed;
  bottom: 60px;
  width: calc(100% - 40px);
  left: 50%;
  display: flex;
  justify-content: space-between;
  transform: translateX(-50%);
}
.btn {
  width: 22%;
  padding: 0;
  box-shadow: 0px 0px 4vmin 0.533333vmin #0b41cd3d;
  border: none;
  //color: #656464;
}
.btn-finish {
  background: #0b41cd;
  color: #fff;
}
.popover,
.tab-right-btn {
  color: #0b41cd;
  font-size: 16px;
  position: absolute;
  height: 44px;
  right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}

.dotClass {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: block;
  margin-top: 8px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.notice-type {
  background-color: #ced9f5;
  color: #0b41cd;
  font-size: 12px;
  padding: 0px 5px;
  border-radius: 4px;
  min-width: 50px;
}

.notice-title {
  padding: 0px 10px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #0b41cd;
}

.divNormal {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #8c8c8c;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

.divActive {
  border-radius: 4px;
  background-color: #0b41cd;
  border: 1px solid #0b41cd;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

/deep/.van-tabs__nav {
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}

/deep/.van-collapse-item__title .van-cell__title {
  width: calc(100% - 20px);
}
.text-hide1 {
  /*单行文本溢出*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header {
  padding: 0 2px 0 2px;
  .total {
    font-size: 14px;
    line-height: 24px;
    color: #969799;
  }
  .filter {
    color: #0b41cd;
    font-size: 14px;
  }
}

.popup-box {
  overflow-y: hidden;
  height: 450px;
  .group-box {
    margin-top: 40px;
    height: 340px;
    overflow-y: auto;
    z-index: 10;
  }
  .popup-foot-btn {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    text-align: center;
    height: 36px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    .btn {
      width: 80px;
    }
  }
}
.popup-box2 {
  overflow-y: hidden;
  height: 200px;
  .group-box {
    margin-top: 40px;
    height: 90px;
    overflow-y: auto;
    z-index: 10;
  }
  .popup-foot-btn {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    text-align: center;
    height: 36px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    .btn {
      width: 80px;
    }
  }
}
.margin-lf-10 {
  margin-left: 10px;
}
.collapse {
  padding: 2px;
  .van-collapse-item {
    // overflow: hidden;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe) !important;
    /deep/.van-collapse-item__content {
      border-radius: 0 0 8px 8px;
    }
    .icon {
      font-size: 20px;
    }
    .card-text {
      color: #8c8c8c;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        // margin-bottom: 2px;
      }
      .email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
      .status {
        // margin-bottom: 2px;
        font-size: 12px;
        .table-status {
          display: flex;
          justify-content: right;
          align-items: center;
          .dotClass {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
      .phone {
        font-size: 12px;
        text-align: end;
      }
    }
    /deep/.van-cell {
      align-items: center;
      padding: 10px 8px;
    }
    .diy-cell-value {
      color: #969799;
      text-align: right;
    }

    .collapse-content {
      /deep/.van-cell {
        align-items: normal;
        font-size: 12px;
        line-height: 14px;
        padding-left: 0;
      }

      .table-mark {
        margin-left: 8px;
        padding: 0.2vw 0.8vw;
        border-radius: 12px;
      }
      .mark-del {
        background-color: #ebeef5;
      }
    }
    .foot-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 8px;
      color: #0b41cd;
      border-top: 0.5px solid #ebedf0;
      .vertical-line {
        margin: 0 8px;
      }
    }
    .disable-button {
      color: #a0cfff;
    }
  }
}
.bar {
  position: relative;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.warning-icon {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.font-dis {
  color: #ff4a4a;
  font-size: 12px;
}

/deep/.dealerInfoDetail .van-collapse-item__title .van-cell__title {
  width: 75%;
  flex: 0 0 auto;
}

::v-deep {
  .role-wrapper {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    .role-wrapper-title {
      flex-shrink: 0;
      font-size: 12px;
    }
    .role-wrapper-content {
      text-align: right;
      padding-right: 10px;
      width: 65%;
      .title {
        font-size: 12px;
        color: #0b41cd;
      }
      .role-list {
        font-size: 12px;
        color: #000;
      }
    }
  }
  .last:last-child {
    .child {
      display: none;
    }
  }
}
</style>
