<template>
  <van-row class="main-container">
    <van-row class="header" :class="theme.headerClass">
      <van-row class="title">
        <van-col>渠道库存</van-col>
        <!--        <van-col>-->
        <!--          <van-dropdown-menu :overlay="false" active-color="#0b41cd">-->
        <!--            <van-dropdown-item v-model="channelDataType" :options="options" />-->
        <!--          </van-dropdown-menu>-->
        <!--        </van-col>-->
        <van-col class="date">{{ currentTime }}</van-col>
      </van-row>
      <van-row class="action">
        <van-col
          class="item"
          v-for="item in inventoryData"
          :key="item.productLine"
          :class="currentProduct.productLine === item.productLine ? 'active' : ''"
          @click="changeShowType(item)"
          >{{ item.productLine | dictLabelByValue(productLineOptions) }}</van-col
        >
      </van-row>
    </van-row>
    <van-row class="result">
      <van-col span="12" class="item">
        <van-col class="title">本月库存金额</van-col>
        <van-col class="price"
          >{{ currentProduct.inventoryAmountTheMonth | numberFormat }}<span class="price-mini">元</span></van-col
        >
      </van-col>
      <van-col span="12" class="item">
        <van-col class="title">本月库存天数</van-col>
        <van-col class="price"
          >{{ currentProduct.inventoryDayTheMonth ? currentProduct.inventoryDayTheMonth : 0
          }}<span class="price-mini">天</span></van-col
        >
      </van-col>
    </van-row>
    <van-row class="result">
      <van-col span="12" class="item">
        <van-col class="title">上月库存金额</van-col>
        <van-col class="price"
          >{{ currentProduct.inventoryAmountLastMonth | numberFormat }}<span class="price-mini">元</span></van-col
        >
      </van-col>
      <van-col span="12" class="item">
        <van-col class="title">上月库存天数</van-col>
        <van-col class="price"
          >{{ currentProduct.inventoryDayLastMonth ? currentProduct.inventoryDayLastMonth : 0
          }}<span class="price-mini">天</span></van-col
        >
      </van-col>
    </van-row>
    <div class="bg-color" :class="theme.bgClass" />
    <van-image class="bg-img" :src="require('@/assets/images/home-credit.svg')" />
  </van-row>
</template>
<script>
import { getInventoryData } from '@/api/home'
import { getDicts } from '@/api/dict/data'

const THEME_CONFIG = {
  bgClass: 'bg-blue',
  headerClass: 'header-blue'
}

export default {
  name: 'ChannelCard',
  props: {
    themeType: {
      type: String,
      default: 'blue'
    }
  },
  data() {
    return {
      type: 'CD',
      theme: THEME_CONFIG,
      currentProduct: {},
      currentTime: '',
      inventoryData: [],
      productLineOptions: [],
      channelDataType: '负库存',
      options: []
    }
  },
  created() {
    this.getInventoryData()
    this.getDicts()
  },
  methods: {
    getDicts() {
      getDicts('sys_product_line').then(res => {
        this.productLineOptions = res.data
      })
      getDicts('sys_channel_data_tag').then(({ data }) => {
        this.options = data.map(item => {
          return {
            value: item.dictValue,
            text: item.dictLabel
          }
        })
      })
    },
    changeShowType(currentProduct) {
      this.currentProduct = currentProduct
    },
    getInventoryData() {
      let params = {}
      getInventoryData(params)
        .then(res => {
          this.inventoryData = [...res.data]
          this.currentTime = this.inventoryData[0]
            ? this.inventoryData[0].year + '年' + this.inventoryData[0].month + '月'
            : ''
          this.currentProduct = this.inventoryData[0] || {
            productLine: ' ',
            inventoryAmountTheMonth: 0,
            inventoryDayTheMonth: 0,
            inventoryAmountLastMonth: 0,
            inventoryDayLastMonth: 0
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  z-index: 1;
  overflow: hidden;
  .header {
    height: 75px;
    box-sizing: border-box;
    border-radius: 0 0 100px 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    padding: 15px 16px;
    position: relative;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        color: #595959;
        font-size: 12px;
        font-weight: 400;
        margin-left: auto;
      }
    }
    .action {
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      line-height: 1;
      max-width: 250px;
      overflow-x: auto;
      .item {
        padding: 0 12px;
        color: #595959;
        font-size: 14px;
        font-weight: 500;
        margin-right: 4px;
        border-radius: 6px 6px 0 0;
        background: #f3efff;
        min-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
      }
      .active {
        background: linear-gradient(0deg, #fff 0%, #fff 100%), #f3efff;
        color: #b384ff;
        padding: 0 12px;
        min-height: 30px;
      }
    }
  }
  .desc {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 400;
    margin-top: 27px;
    margin-left: 16px;
  }
}
.bg-img {
  width: 75px;
  height: 75px;
  opacity: 0.4;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.bg-color {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
}

.header-blue {
  background: linear-gradient(90deg, #dfd4fd 0%, rgba(243, 239, 255, 0.55) 100%);
  border-bottom: 1px solid #dfd4fd;
}

.bg-blue {
  background: linear-gradient(180deg, rgba(249, 248, 255, 0.3) 0%, #f1ecff 100%), #fff;
}
.result {
  padding-left: 16px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  line-height: 1;
  .item {
    .title {
      color: #8c8c8c;
      font-size: 12px;
      font-weight: 400;
    }
    .price {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      margin-top: 16px;
      min-height: 22px;
    }
    .price-mini {
      font-size: 12px;
      min-height: 22px;
      padding-left: 2px;
    }
  }
}
.unit {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.empty-container {
  height: 130px;
}
.result {
  display: flex;
  justify-content: space-between;
  .item {
    display: flex;
    flex-direction: column;
  }
}

/deep/ .van-popup--top {
  top: 0;
  left: 55% !important;
  width: 40% !important;
}

/deep/ .van-dropdown-menu__bar {
  height: 30px;
  margin-top: -5px;
  margin-right: 20px;
}
</style>
