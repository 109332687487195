<template>
  <div class="main-container">
    <div class="list-bg" />
    <van-row class="header" type="flex" justify="space-between">
      <van-col class="head-arrow" @click="goBack"><van-icon name="arrow-left" color="#0b41cd" /></van-col>
      <van-col class="head-title">{{ headerTitle }}</van-col>
    </van-row>
    <van-cell-group class="cell-group-bg" style="height: 100%; overflow: auto">
      <van-col span="24">
        <van-field v-model="formInfo.taskTitle" label-class="titleClass" readonly rows="1" autosize type="textarea">
          <template #label>
            <span style="font-weight: bold">任务名称</span>
          </template>
        </van-field>
      </van-col>
      <van-col span="24">
        <van-field v-model="formInfo.taskDescribe" label-class="titleClass" readonly rows="1" autosize type="textarea">
          <template #label>
            <span style="font-weight: bold">任务描述</span>
          </template>
        </van-field>
      </van-col>
      <van-col span="24" v-for="(item, index) in formData" :key="index">
        <van-field
          v-if="item.type === 'input'"
          v-model="item.defaultValue"
          :label="item.label"
          :required="item.required"
          maxlength="200"
          placeholder="请输入"
          readonly
          label-class="titleClass"
          rows="1"
          autosize
          type="textarea"
        >
          <template #label>
            <span style="font-weight: bold">{{ item.label }}</span>
          </template>
        </van-field>
        <van-field
          v-if="item.type === 'textarea'"
          v-model="item.defaultValue"
          :label="item.label"
          :required="item.required"
          type="textarea"
          rows="2"
          autosize
          maxlength="200"
          show-word-limit
          placeholder="请输入"
          readonly
          label-class="titleClass"
        >
          <template #label>
            <span style="font-weight: bold">{{ item.label }}</span>
          </template>
        </van-field>
        <van-field
          v-if="item.type === 'select'"
          readonly
          clickable
          :value="pickerParams[item.props]"
          :label="item.label"
          :required="item.required"
          placeholder="点击选择"
          label-class="titleClass"
          @click="openPicker(item)"
        >
          <template #label>
            <span style="font-weight: bold">{{ item.label }}</span>
          </template>
        </van-field>
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            value-key="label"
            :columns="options"
            @confirm="selectConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field v-if="item.type === 'radio'" :label="item.label" :required="item.required" label-class="titleClass">
          <template #label>
            <span style="font-weight: bold">{{ item.label }}</span>
          </template>
          <template #input>
            <van-radio-group v-model="item.defaultValue" direction="horizontal" disabled>
              <van-radio v-for="obj in item.options" :name="obj.value" :key="obj.value">{{ obj.label }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-if="item.type === 'checkbox'"
          :label="item.label"
          :required="item.required"
          label-class="titleClass"
        >
          <template #label>
            <span style="font-weight: bold">{{ item.label }}</span>
          </template>
          <template #input>
            <van-checkbox-group v-model="item.defaultValue" direction="horizontal" disabled>
              <van-checkbox
                v-for="obj in item.options"
                :name="obj.value"
                :key="obj.value"
                shape="square"
                style="margin-bottom: 5px; min-width: 100px"
                >{{ obj.label }}</van-checkbox
              >
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field v-if="item.type === 'switch'" :label="item.label" :required="item.required" label-class="titleClass">
          <template #label>
            <span style="font-weight: bold">{{ item.label }}</span>
          </template>
          <template #input>
            <van-switch v-model="item.defaultValue" size="24px" disabled />
          </template>
        </van-field>
        <van-field
          v-if="item.type === 'date'"
          readonly
          clickable
          :value="item.defaultValue"
          :label="item.label"
          :required="item.required"
          placeholder="点击选择"
          label-class="titleClass"
          @click="openDatePicker(item)"
        >
          <template #label>
            <span style="font-weight: bold">{{ item.label }}</span>
          </template>
        </van-field>
        <van-popup v-model="showDatePicker" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="请选择日期"
            @confirm="dateConfirm"
            @cancel="showDatePicker = false"
          />
        </van-popup>
        <van-field
          v-if="item.type === 'daterange'"
          readonly
          clickable
          :value="item.defaultValue"
          :label="item.label"
          :required="item.required"
          placeholder="点击选择"
          label-class="titleClass"
          @click="openDateRangePicker(item)"
        >
          <template #label>
            <span style="font-weight: bold">{{ item.label }}</span>
          </template>
        </van-field>
        <van-popup v-model="showDateRangePicker" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="请选择日期"
            @confirm="dateRangeConfirm"
            @cancel="showDateRangePicker = false"
          />
        </van-popup>
        <van-field v-if="item.type === 'file'" :label="item.label" :required="item.required" label-class="titleClass">
          <template #label>
            <span style="font-weight: bold">{{ item.label }}</span>
          </template>
          <template #input>
            <div @click="downloadFile(item.defaultValue)" style="color: #0b41cd">{{ item.defaultValue.fileName }}</div>
          </template>
        </van-field>
      </van-col>
    </van-cell-group>
  </div>
</template>

<script>
import { getTaskDetailById, getTaskDetailByTaskId } from '@/api/task/index'
import { download } from '@/utils/request'

export default {
  name: 'Details',
  dicts: [],
  components: {},
  data() {
    return {
      headerTitle: '查看自定义任务',
      originData: {},
      formData: [],
      formInfo: {
        taskTitle: '',
        taskDescribe: ''
      },
      showPicker: false,
      showDatePicker: false,
      showDateRangePicker: false,
      options: [],
      selectedItem: {},
      pickerParams: {},
      currentDate: new Date()
    }
  },
  created() {
    this.init()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    init() {
      this.formData = []
      if (this.$route.query.from === 'completed') {
        let params = {
          id: this.$route.query?.processInstanceId,
          taskId: this.$route.query?.taskId
        }
        getTaskDetailByTaskId(params)
          .then(res => {
            if (res.data) {
              this.originData = JSON.parse(res.data.defineForm)
              console.log(this.originData)
              this.headerTitle = '查看自定义任务'
              this.formInfo.taskTitle = res.data.taskTitle
              this.formInfo.taskDescribe = res.data.taskDescribe
              this.initFormData(res.data.defineForm)
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        let params = {
          id: this.$route.query?.processInstanceId
        }
        getTaskDetailById(params)
          .then(res => {
            if (res.data) {
              this.originData = JSON.parse(res.data.defineForm)
              console.log(this.originData)
              this.headerTitle = '处理自定义任务'
              this.formInfo.taskTitle = res.data.taskTitle
              this.formInfo.taskDescribe = res.data.taskDescribe
              this.initFormData(res.data.defineForm)
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    initFormData(data) {
      let dataArr = JSON.parse(data).formData.fields
      console.log(dataArr)
      dataArr.map(item => {
        if (item.__config__.tag === 'el-input' && item.__config__.tagIcon === 'input') {
          let obj = {
            props: item.__vModel__,
            label: item.__config__.label,
            type: 'input',
            required: item.__config__.required,
            defaultValue: item.__config__.defaultValue
          }
          this.formData.push(obj)
        } else if (item.__config__.tag === 'el-input' && item.__config__.tagIcon === 'textarea') {
          let obj = {
            props: item.__vModel__,
            label: item.__config__.label,
            type: 'textarea',
            required: item.__config__.required,
            defaultValue: item.__config__.defaultValue
          }
          this.formData.push(obj)
        } else if (item.__config__.tag === 'el-select' && item.__config__.tagIcon === 'select') {
          let obj = {
            props: item.__vModel__,
            label: item.__config__.label,
            type: 'select',
            required: item.__config__.required,
            defaultValue: item.__config__.defaultValue,
            options: item.__slot__.options
          }
          this.formData.push(obj)
          if (item.__config__.defaultValue) {
            item.__slot__.options.map(val => {
              if (val.value === item.__config__.defaultValue) {
                this.pickerParams[item.__vModel__] = val.label
              }
            })
          }
        } else if (item.__config__.tag === 'el-radio-group' && item.__config__.tagIcon === 'radio') {
          let obj = {
            props: item.__vModel__,
            label: item.__config__.label,
            type: 'radio',
            required: item.__config__.required,
            defaultValue: item.__config__.defaultValue,
            options: item.__slot__.options
          }
          this.formData.push(obj)
        } else if (item.__config__.tag === 'el-checkbox-group' && item.__config__.tagIcon === 'checkbox') {
          let obj = {
            props: item.__vModel__,
            label: item.__config__.label,
            type: 'checkbox',
            required: item.__config__.required,
            defaultValue: item.__config__.defaultValue,
            options: item.__slot__.options
          }
          this.formData.push(obj)
        } else if (item.__config__.tag === 'el-switch' && item.__config__.tagIcon === 'switch') {
          let obj = {
            props: item.__vModel__,
            label: item.__config__.label,
            type: 'switch',
            required: item.__config__.required,
            defaultValue: item.__config__.defaultValue
          }
          this.formData.push(obj)
        } else if (item.__config__.tag === 'el-date-picker' && item.__config__.tagIcon === 'date') {
          let obj = {
            props: item.__vModel__,
            label: item.__config__.label,
            type: 'date',
            required: item.__config__.required,
            defaultValue: item.__config__.defaultValue
          }
          this.formData.push(obj)
        } else if (item.__config__.tag === 'el-date-picker' && item.__config__.tagIcon === 'date-range') {
          let obj = {
            props: item.__vModel__ + 'Start',
            originProps: item.__vModel__,
            label: item.__config__.label + '开始',
            type: 'daterange',
            required: item.__config__.required,
            defaultValue: item.__config__.defaultValue[0]
          }
          this.formData.push(obj)
          let obj2 = {
            props: item.__vModel__ + 'End',
            originProps: item.__vModel__,
            label: item.__config__.label + '结束',
            type: 'daterange',
            required: item.__config__.required,
            defaultValue: item.__config__.defaultValue[1]
          }
          this.formData.push(obj2)
        } else if (item.__config__.tag === 'el-upload' && item.__config__.tagIcon === 'upload') {
          let obj = {
            props: item.__vModel__,
            label: item.__config__.label,
            type: 'file',
            required: item.__config__.required,
            defaultValue: item.__config__.defaultValue[0]
          }
          this.formData.push(obj)
        }
      })
    },
    openPicker(item) {
      // this.showPicker = true
      this.options = item.options
      this.selectedItem = item
    },
    selectConfirm(value) {
      this.formData.map(item => {
        if (item.props === this.selectedItem.props) {
          item.defaultValue = value.value
        }
      })
      this.pickerParams[this.selectedItem.props] = value.label
      this.showPicker = false
    },
    openDatePicker(item) {
      // this.showDatePicker = true
      this.selectedItem = item
    },
    dateConfirm(value) {
      this.formData.map(item => {
        if (item.props === this.selectedItem.props) {
          item.defaultValue = formatDate(value)
        }
      })
      this.showDatePicker = false
      function formatDate(date) {
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
      }
    },
    openDateRangePicker(item) {
      // this.showDateRangePicker = true
      this.selectedItem = item
    },
    dateRangeConfirm(value) {
      this.formData.map(item => {
        if (item.props === this.selectedItem.props) {
          item.defaultValue = formatDateRange(value)
        }
      })
      this.showDateRangePicker = false
      console.log(this.formData)
      function formatDateRange(date) {
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
      }
    },
    downloadFile(data) {
      const fileIds = data.fileId
      download('/file/multi-download', {}, null, {
        method: 'POST',
        params: {
          watermarkFlag: true,
          fileIds
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.main-container {
  padding: 0 16px;
}

.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}

.cell-group-bg {
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}

.header {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;

  .head-arrow {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    left: 16px;
  }

  .head-title {
    font-weight: 600;
  }
}

/deep/ .van-cell {
  border-bottom: 1px solid #dddddd;
}

/deep/ .titleClass {
  font-weight: bold !important;
}
</style>
