<template>
  <div class="performance-appraisal-detail">
    <van-nav-bar
      :title="title"
      left-arrow
      class="performance-appraisal-nav"
      fixed
      placeholder
      safe-area-inset-top
      @click-left="$router.back()"
    >
      <template #right>
        <span class="right-btn-link" @click.prevent="onDownload">下载</span>
      </template>
    </van-nav-bar>
    <performance-appraisal-tabs
      v-model="active"
      sticky
      class="detail-tabs"
      v-if="baTabs.length > 1"
      :tabs="baTabs"
      field-label="sheetName"
      filed-key="sheetName"
    >
    </performance-appraisal-tabs>
    <!-- <van-row>
      <van-col span="24">
        <div style="font-size: 12px; color: #0b41cd; font-weight: bold; padding: 5px 20px" v-if="active === '运营'">
          备注：各项考核权重根据每家经销商的适用项做均分，运营KPI满分为100分
        </div>
      </van-col>
    </van-row> -->
    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="initDetailData">
        <van-list v-model="loading" :finished="finished">
          <van-collapse v-model="activeNames" class="collapse">
            <van-collapse-item v-for="(card, cardName) in formatTableData" :name="cardName" :key="active + cardName">
              <template #title>
                <div class="card-title">
                  <b>{{ cardName }} </b>
                </div>
              </template>
              <div class="collapse-content">
                <van-row class="content-item" v-for="(item, idx) in card" :key="active + cardName + idx">
                  <van-col span="24" class="assessment-item">
                    <div class="sub-title">
                      <span class="label">考核项目</span>
                      <span class="content">
                        <span class="text"
                          ><span>{{ item[0].assessmentItem }}</span>
                          <van-icon
                            name="warning-o"
                            @click="onClickTips(item[0])"
                            v-if="item[0].assessmentStandard.trim()"
                        /></span>
                        <!-- <van-popover trigger="click" v-model="item[0].show" placement="bottom-end">
                        <div style="font-size: 12px; max-width: 80vw; padding: 12px; white-space: pre-line">
                          {{ item[0].assessmentStandard }}
                        </div>
                        <template #reference>
                          <van-icon name="info-o" color="#0b41cd" />
                        </template>
                      </van-popover> -->
                      </span>
                    </div>
                  </van-col>
                  <van-col span="24" v-if="!kpiScoreEmpty">
                    <div class="sub-title">
                      <span class="label">年度得分</span>
                      <span class="content">
                        <span class="text">{{ item[0].kpiScore ? `${item[0].kpiScore}分` : '-' }}</span>
                      </span>
                    </div>
                  </van-col>
                  <van-col span="24" v-for="(detail, i) in item" :key="i">
                    <div class="sub-title">
                      <span class="label gray">考核科目</span>
                      <span class="content" @click="openDetail(detail)">
                        <span class="text">{{ detail.assessmentIndicator }}</span>
                        <van-icon name="more-o" class="icon-btn" />
                      </span>
                    </div>
                  </van-col>
                </van-row>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="total-wrapper">
      <div class="total-score" v-if="!loading && totalScore">
        <span class="score">{{ totalScore }}</span> 全年得分
      </div>
      <div class="total-score fine-color" v-if="!loading && totalFine">
        <span class="score">{{ totalFine | numberFormat }}</span> 全年罚金（元）
      </div>
    </div>
    <van-dialog v-model="detailShow" class="detail-dialog">
      <span class="margin-bottom">{{ details.assessmentIndicator }}</span>
      <van-row>
        <van-col span="24">
          <van-cell :title="`年度指标`" :value="`${details.targetValue || '-'}`" />
        </van-col>
      </van-row>
      <van-collapse v-model="activeName" accordion>
        <template v-for="(item, i) in detailsList">
          <van-row>
            <span>{{ item.title }}</span>
            <van-col span="24">
              <!-- <van-cell :title="`季度目标`" :value="item.targetValue" /> -->
              <van-collapse-item :title="item.title" :name="item.title" v-if="item.hasAppeal" :key="i">
                <template #title>
                  <div>
                    实际完成<span class="appeal">{{ item.status | returnFilter }}</span>
                  </div>
                </template>
                <template #value>
                  <div>{{ item.actualValue }}</div>
                </template>
                <div class="sub-title">
                  <span class="label gray">申诉描述:</span>
                  <span class="content">
                    {{ item.appealContent }}
                  </span>
                </div>
                <div class="sub-title">
                  <span class="label gray">申诉附件:</span>
                  <span
                    class="content"
                    style="color: rgb(12, 65, 205)"
                    v-if="item.fileId"
                    @click="showDetail(item.fileId, item.fileName)"
                  >
                    {{ item.fileName }}
                  </span>
                  <span class="content" v-else>-</span>
                </div>
                <div class="sub-title">
                  <span class="label gray">反馈备注:</span>
                  <span class="content">
                    {{ item.comment }}
                  </span>
                </div>
                <div class="sub-title">
                  <span class="label gray">反馈附件:</span>
                  <span
                    class="content"
                    style="color: rgb(12, 65, 205)"
                    v-if="item.approveFileId"
                    @click="showDetail(item.approveFileId, item.approveFileName)"
                  >
                    {{ item.approveFileName }}
                  </span>
                  <span class="content" v-else>-</span>
                </div>
              </van-collapse-item>
              <van-cell v-else :title="`实际完成`" :value="item.actualValue" />
            </van-col>
          </van-row>
        </template>
      </van-collapse>
    </van-dialog>
  </div>
</template>
<script>
import { previewFile } from '@/api/formTable'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import round from 'lodash/round'
import { marketingTableOptions } from './config'
import { getKPIAssessmentResultDetailByDealer } from '@/api/dataCenter/kpi/performacseAppraisal'
import PerformanceAppraisalTabs from '../tabs.vue'
export default {
  components: { PerformanceAppraisalTabs },
  data() {
    return {
      needMergeCell: [],
      needMergeCellProps: [],
      activeName: '',
      activeNamesMap: {},
      refreshing: false,
      loading: false,
      finished: false,
      active: '',
      baTabs: [],
      tableData: [],
      detailShow: false,
      details: {},
      kpiScoreEmpty: false,
      cloneTableData: [],
      detailsList: [
        {
          title: '截至Q1',
          targetValue: '',
          actualValue: '',
          value: 'q1'
        },
        {
          title: '截至Q2',
          targetValue: '',
          actualValue: '',
          value: 'q2'
        },
        {
          title: '截至Q3',
          targetValue: '',
          actualValue: '',
          value: 'q3'
        },
        {
          title: '截至Q4',
          targetValue: '',
          actualValue: '',
          value: 'q4'
        }
      ]
    }
  },
  watch: {
    detailShow(val) {
      if (!val) {
        this.detailsList = [
          {
            title: '截至Q1',
            targetValue: '',
            actualValue: '',
            value: 'q1'
          },
          {
            title: '截至Q2',
            targetValue: '',
            actualValue: '',
            value: 'q2'
          },
          {
            title: '截至Q3',
            targetValue: '',
            actualValue: '',
            value: 'q3'
          },
          {
            title: '截至Q4',
            targetValue: '',
            actualValue: '',
            value: 'q4'
          }
        ]
      }
    }
  },
  filters: {
    returnFilter(value) {
      switch (value) {
        case 0:
          return '申诉'
        case 1:
          return '通过'
        case -1:
          return '拒绝'
      }
      return value
    }
  },
  computed: {
    detailID() {
      return get(this.$route, 'params.id', '')
    },
    title() {
      return decodeURIComponent(get(this.$route, 'query.title', ''))
    },
    period() {
      return decodeURIComponent(get(this.$route, 'query.period', ''))
    },
    totalScore() {
      return get(
        this.baTabs.find(tabItem => tabItem.sheetName === this.active),
        'totalScore',
        false
      )
    },
    totalFine() {
      return get(
        this.baTabs.find(tabItem => tabItem.sheetName === this.active),
        'totalFine',
        false
      )
    },
    formatTableData() {
      const tableData = get(
        this.baTabs.find(tabItem => tabItem.sheetName === this.active),
        'list',
        []
      )
      let obj = groupBy(tableData, 'assessmentCategory')
      let newObj = {}
      for (let key in obj) {
        newObj[key] = groupBy(obj[key], 'assessmentItem')
      }
      console.log(newObj)
      this.kpiScoreEmpty = this.checkAllEmpty('kpiScore')
      return newObj
    },
    activeNames: {
      get() {
        return get(this.activeNamesMap, this.active, [])
      },
      set(v) {
        this.$set(this.activeNamesMap, this.active, v)
      }
    }
  },
  created() {
    this.initDetailData()
  },
  methods: {
    onClickTips(item) {
      this.$dialog.alert({
        title: item.assessmentItem,
        messageAlign: 'left',
        message: item.assessmentStandard,
        confirmButtonText: '知道了'
      })
    },
    showDetail(fileId, fileName) {
      if (fileId === null || fileId === '' || fileName === null || fileName === '') {
        return
      }
      let params = {
        fileId: fileId,
        watermarkFlag: true
      }
      previewFile(params, fileName)
    },
    checkAllEmpty(prop) {
      const rowSpan = this.$data[prop + 'Rowspan'] || []
      //合并得单元格只考虑第一个值
      for (let i = 0; i < this.tableData.length; i++) {
        if (rowSpan.length > 0) {
          if (this.tableData[i][prop] !== '' && (i == 0 || rowSpan.includes(i))) {
            return false
          }
        } else {
          if (this.tableData[i][prop] !== '') {
            return false
          }
        }
      }
      return true
    },
    openDetail(detail) {
      this.detailShow = true
      this.activeName = ''
      this.details = detail

      this.detailsList.map(item => {
        item.targetValue = detail[item.value + 'TargetValue'] || '-'
        item.actualValue = detail[item.value + 'ActualValue'] || '-'
        if (detail.appealList) {
          detail.appealList.map(i => {
            if (i.periodValue === item.value.toUpperCase()) {
              item.status = i.status
              item.hasAppeal = true
              item.appealContent = i.appealContent || '-'
              item.fileId = i.fileId
              item.fileName = i.fileName
              item.comment = i.comment || '-'
              item.approveFileId = i.approveFileId
              item.approveFileName = i.approveFileName
            }
          })
        }
      })
      console.log(this.detailsList)
    },
    initDetailData() {
      this.refreshing = true
      this.loading = true
      return this.queryDetail(this.detailID).then(data => {
        this.loading = false
        this.finished = true
        this.activeNamesMap = {}
        this.cloneTableData = data
        this.initRowspan(this.cloneTableData)
        this.baTabs = data.map(item => {
          this.$set(this.activeNamesMap, item.sheetName, [])
          return {
            ...item,
            sheetName: item.sheetName,
            list: map(item.list, listItem => ({
              ...listItem,
              kpiScore: get(listItem, 'kpiScore', '') + '' || '-',
              actualValue: get(listItem, 'actualValue', '') + '' || '-',
              targetValue: get(listItem, 'targetValue', '') + '' || '-',
              adjustTargetValue: get(listItem, 'adjustTargetValue', '') + '' || '-'
            }))
          }
        })
        this.active = this.baTabs.length ? this.baTabs[0].sheetName : ''
        this.refreshing = false
      })
    },
    initRowspan(table) {
      //根據配置文件中的mergeCell字段，初始化需要合并的单元格，然後再data裡創建对应的数组。
      //需要注意的是可能当前列的prop是最终得分，但是要按照考核类目来合并单元格
      this.needMergeCell = marketingTableOptions.filter(item => item.mergeCell) || []
      this.needMergeCell.map(item => {
        this.$data[item.prop + 'Rowspan'] = []
      })
      //遍历table，根据mergeCell字段的值，判断是否需要合并单元格，每一列返回一个合并的数组
      //合并的原理是，按照顺序，如果当前行的值和上一行的值一样，那么就需要合并
      table.forEach((element, index) => {
        this.needMergeCell.map(item => {
          if (item.mergeCell) {
            if (index > 0 && table[index][item.mergeCell] != table[index - 1][item.mergeCell]) {
              this.$data[item.prop + 'Rowspan'].push(index)
            }
          }
        })
      })
      this.needMergeCellProps = this.needMergeCell.map(item => item.prop)
    },
    queryDetail(params) {
      return getKPIAssessmentResultDetailByDealer(params).then(res => {
        if (res && res.code === 200) {
          return res.data || []
        }
        return []
      })
    },
    onDownload() {
      this.download('/bff-web/kpi-assessmentResult/downloadDetailByDealer', {}, `${this.title}.pdf`, {
        method: 'get',
        params: {
          kpiAssessmentId: this.detailID,
          productLineName: this.active
        }
      })
    },
    calcTotalScore(card) {
      const list = card.filter(item => item.score > 0 || item.score === 0)
      if (list.length > 0) {
        return card.reduce((total, item) => {
          return total + item.score
        }, 0)
      }
      return -1
    }
  }
}
</script>
<style scoped lang="less">
.performance-appraisal-detail {
  background: linear-gradient(296.57deg, #d9ebfb 0%, #d9ebfb 5.27%, #f2f2f7 35.59%, #fef9f7 65.39%, #fffefe 83.33%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(100vh);
  .detail-tabs {
    ::v-deep .van-tab {
      flex: unset;
    }
  }
  .performance-appraisal-nav {
    ::v-deep .van-nav-bar {
      background-color: #fff;
      .van-nav-bar__content {
        .van-nav-bar__left {
          .van-icon {
            color: rgba(38, 38, 38, 1);
          }
        }
      }
    }
  }
  .right-btn-link {
    color: rgba(11, 65, 205, 1);
    border-right: 1px solid rgba(206, 217, 245, 1);
    margin: 10px 0;
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
  .content {
    // flex: 1;
    //overflow-y: auto;
    padding: 0 16px;
  }
  .total-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  .total-score {
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    color: rgba(9, 52, 164, 1);
    justify-content: flex-end;
    padding-bottom: 20px;
    padding-right: 16px;
    .score {
      font-size: 32px;
      font-weight: 600;
    }
  }
  .fine-color {
    color: #ff494b;
  }
  .collapse {
    padding-top: 12px;
    .van-collapse-item {
      align-items: center;
      margin-bottom: 12px;
      border-radius: 12px;
      box-shadow: 0 8px 12px #ebedf0;
      overflow: hidden;
      ::v-deep .van-cell {
        align-items: center;
      }
      ::v-deep .van-collapse-item__title {
        background: linear-gradient(
          296.57deg,
          #d9ebfb 0%,
          #d9ebfb 5.27%,
          #f2f2f7 35.59%,
          #fef9f7 65.39%,
          #fffefe 83.33%
        );
      }
      .collapse-content {
        .content-item {
          padding-bottom: 12px;
          &:not(:last-child) {
            border-bottom: 1px solid rgba(235, 237, 240, 1);
            margin-bottom: 12px;
          }
          .sub-title {
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            font-size: 14px;
            margin-bottom: 10px;
            color: rgba(20, 20, 20, 1);
            .label {
              padding-right: 10px;
              &.gray {
                color: #8c8c8c;
                font-size: 12px;
              }
            }
            .content {
              flex: 1;
              display: flex;
              padding-right: 0;
              align-items: flex-start;
              text-align: right;
              > .text {
                flex: 1;
                color: rgba(9, 52, 164, 1);
                display: flex;
                justify-content: flex-end;
                align-items: center;
              }
            }
          }
          .assessment-item {
            .van-icon.van-icon-info-o {
              margin-left: 5px;
            }
          }
        }
        ::v-deep .van-cell {
          align-items: normal;
          font-size: 12px;
          line-height: 14px;
          padding: 10px 0;
          &.high-light {
            .van-cell__value {
              font-size: 16px;
              color: rgba(9, 52, 164, 1);
            }
          }
        }
      }
    }
    .card-title {
      font-size: 16px;
      b {
        font-weight: 600;
        margin-right: 10px;
      }
    }
  }
}
.icon-btn {
  color: rgb(11, 65, 205);
  margin-left: 5px;
  line-height: 23px;
}
.detail-dialog {
  padding-top: 20px;
  .van-row {
    width: 80%;
    margin: 0 auto 10px;
    border-bottom: 1px solid rgba(235, 237, 240, 1);
    &:last-child {
      margin-bottom: 0;
    }
    .van-cell {
      padding: 0 10px;
    }
  }
}
.margin-bottom {
  display: block;
  width: 80%;
  margin: 0 auto 10px;
}
.detail-dialog {
  .sub-title {
    display: flex;
    .label {
      flex-shrink: 0;
    }
  }
  ::v-deep {
    .van-dialog__content {
      max-height: 70vh;
      overflow: scroll;
    }
    .van-cell--clickable {
      padding: 0px 10px;
    }
    .van-cell__right-icon {
      display: none;
    }
    .appeal {
      background: #ee8441;
      border-radius: 8px;
      color: #fff;
      display: inline-block;
      margin-left: 5px;
      padding: 0px 5px;
      font-size: 12px;
    }
    .van-cell {
      padding: 4px !important;
    }
  }
}
</style>
