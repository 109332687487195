<template>
  <van-row class="main-container">
    <van-row class="title">
      <van-col>通知</van-col>
    </van-row>
    <van-row class="line">
      <div class="item" @click="goUnreadNoticeList">
        <van-image class="image" :src="require('@/assets/images/home-notice1.png')" />
        <div class="desc">未读通知</div>
        <div class="number">{{ noticeResult.unreadCount ? noticeResult.unreadCount : 0 }}</div>
      </div>
      <div class="item" @click="goNoticeList">
        <van-image class="image" :src="require('@/assets/images/home-notice3.png')" />
        <div class="desc">重要通知</div>
        <div class="number">{{ noticeResult.unreadUrgentCount ? noticeResult.unreadUrgentCount : 0 }}</div>
      </div>
      <div class="item" @click="goNoticeCalendar">
        <van-image class="image" :src="require('@/assets/images/home-notice2.png')" />
        <div class="desc">待办通知</div>
        <div class="number">{{ noticeResult.feedbackDueDateCount ? noticeResult.feedbackDueDateCount : 0 }}</div>
      </div>
      <van-col :span="12"></van-col>
    </van-row>
  </van-row>
</template>
<script>
import { getNoticeResult } from '@/api/home'

export default {
  name: 'Auth',
  props: {},
  data() {
    return {
      noticeResult: {}
    }
  },
  created() {
    this.getNoticeResult()
  },
  methods: {
    getNoticeResult() {
      let params = {}
      getNoticeResult(params)
        .then(res => {
          this.noticeResult = { ...res.data }
        })
        .catch(err => {
          console.log(err)
        })
    },
    goNoticeList() {
      this.$router.push({
        path: '/notice'
      })
    },
    goUnreadNoticeList() {
      this.$router.push({
        path: '/notice',
        query: { from: 'NOTICE', readStatus: 'N' }
      })
    },
    goNoticeCalendar() {
      this.$router.push({
        path: '/notice/calendar',
        query: { from: 'NOTICE' }
      })
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  z-index: 1;
  overflow: hidden;
  .title {
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
    border-radius: 0 0 100px 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
  }
}
.line {
  padding: 16px 13px 23px;
  background: #fff;
  .item {
    display: flex;
    height: 56px;
    align-items: center;
    position: relative;
    .image {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      margin-left: 6px;
    }
    .desc {
      font-weight: 400;
      font-size: 14px;
      color: #8c8c8c;
    }
    .number {
      font-weight: 400;
      font-size: 32px;
      color: #262626;
      position: absolute;
      right: 40px;
    }
  }
}
</style>
