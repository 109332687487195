<template>
  <div>
    <van-dialog v-model="cibDetailShow" class="detail-dialog">
      <div class="sub-title">
        <span class="label gray">CIB名称:</span>
        <span class="content">
          {{ form.dealerFileName }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">产品线:</span>
        <span class="content">
          {{ form.productLineValue | getProductLabel(sys_product_line) }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">时间:</span>
        <span class="content">
          {{ form.createTime }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">申诉描述:</span>
        <span class="content">
          {{ form.appealDescription || '-' }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">申诉附件:</span>
        <span
          class="content"
          style="color: rgb(12, 65, 205)"
          v-if="form.appealFileId"
          @click="showDetail(form.appealFileId, form.appealFileName)"
        >
          {{ form.appealFileName }}
        </span>
        <span class="content" v-else>-</span>
      </div>

      <div class="sub-title">
        <span class="label gray">反馈备注:</span>
        <span class="content">
          {{ form.comment || '-' }}
        </span>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { previewFile } from '@/api/formTable'
import { getDicts } from '@/api/dict/data'
import { getAppealDetailByTaskId } from '@/api/cib/confirmation'
export default {
  name: 'Details',
  dicts: [],
  components: {},
  data() {
    return {
      form: {},
      cibDetailShow: false,
      sys_product_line: []
    }
  },
  created() {
    getDicts('sys_product_line').then(({ data }) => {
      this.sys_product_line = data
    })
  },
  filters: {
    getProductLabel(value, sys_product_line) {
      let arr = sys_product_line.filter(item => item.value == value)
      if (arr.length > 0) {
        return arr[0].label
      } else {
        return value
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    init(detailsInfo) {
      this.cibDetailShow = true
      this.form = detailsInfo
      this.getDetail(detailsInfo)
    },
    async getDetail(row) {
      getAppealDetailByTaskId(row.appealTaskId, row.processInstanceId)
        .then(res => {
          if (res.code == 200 && res.data) {
            this.form = res.data
            this.form.dealerFileName = res.data.fileName
            this.form.createTime = this.form.createDate
          }
        })
        .catch(error => {})
    },
    showDetail(fileId, fileName) {
      if (fileId === null || fileId === '' || fileName === null || fileName === '') {
        return
      }
      let params = {
        fileId: fileId,
        watermarkFlag: true
      }
      previewFile(params, fileName)
    }
  }
}
</script>
<style scoped lang="less">
.sub-title {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: 14px;
  margin-bottom: 10px;
  color: rgba(20, 20, 20, 1);
  .label {
    padding-right: 10px;
    &.gray {
      color: #8c8c8c;
      font-size: 12px;
    }
  }
  .content {
    flex: 1;
    display: flex;
    padding-right: 0;
    align-items: flex-start;
    text-align: right;
    justify-content: flex-end;
    > .text {
      flex: 1;
      color: rgba(9, 52, 164, 1);
      display: flex;
      justify-content: flex-end;
    }
  }
}
.detail-dialog {
  padding: 20px 15px 0;
  .van-row {
    width: 80%;
    margin: 0 auto 10px;
    border-bottom: 1px solid rgba(235, 237, 240, 1);
    &:last-child {
      margin-bottom: 0;
    }
    .van-cell {
      padding: 0 10px;
    }
  }
}
</style>
