import request from '@/utils/request'

// 查询列表
export function getCIBConfirmationListByDealer(params) {
  return request({
    url: '/bff-web/cib-confirmation/getListByDealer',
    method: 'get',
    params
  })
}

export function getCIBConfirmationByDealer(fileId) {
  return request({
    url: '/file/preview-file',
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    data: {},
    params: {
      fileId,
      watermarkFlag: false
    }
  })
}
