// 用户长时间未操作 退出登录
import store from '@/store'
import Vue from 'vue'
import { goSSO, setIsNeedChangeDealer, setSelectedDealer, setToken } from '@/utils/auth'
import { ssoLogout } from '@/api/login'
let timer = null

clearInterval(timer)

export function isLoginExpire() {
  let lastTime = new Date().getTime() // 最后一次点击时间
  let currentTime = new Date().getTime() // 当前时间
  let timeOut = 30 * 60 * 1000 // 允许最长未操作时间30分钟
  let i = 1 // 辅助作用

  function handleReset() {
    // 重新赋值最后一次点击时间，清除定时器，重新开始定时器
    i = 1

    lastTime = new Date().getTime()

    if (timer) {
      clearInterval(timer)
      timer = null
    }

    if (!timer) {
      handleInterval()
    }
  }

  document.onclick = () => {
    // 单击事件
    handleReset()
  }

  document.ondblclick = () => {
    // 双击事件
    handleReset()
  }

  document.onmousedown = () => {
    // 按下鼠标键时触发
    handleReset()
  }

  document.onmouseup = () => {
    // 释放按下的鼠标键时触发
    handleReset()
  }

  document.onmousemove = () => {
    // 鼠标移动事件
    handleReset()
  }

  document.onmouseover = () => {
    // 移入事件
    handleReset()
  }

  document.onmouseout = () => {
    // 移出事件
    handleReset()
  }

  document.onmouseenter = () => {
    // 移入事件
    handleReset()
  }

  document.onmouseleave = () => {
    // 移出事件
    handleReset()
  }

  function handleInterval() {
    // 定时器
    timer = setInterval(() => {
      currentTime = new Date().getTime() // 当前时间
      // console.log(window.location.pathname)
      // console.log(`${i++}-currentTime`, currentTime)
      // console.log('最后一次点击时间', lastTime)

      let path = ['/login', '/register', '/auth', '/maintenance', '/expire', '/maintenance.html']
      if (currentTime - lastTime > timeOut && !path.includes(window.location.pathname)) {
        console.log('长时间未操作')
        clearInterval(timer) // 清除定时器
        ssoLogout().finally(() => {
          // 清除token
          localStorage.removeItem('token')
          localStorage.removeItem('userInfo')
          store.commit('SET_TOKEN', '')
          store.commit('SET_NAME', '')
          store.commit('SET_BASEINFO', '')
          store.commit('SET_IsNeedChangeDealer', false)
          setToken('')
          setIsNeedChangeDealer(false)
          setSelectedDealer('')
          store.state.user.name = ''
        })
        Vue.prototype.$dialog
          .alert({
            title: '系统提示',
            message: `由于您长时间未操作，请重新登录`,
            confirmButtonText: '重新登录',
            showCancelButton: false
          })
          .then(() => {
            ssoLogout().finally(() => {
              // 清除token
              localStorage.removeItem('token')
              localStorage.removeItem('userInfo')
              store.commit('SET_TOKEN', '')
              store.commit('SET_NAME', '')
              store.commit('SET_BASEINFO', '')
              store.commit('SET_IsNeedChangeDealer', false)
              setToken('')
              setIsNeedChangeDealer(false)
              setSelectedDealer('')
              store.state.user.name = ''
              goSSO()
            })
          })
          .catch(() => {
            ssoLogout().finally(() => {
              // 清除token
              localStorage.removeItem('token')
              localStorage.removeItem('userInfo')
              store.commit('SET_TOKEN', '')
              store.commit('SET_NAME', '')
              store.commit('SET_BASEINFO', '')
              store.commit('SET_IsNeedChangeDealer', false)
              setToken('')
              setIsNeedChangeDealer(false)
              setSelectedDealer('')
              store.state.user.name = ''
              goSSO()
            })
          })
      }
    }, 1000)
  }

  handleInterval() // 一开始程序 默认执行定制器
}
