<template>
  <van-row class="main-container">
    <van-row class="header" :class="theme.headerClass">
      <van-row class="title">
        <van-col>CIB金额</van-col>
        <van-col class="date">截止昨日</van-col>
      </van-row>
      <van-row class="action">
        <van-col
          class="item"
          v-for="item in cibResult"
          :key="item.productLine"
          :class="currentProduct.productLine === item.productLine ? 'active' : ''"
          @click="changeShowType(item)"
          >{{ item.productLine | dictLabelByValue(productLineOptions) }}</van-col
        >
      </van-row>
    </van-row>
    <van-row class="result">
      <van-col span="12" class="item">
        <van-col span="24" class="title">可领用总金额</van-col>
        <van-col span="24" class="price">{{ currentProduct.totalAvailableAmount | numberFormat }}</van-col>
      </van-col>
      <van-col span="12" class="item">
        <van-col span="24" class="title">已领用金额</van-col>
        <van-col span="24" class="price">{{ currentProduct.lastMonthReceivedAmount | numberFormat }}</van-col>
      </van-col>
    </van-row>
    <van-row class="result">
      <van-col span="12" class="item">
        <van-col span="24" class="title">剩余可领用金额</van-col>
        <van-col span="24" class="price" style="min-height: 40px">{{
          currentProduct.remainingAvailableAmount | numberFormat
        }}</van-col>
      </van-col>
    </van-row>
    <div class="unit">单位:元</div>
    <div class="bg-color" :class="theme.bgClass" />
    <van-image class="bg-img" :src="require('@/assets/images/home-cib.svg')" />
  </van-row>
</template>
<script>
import { getCibResult } from '@/api/home'
import { getDicts } from '@/api/dict/data'

const THEME_CONFIG = {
  background: require('@/assets/images/home-jixiao.png'),
  bgClass: 'bg-yellow',
  headerClass: 'header-yellow'
}

export default {
  name: 'TabCard',
  props: {
    themeType: {
      type: String,
      default: 'yellow'
    }
  },
  data() {
    return {
      type: 'CD',
      theme: THEME_CONFIG,
      currentProduct: {},
      currentTime: '',
      cibResult: [],
      productLineOptions: []
    }
  },
  created() {
    this.getCibResult()
    this.getDicts()
  },
  methods: {
    getDicts() {
      getDicts('sys_product_line').then(res => {
        this.productLineOptions = res.data
      })
    },
    changeShowType(currentProduct) {
      this.currentProduct = currentProduct
    },
    getCibResult() {
      let params = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      }
      getCibResult(params)
        .then(res => {
          this.cibResult = [...this.unique(res.data)]
          this.currentTime = this.cibResult[0] ? this.cibResult[0].year + '年' + this.cibResult[0].month + '月' : ''
          this.currentProduct = this.cibResult[0] || {
            productLine: ' ',
            totalAvailableAmount: 0,
            lastMonthReceivedAmount: 0,
            remainingAvailableAmount: 0,
            releaseRunRate: 0
          }
          console.log(this.currentProduct)
        })
        .catch(err => {
          console.log(err)
        })
    },
    unique(arr) {
      const res = new Map()
      return arr.filter(arr => !res.has(arr.productLine) && res.set(arr.productLine, 1))
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  z-index: 1;
  overflow: hidden;
  .header {
    height: 75px;
    box-sizing: border-box;
    border-radius: 0 0 100px 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    padding: 15px 16px;
    position: relative;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        color: #595959;
        font-size: 12px;
        font-weight: 400;
        margin-left: auto;
      }
    }
    .action {
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      line-height: 1;
      max-width: 250px;
      overflow-x: auto;
      .item {
        padding: 0 12px;
        color: #595959;
        font-size: 14px;
        font-weight: 500;
        margin-right: 4px;
        border-radius: 6px 6px 0 0;
        background: #fffcef;
        min-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
      }
      .active {
        background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff5ef;
        color: #e8b606;
        padding: 0 12px;
        min-height: 30px;
      }
    }
  }
  .desc {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 400;
    margin-top: 27px;
    margin-left: 16px;
  }
}
.bg-img {
  width: 75px;
  height: 75px;
  opacity: 0.2;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.bg-color {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
}

.header-yellow {
  background: linear-gradient(to right, rgba(253, 244, 212, 1), rgba(252, 244, 212, 0.55));
  border: 1px solid #ffe584;
}

.bg-yellow {
  background: linear-gradient(to top, rgba(253, 249, 236, 1) 30%, rgba(255, 246, 218, 1) 50%, rgba(255, 255, 255, 1));
}
.result {
  //display: flex;
  //align-items: center;
  padding-left: 16px;
  padding-bottom: 10px;
  margin-top: 20px;
  .item {
    .title {
      color: #8c8c8c;
      font-size: 12px;
      font-weight: 400;
    }
    .price {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      margin-top: 10px;
      min-height: 22px;
    }
    .price-mini {
      font-size: 14px;
      min-height: 22px;
    }
  }
}
.unit {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.empty-container {
  height: 130px;
}
//.result {
//  display: flex;
//  justify-content: space-between;
//  .item {
//    display: flex;
//    flex-direction: column;
//  }
//}
</style>
