<!-- 我的 -->
<template>
  <div class="user-box" v-if="showMockLogin">
    <div class="fix-btn" @click="ifShowModal = true">登</div>
    <!-- 模态框 -->
    <div class="modal" v-if="ifShowModal">
      <div class="modal-bg" @click="ifShowModal = false"></div>
      <div class="modal-content">
        <van-form @submit="onSubmit">
          <van-field
            v-model="username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="pwd"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <div style="margin: 16px">
            <van-button round type="danger" native-type="submit">提交</van-button>
            <van-button round style="margin-left: 10%" @click="handleSSO">SSO</van-button>
            <van-button v-if="ifLogined" round style="margin-left: 10%" @click="loginout">退出登录</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { GoLogin } from '@/https/http'
import headImg from '@/assets/images/touxiang.png' //默认头像
import { goSSO, setToken, setIsNeedChangeDealer, setSelectedDealer } from '@/utils/auth'
// import loginData from '@/utils/loginMockData'

export default {
  name: 'MockLogin',
  data() {
    return {
      username: '',
      pwd: '',
      avatarSrc: headImg, //头像
      ifLogined: false, // 登录状态
      ifShowModal: false, // 是否显示模态框
      showMockLogin: false, // 是否显示登录模块
      gridArr: [
        // grid数组
        { id: 0, icon: 'label-o', type: '我的订单' },
        { id: 1, icon: 'bill-o', type: '优惠券' },
        { id: 2, icon: 'goods-collect-o', type: '礼品卡' },
        { id: 3, icon: 'location-o', type: '我的收藏' },
        { id: 4, icon: 'flag-o', type: '我的足迹' },
        { id: 5, icon: 'contact', type: '会员福利' },
        { id: 6, icon: 'aim', type: '地址管理' },
        { id: 7, icon: 'warn-o', type: '账号安全' },
        { id: 8, icon: 'service-o', type: '联系客服' },
        { id: 9, icon: 'question-o', type: '帮助中心' },
        { id: 10, icon: 'smile-comment-o', type: '意见反馈' }
      ]
    }
  },
  created() {
    this.$router.onReady(() => {
      this.showMockLogin = this.$route.query.login === 'mock'
    })

    // 登陆前先看本人是否登陆过
    let user = this.$store.state.user
    // 用户名存在
    if (user.name) {
      this.username = user.name //用户名
      this.avatarSrc = user.avatar //头像
      this.ifLogined = true // 显示用户名
    }
  },
  methods: {
    // 点击立即登录，显示登录模态框
    ljdl() {
      this.ifShowModal = true
    },

    // 提交用户名，密码信息
    onSubmit() {
      this.getloginData() //发送数据请求
    },

    // 发送数据请求：登录注册
    getloginData() {
      let loginData = {}
      this.$store
        .dispatch('Login', { username: this.username, password: this.pwd })
        .then(loginInfo => {
          loginData.loginInfo = loginInfo
          this.$store.dispatch('GetInfo').then(getInfo => {
            loginData.getInfo = getInfo
            this.$store.dispatch('GenerateRoutes').then(routes => {})
            this.$store.dispatch('GetDetailInfo').then(data => {
              //mock
              this.$toast.success('登录成功')
              const loginInfo = loginData['loginInfo']
              const userInfo = loginData['getInfo']
              localStorage.setItem('token', loginInfo.access_token)
              localStorage.setItem('userInfo', JSON.stringify(userInfo.user))
              this.ifShowModal = false
              this.ifLogined = true
              this.avatarSrc = userInfo.avatar === '' ? headImg : userInfo.avatar
              this.username = userInfo.userName
              location.reload()
            })
          })
        })
        .catch(error => {
          console.log(error)
        })

      //原登录
      // GoLogin({ username: this.username, pwd: this.pwd }).then(res => {
      //   if (res.errno === 0) {
      //     this.$toast.success('登录成功')
      //     localStorage.setItem('token', res.data.token)
      //     localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
      //     this.ifShowModal = false //不显示模态框
      //     this.ifLogined = true // 显示用户名
      //     this.avatarSrc = res.data.userInfo.avatar //头像
      //     this.username = res.data.userInfo.username
      //   }
      // })
    },
    // 退出登录
    loginout() {
      // 登录了
      if (this.ifLogined) {
        this.$dialog
          .confirm({
            title: '退出登录',
            message: '是否退出登录'
          })
          .then(() => {
            // on confirm
            this.ifLogined = false // 不显示用户名
            this.avatarSrc = headImg //头像
            // 清除token
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            this.$store.commit('SET_TOKEN', '')
            this.$store.commit('SET_NAME', '')
            this.$store.commit('SET_BASEINFO', '')
            this.$store.commit('SET_IsNeedChangeDealer', false)
            setToken('')
            setIsNeedChangeDealer(false)
            setSelectedDealer('')

            this.$store.state.user.name = ''
            // 退出到登出页
            goSSO()
          })
          .catch(() => {
            // on cancel
          })
      }
    },
    handleSSO() {
      goSSO()
    }
  }
}
</script>
<style lang="less" scoped>
.van-grid-item {
  padding: 20px;
}
.user-box {
  position: fixed;
  top: 50%;
  font-size: 15px;
  border-radius: 50%;
  color: #fff;
  overflow: hidden;
  width: 30px;
  height: 30px;
  background-color: #0b41cd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  z-index: 999;
  .user-top {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 20px 10px;
    box-sizing: border-box;
    background-color: #333;
    color: white;
    img {
      width: 70px;
      height: 70px;
      margin-right: 10px;
      border-radius: 50%;
    }
    h3 {
      flex: 1;
    }
  }
  .modal {
    width: 100%;
    height: 100%;
    position: fixed; //position: fixed让height:100%起作用
    left: 0;
    top: 0;
    .modal-bg {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .modal-content {
      width: 90%;
      box-sizing: border-box;
      // height: 200px;
      background-color: #fff;
      padding: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }
  }
}
</style>
