const mode = {
  state: {
    editing: false
  },

  mutations: {
    SET_EDITING(state, value) {
      state.editing = value
    }
  }
}

export default mode
