<template>
  <div class="search-popup-box">
    <!--搜索框 -->
    <van-search
      shape="round"
      v-model="value"
      show-action
      :placeholder="placeholderVal"
      @search="onSearch"
      @cancel="onCancel"
      @input="onInput"
    />

    <!-- 历史记录和热门搜索 组件 -->
    <!-- 接受子组件传来的数据 -->
    <HistoryHot
      v-if="blockShow == 1"
      :historyKeywordList="historyKeywordList"
      :hotKeywordList="hotKeywordList"
      @goSearch="setValue"
    ></HistoryHot>

    <!-- 搜索提示 组件 -->
    <SearchTipsList v-else-if="blockShow == 2" :dataList="dataList" @setValue="setValue"></SearchTipsList>

    <!-- 在父组件中绑定自定义事件 priceChange1 categoryChange1 -->
    <!-- 下拉菜单 -->
    <searchProducts
      :filterCategory="filterCategory"
      :goodsList="goodsList"
      @priceChange1="priceChange1"
      @categoryChange1="categoryChange1"
      v-else
    ></searchProducts>
  </div>
</template>

<script>
import HistoryHot from '@/views/home/search/HistoryHot'
import searchProducts from '@/views/home/search/searchProducts'
import SearchTipsList from '@/views/home/search/SearchTipsList'
// 引入请求接口
import { GetSearchData, GetPopupData, GetSearchTipsListData } from '@/https/http'
export default {
  name: 'search-popup',
  data() {
    return {
      value: '', // 搜索值
      page: 1, //页数
      size: 20, // 每页数据条数
      order: 'desc', // 价格由高到底
      categoryId: 0, // 商品类别id 全家、居家、餐饮。。。
      sort: 'id', // 排序字段 price 和id  2种情况
      goodsList: [], // 搜索出来的商品数据
      filterCategory: [], // 商品类别参数
      historyKeywordList: [], //历史记录数据
      hotKeywordList: [], // 热门搜索 数据
      blockShow: 1, //控制显示哪个？（ 历史记录和热门搜索组件 、 搜索提示组件、下拉菜单组件）
      dataList: [], //搜索提示数据
      placeholderVal: '' // 搜索框的默认提示词
    }
  },
  methods: {
    priceChange1(value) {
      // value为子组件searchProducts传来的价格排序参数asc，desc
      console.log('父组件：价格', value)
      this.order = value
      this.sort = 'price'
      this.onSearch()
      //   this.$router.go(0);
    },

    categoryChange1(value) {
      // value为子组件searchProducts传来的种类参数
      console.log('父组件：类别id', value)
      this.sort = 'id'
      this.categoryId = value
      this.onSearch()
    },

    // 搜索框搜索功能
    onSearch() {
      this.blockShow = 3
      // 关键字搜索接口数据
      let obj = {
        keyword: this.value,
        page: 1,
        size: this.size, // 每页数据条数
        order: this.order, // 价格由高到底
        categoryId: this.categoryId, // 商品类别id 全家、居家、餐饮。。。
        sort: this.sort // 排序字段
      }
      //   发送数据请求
      // 搜索框商品搜索功能接口，获取对应的商品列表
      GetSearchData(obj).then(res => {
        // console.log(22, res);

        this.filterCategory = res.data.filterCategory
        // 将商品的类别中的字段替换一下
        this.filterCategory = JSON.parse(
          JSON.stringify(this.filterCategory).replace(/id/g, 'value').replace(/name/g, 'text')
        )

        this.goodsList = res.data.goodsList
      })
    },

    // 搜索取消
    onCancel() {
      this.$router.push('/home')
    },

    // 获得历史记录
    gethistoryHotData() {
      GetPopupData().then(res => {
        console.log(555, res)
        this.historyKeywordList = res.data.historyKeywordList
        this.hotKeywordList = res.data.hotKeywordList
        this.placeholderVal = res.data.defaultKeyword.keyword
      })
    },

    // 获取搜索提示
    getSearchHelperData(value) {
      this.blockShow = 2 // 展示搜索提示列表组件
      // 输入触发
      // 搜索提示数据请求
      GetSearchTipsListData({ keyword: value }).then(res => {
        console.log(333333, res)
        this.dataList = res.data
      })
    },
    setValue(m) {
      console.log(6666, m)
      this.value = m
      this.onSearch()
    },
    onInput(value) {
      this.getSearchHelperData(value)
    }
  },
  created() {
    this.gethistoryHotData()
  },
  components: {
    HistoryHot,
    searchProducts,
    SearchTipsList
  }
}
</script>

<style></style>
