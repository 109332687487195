<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :query-permission="['system:notice:query']"
      :isCheckbox="false"
      :hasIcon="false"
      :moreSearch="true"
      :more-column="true"
      :isNeedOperate="true"
      :isCardDisabled="false"
    >
      <template slot="table-btn" slot-scope="data">
        <span @click="setMoreColumn(data.row)" style="margin-top: 10px"
          >{{ data.row.isShowMoreColumn ? '收起' : '展示' }}更多字段</span
        >
      </template>
    </form-table>
    <span class="btn" @click="handleExport">导出</span>
  </div>
</template>

<script>
import { dataCenter } from '@/utils/dataCenter'
export default {
  name: 'DemoList',
  data() {
    return {
      //单个
      // btnData: [{ text: '下载' }],
      //多个
      //   btnData: [{ text: '下载' }, { text: '新增' }],
      searchFormData: [
        {
          props: 'authorizationType',
          label: 'LOA类型',
          type: 'DictSelect',
          dictCode: 'authorization_type',
          dictValueFormat: 'dictLabel',
          multiple: false,
          isMore: true
        },
        {
          props: 'serialNumber',
          label: 'LOA编码',
          type: 'input'
        },
        {
          props: 'customerName',
          label: '终端名称',
          type: 'input'
        },
        {
          props: 'customerCode',
          label: '终端代码',
          type: 'input',
          isMore: true
        },
        {
          props: 'province',
          label: '终端省份',
          type: 'input'
        },
        {
          props: 'departmentCode',
          label: '科室',
          type: 'DictSelect',
          dictCode: 'customer_department',
          dictValueFormat: 'dictLabel',
          multiple: false
        },
        // {
        //   props: 'excludedDepartmentName',
        //   label: '排除科室',
        //   type: 'DictSelect',
        //   dictCode: 'customer_department',
        //   dictValueFormat: 'dictLabel',
        //   multiple: false,
        //   isMore: true
        // },
        {
          props: 'productLine',
          label: '产品线',
          type: 'DictSelect',
          dictCode: 'sys_product_line',
          dictValueFormat: 'dictLabel',
          multiple: false
        },
        {
          props: 'productName',
          label: '产品',
          type: 'input'
        },
        // {
        //   props: 'excludedProductName',
        //   label: '排除产品',
        //   type: 'input',
        //   isMore: true
        // },
        {
          props: 'version',
          label: '版本',
          type: 'input'
        },
        {
          props: 'loaEffectiveYear',
          label: '生效年份',
          width: '120px',
          type: 'date',
          valueFormat: 'yyyy',
          dateFormat: 'yyyy',
          dateType: 'year',
          isMore: true
        },
        {
          props: 'terminatedYear',
          label: '过期年份',
          width: '120px',
          type: 'date',
          valueFormat: 'yyyy',
          dateFormat: 'yyyy',
          dateType: 'year',
          isMore: true
        }
      ],
      tableOptions: [
        {
          prop: 'serialNumber',
          label: 'LOA编码',
          type: 'fileName',
          height: 'auto',
          hideFileIcon: true,
          'show-overflow-tooltip': true,
          isMore: true
        },
        {
          prop: 'authorizationType',
          label: 'LOA类型',
          'show-overflow-tooltip': true,
          isMore: true
        },
        {
          prop: 'customerCode',
          label: '终端代码',
          'show-overflow-tooltip': true,
          isMore: true
        },
        {
          prop: 'customerName',
          label: '终端名称',
          'show-overflow-tooltip': true,
          type: 'fileName',
          height: 'auto',
          hideFileIcon: true
        },
        {
          prop: 'province',
          label: '终端省份',
          'show-overflow-tooltip': true
        },
        {
          prop: 'includedDepartmentName',
          'show-overflow-tooltip': true,
          label: '科室'
        },
        {
          prop: 'excludedDepartmentName',
          'show-overflow-tooltip': true,
          label: '排除科室',
          isMore: true
        },
        {
          prop: 'productLine',
          'show-overflow-tooltip': true,
          label: '产品线',
          type: 'fileName',
          cardTitleRight: 'productLine',
          height: 'auto',
          hideFileIcon: true
        },
        {
          prop: 'includedProductName',
          'show-overflow-tooltip': true,
          label: '产品'
        },
        {
          prop: 'excludedProductName',
          'show-overflow-tooltip': true,
          label: '排除产品',
          isMore: true
        },

        {
          prop: 'loaEffectiveDate',
          label: '生效日期'
        },
        {
          prop: 'loaTerminatedDate',
          label: '过期日期',
          isMore: true
        },
        {
          prop: 'version',
          label: '版本'
        }
      ],
      headerTitle: 'LOA信息',
      queryUrl: '/dealer/loa-auth/dealer-auth-list'
    }
  },
  created() {},
  methods: {
    onSelect(text, queryParams, list) {
      console.log(text, queryParams)
      this.daowload(list)
    },
    daowload(list) {
      dataCenter.downloadFile(list)
    },
    handleExport() {
      this.download('/dealer/loa-auth/export/dealer-auth-list', {}, `LOA授权信息${new Date().getTime()}.xlsx`, {
        method: 'get',
        params: {
          ...this.$refs['baseForm'].queryParams,
          ...this.$refs['baseForm'].extraParams
        }
      })
    },
    setMoreColumn(data) {
      const isShowMoreColumn = data.isShowMoreColumn ? !data.isShowMoreColumn : true
      this.$set(data, 'isShowMoreColumn', isShowMoreColumn)
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  height: 50px;
  align-items: center;
  display: flex;
  position: absolute;
  right: 16px;
  font-size: 16px;
  color: #0b41cd;
  top: 0;
}
</style>
