<template>
  <div class="app-container">
    <form-table
      ref="baseForm"
      module="productDocument"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      isNeedOperate
      :hide-btn-with-empty-data="true"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
    >

    <template slot="table-btn" slot-scope="data">
        <span style="color: #ced9f5; margin: 0 5px">|</span>
        <span
          :style="data.row.existLastFlag == 'Y' ? { color: '#0b41cd' } : { color: '#8c8c8c' }"
          @click="lastValidity(data.row)"
          >上一版本</span
        >
      </template>
    </form-table>

    <van-dialog v-model="show" title="上一版本" :showConfirmButton="false" :closeOnClickOverlay="true">
      <van-row style="margin-top: 0px">
        <van-col span="24">
          <div style="padding: 10px 10px" v-if="Object.keys(lastValidInfo).length > 0">
            <div style="width: 300px; min-height: 60px; font-size: 12px; color: #323233; display: flex">
              <div style="min-width: 60px; font-weight: bold">文件名：</div>
              <div>{{ lastValidInfo.fileName }}</div>
            </div>
            <div style="width: 300px; min-height: 60px; font-size: 12px; color: #323233; display: flex">
              <div style="min-width: 60px; font-weight: bold">上传人：</div>
              <div>{{ lastValidInfo.uploadUserName }}</div>
            </div>
            <div style="width: 300px; min-height: 60px; font-size: 12px; color: #323233; display: flex">
              <div style="min-width: 60px; font-weight: bold">上传时间：</div>
              <div>{{ lastValidInfo.uploadTime }}</div>
            </div>
            <div style="height: 30px; text-align: center; margin-bottom: 10px">
              <van-button type="default" style="width: 70px" @click="showFile">预览</van-button>
              <van-button type="default" style="width: 70px; margin-left: 10px" @click="downloadLastFile"
                >下载</van-button
              >
            </div>
          </div>
          <div style="height: 30px; line-height: 30px; font-size: 12px; color: #323233; padding: 10px 10px" v-else>
            暂无上个有效期的信息
          </div>
        </van-col>
      </van-row>
    </van-dialog>
  </div>
</template>

<script>
import { dataCenter } from '@/utils/dataCenter'
import { queryUrl, searchFormData, tableOptions } from './config'
import { lastAttachment } from '@/api/file'
import { getDicts } from '@/api/dict/data'
import { previewFile } from '@/api/formTable'
import { download } from '@/utils/request'
export default {
  name: 'ShareDocument',
  data() {
    return {
      btnData: [{ text: '下载' }],
      headerTitle: '产品六面照',
      searchFormData: searchFormData,
      tableOptions: tableOptions,
      queryUrl: queryUrl,
      lastValidInfo: {},
      show: false
    }
  },
  created() {
    this.getDictData()
  },
  methods: {
    async getDictData() {
      const departmentOptions = await getDicts('cc_shared_document_department').then(({ data }) => {
        return data
      })
      const fileTypeOptions = await getDicts('cc_shared_document_file_type').then(({ data }) => {
        return data
      })
      this.tableOptions.forEach(element => {
        if (element.prop === 'departCode') {
          element.options = [...departmentOptions]
        } else if (element.prop === 'fileType') {
          element.options = [...fileTypeOptions]
        }
      })
    },
    onSelect(text, queryParams, list) {
      this.download(list)
    },
    download(list) {
      // dataCenter.downloadFile(list)
      if (list.length === 0) {
        this.$toast('请先勾选要操作的记录')
        return false
      }
      const selectedList = [...list]
      const selectedFileIdList = selectedList.map(item => {
        return item.fileId
      })
      const fileIds = selectedFileIdList.join(',')
      download('/file/multi-download', {}, null, {
        method: 'POST',
        params: {
          regCertFlag: true,
          watermarkFlag: true,
          fileIds
        }
      })
    },
    showFile() {
      let params = {
        regCertFlag: true,
        fileId: this.lastValidInfo.fileId,
        watermarkFlag: true
      }
      previewFile(params, this.lastValidInfo.fileName)
    },
    lastValidity(obj) {
      if (obj.existLastFlag == 'N') {
        return false
      }
      lastAttachment(obj.productAttachmentId).then(res => {
        this.lastValidInfo = { ...res.data }
        this.show = true
      })
    },
    downloadLastFile() {
      const fileIds = this.lastValidInfo.fileId
      download('/file/multi-download', {}, null, {
        method: 'POST',
        params: {
          regCertFlag: true,
          watermarkFlag: true,
          fileIds
        }
      })
    },
  }
}
</script>
