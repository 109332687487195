export const operationTableOptions = [
  {
    prop: 'assessmentCategory',
    label: '考核类目',
    hasPopover: 'assessmentStandard',
    width: '150',
    class: 'cols-light-grey',
    mergeCell: 'assessmentCategory'
  },
  {
    prop: 'assessmentItem',
    label: '考核项目',
    width: '150',
    class: 'cols-light-grey border-left',
    mergeCell: 'assessmentItem'
  },
  {
    prop: 'q1Score',
    label: 'Q1',
    align: 'center',
    class: 'cols-light-grey border-left'
  },
  {
    prop: 'q2Score',
    label: 'Q2',
    align: 'center',
    class: 'cols-light-grey border-left'
  },
  {
    prop: 'q3Score',
    label: 'Q3',
    align: 'center',
    class: 'cols-light-grey border-left'
  },
  {
    prop: 'q4Score',
    label: 'Q4',
    align: 'center',
    class: 'cols-light-grey border-left'
  },
  {
    prop: 'annualViolationsTimes',
    label: '截至当季度违反情况',
    width: '150',
    align: 'center',
    class: 'cols-light-grey border-left bg-gray'
  },
  {
    prop: 'annualDeductionPoints',
    label: '截至当季度总扣分',
    width: '150',
    align: 'center',
    class: 'cols-light-grey border-left bg-blue'
    // 需要注意的是可能当前列的prop是最终得分，但是要按照考核类目来合并单元格
    // mergeCell: 'assessmentCategory'
  }
]

export const marketingTableOptions = [
  {
    prop: 'assessmentCategory',
    label: '考核类目',
    width: '150',
    class: 'cols-light-grey',
    mergeCell: 'assessmentCategory'
  },
  {
    prop: 'assessmentItem',
    label: '考核项目',
    width: '150',
    class: 'cols-light-grey border-left',
    hasPopover: 'assessmentStandard',
    mergeCell: 'assessmentItem'
  },
  {
    prop: 'assessmentIndicator',
    label: '考核科目',
    width: '150',
    class: 'cols-light-grey border-left'
  },
  {
    prop: 'targetValue',
    label: '年度指标',
    width: '150',
    class: 'cols-light-grey border-left'
  },
  {
    prop: 'q1',
    label: '截至Q1',
    class: 'cols-light-grey',
    align: 'center',
    children: [
      {
        prop: 'q1TargetValue',
        label: '季度目标',
        class: 'cols-light-grey',
        align: 'center'
      },
      {
        prop: 'q1ActualValue',
        label: '实际完成',
        align: 'center',
        class: 'cols-light-grey'
      }
    ]
  },
  {
    prop: 'q2',
    label: '截至Q2',
    class: 'cols-light-grey',
    align: 'center',
    children: [
      {
        prop: 'q2TargetValue',
        label: '季度目标',
        class: 'cols-light-grey',
        align: 'center'
      },
      {
        prop: 'q2ActualValue',
        label: '实际完成',
        class: 'cols-light-grey',
        align: 'center'
      }
    ]
  },
  {
    prop: 'q3',
    label: '截至Q3',
    class: 'cols-light-grey',
    align: 'center',
    children: [
      {
        prop: 'q3TargetValue',
        label: '季度目标',
        class: 'cols-light-grey',
        align: 'center'
      },
      {
        prop: 'q3ActualValue',
        label: '实际完成',
        align: 'center',
        class: 'cols-light-grey'
      }
    ]
  },
  {
    prop: 'q4',
    label: '截至Q4',
    class: 'cols-light-grey',
    align: 'center',
    children: [
      {
        prop: 'q4TargetValue',
        label: '季度目标',
        class: 'cols-light-grey',
        align: 'center'
      },
      {
        prop: 'q4ActualValue',
        label: '实际完成',
        align: 'center',
        class: 'cols-light-grey'
      }
    ]
  },
  {
    prop: 'kpiScore',
    label: '年度得分',
    class: 'cols-light-blue',
    align: 'center',
    mergeCell: 'assessmentItem'
    // fixed: 'right'
  }
]

export const achievementTableOptions = [
  {
    prop: 'assessmentCategory',
    label: '考核类目',
    width: '150',
    class: 'cols-light-grey'
  },
  {
    prop: 'firstHalfYear',
    label: '上半年',
    class: 'cols-light-grey',
    align: 'center',
    children: [
      {
        prop: 'q1Score',
        label: 'Q1',
        class: 'cols-light-grey',
        align: 'center',
        children: [
          {
            prop: 'January',
            label: '1月',
            align: 'center',
            class: 'cols-light-grey'
          },
          {
            prop: 'February',
            label: '2月',
            align: 'center',
            class: 'cols-light-grey'
          },
          {
            prop: 'March',
            label: '3月',
            align: 'center',
            class: 'cols-light-grey'
          }
        ]
      },
      {
        prop: 'q2Score',
        label: 'Q2',
        align: 'center',
        class: 'cols-light-grey',
        children: [
          {
            prop: 'April',
            label: '4月',
            align: 'center',
            class: 'cols-light-grey'
          },
          {
            prop: 'May',
            label: '5月',
            align: 'center',
            class: 'cols-light-grey'
          },
          {
            prop: 'June',
            label: '6月',
            align: 'center',
            class: 'cols-light-grey'
          }
        ]
      }
    ]
  },
  {
    prop: 'secondHalfYear',
    label: '下半年',
    align: 'center',
    class: 'cols-light-grey',
    children: [
      {
        prop: 'q3Score',
        label: 'Q3',
        align: 'center',
        class: 'cols-light-grey',
        children: [
          {
            prop: 'July',
            label: '7月',
            align: 'center',
            class: 'cols-light-grey'
          },
          {
            prop: 'August',
            label: '8月',
            align: 'center',
            class: 'cols-light-grey'
          },
          {
            prop: 'September',
            label: '9月',
            align: 'center',
            class: 'cols-light-grey'
          }
        ]
      },
      {
        prop: 'q4Score',
        label: 'Q4',
        align: 'center',
        class: 'cols-light-grey',
        children: [
          {
            prop: 'October',
            label: '10月',
            align: 'center',
            class: 'cols-light-grey'
          },
          {
            prop: 'November',
            label: '11月',
            align: 'center',
            class: 'cols-light-grey'
          },
          {
            prop: 'December',
            label: '12月',
            align: 'center',
            class: 'cols-light-grey'
          }
        ]
      }
    ]
  },
  {
    prop: 'totalScore',
    label: '截至当前',
    class: 'cols-light-blue',
    align: 'center'
    // fixed: 'right'
  }
]

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  'totalScore'
]
export const seasons = ['q1', 'q2', 'q3', 'q4', 'totalScore']
export const halfYears = ['firstHalfYear', 'secondHalfYear', 'totalScore']
export const years = ['year', 'totalScore']
