// loadgin.vue
<template>
  <van-popup
    :close-on-click-overlay="false"
    v-model="show"
    :overlay-style="{ 'background-color': 'rgba(255, 255, 255, 0.7)' }"
    style="background-color: transparent; overflow-y: unset"
  >
    <div class="loadingWrap">
      <van-loading v-if="show" type="spinner" color="#1989fa" />
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'loading',
  data() {
    return {
      show: false
    }
  }
}
</script>

<style lang="less">
.aaa {
  background-color: rgba(255, 255, 255, 0.7);
}
// .loadingWrap{
//     .van-popup{
//         background-color: rgba(0,0,0,.7)
//     }
// }
</style>
