<template>
  <div
    class="van-loading"
    v-show="loading"
    style="width: 100vw; height: 100vh; display: flex; justify-content: center; align-items: center"
  >
    <van-loading size="20px" type="spinner" vertical text-size="14px" color="#0094ff">登录中</van-loading>
  </div>
</template>

<script>
import { ssoLogin } from '@/api/login'
import headImg from '@/assets/images/touxiang.png'

export default {
  name: 'Auth',
  data() {
    return {
      loading: false
    }
  },

  created() {
    this.login(this.$route.query)
  },
  methods: {
    login(params) {
      let loginData = {}
      this.loading = true
      ssoLogin(params).then(res => {
        if (res.code !== 200) {
          this.$toast.fail({
            message: res.msg || '登录失败',
            onClose: () => this.$router.push('/login').catch(() => {})
          })
          return
        }
        loginData.loginInfo = res
        this.$store.dispatch('ssoLogin', res.data.access_token)
        this.$store.dispatch('ssoLoginRefresh', res.data.refresh_token)
        this.$store.dispatch('GetInfo').then(getInfo => {
          loginData.getInfo = getInfo
          this.$store.dispatch('GenerateRoutes').then(routes => {})
          this.$store.dispatch('GetDetailInfo').then(data => {
            //mock
            this.loading = false

            const loginInfo = loginData['loginInfo']
            const userInfo = loginData['getInfo']
            localStorage.setItem('token', loginInfo.access_token)
            localStorage.setItem('userInfo', JSON.stringify(userInfo.user))
            this.$router.push('/').catch(() => {})
          })
        })
      })
    }
  }
}
</script>
