<template>
  <div>
    <van-row style="padding: 20px 20px; font-size: 16px">
      <van-radio-group v-model="searchType" :icon-size="16" direction="horizontal">
        <van-radio name="PendingTask">待办任务</van-radio>
        <van-radio name="OvertimeTask" style="margin-left: 50px">超时未完成任务</van-radio>
      </van-radio-group>
    </van-row>
    <PendingTask ref="PendingTask" v-show="searchType === 'PendingTask'" />
    <OvertimeTask ref="OvertimeTask" v-show="searchType === 'OvertimeTask'" />
  </div>
</template>

<script>
import PendingTask from '@/views/task/pendingTasks/components/pendingTasks.vue'
import OvertimeTask from '@/views/task/pendingTasks/components/overtimeTasks.vue'
export default {
  name: 'DemoList',
  components: { PendingTask, OvertimeTask },
  data() {
    return {
      searchType: 'PendingTask'
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang="less" scoped></style>
