import Vue from 'vue'
import App from './App.vue'
import store from './store'
import 'amfe-flexible'
import '@/assets/icons/iconfont.css'
// 引入重置样式表
import '@/assets/css/reset.css'
import less from 'less'
import { download } from '@/utils/request'
// 通用表单列表组件
import FormTable from '@/components/FormTable'
Vue.use(less)

//字号
import './utils/fontSize'
//导入所有组件。
import Vant from 'vant'
import 'vant/lib/index.less'
Vue.use(Vant)

Vue.prototype.download = download
// 引入路由文件
import router from '@/router/index.js'
import directive from './directive' // directive
// 引入vant 提供过的懒加载
import { Lazyload } from 'vant'
Vue.use(Lazyload)
Vue.use(directive)

Vue.component('FormTable', FormTable)

Vue.config.productionTip = false

import Loading from '@/utils/loading.js'
Vue.use(Loading)

// import Vconsole from 'vconsole'

// const vConsole = new Vconsole()

// Vue.use(vConsole)

//日历组件
import 'vue-event-calendar/dist/style.css' //1.1.10之后的版本，css被放在了单独的文件中，方便替换
import vueEventCalendar from 'vue-event-calendar'
Vue.use(vueEventCalendar, { locale: 'zh', color: 'rgba(114, 144, 223, 1)' }) //可以设置语言，支持中文和英文

//全局 Mixin
import FiledConfigMixin from '@/utils/FiledConfigMixin'
Vue.mixin(FiledConfigMixin)

// 全局过滤器
import './filter'

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
