<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      @clickBtn="onSelect"
      :is-need-operate="false"
    >
    </form-table>
  </div>
</template>

<script>
import { getDicts } from '@/api/dict/data'
import { delProductCertificate, lastValidPeriod } from '@/api/file'
import { dataCenter } from '@/utils/dataCenter'

export default {
  name: 'productQualificationRDSZ',
  data() {
    return {
      btnData: [{ text: '下载' }],
      searchFormData: [
        { props: 'fileName', label: '文件名', type: 'input' },
        { props: 'type', label: '类型', type: 'DictSelect', dictCode: 'rdsz_type' },
        { props: 'productLineCode', label: '产品线', type: 'DictSelect', dictCode: 'sys_product_line', options: [] }
      ],
      tableOptions: [
        {
          prop: 'fileName',
          label: '文件名',
          type: 'fileName',
          // rightTop: {
          //   left: {
          //     prop: 'releaseTime',
          //     label: '上市时间'
          //   }
          // },
          rightBottom: { prop: 'type', label: '类型', dictCode: 'rdsz_type', options: [] }
        },
        // {
        //   prop: 'type',
        //   label: '类型',
        //   dictCode: 'rdsz_type'
        // },
        {
          prop: 'releaseTime',
          label: '上市时间'
        },
        {
          prop: 'productLineCode',
          label: '产品线',
          dictCode: 'sys_product_line',
          options: []
        },
        {
          prop: 'updateTime',
          label: '上传时间'
        }
      ],
      headerTitle: 'RDSZ上市资料',
      queryUrl: '/bff-dp-mobile/rdsz/dealer-list',
      showPopover: false,
      lastValidInfo: {}
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    async setOptions() {
      const options = await getDicts('rdsz_type').then(res => {
        let list = []
        if (res.code === 200 && res.data.length > 0) {
          list = res.data
        }
        return list
      })
      this.tableOptions[0].rightBottom.options = options

      const options2 = await getDicts('sys_product_line').then(res => {
        let list = []
        if (res.code === 200 && res.data.length > 0) {
          list = res.data
        }
        return list
      })
      this.searchFormData[2].options = options2
      this.tableOptions[2].options = options2
    },
    handleDelete(obj) {
      let params = {
        regCertDocumentId: obj.regCertDocumentId,
        fileId: obj.fileId
      }
      this.$dialog
        .alert({
          message: `是否确认删除该文件？`,
          showCancelButton: true
        })
        .then(() => {
          delProductCertificate(params).then(res => {
            if (res.code === 200) {
              this.$toast.success('删除成功')
              this.$refs.baseForm.getList()
            } else {
              this.$toast.fail(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    lastValidity(obj) {
      let params = {
        id: obj.regCertDocumentId
      }
      lastValidPeriod(params).then(res => {
        this.lastValidInfo = { ...res }
      })
    },
    downloadLastFile(obj) {
      console.log(obj)
    },
    onSelect(text, queryParams, list) {
      this.download(list)
    },
    download(list) {
      dataCenter.downloadFile(list)
    }
  }
}
</script>

<style></style>
