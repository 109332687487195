<template>
  <div id="app">
    <!-- 路由匹配到的组件将显示在这里 -->
    <keep-alive :include="['Search']">
      <router-view> </router-view>
    </keep-alive>

    <!-- 底部tabbar 组件test -->
    <AppTabBar v-show="$route.meta.isShowTabbar"></AppTabBar>
    <ChangeDealer />
    <MockLogin v-if="nodeEnv !== 'production'" />
  </div>
</template>

<script>
import AppTabBar from '@/components/AppTabBar'
import MockLogin from '@/components/MockLogin'
import ChangeDealer from '@/components/ChangeDealer.vue'
import { isLoginExpire } from '@/utils/loginExpire'

export default {
  name: 'App',
  data() {
    return {
      nodeEnv: process.env.NODE_ENV
    }
  },
  components: {
    ChangeDealer,
    AppTabBar,
    MockLogin
  },
  created() {
    isLoginExpire()
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
