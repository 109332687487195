export function formatNumberWithCommas(number) {
  if (!number) {
    return number
  }
  // 将数字转换成字符串
  let numberStr = number.toString()

  // 使用正则表达式在逗号前插入逗号
  numberStr = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return numberStr
}

export function formatTargetDate(date) {
  // 目标日期
  const targetDate = new Date(date) // 替换成你的目标日期

  // 当前日期
  const currentDate = new Date()

  // 计算日期差距（以毫秒为单位）
  const timeDiff = currentDate - targetDate

  // 将毫秒差距转换为天数
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24))

  if (daysDiff > 7) {
    return date
  } else if (daysDiff === 0) {
    return '今天'
  }

  // 显示结果
  return `${daysDiff}天前`
}
