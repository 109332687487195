<template>
  <div class="warpper">
    <div class="list-bg" />
    <van-nav-bar
      :title="title"
      right-text="完成"
      :class="loading ? 'btn-loading' : ''"
      left-arrow
      @click-left="goToBack"
      @click-right="onSubmit"
    >
      <template #right>
        <span class="header-btn" :class="loading ? 'btn-loading' : ''" @click="onSubmit">完成</span>
      </template>
    </van-nav-bar>

    <van-form validate-first ref="form">
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.contactName"
        name="contactName"
        required
        label="姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true, message: '姓名不能为空' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.phonenumber"
        name="phonenumber"
        label="手机"
        required
        placeholder="请输入手机号"
        :rules="[{ pattern: phonePattern, message: '请输入正确的手机号码' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.email"
        name="email"
        required
        label="邮箱"
        placeholder="请输入邮箱"
        rows="1"
        autosize
        type="textarea"
        :rules="[{ pattern: emailPattern, message: '请输入正确的邮箱地址' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.position"
        name="position"
        required
        label="职位"
        placeholder="请输入职位"
        :rules="[{ required: true, message: '职位不能为空' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        readonly
        required
        clickable
        rows="1"
        autosize
        type="textarea"
        name="picker"
        :value="contactGroupsLabel"
        label="联络人组别"
        placeholder="请点击选择联络人组别"
        @click="showPicker = true"
        :rules="[{ required: true, message: '请选择联络人组别' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        name="status"
        label="状态"
        input-align="right"
        required
        v-if="title == '编辑联络人'"
      >
        <template #input>
          <van-switch size="20" v-model="status" @input="changeStatus" />
        </template>
      </van-field>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          value-key="name"
          item-height="56px"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        >
          <template #option="option">
            <div style="width: 100%">
              <van-checkbox-group
                v-model="contactGroupsClone"
                style="display: inline-block; margin-left: 130px; font-size: 14px"
              >
                <van-checkbox :name="option.dictValue">{{ option.dictLabel }}</van-checkbox>
              </van-checkbox-group>
            </div>
          </template>
        </van-picker>
        <!-- <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" /> -->
      </van-popup>
      <div style="margin: 16px">
        <!-- <van-button round block type="info" native-type="submit">提交</van-button> -->
      </div>
    </van-form>
  </div>
</template>

<script>
import { getDealerContact, updateDealerContact, addDealerContact } from '@/api/userCenter/dealerContact'
import { checkBinding } from '@/api/userCenter/dealer'
import { getDicts } from '@/api/dict/data'
import _ from 'lodash'

export default {
  name: 'dealer-contact-info',
  props: {
    contactInfoDetail: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      title: '',
      lengthPattern: /^.{1,10}$/,
      phonePattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      emailPattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      columns: [],
      showPicker: false,
      selectedOptions: [],
      form: {
        contactName: '',
        phonenumber: '',
        email: '',
        position: '',
        contactGroups: [],
        status: 0
      },
      status: false,
      contactGroupsClone: [],
      loading: false,
      beforeEditInfo: {}
    }
  },
  watch: {
    contactInfoDetail: {
      handler(value) {
        this.init(value)
      },
      deep: true
    }
  },
  computed: {
    contactGroupsLabel: function () {
      return this.columns
        .filter(itemA => this.form.contactGroups.includes(itemA.dictValue.toString()))
        .map(filteredItem => filteredItem.dictLabel)
        .join('，')
    },
    dealerInfo() {
      return this.$store.state.user.baseInfo
    }
  },
  created() {
    getDicts('sys_contacts_group').then(({ data }) => {
      this.columns = data
    })
    // this.onLoad()
  },
  mounted() {
    if (this.$route.params.dealerContactId != 'new') {
      this.title = '编辑联络人'
    } else {
      this.title = '新增联络人'
    }
    this.init(this.contactInfoDetail)
  },
  methods: {
    init(obj) {
      this.form = _.cloneDeep(obj)
      this.contactGroupsClone = this.form.contactGroups
      let status = _.cloneDeep(this.form.status == 0 ? true : false)
      this.status = status
      console.log(this.form)
    },
    onConfirm() {
      this.form.contactGroups = _.cloneDeep(this.contactGroupsClone)
      this.showPicker = false
    },
    goToBack() {
      this.$emit('close')
    },
    goToBackWithRefresh() {
      this.$router.push({
        name: 'UserCenter',
        params: {
          page: 'Contact'
        }
      })
    },
    changeStatus(val) {
      this.form.status = val ? 0 : 1
      // this.$forceUpdate()
      console.log(this.form.status)
      // checkBinding(param).then(res => {
      //   let msgStr = null
      //   let text = val ? '启用' : '停用'
      //   let status = val ? '0' : '1'
      //   if (res.data.isBindFlag) {
      //     if (text === '停用') {
      //       msgStr =
      //         '该经销商联络人账号已绑定过经销商账号，如果停用该账号，会将已绑定的经销商账号进行解绑操作，请确认是否继续停用该账号？'
      //     } else {
      //       msgStr = '确认要' + text + ' ' + this.form.contactName + ' 这个联络人吗？'
      //     }
      //   } else {
      //     msgStr = '确认要' + text + ' ' + this.form.contactName + ' 这个联络人吗？'
      //   }
      //   this.$dialog
      //     .confirm({
      //       message: msgStr
      //     })
      //     .then(() => {
      //       updateDealerContact({ ...this.form, status: status }).then(({ code, msg }) => {
      //         if (code != 200) {
      //           this.$toast.fail({
      //             message: msg,
      //             className: 'fail'
      //           })
      //           return
      //         }
      //         this.$toast.success('操作成功')
      //         this.form.status = val
      //       })
      //     })
      //     .catch(() => {})
      // })
    },
    onSubmit() {
      this.$refs.form
        .validate()
        .then(() => {
          if (this.form.processFlag != 'add') {
            this.form.processFlag = 'update'
          }
          this.$emit('submit', this.form)
        })
        .catch(() => {
          // this.$toast.fail('提交失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}
/deep/.van-cell {
  border-bottom: 1px solid #ebedf0;
}
/deep/ .van-form {
  background: #fff;
}
/deep/ .van-toast--fail {
  width: 300px;
}
/deep/ .van-nav-bar__title {
  font-weight: bold !important;
}
/deep/ .van-field__label {
  color: #000;
}
/deep/.van-field__error-message {
  text-align: right;
}
.header-btn {
  color: #0b41cd;
}

.warpper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
