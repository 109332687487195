import request from '@/utils/request'

//删除
export function deleteVadScoreFile(id) {
  return request({
    url: '/bff-dp-mobile/performance-result/delete-document/' + id,
    method: 'delete'
  })
}

//查询结构化详情数据
export function getScoreDetail(id) {
  return request({
    url: '/bff-dp-mobile/performance-result/performance-merge-detail/' + id,
    method: 'get'
  })
}
