const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userInfo: state => state.user.userInfo,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  routes: state => state.user.routes,
  allRoutes: state => state.user.allRoutes,
  baseInfo: state => state.user.baseInfo
}
export default getters
