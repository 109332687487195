var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-bg"},[_c('van-search',{attrs:{"placeholder":"请输入搜索关键词","show-action":"","clearable":false},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [(_vm.searchResult != '')?_c('van-icon',{attrs:{"name":"clear"},on:{"click":_vm.onClear}}):_vm._e()]},proxy:true},{key:"action",fn:function(){return [_c('div',{on:{"click":function($event){return _vm.onSearch(_vm.searchResult)}}},[_vm._v("搜索")])]},proxy:true}]),model:{value:(_vm.searchResult),callback:function ($$v) {_vm.searchResult=$$v},expression:"searchResult"}}),(_vm.NoSearchResult)?_c('div',{staticClass:"noSearch"},[_vm._v(" "+_vm._s(_vm.tips)+" ")]):_c('van-list',{staticClass:"padding"},[_c('van-collapse',{staticClass:"collapse",attrs:{"accordion":""},model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},_vm._l((_vm.tabs),function(tab){return _c('van-collapse-item',{key:tab.documentType,attrs:{"name":tab.documentType},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.filterTitle(tab.documentType)))]),_c('van-badge',{attrs:{"content":tab.count,"color":"#0b41cd"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"collapse-content"},[_c('van-list',{attrs:{"finished":tab.finished,"finished-text":"没有更多了"},on:{"load":function($event){return _vm.onLoad(tab)}},model:{value:(tab.listLoading),callback:function ($$v) {_vm.$set(tab, "listLoading", $$v)},expression:"tab.listLoading"}},[_c('ul',{staticClass:"ul"},_vm._l((tab.results),function(result,i){return _c('li',{key:i},[_c('div',{staticClass:"wrapper"},[_c('div',[(result.fileName && _vm.getFileType(result.fileName) === '.PDF')?_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/pdf.svg')}}):(
                        result.fileName &&
                        (_vm.getFileType(result.fileName) === '.XLS' || _vm.getFileType(result.fileName) === '.XLSX')
                      )?_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/excel.svg')}}):(
                        result.fileName &&
                        (_vm.getFileType(result.fileName) === '.DOC' || _vm.getFileType(result.fileName) === '.DOCX')
                      )?_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/word.svg')}}):(result.fileName && _vm.getFileType(result.fileName) === '.PNG')?_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/png.svg')}}):(
                        result.fileName &&
                        (_vm.getFileType(result.fileName) === '.JPG' || _vm.getFileType(result.fileName) === '.JPEG')
                      )?_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/jpg.svg')}}):_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/images/file.svg')}})]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.highlighted(result.fileName))},on:{"click":function($event){return _vm.goToPage(result)}}}),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(result.content ? '...' + result.content : '')}})])])])}),0)])],1)])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }