<!-- 我的 -->
<template>
  <div class="user-box list-bg">
    <van-nav-bar title="联系方式" left-arrow @click-left="goBack"> </van-nav-bar>
    <van-list>
      <van-list>
        <van-collapse v-model="activeNames" class="collapse" disabled>
          <van-collapse-item name="index" v-for="item in menuList" :key="item.title" :is-link="false" disabled>
            <template #title>
              <van-row type="flex" align="center" class="card">
                <van-row class="name">客户支持中心</van-row>
              </van-row>
            </template>
            <div class="collapse-content">
              <van-row span="24" class="card-line">
                <van-col class="label">电话</van-col>
                <van-col class="value">400-820-8820 </van-col>
              </van-row>
              <van-row class="card-line">
                <van-col class="label">邮箱</van-col>
                <van-col class="value">shanghai@roche.com </van-col>
              </van-row>
            </div>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-list>
  </div>
</template>

<script>
export default {
  name: 'user',
  data() {
    return {
      activeNames: ['index'],
      menuList: [
        {
          title: '绩效',
          icon: 'menu-jixiao',
          children: [
            {
              title: 'KPI政策',
              url: '/dataCenter/kpi/policy'
            },
            {
              title: 'KPI绩效考核结果',
              url: '/dataCenter/kpi/kpiScore'
            },
            // {
            //   title: 'KPI绩效考核结果-详情',
            //   url: '/dataCenter/kpi/kpiScore/detail/123'
            // },
            {
              title: 'SAP Scorecard',
              url: '/dataCenter/performance/sap'
            },
            {
              title: 'RCR Scorecard',
              url: '/dataCenter/performance/rcr'
            }
          ]
        },
        {
          title: 'CIB',
          icon: 'menu-cib',
          children: [
            {
              title: 'CIB政策',
              url: '/dataCenter/cib/policy'
            },
            {
              title: 'CIB Framework',
              url: '/dataCenter/cib/framework'
            },
            {
              title: 'CIB预警',
              url: '/dataCenter/cib/warning'
            }
          ]
        },
        {
          title: '仪器管理',
          icon: 'menu-yiqi',
          children: [
            {
              title: '仪器政策',
              url: '/dataCenter/instrument/policy'
            },
            {
              title: '仪器清单',
              url: '/dataCenter/instrument/list'
            }
          ]
        },
        {
          title: '渠道数据管理',
          icon: 'menu-qudao',
          children: [
            {
              title: '渠道数据反馈',
              url: '/dataCenter/channelData/feedback'
            }
          ]
        },
        {
          title: '产品资质',
          icon: 'menu-tongzhi',
          children: [
            {
              title: '产品注册证',
              url: '/dataCenter/productCertificate'
            }
          ]
        }
      ]
    }
  },
  created() {},
  methods: {
    handleChange() {
      this.activeNames = ['index']
    },
    goBack() {
      // this.$router.push({
      //   path: '/notice'
      // })
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.list-bg {
  min-height: 100vh;
  padding: 0 0.4rem 50px;
  background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
}
.collapse {
  .van-collapse-item {
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe) !important;
    /deep/.van-collapse-item__content {
      border-radius: 0 0 8px 8px;
    }
    .card-line {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 16px;
      display: flex;
      .label {
        color: #8c8c8c;
        width: 50px;
        min-width: 50px;
      }
      .text {
        color: #262626;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    /deep/.van-cell--clickable:active {
      background-color: transparent;
    }
  }
}

.name {
  font-size: 14px;
  font-weight: 500;
  color: #262626 !important;
}
.card-text {
  margin-left: auto;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
}

.menu-icon {
  color: #0b41cd;
  margin-right: 8px;
}
</style>
