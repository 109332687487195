import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

const RefreshTokenKey = 'Refresh-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  //退出登录清除掉状态
  Cookies.remove('isNeedChangeDealer')
  Cookies.remove('selectedDealer')
  return Cookies.remove(TokenKey)
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshTokenKey, token)
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey)
}

export function goSSO() {
  window.location = process.env['VUE_APP_SSO_PATH']
}

//如果是区域经理，可以选择经销商，来查看数据
export function setIsNeedChangeDealer(state) {
  Cookies.set('isNeedChangeDealer', state)
}

export function getIsNeedChangeDealer() {
  if (Cookies.get('isNeedChangeDealer')) {
    return JSON.parse(Cookies.get('isNeedChangeDealer'))
  } else {
    return false
  }
}

export function setSelectedDealer(dealer) {
  Cookies.set('selectedDealer', dealer)
}

export function getSelectedDealer() {
  return Cookies.get('selectedDealer')
}

export function parsingJWT(jwt) {
  const [, payloadBase64] = jwt.split('.')
  const payload = JSON.parse(atob(payloadBase64))
  console.log(payload)
  return payload
}
