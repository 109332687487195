import request from '@/utils/request'

// 查询列表
export function getKPIAssessmentResultByDealer(params) {
  return request({
    url: '/bff-web/kpi-assessmentResult/getListByDealer',
    method: 'get',
    params
  })
  //?year&baValue&kpiAssessmentName&periodValue&pageNum=1
}

export function getKPIAssessmentResultDetailByDealer(kpiAssessmentId) {
  return request({
    url: '/bff-web/kpi-assessmentResult/getDetailByDealer',
    method: 'get',
    params: { kpiAssessmentId }
  })
}

export function downloadKPIAssessmentResultByDealer(kpiAssessmentId) {
  return request({
    url: '/bff-web/kpi-assessmentResult/downloadDetailByDealer',
    method: 'get',
    responseType: 'blob',
    params: { kpiAssessmentId }
  })
}
