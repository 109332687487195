var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form-table',{ref:"baseForm",attrs:{"queryOptions":_vm.searchFormData,"tableOptions":_vm.tableOptions,"headerTitle":_vm.headerTitle,"queryUrl":_vm.queryUrl,"btnData":_vm.btnData,"initSearchParams":_vm.initSearchParams,"query-permission":['system:notice:query'],"is-need-operate":true,"more-column":true,"more-search":true,"isCardDisabled":false,"isCheckbox":false,"hideBtnWithEmptyData":true,"refreshMethod":true},on:{"clickBtn":_vm.onSelect,"submitBtn":_vm.handleSubmit,"on-search":_vm.handleSearchData,"on-refresh":_vm.refreshData},scopedSlots:_vm._u([{key:"table-btn",fn:function(data){return [_c('span',{staticStyle:{"margin-top":"10px"},on:{"click":function($event){return _vm.setMoreColumn(data.row)}}},[_vm._v(_vm._s(data.row.isShowMoreColumn ? '收起' : '展示')+"更多字段")])]}},{key:"column-item",fn:function(scope){return [_c('div',[_c('van-row',{staticClass:"item"},[_c('van-col',{attrs:{"span":20}},[_c('span',{staticClass:"name"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(scope.row['data_label']))]),_c('br'),_vm._v(" "+_vm._s(scope.row['roche_product_name']))])]),_c('van-col',{staticClass:"year",attrs:{"span":6}},[_c('div',[_vm._v(_vm._s(scope.row['year'])),_c('span',{staticClass:"line"},[_vm._v("-")]),_vm._v(_vm._s(scope.row['month'].padStart(2, '0')))])])],1)],1)]}},{key:"column-item-form",fn:function(scope){return [(
          scope.item.prop === 'dealer_reason_feedback' &&
          _vm.checkSubmitFlag &&
          _vm.orgId != 1002 &&
          _vm.orgId != 1003 &&
          !_vm.isDealerExpire
        )?[_c('van-field',{staticStyle:{"font-size":"12px"},attrs:{"readonly":"","clickable":"","name":scope.item.prop,"value":scope.row.dealer_reason_feedback,"label":scope.item.label,"placeholder":"点击选择","input-align":"right","label-class":"titleClass"},on:{"click":function($event){return _vm.openPicker(scope.row, scope.item, scope.index)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(scope.item.label))]),_c('span',{staticStyle:{"color":"#0b41cd"}},[_vm._v("*")])]},proxy:true}],null,true)}),_c('van-popup',{attrs:{"position":"bottom","get-container":"body"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","value-key":"dictLabel","columns":_vm.options},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showPicker = false}}})],1)]:(
          scope.item.prop === 'dealer_reason_description' &&
          _vm.checkSubmitFlag &&
          _vm.orgId != 1002 &&
          _vm.orgId != 1003 &&
          !_vm.isDealerExpire
        )?[_c('van-field',{staticStyle:{"font-size":"12px"},attrs:{"label":scope.item.label,"placeholder":"请输入","maxlength":200,"input-align":"right","label-class":"titleClass","clearable":""},model:{value:(scope.row[scope.item.prop]),callback:function ($$v) {_vm.$set(scope.row, scope.item.prop, $$v)},expression:"scope.row[scope.item.prop]"}})]:[_c('van-cell',{staticStyle:{"font-size":"12px"},attrs:{"title":scope.item.label,"value":scope.row[scope.item.prop]},scopedSlots:_vm._u([(scope.item.prop === 'dealer_reason_feedback')?{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(scope.item.label))]),_c('span',{staticStyle:{"color":"#0b41cd"}},[_vm._v("*")])]},proxy:true}:null],null,true)})]]}}])},[_c('template',{slot:"custom-title"},[(_vm.contactUser)?_c('div',{staticClass:"contact"},[_c('van-image',{staticStyle:{"margin-right":"5px"},attrs:{"width":"15","height":"15","src":require('@/assets/images/dealer-tree-people.svg')}}),_c('span',[_vm._v("罗氏区域联系人")]),(_vm.contactUser.indexOf('/') > -1)?_c('span',{staticStyle:{"font-weight":"bold","padding-left":"10px","width":"60%"}},_vm._l((_vm.contactUser.split('/')),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0):_c('span',{staticStyle:{"font-weight":"bold","padding-left":"10px","width":"60%"}},[_vm._v(" "+_vm._s(_vm.contactUser)+" ")])],1):_vm._e()])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }