<template>
  <div class="main-container">
    <van-image class="image" fit="widthFix" :src="require('@/assets/images/banner.png')" />
  </div>
</template>

<script>
export default {
  name: 'Banner'
}
</script>

<style scoped lang="less">
.main-container {
  display: flex;
  .image {
    width: 100%;
    /deep/ img {
      border-radius: 8px;
    }
  }
}
</style>
