<template>
  <div class="performance-appraisal-detail">
    <van-nav-bar
      :title="title"
      left-arrow
      class="performance-appraisal-nav"
      fixed
      placeholder
      safe-area-inset-top
      @click-left="$router.back()"
    >
      <template #right>
        <span class="right-btn-link" @click.prevent="onDownload">下载</span>
      </template>
    </van-nav-bar>
    <performance-appraisal-tabs
      v-model="active"
      sticky
      class="detail-tabs"
      v-if="baTabs.length > 1"
      :tabs="baTabs"
      field-label="sheetName"
      filed-key="sheetName"
    >
    </performance-appraisal-tabs>
    <van-row>
      <van-col span="24">
        <div style="font-size: 12px; color: #0b41cd; font-weight: bold; padding: 5px 20px" v-if="active === '运营'">
          备注：各项考核权重根据每家经销商的适用项做均分，运营KPI满分为100分
        </div>
      </van-col>
    </van-row>
    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="initDetailData">
        <van-collapse v-model="activeNames" class="collapse">
          <van-collapse-item v-for="(card, cardName) in formatTableData" :name="cardName" :key="active + cardName">
            <template #title>
              <div class="card-title">
                <b>{{ cardName }} {{ calcTotalScore(card) !== -1 ? `（${calcTotalScore(card)}分）` : '' }}</b>
              </div>
            </template>
            <div class="collapse-content">
              <van-row class="content-item" v-for="(item, idx) in card" :key="active + cardName + idx">
                <van-col span="24" class="assessment-item">
                  <div class="sub-title">
                    <span class="label">考核项目</span>
                    <span class="content">
                      <span class="text"
                        ><span
                          >{{ item.assessmentItem
                          }}{{ item.score > 0 || item.score === 0 ? `（${item.score}分）` : '' }}</span
                        ></span
                      >
                      <van-popover trigger="click" v-model="item.show" placement="bottom-end">
                        <div style="font-size: 12px; max-width: 80vw; padding: 12px; white-space: pre-line">
                          {{ item.assessmentStandard }}
                        </div>
                        <template #reference>
                          <van-icon name="info-o" color="#0b41cd" />
                        </template>
                      </van-popover>
                    </span>
                  </div>
                </van-col>
                <van-col span="24">
                  <van-cell :title="'KPI得分'" :value="item.kpiScore" class="high-light" />
                </van-col>
                <van-col span="24">
                  <van-cell :title="`YTD ${period} 实际完成`" :value="item.actualValue" />
                </van-col>
                <van-col span="24">
                  <van-cell :title="`YTD ${period} 调整目标`" :value="item.adjustTargetValue" />
                </van-col>
                <van-col span="24">
                  <van-cell :title="`YTD ${period} 原始目标`" :value="item.targetValue" />
                </van-col>
              </van-row>
            </div>
          </van-collapse-item>
        </van-collapse>
      </van-pull-refresh>
    </div>
    <div class="total-score">
      <span class="score">{{ totalScore }}</span> 得分
    </div>
  </div>
</template>
<script>
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import round from 'lodash/round'
import { getKPIAssessmentResultDetailByDealer } from '@/api/dataCenter/kpi/performacseAppraisal'
import PerformanceAppraisalTabs from '../tabs.vue'
export default {
  components: { PerformanceAppraisalTabs },
  data() {
    return {
      activeNamesMap: {},
      refreshing: false,
      loading: false,
      finished: false,
      active: '',
      baTabs: [],
      tableData: []
    }
  },
  computed: {
    detailID() {
      return get(this.$route, 'params.id', '')
    },
    title() {
      return decodeURIComponent(get(this.$route, 'query.title', ''))
    },
    period() {
      return decodeURIComponent(get(this.$route, 'query.period', ''))
    },
    totalScore() {
      return get(
        this.baTabs.find(tabItem => tabItem.sheetName === this.active),
        'totalScore',
        ''
      )
    },
    formatTableData() {
      const tableData = get(
        this.baTabs.find(tabItem => tabItem.sheetName === this.active),
        'list',
        []
      )
      return groupBy(tableData, 'assessmentCategory')
    },
    activeNames: {
      get() {
        return get(this.activeNamesMap, this.active, [])
      },
      set(v) {
        this.$set(this.activeNamesMap, this.active, v)
      }
    }
  },
  created() {
    this.initDetailData()
  },
  methods: {
    initDetailData() {
      return this.queryDetail(this.detailID).then(data => {
        this.activeNamesMap = {}
        this.baTabs = data.map(item => {
          this.$set(this.activeNamesMap, item.sheetName, [])
          return {
            ...item,
            sheetName: item.sheetName,
            list: map(item.list, listItem => ({
              ...listItem,
              kpiScore: get(listItem, 'kpiScore', '') + '' || '-',
              actualValue: get(listItem, 'actualValue', '') + '' || '-',
              targetValue: get(listItem, 'targetValue', '') + '' || '-',
              adjustTargetValue: get(listItem, 'adjustTargetValue', '') + '' || '-'
            }))
          }
        })
        this.active = this.baTabs.length ? this.baTabs[0].sheetName : ''
        this.refreshing = false
      })
    },
    queryDetail(params) {
      return getKPIAssessmentResultDetailByDealer(params).then(res => {
        if (res && res.code === 200) {
          return res.data || []
        }
        return []
      })
    },
    onDownload() {
      this.download('/bff-web/kpi-assessmentResult/downloadDetailByDealer', {}, `${this.title}.pdf`, {
        method: 'get',
        params: {
          kpiAssessmentId: this.detailID,
          productLineName: this.active
        }
      })
    },
    calcTotalScore(card) {
      const list = card.filter(item => item.score > 0 || item.score === 0)
      if (list.length > 0) {
        return card.reduce((total, item) => {
          return total + item.score
        }, 0)
      }
      return -1
    }
  }
}
</script>
<style scoped lang="less">
.performance-appraisal-detail {
  background: linear-gradient(296.57deg, #d9ebfb 0%, #d9ebfb 5.27%, #f2f2f7 35.59%, #fef9f7 65.39%, #fffefe 83.33%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(100vh);
  .detail-tabs {
    ::v-deep .van-tab {
      flex: unset;
    }
  }
  .performance-appraisal-nav {
    ::v-deep .van-nav-bar {
      background-color: #fff;
      .van-nav-bar__content {
        .van-nav-bar__left {
          .van-icon {
            color: rgba(38, 38, 38, 1);
          }
        }
      }
    }
  }
  .right-btn-link {
    color: rgba(11, 65, 205, 1);
    border-right: 1px solid rgba(206, 217, 245, 1);
    margin: 10px 0;
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
  .content {
    // flex: 1;
    //overflow-y: auto;
    padding: 0 16px;
  }
  .total-score {
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    color: rgba(9, 52, 164, 1);
    justify-content: flex-end;
    padding-bottom: 20px;
    padding-right: 16px;
    .score {
      font-size: 32px;
      font-weight: 600;
    }
  }
  .collapse {
    padding-top: 12px;
    .van-collapse-item {
      align-items: center;
      margin-bottom: 12px;
      border-radius: 12px;
      box-shadow: 0 8px 12px #ebedf0;
      overflow: hidden;
      ::v-deep .van-cell {
        align-items: center;
      }
      ::v-deep .van-collapse-item__title {
        background: linear-gradient(
          296.57deg,
          #d9ebfb 0%,
          #d9ebfb 5.27%,
          #f2f2f7 35.59%,
          #fef9f7 65.39%,
          #fffefe 83.33%
        );
      }
      .collapse-content {
        .content-item {
          padding-bottom: 12px;
          &:not(:last-child) {
            border-bottom: 1px solid rgba(235, 237, 240, 1);
            margin-bottom: 12px;
          }
          .sub-title {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            font-size: 14px;
            margin-bottom: 10px;
            color: rgba(20, 20, 20, 1);
            .label {
              padding-right: 10px;
            }
            .content {
              flex: 1;
              display: flex;
              > .text {
                flex: 1;
                color: rgba(9, 52, 164, 1);
                display: flex;
                justify-content: flex-end;
              }
            }
          }
          .assessment-item {
            .van-icon.van-icon-info-o {
              margin-left: 16px;
            }
          }
        }
        ::v-deep .van-cell {
          align-items: normal;
          font-size: 12px;
          line-height: 14px;
          padding: 10px 0;
          &.high-light {
            .van-cell__value {
              font-size: 16px;
              color: rgba(9, 52, 164, 1);
            }
          }
        }
      }
    }
    .card-title {
      font-size: 16px;
      b {
        font-weight: 600;
        margin-right: 10px;
      }
    }
  }
}
</style>
