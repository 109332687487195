<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
    >
    </form-table>
  </div>
</template>

<script>
import { dataCenter } from '@/utils/dataCenter'
import { getDicts } from '@/api/dict/data'
export default {
  name: 'DemoList',
  data() {
    return {
      currentDate: new Date(2021, 0, 17),
      //单个
      btnData: [{ text: '下载' }],
      //多个
      //   btnData: [{ text: '下载' }, { text: '新增' }],
      searchFormData: [
        { props: 'fileName', label: '文件名', type: 'input' },
        {
          props: 'year',
          label: '年份',
          width: '120px',
          type: 'date',
          valueFormat: 'yyyy',
          dateFormat: 'yyyy',
          dateType: 'year'
        },
        {
          props: 'productLineCode',
          label: '产品线',
          type: 'DictSelect',
          dictCode: 'sys_product_line',
          multiple: false
        }
      ],
      tableOptions: [
        {
          prop: 'fileName',
          label: '仪器管理政策文件',
          type: 'fileName',
          rightTop: {
            left: {
              prop: 'year',
              label: '年份'
            }
          },
          rightBottom: { prop: 'productLineLabel', label: '产品线', dictCode: 'sys_product_line', options: [] }
        },
        // {
        //   prop: 'year',
        //   label: '年份'
        // },
        // {
        //   prop: 'productLineLabel',
        //   label: '产品线'
        // },
        {
          prop: 'createTime',
          label: '上传时间'
        }
      ],
      headerTitle: '仪器管理文件',
      queryUrl: '/bff-dp-mobile/instrument-policy/dealer-list',
      deleteUrl: '/instrument-policy/delete'
    }
  },
  created() {
    getDicts('sys_product_line').then(({ data }) => {
      this.searchFormData[2].options = data
      this.tableOptions[0].rightBottom.options = data
    })
  },
  methods: {
    handleDelete(obj) {
      this.$dialog
        .confirm({
          title: '删除',
          message: '是否确认删除该文件？'
        })
        .then(() => {
          return dataCenter.delete(this.deleteUrl, obj)
        })
        .then(() => {
          this.$refs.baseForm.onRefresh()
          this.$toast.success('删除成功')
        })
        .catch(() => {
          // on cancel
        })
    },
    onSelect(text, queryParams, list) {
      console.log(text, queryParams)
      this.daowload(list)
    },
    daowload(list) {
      dataCenter.downloadFile(list)
    }
  }
}
</script>

<style></style>
