<template>
  <div>
    <van-popover
      class="popover"
      placement="bottom-end"
      v-model="showPopover"
      trigger="click"
      :actions="actions"
      @select="onSelect"
    >
      <template #reference>
        <van-icon name="ellipsis" />
      </template>
    </van-popover>
    <form-table
      ref="baseForm"
      :hasHeader="false"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :query-permission="['system:notice:query']"
      :isCheckbox="false"
      :hasIcon="false"
      :moreSearch="false"
      :more-column="true"
      :isNeedOperate="false"
      :isCardDisabled="false"
    >
      <template #column-item="scope">
        <div class="item">
          <div class="name">
            <span> {{ scope.row.processDefinitionName }}({{ scope.row.processInstanceId }})</span>
          </div>
          <div class="state" v-html="statusHtml(scope.row.result)"></div>
        </div>
      </template>
      <template slot="table-btn" slot-scope="data">
        <span type="text" @click="checkDetail(data.row)">查看详情</span>
      </template>
    </form-table>
    <Details ref="detailComp"></Details>
  </div>
</template>

<script>
import Details from '@/views/task/details.vue'
import { tableOptions, queryUrl, searchFormData, headerTitle } from './overtimeConfig'
import { getDicts } from '@/api/dict/data'
export default {
  name: 'DemoList',
  components: { Details },
  data() {
    return {
      searchFormData: searchFormData,
      tableOptions: tableOptions,
      headerTitle: headerTitle,
      queryUrl: queryUrl,
      process_result_status: {},
      canNotStopTask: ['CIB_CONF_CHECK', 'CHANNEL_LINK_COLLECT', 'CHANNEL_DATA_COLLECT'],
      showPopover: false,
      actions: [{ text: '导出' }]
    }
  },
  created() {
    getDicts('task_result_status').then(res => {
      if (res.code === 200 && res.data.length > 0) {
        let statusMap = {}
        res.data.map(item => {
          statusMap[item.dictValue] = { text: item.dictLabel, color: item.remark }
        })
        this.process_result_status = statusMap
      }
    })
  },
  methods: {
    statusHtml(status) {
      return '超时未完成'
    },
    checkDetail(detailsInfo) {
      this.$refs.detailComp.init(detailsInfo)
    },
    onRefresh() {
      this.$refs.baseForm.onRefresh()
    },
    onSelect(data) {
      const requestParams = this.$refs.baseForm.queryParams
      if (data.text === '导出') {
        this.download(
          '/bff-dp-mobile/task-flow/dealer-timeout-export',
          {},
          `超时未完成任务_${new Date().getTime()}.xlsx`,
          {
            method: 'get',
            params: requestParams
          }
        )
      }
    }
  }
}
</script>

<style lang="less" scoped>
.popover {
  color: #0b41cd;
  font-size: 16px;
  position: absolute;
  height: 44px;
  right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
</style>
