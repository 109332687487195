<template>
  <div class="change-dealer" @click.stop="click" v-if="isNeedChangeDealer">
    <van-button icon="exchange" round type="info" class="change-btn" size="small" @click="showPicker = true" />
    <van-popup :close-on-click-overlay="false" v-model="showPicker" position="bottom">
      <van-picker
        title="请选择经销商"
        show-toolbar
        :default-index="selectIndex"
        :columns="dealerList"
        @confirm="onConfirm"
        @cancel="onCancel"
      >
        <template #columns-top>
          <div>
            <van-search v-model="searchValue" placeholder="请输入经销商名称" />
            <ul>
              <li v-for="option in dealerList" :key="option.value">
                {{ option.text }}
              </li>
            </ul>
            <div class="search-empty" v-if="dealerList.length === 0">无匹配数据</div>
          </div>
        </template>
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import { listDealerAllForCMCL, getTokenByOrgIdForCMCL } from '@/api/userCenter/dealer'
import { setSelectedDealer, getSelectedDealer, setToken } from '@/utils/auth'
import { cloneDeep } from 'lodash'
export default {
  name: 'ChangeDealer',
  data() {
    return {
      search: '',
      dealerList: [],
      dealerInfoList: [],
      dealerListOrigin: [],
      fuse: undefined,
      open: false,
      dealerClone: getSelectedDealer(),
      loading: false,
      form: {
        dealer: ''
      },
      rules: {
        dealer: [{ required: true, message: '经销商不能为空', trigger: 'change' }]
      },
      showPicker: false,
      selectIndex: 0,
      searchValue: ''
    }
  },
  computed: {
    isNeedChangeDealer() {
      if (this.$store.state.user.isNeedChangeDealer) {
        this.init()
      }
      return this.$store.state.user.isNeedChangeDealer
    },
    dealer: {
      get() {
        return this.dealerClone
      },
      set(value) {
        this.dealerClone = value
        setSelectedDealer(value)
      }
    }
  },
  watch: {
    searchValue(value) {
      this.dealerList = cloneDeep(this.dealerListOrigin).filter(item => item.includes(value))
      this.selectIndex = this.dealerList.findIndex(item => item === this.dealerClone) || 0
    }
  },
  mounted() {
    //是否是区域经理
    console.log('isNeedChangeDealer', this.isNeedChangeDealer)
    if (this.isNeedChangeDealer) {
      this.init()
    }
  },
  methods: {
    init() {
      this.getDealerList()
      //是否选过经销商
      if (!this.dealerClone) {
        this.showPicker = true
      } else {
        this.form.dealer = this.dealerClone
      }
    },
    // onConfirm(value, index) {
    //   this.$toast(`当前值：${value}, 当前索引：${index}`)
    // },
    onConfirm(picker, value, index) {
      if (!picker) {
        this.$toast('请选择经销商')
        return
      }
      this.dealer = picker
      const dealerId = this.dealerInfoList.filter(item => item.orgName == picker)[0]?.orgId
      this.getToken(dealerId)
      this.showPicker = false
      this.selectIndex = index
    },
    onCancel() {
      if (!this.dealerClone) {
        this.$toast('请选择经销商')
        return
      }
      this.showPicker = false
    },
    getDealerList() {
      this.loading = true
      listDealerAllForCMCL().then(response => {
        this.loading = false
        this.dealerInfoList = response.data
        this.dealerList = response.data.map(item => item.orgName)
        this.dealerListOrigin = cloneDeep(this.dealerList)
        this.selectIndex = response.data.findIndex(item => item.orgName === this.dealerClone) || 0
      })
    },
    click() {
      this.open = true
      // this.show = !this.show
    },
    getToken(dealer) {
      getTokenByOrgIdForCMCL(dealer).then(response => {
        setToken(response.data.access_token)
        this.$store.commit('SET_TOKEN', response.data.access_token)
        location.href = '/'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.change-dealer {
  font-size: 14px;
  transition: all 0.3s;
  position: fixed;
  right: 0;
  top: 30vh;
  z-index: 999;
}
.text-hide1 {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon {
  display: block;
  transform: rotate(90deg);
  margin: 5px;
}
::v-deep {
  .no-pointer .el-dialog__wrapper {
    cursor: default;
  }
  .el-dialog:not(.is-fullscreen) {
    margin-top: 30vh !important;
  }
  .el-dialog__body {
    padding: 0 30px;
  }
}

.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  z-index: 1;
  overflow: hidden;
  .title {
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
    border-radius: 0 0 100px 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
  }
}
.line {
  padding: 16px 13px 23px;
  background: #fff;
  .item {
    display: flex;
    height: 56px;
    align-items: center;
    position: relative;
    .image {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      margin-left: 6px;
    }
    .desc {
      font-weight: 400;
      font-size: 14px;
      color: #8c8c8c;
    }
    .number {
      font-weight: 400;
      font-size: 32px;
      color: #262626;
      position: absolute;
      right: 40px;
    }
  }
}
.change-btn {
  width: 30px;
  height: 30px;
}
::v-deep .van-picker-column__item--selected {
  font-weight: bold;
}
.search-empty {
  width: 100%;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999999;
  font-size: 16px;
  height: 0;
  transform: translateY(100px);
}
</style>
