import { render, staticRenderFns } from "./managerInfo.vue?vue&type=template&id=4b920840&scoped=true&"
import script from "./managerInfo.vue?vue&type=script&lang=js&"
export * from "./managerInfo.vue?vue&type=script&lang=js&"
import style0 from "./managerInfo.vue?vue&type=style&index=0&id=4b920840&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b920840",
  null
  
)

export default component.exports