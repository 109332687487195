<template>
  <div class="file-card" :class="$attrs.class" @click.stop="$emit('on-click')">
    <div class="file-card-title">
      <div class="file-card-name">
        <van-image class="file-card-img" :src="require('@/assets/images/pdf.svg')" />
        <div class="name-content">{{ name }}</div>
      </div>
      <div class="year">{{ desc }}</div>
    </div>
    <van-row class="content" gutter="12">
      <van-col span="24" v-for="item in fields" :key="item.value">
        <van-cell :title="item.text" :value="data[item.value]" />
      </van-col>
    </van-row>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => {
        return [{ text: '上传时间', value: 'updateTime' }]
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showPopover: false
    }
  },
  computed: {
    displayName() {
      if (this.format) {
        return this.format(this.name)
      }
      return this.name
    },
    actionsFormat() {
      return this.actions.map(item => {
        return {
          ...item,
          className: item.className || 'action-item'
        }
      })
    }
  },
  methods: {
    onSelect({ value }) {
      console.log(value)
      this.$emit('on-operate', value)
    }
  }
}
</script>
<style scoped lang="less">
.file-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(11, 65, 205, 0.15);
  border: 0.5px solid rgba(217, 217, 217, 1);
  overflow: hidden;
  cursor: pointer;
  .file-card-title {
    background: linear-gradient(296.57deg, #d9ebfb 0%, #d9ebfb 5.27%, #f2f2f7 35.59%, #fef9f7 65.39%, #fffefe 83.33%);
    display: flex;
    padding: 12px 17px;
    .file-card-name {
      flex: 1;
      display: flex;
      align-items: center;
      .file-card-img {
        width: 20px;
        height: 24px;
        margin-right: 8px;
      }
      .name-content {
        font-size: 12px;
        color: rgba(11, 65, 205, 1);
      }
    }
    .year {
      font-size: 12px;
      color: rgba(140, 140, 140, 1);
    }
  }
  .content {
    padding: 0 12px;
    .van-col {
      .van-cell {
        color: rgba(140, 140, 140, 1);
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;
        .van-cell__value {
          color: rgba(38, 38, 38, 1);
        }
      }
    }
  }
}
</style>
