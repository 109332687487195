<template>
  <div class="search-tips-list-box">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-cell v-for="item in dataList" :key="item" :title="item" @click="geiValue(item)" />
    </van-list>
  </div>
</template>

<script>
export default {
  name: 'search-tips-list',
  data() {
    return {
      list: [],
      loading: false, // //是否处于加载状态
      finished: false // 是否加载完成
    }
  },
  props: ['dataList'], //// 父传子数组
  methods: {
    onLoad() {},
    geiValue(value) {
      this.$emit('setValue', value)
    }
  }
}
</script>

<style lang="less" scoped></style>
