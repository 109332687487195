import request, { preview } from '@/utils/request'

export function getListApi(url, query) {
  return request({
    url,
    method: 'get',
    params: query
  })
}

export function getListApiCommon(url, data) {
  return request({
    url,
    method: 'post',
    data: data
  })
}

//预览文件
export function previewFile(query, fileName) {
  preview('/file/preview-file', {}, fileName, {
    method: 'get',
    params: {
      ...query
    }
  })
}
