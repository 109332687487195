import request from '@/utils/request'

// vad仪器根据产品线获取经销商
export function getVadDealer(query) {
  return request({
    url: '/bff-dp-mobile/vad-dealer/list',
    method: 'get',
    params: query
  })
}

//查询变更日志
export function getChangeLogVad(query) {
  return request({
    url: '/bff-dp-mobile/instrument-vad/data/log',
    method: 'get',
    params: query
  })
}

// vad标签上传列表
export function getVadInstrument(query) {
  return request({
    url: '/bff-dp-mobile/instrument-vad/data/list',
    method: 'get',
    params: query
  })
}

// vad工程师上传列表
export function getVadEngineer(query) {
  return request({
    url: '/bff-dp-mobile/instrument-vad/engineer/list',
    method: 'get',
    params: query
  })
}

//上传vad仪器标签
export function uploadVadInstrument(data, noticeTime) {
  return request({
    url: '/bff-dp-mobile/instrument-vad/data/import?noticeTime=' + noticeTime,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

//上传vad工程师
export function uploadVadEngineer(data) {
  return request({
    url: '/bff-dp-mobile/instrument-vad/engineer/import',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}
