<template>
  <div>
    <KpiPopUp ref="kpiPopUp" />
    <CibPopUp ref="cibPopUp" />
    <CompanyPopUp ref="CompanyPopUp" />
    <ManagerPopUp ref="ManagerPopUp" />
  </div>
</template>

<script>
import KpiPopUp from './popUp/kpi.vue'
import CibPopUp from './popUp/cib.vue'
import CompanyPopUp from './popUp/companyInfo.vue'
import ManagerPopUp from './popUp/managerInfo.vue'

import { getInstrumentAppealDetail } from '@/api/dataCenter/instrument'

export default {
  name: 'Details',
  dicts: [],
  components: { KpiPopUp, CibPopUp, CompanyPopUp, ManagerPopUp },
  data() {
    return {
      detailsInfo: {},
      initData: {}
    }
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    init(detailsInfo) {
      switch (detailsInfo.category) {
        //KPI绩效结果申述--弹窗
        case 'KPI_RESULT_COMPLAINT':
          this.$refs.kpiPopUp.init(detailsInfo)
          console.log('KPI_RESULT_COMPLAINT')
          break
        //负责人变更--跳转
        case 'CONTACT_CHANGE':
          this.$refs.ManagerPopUp.init(detailsInfo)
          break
        //公司信息变更--弹窗
        case 'COMPANY_CHANGE':
          this.$refs.CompanyPopUp.init(detailsInfo)
          break
        //CIB确认函核对--跳转
        case 'CIB_CONF_CHECK':
          this.$router.push({
            path: '/dataCenter/cib/confirmation/index?title=激励'
          })
          break
        //CIB确认函申述--弹窗
        case 'CIB_CONF_COMPLAINT':
          this.$refs.cibPopUp.init(detailsInfo)
          break
        //仪器清单申述--弹窗
        case 'INSTRUMENT_COMPLAINT':
          let params = {
            processId: detailsInfo.processInstanceId,
            taskId: detailsInfo.taskId
          }
          getInstrumentAppealDetail(params)
            .then(res => {
              if (res.data) {
                this.initData = { ...res.data }
                this.$router.push({
                  path: '/dataCenter/instrument/appealDetail',
                  query: { initData: this.initData }
                })
              } else {
                this.$toast.fail('暂未查到反馈详情数据！')
                return false
              }
            })
            .catch(err => {
              console.log(err)
            })
          break
        //全渠道链收集--跳转
        case 'CHANNEL_LINK_COLLECT':
          this.$router.push({
            path: '/dataCenter/channel-data/chain'
          })
          break
        //渠道数据反馈--跳转
        case 'CHANNEL_DATA_COLLECT':
          this.$router.push({
            path: '/dataCenter/channel-data/feedback'
          })
          break
        //自定义任务--跳转
        case 'DEFINE_TASK_COLLECT':
          if (!detailsInfo.endTime) {
            this.$toast.fail('待办的自定义任务请去PC端处理！')
            return false
          }
          this.$router.push({
            path: '/task/taskDetail',
            query: {
              processInstanceId: detailsInfo.processInstanceId,
              taskId: detailsInfo.taskId,
              from: detailsInfo.endTime ? 'completed' : 'pending'
            }
          })
          break
        default:
          break
      }
    }
  }
}
</script>
<style scoped lang="less">
.sub-title {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: 14px;
  margin-bottom: 10px;
  color: rgba(20, 20, 20, 1);
  .label {
    padding-right: 10px;
    &.gray {
      color: #8c8c8c;
      font-size: 12px;
    }
  }
  .content {
    flex: 1;
    display: flex;
    padding-right: 0;
    align-items: flex-start;
    text-align: right;
    > .text {
      flex: 1;
      color: rgba(9, 52, 164, 1);
      display: flex;
      justify-content: flex-end;
    }
  }
}
.detail-dialog {
  padding: 20px 10px 0;
  .van-row {
    width: 80%;
    margin: 0 auto 10px;
    border-bottom: 1px solid rgba(235, 237, 240, 1);
    &:last-child {
      margin-bottom: 0;
    }
    .van-cell {
      padding: 0 10px;
    }
  }
}
</style>
