<template>
  <van-row class="main-container">
    <van-row class="header" :class="theme.headerClass">
      <van-row class="title">
        <van-col>重点机型</van-col>
        <van-col class="date">{{ instrumentDate }}</van-col>
      </van-row>
      <van-row class="action">
        <van-col
          class="item"
          v-for="(item, index) in instrumentInfo"
          :key="index"
          :class="currentProduct.productLine === item.productLine ? 'active' : ''"
          @click="changeShowType(item)"
          >{{ item.productLine | dictLabelByValue(productLineOptions) }}</van-col
        >
      </van-row>
    </van-row>
    <van-row style="min-height: 120px; margin-top: 10px">
      <!--      <van-collapse v-model="activeNames" class="collapse">-->
      <!--        <van-collapse-item v-for="(item, index) in instrumentData" :key="index" :name="item.instrumentName">-->
      <!--          <template #title>-->
      <!--            <van-row type="flex" align="center" class="card">-->
      <!--              <van-col span="24" class="card-text">-->
      <!--                <van-row class="name">{{ item.instrumentName }} </van-row>-->
      <!--              </van-col>-->
      <!--            </van-row>-->
      <!--          </template>-->
      <!--          <div class="collapse-content">-->
      <!--            <van-row class="divider">-->
      <!--              <van-col span="24"><van-cell title="实际销量" :value="item.actual" /></van-col>-->
      <!--              <van-col span="24"><van-cell title="目标销量" :value="item.target" /></van-col>-->
      <!--              &lt;!&ndash;              <van-col span="24">&ndash;&gt;-->
      <!--              &lt;!&ndash;                <van-cell title="增长率">&ndash;&gt;-->
      <!--              &lt;!&ndash;                  <div>{{ item.growthRate | formatRate }}</div>&ndash;&gt;-->
      <!--              &lt;!&ndash;                </van-cell>&ndash;&gt;-->
      <!--              &lt;!&ndash;              </van-col>&ndash;&gt;-->
      <!--              &lt;!&ndash;              <van-col span="24">&ndash;&gt;-->
      <!--              &lt;!&ndash;                <van-cell title="达成率">&ndash;&gt;-->
      <!--              &lt;!&ndash;                  <div>{{ item.achievementRate | formatRate }}</div>&ndash;&gt;-->
      <!--              &lt;!&ndash;                </van-cell>&ndash;&gt;-->
      <!--              &lt;!&ndash;              </van-col>&ndash;&gt;-->
      <!--            </van-row>-->
      <!--          </div>-->
      <!--        </van-collapse-item>-->
      <!--      </van-collapse>-->
      <van-row style="padding-left: 16px">
        <van-col span="12" class="left-title">机型</van-col>
        <van-col span="6" class="left-title">全年指标</van-col>
        <van-col span="6" class="left-title">实际台数</van-col>
      </van-row>
      <van-row v-for="(item, index) in instrumentData" :key="index" style="padding-left: 16px">
        <van-col span="12" class="content">{{ item.instrumentName | formatInstrumentName(that) }}</van-col>
        <van-col span="6" class="content">{{ item.target }}</van-col>
        <van-col span="6" class="content">{{ item.actual }}</van-col>
      </van-row>
      <van-row v-if="instrumentData.length === 0 && loadingFlag">
        <van-col span="24" class="content"><div class="nodata">暂无数据</div></van-col>
      </van-row>
    </van-row>
    <div class="bg-color" :class="theme.bgClass" />
    <van-image class="bg-img" :src="require('@/assets/images/home-cib.svg')" />
  </van-row>
</template>
<script>
import { getInstrumentInfo } from '@/api/home'
import { getDicts } from '@/api/dict/data'

const THEME_CONFIG = {
  bgClass: 'bg-yellow',
  headerClass: 'header-yellow'
}

export default {
  name: 'TabCard',
  props: {
    themeType: {
      type: String,
      default: 'yellow'
    }
  },
  data() {
    return {
      activeNames: [],
      currentProduct: {},
      theme: THEME_CONFIG,
      currentTime: new Date().getFullYear() + '年 ' + new Date().getMonth() + '月',
      instrumentInfo: [],
      instrumentDate: '',
      instrumentData: [],
      productLineOptions: [],
      specialInstrument: [],
      loadingFlag: false,
      that: this
    }
  },
  created() {
    this.getInstrumentInfo()
    this.getDicts()
  },
  filters: {
    formatRate(val) {
      return val ? (val * 100).toFixed(1) + '%' : '0%'
    },
    formatInstrumentName(val, that) {
      let flag = 0
      let str = null
      that.specialInstrument.forEach(item => {
        if (item.dictLabel === val) {
          str = item.dictValue
          flag++
        }
      })
      if (flag > 0) {
        return str
      } else {
        return val
      }
    }
  },
  methods: {
    getDicts() {
      getDicts('sys_product_line').then(res => {
        this.productLineOptions = res.data
      })
      getDicts('dashboard_instrument_name_conversion').then(res => {
        this.specialInstrument = res.data
      })
    },
    changeShowType(currentProduct) {
      this.currentProduct = currentProduct
      this.instrumentData = this.currentProduct.instrumentList
    },
    getInstrumentInfo() {
      let params = {}
      getInstrumentInfo(params)
        .then(res => {
          this.loadingFlag = true
          this.instrumentInfo = res.data ? res.data : []
          if (this.instrumentInfo.length > 0) {
            this.instrumentDate = this.instrumentInfo[0].year + '年 ' + this.instrumentInfo[0].month + '月'
            this.instrumentData = this.instrumentInfo[0].instrumentList
            this.currentProduct = this.instrumentInfo[0]
          } else {
            this.instrumentDate = ''
            this.instrumentData = []
          }
        })
        .catch(err => {
          this.loadingFlag = true
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  z-index: 1;
  overflow: hidden;
  .header {
    height: 75px;
    box-sizing: border-box;
    border-radius: 0 0 100px 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    padding: 15px 16px;
    position: relative;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        color: #595959;
        font-size: 12px;
        font-weight: 400;
        margin-left: auto;
      }
    }
    .action {
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      line-height: 1;
      max-width: 250px;
      overflow-x: auto;
      .item {
        padding: 0 12px;
        color: #595959;
        font-size: 14px;
        font-weight: 500;
        margin-right: 4px;
        border-radius: 6px 6px 0 0;
        background: #fff7f2;
        min-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
      }
      .active {
        background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff5ef;
        color: #ff7d2a;
        padding: 0 12px;
        min-height: 30px;
      }
    }
  }
  .desc {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 400;
    margin-top: 27px;
    margin-left: 16px;
  }
}
.bg-img {
  width: 75px;
  height: 75px;
  opacity: 0.2;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.bg-color {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
}
.header-yellow {
  background: linear-gradient(to right, rgba(253, 244, 212, 1), rgba(252, 244, 212, 0.55));
  border: 1px solid #ffe584;
}
.bg-yellow {
  background: linear-gradient(to top, rgba(253, 249, 236, 1) 30%, rgba(255, 246, 218, 1) 50%, rgba(255, 255, 255, 1));
}
.empty-container {
  height: 130px;
}

.left-title {
  padding: 5px 5px;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
}

.content {
  padding: 5px 5px;
  color: #262626;
  font-size: 14px;
  font-weight: 400;
}
.nodata {
  color: #8c8c8c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding-top: 5px;
}
</style>
