import request from '@/utils/request'

// 查询渠道数据表头
export function getTableHeader(type) {
  return request({
    url: '/bff-dp-mobile/dynamic/header/' + type,
    method: 'get'
  })
}

//获取经销商联系人
export function getContactUser() {
  return request({
    url: '/bff-dp-mobile/dealer-contacts/data-contacts-list',
    method: 'get'
  })
}

//提交反馈
export function submitFeedback(data) {
  return request({
    url: '/bff-dp-web/channel-data/details/submit',
    method: 'put',
    data: data
  })
}

//反馈check
export function checkBeforeSubmit() {
  return request({
    url: '/bff-dp-web/channel-data/submit-check',
    method: 'get'
  })
}
