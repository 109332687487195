import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import mode from './modules/mode'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    mode
  },
  getters,
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_COOKIE_KEY,
      paths: ['user']
    })
  ]
})

export default store
