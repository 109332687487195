<template>
  <div class="list-bg">
    <van-nav-bar title="附件信息" left-arrow right-text="下载全部" @click-left="goBack" @click-right="downloadAll" />
    <van-row style="padding: 10px 10px">
      <van-col span="24">
        <van-cell-group class="cell-group" v-for="(item, index) in list" :key="index">
          <van-cell class="cell-bg">
            <van-row>
              <van-col span="20">
                <van-col span="3" style="margin-top: 2px">
                  <van-image
                    v-if="getFileType(item.fileName) === '.PDF'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/pdf.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.XLS' || getFileType(item.fileName) === '.XLSX'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/excel.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.DOC' || getFileType(item.fileName) === '.DOCX'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/word.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.PNG'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/png.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.JPG' || getFileType(item.fileName) === '.JPEG'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/jpg.svg')"
                  />
                  <van-image v-else width="20" height="20" :src="require('@/assets/images/file.svg')" />
                </van-col>
                <van-col span="21">
                  <div style="color: #0b41cd">{{ item.fileName }}</div>
                </van-col>
              </van-col>
              <van-col span="4"><div style="color: #0b41cd" @click="download(item)">下载</div></van-col>
            </van-row>
          </van-cell>
          <van-cell>
            <van-row>
              <van-col span="4">
                <div style="color: #8c8c8c">大小</div>
              </van-col>
              <van-col span="4">
                <div style="color: #262626">{{ item.fileSize }}</div>
              </van-col>
              <van-col span="6">
                <div style="color: #8c8c8c">上传时间</div>
              </van-col>
              <van-col span="10">
                <div style="color: #262626">{{ item.uploadDate }}</div>
              </van-col>
            </van-row>
          </van-cell>
        </van-cell-group>
        <div v-if="list.length === 0" style="text-align: center; color: #8c8c8c; font-size: 14px">暂无数据</div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { readNotice } from '@/api/notice'
import { download } from '@/utils/request'

export default {
  name: 'NoticeFile',
  data() {
    return {
      noticeId: null,
      detail: {},
      list: []
    }
  },
  created() {
    this.noticeId = this.$route.query.noticeId
    this.onLoad()
  },
  methods: {
    onLoad() {
      let params = {
        noticeId: this.$route.query.noticeId
      }
      readNotice(params)
        .then(res => {
          this.detail = res.data
          this.list = res.data.noticeFileList
        })
        .catch(err => {
          console.log(err)
        })
    },
    goBack() {
      this.$router.go(-1)
    },
    downloadAll() {
      const fileList = this.list.map(item => {
        return item.fileId
      })
      const fileIds = fileList.join(',')
      download('/file/multi-download', {}, null, {
        method: 'POST',
        params: {
          watermarkFlag: true,
          fileIds
        }
      })
    },
    download(obj) {
      const fileIds = obj.fileId
      download('/file/multi-download', {}, null, {
        method: 'POST',
        params: {
          watermarkFlag: true,
          fileIds
        }
      })
    },
    getFileType(fileName) {
      if (!fileName) {
        return null
      }
      const fileType = fileName.substr(fileName.lastIndexOf('.'), fileName.length - 1).toUpperCase()
      return fileType
    }
  }
}
</script>

<style lang="less" scoped>
.list-bg {
  min-height: 100vh;
  padding-bottom: 50px;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}

.cell-bg {
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}

.cell-group {
  border-radius: 8px;
  border: 0.5px solid var(--neutrals-grey-5-divider, #d9d9d9);
  background: var(--neutrals-grey-1-white, #fff);
  box-shadow: 0px 0px 10px 0px rgba(11, 65, 205, 0.15);
  margin-bottom: 10px;
}
</style>
