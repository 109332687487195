<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      @clickBtn="onSelect"
      @dealerListBtn="goDealerList"
      @on-search="handleSearchData"
      @on-check="handleCheck"
      :query-permission="['system:notice:query']"
      :is-need-operate="true"
      :more-column="false"
      :more-search="false"
      :isCardDisabled="false"
      :isCheckbox="false"
      :hideBtnWithEmptyData="true"
      :rowStyle="true"
      :specialQuery="true"
    >
      <template slot="custom-title">
        <van-row style="margin-bottom: 10px">
          <van-col :span="6">
            <div style="font-size: 12px; color: #595959">信息核对进度</div>
          </van-col>
          <van-col :span="18">
            <van-progress
              color="#0B41CD"
              track-color="#a6b8e7"
              :percentage="feedbackPercentage"
              style="margin-top: 5px"
            />
          </van-col>
        </van-row>
      </template>
      <template #column-item="scope">
        <van-row class="item">
          <van-col :span="18">
            <span class="name"
              ><span style="font-weight: bold">{{ scope.row['end_customer_code'] }}</span
              ><br />
              {{ scope.row['end_customer_name'] }}</span
            >
          </van-col>
          <van-col :span="6" class="status">
            <span v-if="scope.row['data_confirm_status'] === '待确认'" class="red-span">待确认</span>
            <span v-if="scope.row['data_confirm_status'] === '已确认'" class="green-span">已确认</span>
            <span v-if="scope.row['data_confirm_status'] === '已失效'" class="gray-span">已失效</span>
            <span v-if="scope.row['data_confirm_status'] === '当前无授权'" class="gray-span">当前无授权</span>
            <span v-if="scope.row['data_confirm_status'] === '经销商新增'" class="gray-span">经销商新增</span>
          </van-col>
        </van-row>
      </template>
      <template #column-item-form="scope">
        <template
          v-if="
            scope.item.prop === 'distributor_level2' ||
            scope.item.prop === 'distributor_level3' ||
            scope.item.prop === 'distributor_level4' ||
            scope.item.prop === 'distributor_level5'
          "
        >
          <van-cell
            :title="scope.item.label"
            :value="scope.row[scope.item.prop]"
            :value-class="hasUnusual(scope.row[scope.item.prop]) ? 'isUnusual' : ''"
          ></van-cell>
        </template>
        <template v-else>
          <van-cell :title="scope.item.label" :value="scope.row[scope.item.prop]"></van-cell>
          <!--          <span>{{ scope.row[scope.item.prop] }}</span>-->
        </template>
      </template>
      <template slot="table-btn" slot-scope="data" v-if="orgId != 1002 && orgId != 1003 && !isDealerExpire">
        <div
          v-if="!data.row.isCopy && canSubmit"
          style="display: flex; align-items: center; justify-content: space-around; width: 100%"
        >
          <div v-if="data.row.data_confirm_status === '待确认'" @click="doEdit(data.row)">编辑</div>
          <div v-if="data.row.data_confirm_status === '待确认'" @click="handleConfirm(data.row)">确认</div>
          <div
            v-if="data.row.data_confirm_status !== '待确认' && data.row.is_add !== '1' && !checkReset(data.row)"
            @click="handleReset(data.row, data.index)"
          >
            重置
          </div>
          <div @click="handleCopy(data.row, data.index)">复制新增</div>
          <div v-if="data.row.data_confirm_status === '待确认'" @click="handleAuth(data.row, '当前无授权')">
            当前无授权
          </div>
          <div v-if="data.row.data_confirm_status !== '已失效'" @click="handleDelete(data.row, data.index)">删除</div>
          <!--          <van-popover-->
          <!--            class="popover-foot"-->
          <!--            placement="bottom"-->
          <!--            v-model="data.row.showPopover"-->
          <!--            trigger="click"-->
          <!--            :actions="actions"-->
          <!--            @select="onSelectAction($event, data.row, data.index)"-->
          <!--          >-->
          <!--            <template #reference>-->
          <!--              <van-icon name="ellipsis" />-->
          <!--            </template>-->
          <!--          </van-popover>-->
        </div>
        <div
          v-if="data.row.isCopy && canSubmit"
          style="display: flex; align-items: center; justify-content: space-around; width: 100%"
        >
          <div @click="doEdit(data.row)">编辑</div>
          <div @click="handleConfirm(data.row)">确认</div>
        </div>
      </template>
    </form-table>
    <!-- 编辑组件 -->
    <detail v-if="showDetailPop" :detail="selectItem" @close="closeDetailPop" @submit="changeItem"></detail>
  </div>
</template>

<script>
import provinceAndCity from '@/utils/provinceAndCity'
import { getTableHeader } from '@/api/dataCenter/channelData'
import {
  getDistributor,
  getFeedbackProcess,
  submitChainData,
  addChainData,
  editChainData,
  deleteChainData
} from '@/api/dataCenter/chain'
import { getDicts } from '@/api/dict/data'
import Detail from './components/detail.vue'
export default {
  name: 'ChannelChain',
  components: { Detail },
  data() {
    return {
      btnData: [{ text: '下载' }, { text: '分销商清单' }],
      actions: [{ text: '复制新增' }, { text: '当前无授权' }, { text: '删除' }],
      searchFormData: [
        {
          props: 'endCustomerCode',
          label: '终端编码',
          type: 'input'
        },
        {
          props: 'endCustomerName',
          label: '终端名称',
          type: 'input'
        },
        {
          props: 'distributor2345Name',
          label: '分销商名称',
          type: 'input'
        },
        // {
        //   props: 'year',
        //   label: '年份',
        //   type: 'date',
        //   placeholder: '请选择年份',
        //   valueFormat: 'yyyy',
        //   dateFormat: 'yyyy',
        //   dateType: 'year'
        // },
        // {
        //   props: 'province',
        //   label: '省份',
        //   type: 'DictSelect',
        //   placeholder: '请选择省份',
        //   options: [],
        //   multiple: false
        // },
        {
          props: 'BA',
          label: 'BA',
          type: 'input'
        },
        {
          props: 'BL',
          label: 'BL',
          type: 'DictSelect',
          placeholder: '请选择',
          options: [],
          multiple: false
        }
      ],
      tableOptions: [],
      headerTitle: '渠道链路信息收集',
      queryUrl: '/bff-dp-web/all-channel-data/data/list',
      feedbackPercentage: 0,
      showDetailPop: false,
      selectItem: {},
      distributorList: [],
      canSubmit: true
    }
  },
  created() {
    getDicts('sys_product_line').then(({ data }) => {
      this.searchFormData.forEach(item => {
        if (item.props === 'BL') {
          item.options = data
        }
      })
    })
    this.getDistributorInfo()
    this.getTableHeader()
    let arr = provinceAndCity.dataList.map(item => {
      return {
        dictLabel: item.label,
        dictValue: item.label
      }
    })
    this.searchFormData.forEach(item => {
      if (item.props === 'province') {
        item.options = arr
      }
    })
  },
  mounted() {
    this.getProcess()
  },
  computed: {
    orgId() {
      return this.$store.state.user.userInfo.orgId
    },
    isDealerExpire() {
      return this.$store.state.user.userInfo.isDealerExpire
    },
    hasUnusual() {
      return val => {
        if (val && this.distributorList.indexOf(val) === -1) {
          return true
        } else {
          return false
        }
      }
    },
    checkReset() {
      return data => {
        if (
          data.distributor_level2 === data.original_distributor_level2 &&
          data.distributor_level3 === data.original_distributor_level3 &&
          data.distributor_level4 === data.original_distributor_level4 &&
          data.distributor_level5 === data.original_distributor_level5 &&
          data.reasons_difference === data.original_reasons_difference &&
          data.dealer_feedback === data.original_dealer_feedback &&
          data.data_confirm_status === data.original_data_confirm_status
        ) {
          return true
        } else {
          return false
        }
      }
    }
  },
  methods: {
    async getDistributorInfo() {
      let params = {
        pageNum: 1,
        pageSize: 10000
      }
      const { rows } = await getDistributor(params)
      this.distributorList = rows.map(item => {
        return item.distributorName
      })
    },
    getTableHeader() {
      getTableHeader(3).then(res => {
        let list = res.data.filter(
          item =>
            ![
              'end_customer_code',
              'end_customer_name',
              'data_confirm_status',
              'dealer_code',
              'distributor_name'
            ].includes(item.columnName)
        )
        list = list.map(item => {
          return {
            ...item,
            prop: item.columnName,
            label: item.columnBusinessName,
            isMore: item.isMore == 1,
            isEdit: true
          }
        })
        this.tableOptions = [
          {
            type: 'fileName',
            hideFileIcon: true
          },
          ...list
        ]
      })
    },
    getProcess() {
      const formData = this.$refs.baseForm.queryParams
      let params = {
        year: formData?.year,
        month: formData?.month
      }
      getFeedbackProcess(params).then(res => {
        this.feedbackPercentage = parseInt((res.data * 100).toFixed(0))
      })
    },
    onSelect(_, searchParams, selectedData) {
      const selectedFileIdList = [...selectedData].map(item => {
        return item.id
      })
      const requestParams = {
        ...searchParams,
        pageNum: undefined,
        pageSize: undefined
      }
      this.download('/bff-dp-mobile/all-channel-data/data/export', {}, `全渠道链_${new Date().getTime()}.xlsx`, {
        method: 'get',
        params: requestParams
      })
    },
    handleSearchData(data) {
      data.forEach(item => {
        this.$set(item, 'showPopover', false)
      })
      this.getProcess()
    },
    handleCheck(data) {
      this.canSubmit = data.canSubmit
    },
    onSelectAction(data, obj, index) {
      if (data.text === '复制新增') {
        this.handleCopy(obj, index)
      } else if (data.text === '当前无授权') {
        this.handleAuth(obj, '当前无授权')
      } else if (data.text === '删除') {
        this.handleDelete(obj)
      }
    },
    doEdit(data) {
      this.selectItem = { ...data }
      this.showDetailPop = true
    },
    handleConfirm(data) {
      // if (!data.distributor_level2) {
      //   this.$toast.fail('二级商不能为空！')
      //   return false
      // }
      if (data.hint_modify_tab === '异常数据' && (!data.reasons_difference || !data.dealer_feedback)) {
        this.$toast.fail('该条数据确认时，差异原因和经销商反馈不能为空！')
        return false
      }
      let flag = 0
      if (data.distributor_level2 && this.distributorList.indexOf(data.distributor_level2) === -1) {
        flag++
      }
      if (data.distributor_level3 && this.distributorList.indexOf(data.distributor_level3) === -1) {
        flag++
      }
      if (data.distributor_level4 && this.distributorList.indexOf(data.distributor_level4) === -1) {
        flag++
      }
      if (data.distributor_level5 && this.distributorList.indexOf(data.distributor_level5) === -1) {
        flag++
      }
      if (flag > 0) {
        this.$toast.fail('分销商数据有异常，无法操作！')
        return false
      }
      if (
        (data.distributor_level2 === data.distributor_level3 && data.distributor_level2 && data.distributor_level3) ||
        (data.distributor_level2 === data.distributor_level4 && data.distributor_level2 && data.distributor_level4) ||
        (data.distributor_level2 === data.distributor_level5 && data.distributor_level2 && data.distributor_level5) ||
        (data.distributor_level3 === data.distributor_level4 && data.distributor_level3 && data.distributor_level4) ||
        (data.distributor_level3 === data.distributor_level5 && data.distributor_level3 && data.distributor_level5) ||
        (data.distributor_level4 === data.distributor_level5 && data.distributor_level4 && data.distributor_level5)
      ) {
        this.$toast.fail('链路信息中的分销商不能重复！')
        return false
      }
      let obj = {
        id: data.id,
        fileId: data.file_id,
        endCustomerCode: data.end_customer_code,
        endCustomerName: data.end_customer_name,
        bl: data.BL,
        distributorLevel2: data.distributor_level2,
        distributorLevel3: data.distributor_level3,
        distributorLevel4: data.distributor_level4,
        distributorLevel5: data.distributor_level5,
        reasonsDifference: data.reasons_difference,
        dealerFeedback: data.dealer_feedback,
        isCopy: data.isCopy ? data.isCopy : false
      }
      if (data.isCopy) {
        let params = { ...obj }
        addChainData(params)
          .then(res => {
            this.$toast.success('操作成功')
            this.$refs.baseForm.onRefresh()
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        let params = []
        params.push(obj)
        submitChainData(params)
          .then(res => {
            this.$toast.success('操作成功')
            this.$refs.baseForm.onRefresh()
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    handleReset(data, index) {
      let obj = {
        ...data,
        distributor_level2: data.original_distributor_level2,
        distributor_level3: data.original_distributor_level3,
        distributor_level4: data.original_distributor_level4,
        distributor_level5: data.original_distributor_level5,
        reasons_difference: data.original_reasons_difference,
        dealer_feedback: data.original_dealer_feedback,
        data_confirm_status: data.original_data_confirm_status
      }
      this.$refs.baseForm.tableData.splice(index, 1, obj)
      this.handleUpdate(obj)
    },
    handleCopy(data, index) {
      let obj = {
        ...data,
        data_confirm_status: '待确认',
        isCopy: true
      }
      this.$refs.baseForm.tableData.splice(index + 1, 0, obj)
    },
    handleAuth(data, type) {
      // if (!data.reasons_difference) {
      //   this.$toast.fail('差异原因不能为空！')
      //   return false
      // }
      // if (!data.distributor_level2) {
      //   this.$toast.fail('二级商不能为空！')
      //   return false
      // }
      // let flag = 0
      // if (data.distributor_level2 && this.distributorList.indexOf(data.distributor_level2) === -1) {
      //   flag++
      // }
      // if (data.distributor_level3 && this.distributorList.indexOf(data.distributor_level3) === -1) {
      //   flag++
      // }
      // if (data.distributor_level4 && this.distributorList.indexOf(data.distributor_level4) === -1) {
      //   flag++
      // }
      // if (data.distributor_level5 && this.distributorList.indexOf(data.distributor_level5) === -1) {
      //   flag++
      // }
      // if (flag > 0) {
      //   this.$toast.fail('分销商数据有异常，无法操作！')
      //   return false
      // }
      let params = {
        id: data.id,
        fileId: data.file_id,
        dataConfirmStatus: type,
        endCustomerCode: data.end_customer_code,
        endCustomerName: data.end_customer_name,
        bl: data.BL,
        distributorLevel2: data.distributor_level2,
        distributorLevel3: data.distributor_level3,
        distributorLevel4: data.distributor_level4,
        distributorLevel5: data.distributor_level5,
        reasonsDifference: data.reasons_difference,
        dealerFeedback: data.dealer_feedback,
        isReset: false
      }
      editChainData(params)
        .then(res => {
          this.$toast.success('操作成功')
          this.$refs.baseForm.onRefresh()
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleUpdate(data) {
      let params = {
        id: data.id,
        fileId: data.file_id,
        dataConfirmStatus: data.data_confirm_status,
        endCustomerCode: data.end_customer_code,
        endCustomerName: data.end_customer_name,
        bl: data.BL,
        distributorLevel2: data.distributor_level2,
        distributorLevel3: data.distributor_level3,
        distributorLevel4: data.distributor_level4,
        distributorLevel5: data.distributor_level5,
        reasonsDifference: data.reasons_difference,
        dealerFeedback: data.dealer_feedback,
        isReset: true
      }
      editChainData(params)
        .then(res => {
          this.$toast.success('操作成功')
          this.getDistributorInfo()
          this.$refs.baseForm.onRefresh()
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleDelete(data) {
      // if (!data.reasons_difference) {
      //   this.$toast.fail('差异原因不能为空！')
      //   return false
      // }
      // if (!data.distributor_level2) {
      //   this.$toast.fail('二级商不能为空！')
      //   return false
      // }
      // let flag = 0
      // if (data.distributor_level2 && this.distributorList.indexOf(data.distributor_level2) === -1) {
      //   flag++
      // }
      // if (data.distributor_level3 && this.distributorList.indexOf(data.distributor_level3) === -1) {
      //   flag++
      // }
      // if (data.distributor_level4 && this.distributorList.indexOf(data.distributor_level4) === -1) {
      //   flag++
      // }
      // if (data.distributor_level5 && this.distributorList.indexOf(data.distributor_level5) === -1) {
      //   flag++
      // }
      // if (flag > 0 && data.is_add === '0') {
      //   this.$toast.fail('分销商数据有异常，无法操作！')
      //   return false
      // }
      let params = {
        id: data.id,
        fileId: data.file_id,
        endCustomerCode: data.end_customer_code,
        endCustomerName: data.end_customer_name,
        bl: data.BL,
        distributorLevel2: data.distributor_level2,
        distributorLevel3: data.distributor_level3,
        distributorLevel4: data.distributor_level4,
        distributorLevel5: data.distributor_level5,
        reasonsDifference: data.reasons_difference,
        dealerFeedback: data.dealer_feedback
      }
      this.$dialog
        .confirm({
          message: '是否确认删除该数据？'
        })
        .then(() => {
          deleteChainData(params).then(({ code, msg }) => {
            if (code != 200) {
              this.$toast.fail({
                message: msg,
                className: 'fail'
              })
              return
            }
            this.$toast.success('删除成功')
            this.$refs.baseForm.onRefresh()
          })
        })
        .catch(() => {})
    },
    goDealerList() {
      this.$router.push({ path: '/dataCenter/channel-data/chainDealer' })
    },
    closeDetailPop() {
      this.showDetailPop = false
    },
    changeItem(obj) {
      this.$refs.baseForm.tableData.forEach(item => {
        if (item.id == obj.id) {
          item.distributor_level2 = obj.distributor_level2
          item.distributor_level3 = obj.distributor_level3
          item.distributor_level4 = obj.distributor_level4
          item.distributor_level5 = obj.distributor_level5
          item.reasons_difference = obj.reasons_difference
          item.dealer_feedback = obj.dealer_feedback
        }
      })
      this.showDetailPop = false
    }
  }
}
</script>

<style lang="css" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    color: #0b41cd;
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(10px) translateY(0px);
  }
}

/deep/ .titleClass {
  color: #8c8c8c !important;
}

.green-span {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding: 1px 5px;
  background-color: #cbede8;
  color: #21201f;
  border-radius: 24px 24px 24px 24px;
}

.red-span {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding: 1px 5px;
  background-color: #ffb4b1;
  color: #21201f;
  border-radius: 24px 24px 24px 24px;
}

.gray-span {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding: 1px 5px;
  background-color: #f5f5f5;
  color: #21201f;
  border-radius: 24px 24px 24px 24px;
}

/deep/ .isUnusual {
  color: #c40000 !important;
}

.disableStyle {
  cursor: not-allowed;
}
</style>
