import request from '@/utils/request'

export function getAppealDetailByTaskId(taskId, processInstanceId) {
  let params = ''
  if (taskId) {
    params = `appealTaskId=${taskId}`
  }
  if (processInstanceId) {
    params = `appealProcessInstanceId=${processInstanceId}`
  }
  return request({
    url: `/bff-dp-web/cib-confirmation/appeal-detail-taskId?${params}`,
    method: 'get'
  })
}
