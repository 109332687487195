<template>
  <div class="warpper">
    <div class="list-bg" />
    <van-nav-bar
      :title="title"
      right-text="完成"
      :class="loading ? 'btn-loading' : ''"
      left-arrow
      @click-left="goToBack"
      @click-right="onSubmit"
    >
      <template #right>
        <span class="header-btn" :class="loading ? 'btn-loading' : ''" @click="onSubmit">完成</span>
      </template>
    </van-nav-bar>

    <van-form validate-first ref="form">
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.nickName"
        name="nickName"
        required
        label="姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true, message: '姓名不能为空' }]"
      />
      <van-field
        :maxlength="11"
        input-align="right"
        v-model="form.phonenumber"
        name="phonenumber"
        label="手机"
        required
        placeholder="请输入手机号"
        :rules="[{ pattern: phonePattern, message: '请输入正确的手机号码' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.email"
        name="email"
        required
        label="邮箱"
        placeholder="请输入邮箱"
        rows="1"
        autosize
        type="textarea"
        :rules="[{ pattern: emailPattern, message: '请输入正确的邮箱地址' }]"
      />
      <van-field
        maxlength="250"
        input-align="right"
        v-model="form.appealDescription"
        name="appealDescription"
        required
        label="申请描述"
        placeholder="请输入申请描述"
        :rules="[{ required: true, message: '申请描述不能为空' }]"
      />
      <!-- <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.roleName"
        name="roleName"
        required
        readonly
        label="账号类型"
        placeholder="请输入账号类型"
        :rules="[{ required: true, message: '账号类型不能为空' }]"
      /> -->
      <!-- <van-field
        class="text-hide1"
        :maxlength="defaultMaxLength"
        input-align="right"
        required
        clickable
        readonly
        name="platformRoleNames"
        :value="form.platformRoleNames"
        label="角色"
        placeholder="请点击选角色"
        @click="showRolePicker"
        :rules="[{ required: true, message: '请选择角色' }]"
      >
      </van-field>
      <van-collapse v-model="activeNames" v-if="form.roleTreeList.length > 0">
        <van-collapse-item title="角色划分展示" name="1" class="role">
          <template v-for="(item, i) in roleTypeList">
            <div v-if="item.checkedRole.length > 0" :key="i" class="border-bottom">
              <h3 class="title">{{ item.systemName }}</h3>
              <p
                class="role-list"
                v-for="(role, j) in item.roleList"
                :key="j"
                style="padding-left: 5px; line-height: 1.25"
              >
                <span v-if="item.checkedRole.includes(role.roleId)">{{ role.roleName }} </span>
              </p>
            </div>
          </template>
        </van-collapse-item>
      </van-collapse> -->
    </van-form>

    <van-dialog v-model="showPickerRoleFlag" class="detail-dialog" @confirm="onConfirmRole">
      <van-tree-select :items="columnsRole" :active-id.sync="activeIds" :main-active-index.sync="activeIndex" />
    </van-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { getRoleTypeList, createAppealForManager } from '@/api/userCenter/dealer'
export default {
  name: 'dealer-contact-info',
  props: {
    manageChangeInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    hasCompanyManager: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      title: '',
      lengthPattern: /^.{1,10}$/,
      phonePattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      emailPattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      form: {
        nickName: '',
        phonenumber: '',
        email: '',
        appealDescription: ''
      },
      formClone: {},
      status: false,
      loading: false,

      //新增东西
      showPickerRoleFlag: false,
      columnsRole: [],
      activeIds: [],
      activeIndex: '0',
      roleTypeList: [],
      activeNames: [1],
      isDAoverdue: false,
      companyManager: false,
      dealerManager: false
    }
  },
  async created() {},
  mounted() {
    //da合同是否过期
    // this.isDAoverdue = this.$store.state.user.userInfo.isDealerExpire
    this.title = '公司负责人变更申请'
    this.init(this.manageChangeInfo)
  },
  methods: {
    onConfirmRole() {
      this.roleTypeList.map(item => {
        if (item.roleList.length > 0) {
          item.checkedRole = []
          item.roleList.map(role => {
            if (this.activeIds.includes(role.roleId)) {
              item.checkedRole = [...item.checkedRole, role.roleId]
            }
          })
        }
      })
      this.form.roleTreeList = this.roleTypeList
      this.setPlatformRoleNames(this.roleTypeList)
    },
    setPlatformRoleNames(treeList) {
      let platformRoleNames = []
      treeList.map(item => {
        item.roleList.map(role => {
          if (item.checkedRole.includes(role.roleId)) {
            platformRoleNames.push(role.roleName)
          }
        })
      })
      this.form.platformRoleNames = platformRoleNames.join(',')
    },
    getTextValues(a, b) {
      var c = []
      b.forEach(function (roleId) {
        var obj = a.find(function (item) {
          return item.roleId === roleId
        })
        if (obj) {
          c.push(obj.roleName)
        }
      })
      return c
    },
    async getRoleTypeListFun() {
      let _isManage = this.form.subAccountFlag == '1' ? false : true
      await getRoleTypeList(_isManage === true ? 1 : 0).then(response => {
        this.roleTypeList = response.data
        this.roleTypeList = this.roleTypeList.map(item => {
          return {
            ...item,
            checkedRole: item.checkedRole || []
          }
        })
        this.form.roleTreeList.map(item => {
          this.roleTypeList.map(role => {
            if (role.systemName == item.systemName) {
              role.checkedRole = item.checkedRole
            }
          })
        })
        this.setPlatformRoleNames(this.roleTypeList)
        this.columnsRole = _.cloneDeep(this.roleTypeList).map(item => {
          return {
            text: item.systemName,
            children: item.roleList.map(role => {
              return {
                text: role.roleName,
                id: role.roleId,
                disabled: this.setDisabled(role, item.systemName)
              }
            })
          }
        })
      })
    },
    setDisabled(role, systemName) {
      //如果列表里所有人有一个是公司负责人，并且role.isAdmin，则不可选
      if (
        (this.form.isCompanyManager && role.isAdmin) ||
        (this.companyManager && systemName == 'CEP') ||
        (this.hasCompanyManager && role.isAdmin)
      ) {
        //如果当前这条数据就是公司负责人，只有新增的时候可以选，否则都不可选
        if (this.companyManager && this.form.processFlag == 'add') {
          return false
        } else {
          return true
        }
      }
      if (this.form.isDealerManager && role.roleId == '3') {
        //如果当前这条数据就是公司负责人，只有新增的时候可以选，否则都不可选
        if (this.dealerManager && this.form.processFlag == 'add') {
          return false
        } else {
          return true
        }
      }
    },
    showRolePicker() {
      this.activeIds = this.form.roleTreeList
        .map(item => {
          return item.checkedRole.map(role => {
            return role
          })
        })
        .flat()
      this.showPickerRoleFlag = true
    },
    checkHasChange() {
      let hasChange = false
      for (let key in this.form) {
        if (this.form[key] != this.formClone[key] && key != 'appealDescription') {
          hasChange = true
          console.log(key)
          break
        }
      }
      return hasChange
    },
    init(obj) {
      const { nickName, phonenumber, email } = obj
      this.form = {
        ...this.form,
        nickName,
        phonenumber,
        email
      }
      this.formClone = _.cloneDeep(this.form)
    },
    goToBack(type) {
      this.$emit('close', type)
    },
    goToBackWithRefresh() {
      this.$router.push({
        name: 'UserCenter',
        params: {
          page: 'Contact'
        }
      })
    },
    goToChangeDealer() {
      if (!this.checkHasChange()) {
        this.loading = false
        this.$toast.fail('请先修改信息再提交')
        return
      }
      this.$dialog
        .alert({
          title: '提醒',
          message: `根据经销协议条款18.4的内容：如经销商的送达地址、受送达人、联系电话等送达方式发生变动，应立即书面通知罗氏。</br>
          通知方式为：</br>
          ① 通过原经销商协议上约定的邮箱将变更的信息发送至china.ctm@roche.com；</br>
          ② 若原经销商协议上约定的邮箱目前已无法发送邮件，可提供说明函，说明发生的变更，并将加盖公章的说明函扫描件发送至china.ctm@roche.com。`,
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true,
          messageAlign: 'left'
        })
        .then(() => {
          try {
            let params = {
              nickName: this.form.nickName,
              phoneNumber: this.form.phonenumber,
              email: this.form.email,
              beforeNickName: this.formClone.nickName,
              beforePhoneNumber: this.formClone.phonenumber,
              beforeEmail: this.formClone.email,
              appealDescription: this.form.appealDescription
            }
            createAppealForManager(params).then(() => {
              this.$toast.success('提交成功')
              this.loading = false
              this.$emit('success')
              this.goToBack('init')
            })
          } catch (error) {
            console.error('操作失败:', error)
            this.$toast.msgError('操作失败，请重试')
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    onSubmit() {
      this.$refs.form
        .validate()
        .then(() => {
          if (this.loading) {
            return
          }
          this.loading = true
          this.goToChangeDealer()
        })
        .catch(() => {
          // this.$toast.fail('提交失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}
/deep/.van-cell {
  border-bottom: 1px solid #ebedf0;
}
/deep/ .van-form {
  background: #fff;
  height: calc(100vh - 75px);
  overflow: scroll;
}
/deep/ .van-toast--fail {
  width: 300px;
}
/deep/ .van-nav-bar__title {
  font-weight: bold !important;
}
/deep/ .van-field__label {
  color: #000;
}
/deep/.van-field__error-message {
  text-align: right;
}
.header-btn {
  color: #0b41cd;
}

.warpper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}
::v-deep {
  .detail-dialog {
    // padding-top: 20px;
  }
  .text-hide1 {
    /*单行文本溢出*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .border-bottom {
    border-bottom: 1px solid #0b41cd52;
    padding-bottom: 3px;
    margin-bottom: 3px;
  }
  .border-bottom:last-child {
    border-bottom: none;
  }
  .title {
    font-size: 16px;
    color: #0b41cd;
  }
  .role {
    font-size: 12px;
  }
  .role {
    .van-collapse-item__content {
      background: linear-gradient(180deg, rgba(233, 242, 253, 0.3) 0%, #e9f2fd 100%);
    }
  }
}
</style>
