<template>
  <div class="main-container">
    <div class="list-bg" />
    <van-row class="header" type="flex" justify="space-between">
      <van-col class="head-arrow" @click="goBack"><van-icon name="arrow-left" color="#0b41cd" /></van-col>
      <van-col class="head-title">{{ headerTitle }}</van-col>
    </van-row>
    <van-collapse v-model="activeNames" class="collapse">
      <van-collapse-item name="index" :is-link="false" :disabled="true" :class="rowClassName">
        <template #title>
          <van-row type="flex" align="center" justify="start">
            <van-col class="card-text">
              <van-row class="orange-span" v-if="appealType === 1">仪器在本公司名下，但信息有差异</van-row>
              <van-row class="red-span" v-if="appealType === 2">仪器不在本公司名下</van-row>
              <van-row class="green-span" v-if="appealType === 0">新增仪器反馈</van-row>
            </van-col>
          </van-row>
        </template>
        <div class="collapse-content">
          <van-row class="divider" v-if="appealType === 1">
            <van-col span="24"><van-cell title="终端代码(现有数据)" :value="originalData.instrLocKey" /></van-col>
            <van-col span="24"
              ><van-cell
                title="终端代码(反馈数据)"
                :value="appealData.instrLocKey"
                title-class="isAppeal"
                :value-class="appealData.instrLocKey !== originalData.instrLocKey ? 'isAppealValue' : ''"
            /></van-col>
            <van-col span="24"><van-cell title="终端名称(现有数据)" :value="originalData.instrLocName" /></van-col>
            <van-col span="24"
              ><van-cell
                title="终端名称(反馈数据)"
                :value="appealData.instrLocName"
                title-class="isAppeal"
                :value-class="appealData.instrLocName !== originalData.instrLocName ? 'isAppealValue' : ''"
            /></van-col>
            <van-col span="24"><van-cell title="仪器序列号(现有数据)" :value="originalData.serialNumber" /></van-col>
            <van-col span="24"
              ><van-cell
                title="仪器序列号(反馈数据)"
                :value="appealData.serialNumber"
                title-class="isAppeal"
                :value-class="appealData.serialNumber !== originalData.serialNumber ? 'isAppealValue' : ''"
            /></van-col>
            <van-col span="24"><van-cell title="仪器使用状态(现有数据)" :value="originalData.moduleStatus" /></van-col>
            <van-col span="24"
              ><van-cell
                title="仪器使用状态(反馈数据)"
                :value="appealData.moduleStatus"
                title-class="isAppeal"
                :value-class="appealData.moduleStatus !== originalData.moduleStatus ? 'isAppealValue' : ''"
            /></van-col>
            <van-col span="24"
              ><van-cell title="状态补充说明(现有数据)" :value="originalData.moduleStatusSupplement"
            /></van-col>
            <van-col span="24"
              ><van-cell
                title="状态补充说明(反馈数据)"
                :value="appealData.moduleStatusSupplement"
                title-class="isAppeal"
            /></van-col>
            <!--            <van-col span="24"-->
            <!--              ><van-cell title="使用状态描述(现有数据)" :value="originalData.moduleStatusDescription"-->
            <!--            /></van-col>-->
            <!--            <van-col span="24"-->
            <!--              ><van-cell-->
            <!--                title="使用状态描述(反馈数据)"-->
            <!--                :value="appealData.moduleStatusDescription"-->
            <!--                title-class="isAppeal"-->
            <!--            /></van-col>-->
            <van-col span="24" style="margin-top: 30px"
              ><van-field
                v-model="appealData.appealDescription"
                label-class="notAppeal"
                input-align="right"
                rows="2"
                autosize
                label="反馈描述"
                type="textarea"
                placeholder="请输入"
                readonly
            /></van-col>
            <van-col span="24"
              ><van-field
                v-model="appealData.remark"
                label-class="notAppeal"
                input-align="right"
                rows="2"
                autosize
                label="备注"
                type="textarea"
                placeholder="请输入"
                readonly
            /></van-col>
          </van-row>
          <van-row class="divider" v-if="appealType === 2 || appealType === 0">
            <van-col span="24"><van-cell title="设备号" :value="appealData.equipmentNum || '-'" /></van-col>
            <van-col span="24"><van-cell title="物料号" :value="appealData.materialNumber || '-'" /></van-col>
            <van-col span="24"><van-cell title="物料名称" :value="appealData.materialDesc || '-'" /></van-col>
            <van-col span="24" v-if="appealType === 2"
              ><van-cell title="终端代码" :value="appealData.instrLocKey || '-'"
            /></van-col>
            <van-col span="24" v-if="appealType === 3"
              ><van-cell title="终端名称" :value="appealData.instrLocName || '-'"
            /></van-col>
            <van-col span="24"><van-cell title="仪器序列号" :value="appealData.serialNumber || '-'" /></van-col>
            <van-col span="24" v-if="appealType === 0"
              ><van-cell title="仪器使用状态" :value="appealData.moduleStatus || '-'"
            /></van-col>
            <van-col span="24" v-if="appealType === 0"
              ><van-cell title="状态补充说明" :value="appealData.moduleStatusSupplement || '-'"
            /></van-col>
            <van-col span="24" v-if="appealType === 0"
              ><van-cell title="仪器使用状态描述" :value="appealData.moduleStatusDescription || '-'"
            /></van-col>
            <van-col span="24" style="margin-top: 30px"
              ><van-field
                v-model="appealData.remark"
                label-class="notAppeal"
                input-align="right"
                rows="2"
                autosize
                label="备注"
                type="textarea"
                placeholder="请输入"
                readonly
            /></van-col>
          </van-row>
        </div>
      </van-collapse-item>
    </van-collapse>
    <van-row style="padding: 10px 10px" v-if="fileList.length > 0">
      <van-col span="24" class="cell-group">
        <van-row style="height: 40px; line-height: 40px; padding: 5px 10px">
          <van-col span="12" style="text-align: left; font-weight: bold; font-size: 14px"> 附件 </van-col>
        </van-row>
        <van-cell-group class="cell-bg" v-for="(item, index) in fileList" :key="index">
          <van-cell @click="downloadOne(item)">
            <van-row>
              <van-col span="24">
                <van-col span="3" style="margin-top: 2px">
                  <van-image
                    v-if="getFileType(item.fileName) === '.PDF'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/pdf.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.ZIP'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/zip.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.XLS' || getFileType(item.fileName) === '.XLSX'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/excel.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.DOC' || getFileType(item.fileName) === '.DOCX'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/word.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.PNG'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/png.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.JPG' || getFileType(item.fileName) === '.JPEG'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/jpg.svg')"
                  />
                  <van-image v-else width="20" height="20" :src="require('@/assets/images/file.svg')" />
                </van-col>
                <van-col span="21">
                  <div style="color: #0b41cd">{{ item.fileName }}</div>
                </van-col>
              </van-col>
            </van-row>
          </van-cell>
        </van-cell-group>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { download } from '@/utils/request'
import { getDicts } from '@/api/dict/data'

export default {
  name: 'InstrumentAppeal',
  data() {
    return {
      headerTitle: '反馈详情',
      activeNames: ['index'],
      appealType: null,
      form: {},
      originalData: {},
      appealData: {},
      fileList: [],
      statusList: []
    }
  },
  computed: {
    orgId() {
      return this.$store.state.user.userInfo.orgId
    },
    rowClassName() {
      if (this.appealType === 1) {
        return 'orange-row'
      } else if (this.appealType === 2) {
        return 'red-row'
      } else if (this.appealType === 0) {
        return 'green-row'
      } else {
        return ''
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await getDicts('module_status').then(({ data }) => {
        this.statusList = data
      })
      this.form = { ...this.$route.query.initData }
      this.fileList = []
      if (this.form.fileId) {
        this.fileList.push({
          fileId: this.form.fileId,
          fileName: this.form.fileName
        })
      }
      this.appealType = parseInt(this.form.appealType)
      if (this.appealType === 1) {
        this.originalData = {
          ...this.$route.query.initData,
          dataType: '现有数据',
          instrLocKey: this.form.lastInstrLocKey,
          instrLocName: this.form.lastInstrLocName,
          serialNumber: this.form.lastSerialNumber,
          moduleStatus: this.form.lastModuleStatus,
          moduleStatusSupplement: this.form.lastModuleStatusSupplement,
          moduleStatusDescription: this.form.lastModuleStatusDescription
        }
        this.statusList.forEach(item => {
          if (item.dictValue === this.originalData.moduleStatus) {
            this.originalData.moduleStatus = item.dictLabel
          }
        })
        this.appealData = {
          dataType: '反馈数据',
          instrLocKey: this.form.instrLocKey,
          instrLocName: this.form.instrLocName,
          serialNumber: this.form.serialNumber,
          moduleStatus: this.form.moduleStatus,
          moduleStatusSupplement: this.form.moduleStatusSupplement,
          moduleStatusDescription: this.form.moduleStatusDescription,
          remark: this.form.remark,
          appealDescription: this.form.appealDescription
        }
        this.statusList.forEach(item => {
          if (item.dictValue === this.appealData.moduleStatus) {
            this.appealData.moduleStatus = item.dictLabel
          }
        })
      } else if (this.appealType === 2) {
        this.appealData = {
          dataType: '反馈数据',
          ...this.$route.query.initData
        }
        this.statusList.forEach(item => {
          if (item.dictValue === this.appealData.moduleStatus) {
            this.appealData.moduleStatus = item.dictLabel
          }
        })
      } else {
        this.appealData = {
          dataType: '新增数据',
          ...this.$route.query.initData
        }
        this.statusList.forEach(item => {
          if (item.dictValue === this.appealData.moduleStatus) {
            this.appealData.moduleStatus = item.dictLabel
          }
        })
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    getFileType(fileName) {
      if (!fileName) {
        return null
      }
      const fileType = fileName.substr(fileName.lastIndexOf('.'), fileName.length - 1).toUpperCase()
      return fileType
    },
    downloadOne(obj) {
      const fileIds = obj.fileId
      download('/file/multi-download', {}, null, {
        method: 'POST',
        params: {
          watermarkFlag: true,
          fileIds
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main-container {
  padding: 0 16px;
}

.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
}

.header {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;

  .popover {
    color: #0b41cd;
    font-size: 16px;
    position: absolute;
    height: 52px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
  }

  .head-arrow {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    left: 16px;
  }

  .head-title {
    font-weight: 600;
  }

  .head-btn {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    right: 16px;
    font-size: 16px;
    color: #0b41cd;
  }
}

.title {
  padding: 6px 0 12px;

  .total {
    font-size: 14px;
    line-height: 24px;
    color: #969799;
  }

  .filter {
    color: #0b41cd;
    font-size: 14px;
  }
}

.margin-lf-10 {
  margin-left: 10px;
}

.divider {
  border-bottom: 0px solid #ebedf0;
}

.collapse {
  padding: 2px;
  .van-collapse-item {
    // overflow: hidden;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
    /deep/.van-collapse-item__content {
      border-radius: 0 0 8px 8px;
    }
    .card-text {
      color: #8c8c8c;
      text-align: right;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #0b41cd;
        font-size: 14px;
        font-weight: 600;
        // margin-bottom: 2px;
      }
    }
    /deep/.van-cell {
      align-items: center;
      padding: 10px 8px;
    }
    .diy-cell-value {
      color: #969799;
      text-align: right;
    }

    .collapse-content {
      /deep/.van-cell {
        align-items: normal;
        font-size: 12px;
        line-height: 14px;
        padding-left: 0;
      }

      .table-mark {
        margin-left: 8px;
        padding: 0.2vw 0.8vw;
        border-radius: 12px;
      }
      .mark-del {
        background-color: #ebeef5;
      }
    }
    .foot-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 8px;
      color: #0b41cd;
      border-top: 0.5px solid #ebedf0;
      .vertical-line {
        margin: 0 8px;
      }
    }
    .disable-button {
      color: #a0cfff;
    }
  }
}

.green-span {
  padding: 2px 5px;
  background-color: #cbede8;
  color: #21201f;
  border-radius: 24px 24px 24px 24px;
}

.red-span {
  padding: 2px 5px;
  background-color: #ffb4b1;
  color: #21201f;
  border-radius: 24px 24px 24px 24px;
}

.orange-span {
  padding: 2px 5px;
  background-color: #fad6c7;
  color: #21201f;
  border-radius: 24px 24px 24px 24px;
}

/deep/ .green-row {
  background: #cbede8 !important;
}

/deep/ .red-row {
  background: #ffb4b1 !important;
}

/deep/ .orange-row {
  background: #fad6c7 !important;
}

.isAppeal {
  color: #c40000 !important;
}

.isAppealValue {
  color: #ff7016 !important;
}

.notAppeal {
  color: #8c8c8c !important;
}

.cell-bg {
  background: #ffffff;
}

.cell-group {
  //border-radius: 8px;
  //border: 0.5px solid var(--neutrals-grey-5-divider, #d9d9d9);
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
  box-shadow: 0px 0px 10px 0px rgba(11, 65, 205, 0.15);
  margin-bottom: 10px;
}
</style>
