import request from '@/utils/request'
import { getRefreshToken } from '@/utils/auth'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid,
    isRocheAccount: false
  }
  return request({
    url: '/auth/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/user/user/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

export function ssoLogin(data) {
  return request({
    url: '/auth/callback/DEALER_M?session_state=' + data.session_state + '&code=' + data.code,
    headers: {
      isToken: false
    },
    method: 'get'
  })
}

export function getDealer(orgId) {
  return request({
    url: '/bff-dp-mobile/dealer/info/' + orgId,
    method: 'get'
  })
}

// 获取路由
export function getRouters() {
  return request({
    url: '/user/menu/getRouters',
    method: 'get'
  })
}

export function getAllRouters() {
  return request({
    url: '/user/menu/allList',
    method: 'get'
  })
}

export function ssoLogout() {
  return request({
    url: '/auth/logout',
    method: 'delete'
  })
}

//刷新token
export function refreshToken() {
  return request({
    url: '/auth/refresh',
    headers: {
      isToken: false,
      Authorization: 'Bearer ' + getRefreshToken()
    },
    method: 'post'
  })
}
