// 所有的请求都放在该目录
import instance from '../utils/request'
//首页所有请求
//1. 获取首页数据列表
export function getIndexList() {
  return instance.get('/index/index')
}

//]专题页 Topic
//专题请求
export function getTopicList(params) {
  return instance({
    url: '/topic/list',
    method: 'get',
    params
  })
}

//6. 分类页 Category
// 全部分类数据接口
export function GetChannelDataApi(params) {
  return instance({
    url: '/api/catalog/index',
    method: 'get',
    params
  })
}
// 获取当前分类数据
export function GetFenleiDataApi(params) {
  return instance({
    url: '/catalog/current',
    method: 'get',
    params
  })
}

//我的页面 User
//登陆
export function GoLogin(params) {
  return instance({
    url: '/auth/loginByWeb',
    method: 'post',
    data: params
  })
}

// 搜索页
// 根据关键字搜索接口
export function GetSearchData(params) {
  return instance.get('/goods/list', {
    params
  })
}

// 详情页
//根据id查询对应数据接口
export function getDetailData(params) {
  return instance.get('/goods/detail', {
    params
  })
}

//详情页相关产品
export function GetGoodsRelatedData(params) {
  return instance({
    url: '/goods/related',
    method: 'get',
    params
  })
}

// 2.搜索页 SearchPopup
// 历史记录列表和热门搜索列表
export function GetPopupData(params) {
  return instance({
    url: '/search/index',
    method: 'get',
    params
  })
}

//删除历史记录
export function Clearhistory(params) {
  return instance({
    url: '/search/clearhistory',
    method: 'post',
    data: params
  })
}

//搜索提示列表
export function GetSearchTipsListData(params) {
  return instance({
    url: '/search/helper',
    method: 'get',
    params
  })
}

//4.分类数据获取 Channel
export function GetCateGoryData(params) {
  return instance({
    url: '/goods/category',
    method: 'get',
    params
  })
}
// 分类页面商品列表请求
export function GetCateGoryList(params) {
  return instance({
    url: '/goods/list',
    method: 'get',
    params
  })
}

// 获取品牌详情数据列表请求
export function GetBrandList(params) {
  return instance({
    url: '/brand/detail',
    method: 'get',
    params
  })
}

// 获取分页品牌详情中的产品列表
export function GetBrandListData(params) {
  return instance({
    url: '/goods/list',
    method: 'get',
    params
  })
}

//购物车页 Cart
// 购物车列表
export function GetCartData(params) {
  return instance({
    url: '/cart/index',
    method: 'get',
    params
  })
}
// 加入购物车
export function AddToCart(params) {
  return instance.post('/cart/add', params)
}

// 获取购物车产品数量
export function GetCartCountData(params) {
  return instance({
    url: '/cart/goodscount',
    method: 'get',
    params
  })
}

// 加入购物车
export function UpdateCartData(params) {
  return instance({
    url: '/cart/update',
    method: 'post',
    data: params
  })
}

// 删除购物车商品
export function DeleteCartData(params) {
  return instance({
    url: '/cart/delete',
    method: 'get',
    params
  })
}

// 删除购物车商品
export function DeleteCartData2(params) {
  return instance({
    url: '/cart/delete',
    method: 'post',
    data: params
  })
}

// 切换购物车商品选中状态功能接口（含全选
export function ToggleCartCheckedData(params) {
  return instance({
    url: '/cart/checked',
    method: 'post',
    data: params
  })
}
