import request from '@/utils/request'

// 查询列表个数
export function getSearchTypeAndCount(fileName) {
  return request({
    url: `/bff-dp-mobile/global-search/count-dealer?fileName=${fileName}`,
    method: 'get'
  })
}

// 查询某个分类下的具体列表
export function getListByType(query) {
  return request({
    url: `/bff-dp-mobile/global-search/dealer-list`,
    method: 'get',
    params: query
  })
}
