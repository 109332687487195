<template>
  <div class="search-products-box">
    <van-dropdown-menu>
      <van-dropdown-item disabled title="全部" />

      <!-- van-dropdown-item的change自带回调参数value -->
      <van-dropdown-item v-model="value2" :options="option2" title="价格排序" @change="priceChange" />
      <van-dropdown-item v-model="value3" :options="filterCategory" title="分类" @change="categoryChange" />
    </van-dropdown-menu>

    <!-- 如有没有搜索到商品显示 empty-->
    <van-empty v-if="goodsList.length == 0" class="custom-image" image="search" description="抱歉,没有搜索到商品" />

    <!-- 引入下方产品展示组件，如果搜索到商品显示 -->
    <Products :goodsList="goodsList"></Products>
  </div>
</template>

<script>
import Products from '@/components/Products'
export default {
  name: 'search-products',
  data() {
    return {
      value2: 'desc',
      option2: [
        { text: '价格由高到低', value: 'desc' },
        { text: '价格由低到高', value: 'asc' }
      ]
    }
  },
  props: ['filterCategory', 'goodsList'],
  methods: {
    priceChange(value) {
      console.log('价格' + value)
      //   在子组件中触发自定义事件，给父组件传递参数
      this.$emit('priceChange1', this.value2)
    },
    categoryChange(value) {
      console.log('类别id' + value)
      //   在子组件中触发自定义事件，给父组件传递参数
      this.$emit('categoryChange1', value)
    }
  },
  components: {
    Products
  },
  computed: {
    value3: {
      get() {
        let val = ''
        this.filterCategory.forEach(item => {
          if (item.checked) {
            val = item.value
          }
        })
        return val
      },
      set(value) {
        console.log(value)
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
