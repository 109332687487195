<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      headerTitle="KPI绩效考核结果"
      :queryUrl="queryUrl"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
      :is-need-operate="false"
      :forcePDF="true"
    >
      <template #column-item="scope">
        <div class="item" @click="onCardClick(scope.row)">
          <div class="name">
            {{ scope.row.year }}_{{ scope.row.baValue | regionFilter(baOptions) }}_{{
              scope.row['productLineValue'] ? scope.row.productLineValue + '_' : ''
            }}KPI考核
            <!-- <span>周期：{{ scope.row['periodValue'] || '-' }}</span>
            <span>KPI类型：{{ scope.row.baValue | regionFilter(baOptions) }} </span>
            <span>产品线：{{ scope.row['productLineValue'] || '-' }} </span> -->
          </div>
        </div>
      </template>
    </form-table>
  </div>
</template>
<script>
import { getDicts } from '@/api/dict/data'
import get from 'lodash/get'
import find from 'lodash/find'
import { listTableOptions, queryUrl, searchFormData } from './config'
import { dataCenter } from '@/utils/dataCenter'
export default {
  data() {
    return {
      //单个
      queryUrl: queryUrl,
      tableOptions: listTableOptions,
      searchFormData: searchFormData,
      searchValue: '',
      filterDisplay: false,
      searchParams: {
        kpiAssessmentName: '',
        year: '',
        periodValue: '',
        baValue: ''
      },
      filter: {
        kpiAssessmentName: '',
        year: '',
        periodValue: '',
        baValue: ''
      },
      pagination: {
        total: 0,
        pageNum: 0,
        pageSize: 10
      },
      baOptions: [],
      periodOptions: [],
      productLineOptions: [],
      pickerOptions: {
        display: false,
        columns: [],
        key: ''
      },
      fileDataList: [],
      loading: false,
      finished: false
    }
  },
  created() {
    this.initDicts()
  },
  mounted() {},
  filters: {
    regionFilter(value, sys_region) {
      let arr = sys_region.filter(item => item.dictValue == value)
      if (arr.length > 0) {
        return arr[0].dictLabel
      } else {
        return value
      }
    }
  },
  methods: {
    initDicts() {
      getDicts('ba_type').then(res => {
        this.baOptions = res.data
        this.tableOptions.map(item => {
          if (item.dictCode === 'ba_type') {
            item.options = res.data
          }
        })
      })
      getDicts('period_value').then(res => {
        this.periodOptions = res.data
      })
      getDicts('sys_product_line').then(res => {
        this.productLineOptions = res.data
      })
    },
    regionFilter(value) {
      let arr = this.baOptions.filter(item => item.dictValue == value)
      if (arr.length > 0) {
        return arr[0].dictLabel
      } else {
        return value
      }
    },
    onCardClick(raw) {
      let fileName = `${raw.year}_${get(find(this.baOptions, { dictValue: raw.baValue }), 'dictLabel')}_${
        raw.productLineValue ? raw.productLineValue + '_' : ''
      }KPI考核`
      this.$router.push({
        path: `/dataCenter/kpi/kpiScore/dear/detail/${raw.kpiAssessmentId}`,
        query: {
          title: encodeURIComponent(fileName),
          period: get(find(this.periodOptions, { dictValue: raw.periodValue }), 'dictLabel'),
          baValue: raw.baValue,
          version: raw.version || 2
        }
      })
    },
    onSelect(text, queryParams, list) {
      dataCenter.downloadFile(list)
    }
  }
}
</script>
<style scoped lang="less">
.item {
  color: #0b41cd;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  justify-content: space-between;
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.name {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
