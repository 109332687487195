<template>
  <div>
    <van-dialog v-model="kpiDetailShow" class="detail-dialog">
      <div class="sub-title">
        <span class="label gray">KPI名称:</span>
        <span class="content">
          {{ form.title }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">产品线<span style="color: #0b41cd">*</span>:</span>
        <span class="content">
          {{ !form.productLineName || form.productLineName == 'null' ? '-' : form.productLineName }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">时间<span style="color: #0b41cd">*</span>:</span>
        <span class="content">
          {{ form.info }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">考核类目<span style="color: #0b41cd">*</span>:</span>
        <span class="content">
          {{ path }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">申诉描述<span style="color: #0b41cd">*</span>:</span>
        <span class="content">
          {{ form.appealContent || '-' }}
        </span>
      </div>
      <div class="sub-title">
        <span class="label gray">申诉附件:</span>
        <span
          class="content"
          style="color: rgb(12, 65, 205)"
          v-if="form.fileId"
          @click="showDetail(form.fileId, form.fileName)"
        >
          {{ form.fileName }}
        </span>
        <span class="content" v-else>-</span>
      </div>

      <div class="sub-title">
        <span class="label gray">反馈备注:</span>
        <span class="content">
          {{ form.comment || '-' }}
        </span>
      </div>

      <div class="sub-title">
        <span class="label gray">反馈附件:</span>
        <span
          class="content"
          style="color: rgb(12, 65, 205)"
          v-if="form.approveFileId"
          @click="showDetail(form.approveFileId, form.approveFileName)"
        >
          {{ form.approveFileName }}
        </span>
        <span class="content" v-else>-</span>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { previewFile } from '@/api/formTable'
import { getDicts } from '@/api/dict/data'
import { getAppealPeriod, getIndicatorDetail, getKpiAppealDetailByTaskId } from '@/api/kpi/performacseAppraisal'
export default {
  name: 'Details',
  dicts: [],
  components: {},
  data() {
    return {
      form: {},
      kpiDetailShow: false,
      indicatorDetailList: [],
      ba_type: [],
      appealPeriodList: [],
      path: ''
    }
  },
  created() {
    getDicts('ba_type').then(({ data }) => {
      this.ba_type = data
    })
  },
  computed: {},
  mounted() {},
  methods: {
    init(detailsInfo) {
      this.kpiDetailShow = true
      this.form = detailsInfo
      this.path = ''
      this.getDetail(detailsInfo)
    },
    async getDetail(row) {
      this.form = {}
      this.baValue = row.baValue

      this.loading = true
      await getKpiAppealDetailByTaskId(row.taskId, row.processInstanceId).then(res => {
        this.loading = false
        this.form = res.data
        this.form.info = '截至' + res.data.periodValue
        let baValue = _.get(_.find(this.ba_type, { dictValue: this.form.baValue }), 'dictLabel')
        this.form.title = `${this.form.year}_${baValue ? baValue + '_' : ''}${
          this.form.productLineValue ? this.form.productLineValue + '_' : ''
        }KPI考核`

        if (typeof this.form.kpiTemplateIndicatorId !== 'string') return
        this.targetValue = this.form.kpiTemplateIndicatorId
        this.findLevel(this.indicatorDetailList, 1)
        var array = new Array(this.targetLevel).fill(null)
        array[array.length - 1] = this.targetValue
        this.form.kpiTemplateIndicatorId = array
      })
      await this.getList(this.form.kpiAssessmentId, this.form.kpiTemplateId)
    },
    getList(id, kpiTemplateId) {
      getIndicatorDetail(null, kpiTemplateId).then(({ data }) => {
        this.indicatorDetailList = data || []
        this.indicatorDetailListThree = _.cloneDeep(this.indicatorDetailList)
        this.indicatorDetailListTwo = _.cloneDeep(this.indicatorDetailList)
        this.indicatorDetailListTwo.forEach(level1 => {
          // 遍历二级标签
          level1.children.forEach(level2 => {
            // 如果有子标签
            if (level2.children) {
              // 将二级标签的value设置为第一个子标签的value
              level2.value = level2.children[0].value
              // 移除子标签
              delete level2.children
            }
          })
        })
        if (this.form.info == '截至当季度违反情况') {
          // this.indicatorDetailList = this.indicatorDetailListTwo
          this.getPath(this.indicatorDetailListTwo, this.form.kpiTemplateIndicatorId)
        } else {
          // this.indicatorDetailList = this.indicatorDetailListThree
          this.getPath(this.indicatorDetailListThree, this.form.kpiTemplateIndicatorId)
        }
      })
    },
    findLevel(data, level) {
      if (!data) {
        return
      }
      for (let i = 0; i < data.length; i++) {
        console.log(data[i].label, data[i].value)
        if (data[i].value == this.targetValue) {
          this.targetLevel = level
          return
        }

        if (data[i].children) {
          this.findLevel(data[i].children, level + 1)
        }
      }
    },
    showDetail(fileId, fileName) {
      if (fileId === null || fileId === '' || fileName === null || fileName === '') {
        return
      }
      let params = {
        fileId: fileId,
        watermarkFlag: true
      }
      previewFile(params, fileName)
    },
    getPath(data, id) {
      function findLabelById(data, id) {
        for (const node of data) {
          if (node.value === id) {
            return node.label
          }
          if (node.children) {
            const result = findLabelById(node.children, id)
            if (result) {
              return `${node.label}-${result}`
            }
          }
        }
        return null // 如果未找到对应 id 的节点，则返回 null
      }
      this.path = findLabelById(data, id)
    }
  }
}
</script>
<style scoped lang="less">
.sub-title {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: 14px;
  margin-bottom: 10px;
  color: rgba(20, 20, 20, 1);
  .label {
    padding-right: 10px;
    &.gray {
      color: #8c8c8c;
      font-size: 12px;
    }
  }
  .content {
    flex: 1;
    display: flex;
    padding-right: 0;
    align-items: flex-start;
    text-align: right;
    justify-content: flex-end;
    > .text {
      flex: 1;
      color: rgba(9, 52, 164, 1);
      display: flex;
      justify-content: flex-end;
    }
  }
}
.detail-dialog {
  padding: 20px 15px 0;
  .van-row {
    width: 80%;
    margin: 0 auto 10px;
    border-bottom: 1px solid rgba(235, 237, 240, 1);
    &:last-child {
      margin-bottom: 0;
    }
    .van-cell {
      padding: 0 10px;
    }
  }
}
</style>
