<template>
  <div>
    <van-dialog v-model="cibDetailShow" class="detail-dialog">
      <p class="margin-bottom">经销商公司信息变更详情</p>
      <div class="sub-title before">
        <span class="label gray">原有电话:</span>
        <span class="content">
          {{ form.phoneNumber }}
        </span>
      </div>
      <div class="sub-title after">
        <span class="label gray">变更电话:</span>
        <span class="content" :class="{ org: form.appealPhoneNumber != form.phoneNumber }">
          {{ form.appealPhoneNumber }}
        </span>
      </div>
      <div class="sub-title before">
        <span class="label gray">原有经销协议地址（即DA地址）:</span>
        <span class="content">
          {{ form.address }}
        </span>
      </div>
      <div class="sub-title after">
        <span class="label gray">变更经销协议地址（即DA地址）:</span>
        <span class="content" :class="{ org: form.appealAddress != form.address }">
          {{ form.appealAddress }}
        </span>
      </div>
      <div class="sub-title before">
        <span class="label gray">原有经销协议地址所在地邮编:</span>
        <span class="content">
          {{ form.postCode }}
        </span>
      </div>
      <div class="sub-title after">
        <span class="label gray">变更经销协议地址所在地邮编:</span>
        <span class="content" :class="{ org: form.appealPostCode != form.postCode }">
          {{ form.appealPostCode }}
        </span>
      </div>
      <div class="sub-title before">
        <span class="label gray">原有协议寄件地址:</span>
        <span class="content">
          {{ form.mailingAddress }}
        </span>
      </div>
      <div class="sub-title after">
        <span class="label gray">变更协议寄件地址:</span>
        <span class="content" :class="{ org: form.appealMailingAddress != form.mailingAddress }">
          {{ form.appealMailingAddress }}
        </span>
      </div>

      <div class="sub-title">
        <span class="label gray">上传附件:</span>
        <span
          class="content"
          style="color: rgb(12, 65, 205)"
          v-if="form.fileId"
          @click="showDetail(form.fileId, form.fileName)"
        >
          {{ form.fileName }}
        </span>
        <span class="content" v-else>-</span>
      </div>

      <div class="sub-title">
        <span class="label gray">审批状态:</span>
        <span class="content">
          {{ state || '-' }}
        </span>
      </div>
      <div class="sub-title" v-if="rejectReason">
        <span class="label gray">审批备注:</span>
        <span class="content">
          {{ rejectReason || '-' }}
        </span>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { previewFile } from '@/api/formTable'
import { getDicts } from '@/api/dict/data'
import { getAppealDetailByTaskIdForCompanyInfo } from '@/api/userCenter/dealer'
export default {
  name: 'Details',
  dicts: [],
  components: {},
  data() {
    return {
      form: {},
      cibDetailShow: false,
      sys_product_line: [],
      rejectReason: '',
      state: ''
    }
  },
  created() {
    getDicts('sys_product_line').then(({ data }) => {
      this.sys_product_line = data
    })
  },
  filters: {
    getProductLabel(value, sys_product_line) {
      let arr = sys_product_line.filter(item => item.value == value)
      if (arr.length > 0) {
        return arr[0].label
      } else {
        return value
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    init(detailsInfo) {
      this.cibDetailShow = true
      this.form = detailsInfo
      this.getDetail(detailsInfo)
    },
    async getDetail(row) {
      getAppealDetailByTaskIdForCompanyInfo(row.appealTaskId, row.processInstanceId)
        .then(res => {
          if (res.code == 200 && res.data) {
            let state = ''
            switch (res.data.appealStatus) {
              case '0':
                state = '待审批'
                break
              case '1':
                state = '已同意'
                break
              case '2':
                state = '已拒绝'
                break
              case '3':
                state = '已撤销'
                break
              default:
                break
            }
            this.state = state
            this.rejectReason = res.data.rejectReason
            this.form = res.data
          }
        })
        .catch(error => {})
    },
    showDetail(fileId, fileName) {
      if (fileId === null || fileId === '' || fileName === null || fileName === '') {
        return
      }
      let params = {
        fileId: fileId,
        watermarkFlag: true
      }
      previewFile(params, fileName)
    }
  }
}
</script>
<style scoped lang="less">
.sub-title {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: 14px;
  padding: 10px 5px;
  color: rgba(20, 20, 20, 1);
  .label {
    padding-right: 10px;
    &.gray {
      color: #8c8c8c;
      font-size: 12px;
    }
    &.red {
      color: red;
      font-size: 12px;
    }
  }
  .content {
    flex: 1;
    display: flex;
    padding-right: 0;
    align-items: flex-start;
    text-align: right;
    justify-content: flex-end;
    > .text {
      flex: 1;
      color: rgba(9, 52, 164, 1);
      display: flex;
      justify-content: flex-end;
    }
  }
}
.detail-dialog {
  padding: 20px 15px 0;
  .van-row {
    width: 80%;
    margin: 0 auto 10px;
    border-bottom: 1px solid rgba(235, 237, 240, 1);
    &:last-child {
      margin-bottom: 0;
    }
    .van-cell {
      padding: 0 10px;
    }
  }
}
.margin-bottom {
  margin-bottom: 10px;
}
.before {
  background: #f5f5f5;
}
.after {
  background: #f0f4fd;
}
.line {
  width: 80%;
  background: #000;
  height: 1px;
}
::v-deep {
  .van-dialog__content {
    max-height: 70vh;
    overflow: scroll;
  }
}
.org {
  color: #ff7016;
}
</style>
