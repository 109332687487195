<template>
  <div class="container">
    <div class="bg" />
    <van-tabs
      v-show="showPendingTasks || showCompletedTasks || showPublishedTasks"
      v-model="active"
      class="tab-new"
      @change="tabChange"
    >
      <van-tab v-if="showPublishedTasks" title="已发任务" name="publishedTasks">
        <published-tasks ref="publishedTasks" />
      </van-tab>
      <van-tab v-if="showPendingTasks" title="待办任务" name="pendingTasks">
        <pending-tasks ref="pendingTasks" />
      </van-tab>
      <van-tab v-if="showCompletedTasks" title="已办任务" name="completedTasks">
        <completed-tasks ref="completedTasks" />
      </van-tab>
    </van-tabs>
    <div class="empty" v-show="!showPendingTasks && !showCompletedTasks && !showPublishedTasks">暂无可用数据</div>
  </div>
</template>

<script>
import PendingTasks from './pendingTasks/pendingTasks'
import CompletedTasks from './completedTasks/completedTasks'
import PublishedTasks from './publishedTasks/publishedTasks'
import { getSelectedDealer } from '@/utils/auth'
export default {
  name: 'Task',
  components: { PublishedTasks, CompletedTasks, PendingTasks },
  data() {
    return {
      active: 'publishedTasks',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      timer: null,
      dealerClone: getSelectedDealer(),
      showCompletedTasks: true,
      showPendingTasks: true,
      showPublishedTasks: true,
      editAble: false
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        // if (to.params.page === 'ST') {
        //   this.active = 'students'
        //   this.$refs.student.onRefresh()
        // } else if (to.params.page === 'Account') {
        //   this.active = 'dealerAccount'
        //   this.$refs.dealerAccount.onRefresh()
        // } else if (to.params.page === 'Contact') {
        //   this.active = 'dealerContact'
        //   this.$refs.dealerContactList.onRefresh()
        // }
      }
    }
  },
  created() {
    this.filterTabs()
  },
  methods: {
    tabChange() {
      switch (this.active) {
        case 'publishedTasks':
          // this.$refs.publishedTasks.onRefresh()
          break
        case 'completedTasks':
          //  this.$refs.completedTasks.onRefresh()
          break
        case 'pendingTasks':
          //  this.$refs.pendingTasks.onRefresh()
          break
      }
    },
    filterTabs() {
      const menus = this.$store.state.user.routes
      this.showCompletedTasks = menus.includes('已办任务')
      this.showPendingTasks = menus.includes('待办任务')
      this.showPublishedTasks = menus.includes('已发任务')
      this.active = this.showPublishedTasks
        ? 'publishedTasks'
        : this.showCompletedTasks
        ? 'completedTasks'
        : 'pendingTasks'
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 80vh;
}
.bg {
  background: linear-gradient(268deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
  width: 100vw;
  height: 200vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.dotClass {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: block;
  margin-top: 8px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.notice-type {
  background-color: #ced9f5;
  color: #0b41cd;
  font-size: 12px;
  padding: 0px 5px;
  border-radius: 4px;
  min-width: 50px;
}

.notice-title {
  padding: 0px 10px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #0b41cd;
}

.divNormal {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #8c8c8c;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

.divActive {
  border-radius: 4px;
  background-color: #0b41cd;
  border: 1px solid #0b41cd;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

/deep/.van-tabs__nav {
  background: transparent;
}
/deep/.van-tabs--line .van-tabs__wrap {
  width: 80%;
  padding-top: 17px;
}
/deep/.van-tab__text--ellipsis {
  line-height: 1.75;
}
/deep/.van-tab {
  flex: 0.33;
  font-size: 16px;
  &.van-tab--active {
    font-size: 18px;
    span {
      font-weight: bold;
    }
  }
}
.empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  transform: translateY(40vh);
  color: #969799;
}
</style>
