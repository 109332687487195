<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
    >
    </form-table>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { dataCenter } from '@/utils/dataCenter'
import { getDicts } from '@/api/dict/data'
export default {
  name: 'DemoList',
  data() {
    return {
      //单个
      btnData: [{ text: '下载' }],
      //多个
      //   btnData: [{ text: '下载' }, { text: '新增' }],
      searchFormData: [
        { props: 'fileName', label: '文件名', type: 'input' },
        {
          props: 'year',
          label: '年份',
          width: '120px',
          type: 'date',
          valueFormat: 'yyyy',
          dateFormat: 'yyyy',
          dateType: 'year'
        },
        {
          props: 'baValue',
          label: 'KPI类型',
          type: 'DictSelect',
          dictCode: 'ba_type',
          multiple: false,
          customChange: data => {
            if (data.baValue == 2 || data.baValue === '运营') {
              data.productLineCode = null
            }
            return data
          }
        },
        {
          props: 'productLineCode',
          label: '产品线',
          type: 'DictSelect',
          dictCode: 'sys_product_line',
          multiple: false,
          disableRule: data => {
            return data.baValue == '2' || data.baValue === '运营'
          }
        }
      ],
      tableOptions: [
        {
          prop: 'fileName',
          label: 'KPI政策文件',
          type: 'fileName',
          rightTop: {
            left: {
              prop: 'year',
              label: '年份'
            }
          },
          rightBottom: { prop: 'productLineLabel', label: '产品线', dictCode: 'sys_product_line', options: [] }
        },
        // {
        //   prop: 'productLineLabel',
        //   label: '产品线'
        // },
        // {
        //   prop: 'year',
        //   label: '年份'
        // },
        {
          prop: 'baValue',
          dictCode: 'ba_type',
          label: 'KPI类型',
          options: []
        },
        {
          prop: 'createTime',
          label: '上传时间'
        }
      ],
      headerTitle: 'KPI绩效政策',
      queryUrl: '/bff-dp-mobile/kpi-policy/dealer-list',
      deleteUrl: '/kpi-policy/delete'
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    async setOptions() {
      const options = await getDicts('sys_product_line').then(res => {
        let list = []
        if (res.code === 200 && res.data.length > 0) {
          list = res.data
        }
        return list
      })
      this.tableOptions[0].rightBottom.options = options
      getDicts('ba_type').then(({ data }) => {
        let baType = cloneDeep(data)
        baType = baType
          .map(item => {
            if (item.dictValue != '1') {
              return item
            }
          })
          .filter(item => item)
        this.tableOptions[1].options = data
        this.searchFormData[2].options = baType
        console.log(baType)
      })
    },
    handleDelete(obj) {
      this.$dialog
        .confirm({
          title: '删除',
          message: '是否确认删除该文件？'
        })
        .then(() => {
          return dataCenter.delete(this.deleteUrl, obj)
        })
        .then(() => {
          this.$refs.baseForm.onRefresh()
          this.$toast.success('删除成功')
        })
        .catch(() => {
          // on cancel
        })
    },
    onSelect(text, queryParams, list) {
      console.log(text, queryParams)
      this.daowload(list)
    },
    daowload(list) {
      dataCenter.downloadFile(list)
    }
  }
}
</script>

<style></style>
