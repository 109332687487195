export const queryUrl = '/bff-dp-mobile/task-flow/my-process-list'
export const tableOptions = [
  {
    prop: 'processInstanceName',
    label: '流程名称',
    type: 'fileName',
    hideFileIcon: true
  },
  //  {
  //   prop: 'processInstanceName',
  //   label: '流程名称',
  //   type: 'fileName',
  //   height: 'auto',
  //   hideFileIcon: true,
  //   'show-overflow-tooltip': true
  // },
  // {
  //   prop: 'processInstanceId',
  //   label: '流程ID',
  //   'show-overflow-tooltip': true,
  //   type: 'fileName',
  //   height: 'auto',
  //   hideFileIcon: true
  // },
  // {
  //   prop: 'status',
  //   'show-overflow-tooltip': true,
  //   label: '状态',
  //   type: 'fileName',
  //   cardTitleRight: 'status',
  //   height: 'auto',
  //   hideFileIcon: true
  // },
  {
    prop: 'nodeName',
    label: '当前任务节点',
    'show-overflow-tooltip': true
  },
  {
    prop: 'startTime',
    label: '发起时间',
    'show-overflow-tooltip': true
  },
  {
    prop: 'endTime',
    label: '完成时间',
    'show-overflow-tooltip': true
  },
  {
    prop: 'reason',
    'show-overflow-tooltip': true,
    label: '备注'
  }
]

export const searchFormData = [
  {
    props: 'processInstanceName',
    label: '流程名称',
    type: 'input'
  },
  {
    props: 'status',
    label: '状态',
    type: 'DictSelect',
    dictCode: 'process_result_status',
    multiple: false
  },
  {
    props: 'startTime',
    label: '发起时间',
    width: '120px',
    type: 'dateTime'
  }
]
