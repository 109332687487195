import request from '@/utils/request'

// 查询收到通知列表
export function receiveNoticeList(query) {
  return request({
    url: '/bff-dp-mobile/station-notice/receive-list',
    method: 'get',
    params: query
  })
}

// 通知详情
export function getNoticeDetail(query) {
  return request({
    url: '/bff-dp-mobile/station-notice/detail/' + query.noticeId,
    method: 'get'
  })
}

export function readNotice(query) {
  return request({
    url: '/bff-dp-mobile/station-notice/read/' + query.noticeId,
    method: 'get'
  })
}

//待办日历
export function getCalendarData(query) {
  return request({
    url: '/bff-dp-mobile/calendar/remind-list',
    method: 'get',
    params: query
  })
}

export function getCalendarEvent(query) {
  return request({
    url: '/bff-dp-mobile/station-notice/feedback-notice',
    method: 'get',
    params: query
  })
}
