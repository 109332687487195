<template>
  <div class="app-container">
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      :hide-btn-with-empty-data="true"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
    >
    </form-table>
  </div>
</template>

<script>
import { dataCenter } from '@/utils/dataCenter'
import { queryUrl, searchFormData, tableOptions } from './config'
import { getDicts } from '@/api/dict/data'
export default {
  name: 'ShareDocument',
  data() {
    return {
      btnData: [{ text: '下载' }],
      headerTitle: '共享文档',
      searchFormData: searchFormData,
      tableOptions: tableOptions,
      queryUrl: queryUrl
    }
  },
  created() {
    this.getDictData()
  },
  methods: {
    async getDictData() {
      const departmentOptions = await getDicts('cc_shared_document_department').then(({ data }) => {
        return data
      })
      const fileTypeOptions = await getDicts('cc_shared_document_file_type').then(({ data }) => {
        return data
      })
      this.tableOptions.forEach(element => {
        if (element.prop === 'departCode') {
          element.options = [...departmentOptions]
        } else if (element.prop === 'fileType') {
          element.options = [...fileTypeOptions]
        }
      })
    },
    onSelect(text, queryParams, list) {
      this.download(list)
    },
    download(list) {
      dataCenter.downloadFile(list)
    }
  }
}
</script>
