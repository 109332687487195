import request from '@/utils/request'

export function getAppealPeriod(kpiAssessmentId, productLineName) {
  return request({
    url: '/bff-dp-mobile/kpi-assessmentResult/getAppealPeriod',
    method: 'get',
    params: { kpiAssessmentId, productLineName }
  })
  // ?kpiAssessmentId=533&productLineName=运营
}

export function getIndicatorDetail(kpiAssessmentId, kpiTemplateId) {
  let params = ''

  if (kpiAssessmentId) {
    params = `kpiAssessmentId=${kpiAssessmentId}`
  }
  if (kpiTemplateId) {
    params = `kpiTemplateId=${kpiTemplateId}`
  }
  let url = `/bff-dp-mobile/kpi-assessmentResult/getIndicatorDetail?${params}`
  return request({
    url: url,
    method: 'get'
  })
  // return request({
  //   url: '/bff-dp-mobile/kpi-assessmentResult/getIndicatorDetail',
  //   method: 'get',
  //   params: { kpiAssessmentId }
  // })
  // ?kpiAssessmentId=533
}

// 根据taskId获取申诉内容【get】/kpi-assessmentResult/getAppealDetail?taskId=215069
export function getKpiAppealDetailByTaskId(taskId, processInstanceId) {
  let params = ''

  if (processInstanceId) {
    params = `processInstanceId=${processInstanceId}`
  }
  if (taskId) {
    params = `taskId=${taskId}`
  }

  return request({
    url: `/bff-dp-mobile/kpi-assessmentResult/getAppealDetail?${params}`,
    method: 'get'
  })
}
