<template>
  <div class="container">
    <div class="bg" />
    <van-tabs
      v-show="showDealerContact || showDealerAccount || showStudent"
      v-model="active"
      class="tab-new"
      @change="tabChange"
      :before-change="beforeChange"
    >
      <van-tab v-if="showDealerAccount" title="账号管理" name="dealerAccount">
        <dealer-account ref="dealerAccount" />
      </van-tab>
      <!--      <van-tab v-if="showDealerContact" title="联络人管理" name="dealerContact">-->
      <!--        <dealer-contact-list ref="dealerContactList" @editAble="handleEditAble" />-->
      <!--      </van-tab>-->
      <van-tab v-if="showStudent" title="学员管理" name="students">
        <students ref="student" />
      </van-tab>
    </van-tabs>
    <div class="empty" v-show="!showDealerContact && !showDealerAccount && !showStudent">暂无可用数据</div>
  </div>
</template>

<script>
import get from 'lodash/get'
import DealerContactList from './dealerContact'
import DealerAccount from './dealerAccount'
import Students from './students'
import { getSelectedDealer } from '@/utils/auth'
import { listDealerAllForCMCL } from '@/api/userCenter/dealer'
import { getDealer } from '@/api/login'
export default {
  name: 'Notice',
  components: { DealerContactList, Students, DealerAccount },
  data() {
    return {
      active: '',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      queryParams: {
        pageNum: 0,
        pageSize: 10
      },
      timer: null,
      dealerClone: getSelectedDealer(),
      showDealerAccount: true,
      showDealerContact: true,
      showStudent: true,
      editAble: false
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        if (to.params.page === 'ST') {
          this.active = 'students'
          this.$refs.student.onRefresh()
        } else if (to.params.page === 'Account') {
          this.active = 'dealerAccount'
          this.$refs.dealerAccount.onRefresh()
        } else if (to.params.page === 'Contact') {
          this.active = 'dealerContact'
          this.$refs.dealerContactList.onRefresh()
        }
      }
    },
    channelType(val) {
      if (val == 'Account') {
        this.active = 'dealerAccount'
      }
      if (val == 'Student') {
        this.active = 'students'
      }
    }
  },
  computed: {
    channelType() {
      return decodeURIComponent(get(this.$route, 'query.channelType', ''))
    }
  },
  created() {
    this.onLoad()
    if (this.dealerClone) {
      this.getDetailInfo()
    }
    this.filterTabs()
    if (this.channelType == 'Student') {
      this.active = 'students'
    }
  },
  methods: {
    handleEditAble(val) {
      this.editAble = val
    },
    getDetailInfo() {
      listDealerAllForCMCL().then(response => {
        this.loading = false
        let info = response.data?.filter(item => item.orgName == this.dealerClone)
        if (info.length > 0) {
          getDealer(info[0].orgId).then(res => {
            this.$store.commit('SET_BASEINFO', res[0])
          })
        }
      })
    },
    onLoad() {
      this.loading = true
      // 设置定时器做节流处理，避免滚动时频繁触发加载事件
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.queryParams.pageNum++
          if (this.active == 'dealerContact') {
            this.$refs.dealerContactList.onLoad()
          } else {
            this.getList()
          }
          // 清除定时器，释放内存
          this.timer = null
        }, 1000)
      }
    },
    getList() {},
    beforeChange(index) {
      const eidtState = this.$store.state.mode.editing
      console.log(eidtState)
      if (eidtState) {
        this.active = 'dealerAccount'
        this.$refs.dealerAccount.changeDealer(() => {
          this.active = index
        })
        return false
      }
      this.$store.commit('SET_EDITING', false)
      return true
    },
    tabChange() {
      if (this.active == 'dealerContact') {
        this.$nextTick(() => {
          this.$refs.dealerContactList.tabChange()
        })
      } else {
        // 清空列表数据
        this.finished = false
        // 重新加载数据
        this.queryParams = {
          pageNum: 0,
          pageSize: 10
        }
        this.total = 0
        this.list = []
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true
        this.onLoad()
      }
    },
    filterTabs() {
      const menus = this.$store.state.user.routes
      this.showDealerAccount = menus.includes('账号管理')
      this.showDealerContact = menus.includes('经销商联络人管理')
      this.showStudent = menus.includes('学员信息管理')
      this.active = this.showDealerAccount ? 'dealerAccount' : this.showStudent ? 'students' : 'dealerContact'
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 80vh;
}
.bg {
  background: linear-gradient(268deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
  width: 100vw;
  height: 200vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.dotClass {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: block;
  margin-top: 8px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.notice-type {
  background-color: #ced9f5;
  color: #0b41cd;
  font-size: 12px;
  padding: 0px 5px;
  border-radius: 4px;
  min-width: 50px;
}

.notice-title {
  padding: 0px 10px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #0b41cd;
}

.divNormal {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #8c8c8c;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

.divActive {
  border-radius: 4px;
  background-color: #0b41cd;
  border: 1px solid #0b41cd;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

/deep/.van-tabs__nav {
  background: transparent;
}
/deep/.van-tabs--line .van-tabs__wrap {
  width: 80%;
  padding-top: 17px;
}
/deep/.van-tab__text--ellipsis {
  line-height: 1.75;
}
/deep/.van-tab {
  flex: 0.33;
  font-size: 16px;
  &.van-tab--active {
    font-size: 18px;
    span {
      font-weight: bold;
    }
  }
}
.empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  transform: translateY(40vh);
  color: #969799;
}
</style>
