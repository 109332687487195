export const queryUrl = '/bff-dp-mobile/product-attachment/dealer-attachment-list'
export const moduleType = 'productDocument'
export const tableOptions = [
  {
    prop: 'fileName',
    label: '文件名',
    type: 'fileName'
  },
  {
    prop: 'productNumber',
    label: '产品编号'
  },
  {
    prop: 'productCnName',
    label: '产品中文名称'
  },
  {
    prop: 'productLine',
    label: '产品线',
    dictCode: 'sys_product_line'
  },
  {
    prop: 'uploadTime',
    label: '上传时间'
  }
]

export const searchFormData = [
  { props: 'fileName', label: '文件名', type: 'input' },
  {
    props: 'productNumber',
    label: '产品编号',
    type: 'input'
  },
  {
    props: 'productCnName',
    label: '产品中文名称',
    type: 'input'
  },
  {
    props: 'productLine',
    label: '产品线',
    type: 'DictSelect',
    dictCode: 'sys_product_line',
    multiple: false
  }
]
