import request from '@/utils/request'

// 查询联络人列表/contacts/list?pageNum=1&pageSize=10
export function listContacts(query) {
  return request({
    url: '/bff-dp-mobile/dealer-contacts/list',
    method: 'get',
    params: query
  })
}

// 修改联络人
export function updateDealerContact(data) {
  return request({
    url: '/bff-dp-mobile/dealer-contacts/edit',
    method: 'post',
    data: data
  })
}

// 查询联络人详细
export function getDealerContact(dealerContactId) {
  return request({
    url: '/bff-dp-mobile/dealer-contacts/info/' + dealerContactId,
    method: 'get'
  })
}

// 新增联络人
export function addDealerContact(data) {
  return request({
    url: '/bff-dp-mobile/dealer-contacts/add',
    method: 'post',
    data: data
  })
}

// 删除联络人
export function delDealerContact(data) {
  return request({
    url: '/bff-dp-mobile/dealer-contacts/remove/',
    method: 'post',
    data: data
  })
}

//变更申请/contacts/edit-clm
export function dealerChagne(data) {
  return request({
    url: '/bff-dp-mobile/dealer-contacts/edit-clm',
    method: 'post',
    data: data
  })
}

//查询未绑定的经销商账号
export function getUnbindAccount(query) {
  return request({
    url: '/user/dealerUser/dealer-account-list',
    method: 'get',
    params: query
  })
}

//授权经销商账号
export function authAccount(data) {
  return request({
    url: '/bff-dp-mobile/dealer-contact-user-mapping/create',
    method: 'post',
    data: data
  })
}

//授权经销商账号前的校验
export function checkBeforeAuth(query) {
  return request({
    url: '/bff-dp-mobile/dealer-contact-user-mapping/check-email',
    method: 'get',
    params: query
  })
}

//解绑经销商账号
export function unbindAccount(data) {
  return request({
    url: '/bff-dp-mobile/dealer-contact-user-mapping/unBind',
    method: 'delete',
    params: data
  })
}
