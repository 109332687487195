<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
    >
    </form-table>
  </div>
</template>

<script>
import { dataCenter } from '@/utils/dataCenter'
import { getDicts } from '@/api/dict/data'
export default {
  name: 'DemoList',
  data() {
    return {
      //单个
      btnData: [{ text: '下载' }],
      //多个
      //   btnData: [{ text: '下载' }, { text: '新增' }],
      searchFormData: [
        { props: 'fileName', label: '文件名', type: 'input' },
        {
          props: 'year',
          label: '年份',
          width: '120px',
          type: 'date',
          valueFormat: 'yyyy',
          dateFormat: 'yyyy',
          dateType: 'year'
        },
        {
          props: 'policyTypeValue',
          label: '类型',
          type: 'DictSelect',
          dictCode: 'sys_cib_policy_type',
          multiple: false
        }
      ],
      tableOptions: [
        {
          prop: 'fileName',
          label: 'CIB 政策文件',
          type: 'fileName',
          rightTop: {
            left: {
              prop: 'year',
              label: '年份'
            }
          },
          rightBottom: { prop: 'policyTypeValue', label: '类型', dictCode: 'sys_cib_policy_type', options: [] }
        },
        // {
        //   prop: 'year',
        //   label: '年份'
        // },
        // {
        //   prop: 'policyTypeValue',
        //   label: '类型',
        //   dictCode: 'sys_cib_policy_type'
        // },
        {
          prop: 'createTime',
          label: '上传时间'
        }
      ],
      headerTitle: '激励政策',
      queryUrl: '/bff-dp-mobile/cib-policy/dealer-list',
      deleteUrl: '/cib-policy/delete'
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    async setOptions() {
      const options = await getDicts('sys_cib_policy_type').then(res => {
        let list = []
        if (res.code === 200 && res.data.length > 0) {
          list = res.data
        }
        return list
      })
      this.tableOptions[0].rightBottom.options = options
    },
    handleDelete(obj) {
      this.$dialog
        .confirm({
          title: '删除',
          message: '是否确认删除该文件？'
        })
        .then(() => {
          return dataCenter.delete(this.deleteUrl, obj)
        })
        .then(() => {
          this.$refs.baseForm.onRefresh()
          this.$toast.success('删除成功')
        })
        .catch(() => {
          // on cancel
        })
    },
    onSelect(text, queryParams, list) {
      console.log(text, queryParams)
      this.daowload(list)
    },
    daowload(list) {
      dataCenter.downloadFile(list)
    }
  }
}
</script>

<style></style>
