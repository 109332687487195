<template>
  <div class="list-bg" :class="editMode ? 'edit' : ''">
    <van-popover
      v-if="getCheckPermi('delaer-btn')"
      class="popover"
      placement="bottom-end"
      v-model="showPopover"
      trigger="click"
      :actions="actions"
      @select="onSelect"
    >
      <template #reference>
        <van-icon name="ellipsis" />
      </template>
    </van-popover>
    <van-row v-else class="tab-right-btn" @click="onSelect({ text: '导出' })">
      <van-col class="text">导出</van-col>
    </van-row>
    <van-row class="header" type="flex" justify="space-between">
      <van-col class="total">
        <span>共{{ total }}条数据</span>
      </van-col>
      <div>
        <van-col class="filter" style="margin-right: 5px" @click="changeEditMode" v-if="!editMode && !loading">
          <span>编辑</span><van-icon name="edit" color="#0B41CD" />
        </van-col>
        <van-col class="filter" @click="filterData"
          ><span>筛选</span><van-icon name="filter-o" color="#0B41CD"
        /></van-col>
      </div>
    </van-row>
    <van-notice-bar
      class="bar"
      v-if="editMode"
      left-icon="volume-o"
      text="可点击联络人字段进行编辑，完成后点击“完成”按钮"
    />
    <van-collapse v-model="activeNamesTitle" class="collapse dealerInfoDetail" v-if="!editMode">
      <van-collapse-item :is-link="false" name="1">
        <template #title>
          <h3 class="title" style="cursor: pointer; color: #000" slot="reference" @click="openInfo()">
            {{ dealerClone || dealerInfo.rocheDealerName }}
          </h3>
        </template>
        <template #value>
          <div class="theme-color">
            <span @click.stop="openDealerInfoDetail()">{{
              dealerInfoDetail.isHaveAppeal ? '已提交申请' : '变更申请'
            }}</span>
          </div>
        </template>
        <div class="collapse-content">
          <div style="display: flex" v-if="dealerInfoDetail.state == 'noData'">
            <img class="warning-icon" :src="require('@/assets/images/warning_triangle@2x.png')" />
            <span class="font-dis" style="">暂无相关公司信息，请提交申请至罗氏进行补足</span>
          </div>
          <template v-else>
            <van-row class="divider">
              <van-col class="label-wrapper">
                <van-icon name="phone-o" class="theme-color" />{{
                  (dealerInfoDetail.phonenumber || '暂无信息') | mobileFormat
                }}
              </van-col>
              <van-col class="label-wrapper">
                <van-icon name="envelop-o" class="theme-color" />{{ dealerInfoDetail.postCode || '暂无信息' }}
              </van-col>
            </van-row>
            <van-row class="text-hide1" style="word-break: break-all">
              <van-icon name="location-o" class="theme-color" />{{ dealerInfoDetail.address || '暂无信息' }}
            </van-row>
            <van-row class="text-hide1" style="word-break: break-all">
              <van-icon name="location-o" class="theme-color" /><span class="label theme-color">营业</span
              >{{ dealerInfoDetail.mailingAddress || '暂无信息' }}
            </van-row>
          </template>
        </div>
      </van-collapse-item>
    </van-collapse>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="bg">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-collapse v-model="activeNames" class="collapse">
          <van-checkbox-group v-model="editChecked" shape="square">
            <van-collapse-item :name="i" v-for="(item, i) in contactList" :key="i">
              <template #title>
                <van-row type="flex" align="center" justify="space-between" class="card">
                  <div style="display: flex; align-items: center; width: 60%">
                    <van-col style="color: coral" v-if="editMode">
                      <van-checkbox :name="item.contactId" @click.native.stop></van-checkbox>
                    </van-col>
                    <van-col span="3" style="color: coral; margin: 0 2.5px">
                      <van-icon name="manager" class="icon" />
                    </van-col>
                    <van-col span="24" class="card-text">
                      <van-row class="name">{{ item.contactName || '-' }} </van-row>
                      <van-row class="email text-hide1" v-if="!editMode"
                        ><van-icon name="envelop-o" class="theme-color" />{{ item.email || '-' }}
                      </van-row>
                    </van-col>
                  </div>
                  <van-col span="8" class="card-text">
                    <van-row class="status">
                      <van-row class="diy-cell-value">
                        <span class="table-status" v-if="item.status == 0">
                          <i class="dotClass" style="background-color: springgreen"></i
                          ><span class="custom-title">启用 </span>
                        </span>
                        <span class="table-status" v-if="item.status == 1">
                          <i class="dotClass" style="background-color: red"></i><span class="custom-title">停用</span>
                        </span>
                      </van-row>
                    </van-row>
                    <van-row class="phone" v-if="!editMode">
                      <van-icon name="phone-o" class="theme-color" />{{ item.phonenumber || '-' | mobileFormat }}
                    </van-row>
                  </van-col>
                </van-row>
              </template>
              <div class="collapse-content">
                <van-row class="divider" @click="goToInfo(item)">
                  <van-col span="24"><van-cell title="邮箱" :value="item.email || '-'" /></van-col>
                  <van-col span="24"><van-cell title="手机" :value="item.phonenumber || '-'" /></van-col>
                  <van-col span="24"><van-cell title="职位" :value="item.position || '-'" /></van-col>
                  <van-col span="24"
                    ><van-cell
                      title="联络人组别"
                      :value="
                        sys_contacts_group
                          .filter(itemA => item.contactGroups.includes(itemA.dictValue.toString()))
                          .map(filteredItem => filteredItem.dictLabel)
                          .join('，') || '-'
                      "
                  /></van-col>
                  <van-col span="24"><van-cell title="绑定账号" :value="item.userAccount || '-'" /></van-col>
                  <van-col span="24" v-if="!editMode"
                    ><van-cell title="变更人" :value="item.updateBy || '-'"
                  /></van-col>
                  <van-col span="24" v-if="!editMode"
                    ><van-cell title="变更日期" :value="parseTime(item.updateTime) || '-'"
                  /></van-col>
                </van-row>

                <van-row
                  class="foot-btn"
                  v-has-permission="['delaer-btn']"
                  v-if="editMode && item.processFlag != 'add'"
                >
                  <span v-if="item.contactGroups.includes('0')" @click="goToChange(item)">变更申请</span>
                  <span class="vertical-line" v-if="item.contactGroups.includes('0')">|</span>
                  <span v-if="item.contactGroups.includes('0')" @click="goAuth(item)">绑定账号</span>
                  <span class="vertical-line" v-if="item.contactGroups.includes('0')">|</span>
                  <span v-if="item.contactGroups.includes('0')" @click="goUnbind(item)">解绑账号</span>
                  <span v-if="!item.contactGroups.includes('0')">
                    <!-- <span @click="goToInfo(item.contactId)">编辑</span>
                    <span class="vertical-line">|</span>
                    <span @click="handleDelete(item)">删除</span>
                    <span class="vertical-line">|</span> -->
                    <span @click="goAuth(item)">绑定账号</span>
                    <span class="vertical-line">|</span>
                    <span @click="goUnbind(item)">解绑账号</span>
                  </span>
                </van-row>
              </div>
            </van-collapse-item>
          </van-checkbox-group>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
    <div v-if="editMode" class="edit-btn">
      <van-button class="btn" @click="handleEditCancel">取消</van-button>
      <van-button class="btn" style="color: red" @click="handleDeleteRow"
        >删除<span v-if="editChecked.length > 0">({{ editChecked.length }})</span></van-button
      >
      <van-button class="btn" @click="handleAdd">新增</van-button>
      <van-button class="btn btn-finish" @click="handleEditFinish">完成编辑</van-button>
    </div>
    <van-popup v-model="show" class="popup-box" closeable round position="bottom" @close="cancel">
      <van-cell-group class="group-box">
        <van-field :maxlength="defaultMaxLength" v-model="queryParams.contactName" label="姓名" placeholder="请输入" />
        <van-field :maxlength="defaultMaxLength" v-model="queryParams.phonenumber" label="手机" placeholder="请输入" />
        <van-field :maxlength="defaultMaxLength" v-model="queryParams.email" label="邮箱" placeholder="请输入" />
        <van-field :maxlength="defaultMaxLength" v-model="queryParams.position" label="职位" placeholder="请输入" />
        <van-field
          :maxlength="defaultMaxLength"
          readonly
          clickable
          name="function"
          :value="functionLabel"
          label="联络人组别"
          placeholder="点击选择"
          @click="showPickerFunction = true"
        />
        <van-popup v-model="showPickerFunction" position="bottom">
          <van-picker
            show-toolbar
            value-key="dictLabel"
            :columns="sys_contacts_group"
            @confirm="onConfirmFunctionList"
            @cancel="showPickerFunction = false"
          />
        </van-popup>
      </van-cell-group>
      <van-row class="popup-foot-btn" gutter="20">
        <van-button class="btn margin-lf-10" type="default" @click="reset">重置</van-button>
        <van-button class="btn margin-lf-10" type="info" @click="handleQuery">确定</van-button>
      </van-row>
    </van-popup>
    <van-popup v-model="authShow" class="popup-box2" closeable round position="bottom" @close="cancel2">
      <van-form validate-first ref="authForm">
        <van-cell-group class="group-box">
          <van-field
            readonly
            clickable
            :value="accountLabel"
            label="经销商账号"
            placeholder="点击选择"
            :rules="[{ required: true, message: '请选择经销商账号' }]"
            @click="showPickerAccount = true"
          />
          <van-popup v-model="showPickerAccount" position="bottom">
            <van-picker
              show-toolbar
              value-key="nameLabel"
              :columns="accountList"
              @confirm="onConfirmAccount"
              @cancel="showPickerAccount = false"
            />
          </van-popup>
        </van-cell-group>
      </van-form>
      <van-row class="popup-foot-btn" gutter="20">
        <van-button class="btn margin-lf-10" type="default" @click="reset2">重置</van-button>
        <van-button class="btn margin-lf-10" style="color: #fff" type="info" @click="handleAuth">确定</van-button>
      </van-row>
    </van-popup>
    <info-detail
      v-if="showDetailPop"
      :dealerInfoDetail="info"
      @close="closeShowDetailPop"
      @success="dealerInfoDetail.isHaveAppeal = true"
    />
    <dealer-contact-info
      v-if="showContactDetailPop"
      :contactInfoDetail="contactInfoDetail"
      @close="closeshowContactDetailPop"
      @submit="changeItem"
    ></dealer-contact-info>
  </div>
</template>

<script>
import InfoDetail from './info/infoDetail'
import DealerContactInfo from './info/index'
import { getSelectedDealer } from '@/utils/auth'
import {
  listContacts,
  dealerChagne,
  delDealerContact,
  authAccount,
  getUnbindAccount,
  checkBeforeAuth,
  unbindAccount
} from '@/api/userCenter/dealerContact'
import {
  getDealerDetail,
  editDealer,
  checkBinding,
  getDealerCompanyInfo,
  updateDealerContacts
} from '@/api/userCenter/dealer'
import dayjs from 'dayjs'
import { getDicts } from '@/api/dict/data'
import { checkPermi } from '@/utils/permission'
import _ from 'lodash'

export default {
  name: 'Notice',
  components: {
    InfoDetail,
    DealerContactInfo
  },
  data() {
    return {
      deleteRow: [],
      editChecked: [],
      info: {},
      showDetailPop: false,
      showContactDetailPop: false,
      contactInfoDetail: {},
      showPopover: false,
      actions: [{ text: '导出' }],
      active: 'announcement',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      queryParams: {
        pageNum: 0,
        pageSize: 10,
        contactName: '',
        phonenumber: '',
        email: '',
        position: '',
        orderByColumn: 'updateTime',
        isAsc: 'desc'
      },
      activeNamesTitle: ['1'],
      activeNames: [],
      dealerInfoDetail: {},
      showDealerInfoDetail: false,
      timer: null,
      contactList: [],
      show: false,
      startDate: null,
      endDate: null,
      showPicker: false,
      showPicker2: false,
      //数据字典
      sys_contacts_group: [],
      dealerInfoLoading: false,
      showPickerFunction: false,
      functionLabel: '',
      dealerClone: getSelectedDealer(),
      authShow: false,
      showPickerAccount: false,
      accountLabel: '',
      accountNickName: '',
      accountList: [],
      authInfo: {},
      selectRow: {},
      editMode: false,
      index: 1
    }
  },
  filters: {
    contactGroupFilter(value, sys_contacts_group) {
      return sys_contacts_group
        .filter(itemA => value.includes(itemA.dictValue.toString()))
        .map(filteredItem => filteredItem.dictLabel)
        .join('，')
    }
  },
  watch: {
    editMode(val) {
      this.$emit('editAble', val)
      this.$store.commit('SET_EDITING', val)
    }
  },
  computed: {
    dealerInfo() {
      return this.$store.state.user.baseInfo
    }
  },
  created() {
    getDicts('sys_contacts_group').then(({ data }) => {
      this.sys_contacts_group = data
    })
    // this.onLoad()
  },
  mounted() {
    this.getDealerDetail()
    window.$vm = this
  },
  methods: {
    changeItem(obj) {
      this.contactList.filter(item => {
        if (item.contactId == obj.contactId) {
          item.contactName = obj.contactName
          item.phonenumber = obj.phonenumber
          item.email = obj.email
          item.position = obj.position
          item.contactGroups = obj.contactGroups
          item.userAccount = obj.userAccount
          item.status = obj.status
          item.processFlag = obj.processFlag
        }
      })
      this.showContactDetailPop = false
    },
    changeDealer(callback) {
      this.$dialog.confirm({
        title: '提示',
        message: '是否保存更改?',
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        messageAlign: 'left',
        beforeClose: (action, done, e) => {
          if (action === 'confirm') {
            try {
              updateDealerContacts([...this.contactList, ...this.deleteRow])
                .then(res => {
                  if (res.code === 200) {
                    done() // 关
                    this.$toast.success('修改成功')
                    if (typeof callback === 'function') {
                      this.handleEditCancel(callback)
                      callback()
                    } else {
                      this.handleEditCancel(callback)
                    }
                  } else {
                    this.$toast(res.msg)
                    done() // 关
                  }
                })
                .catch(() => {
                  done()
                })
            } catch (error) {
              done()
            } finally {
              // done()
            }
          } else {
            if (action == 'cancel') {
              if (callback) {
                if (typeof callback === 'function') {
                  this.handleEditCancel(callback)
                  callback()
                } else {
                  console.log('cancel')
                  this.handleEditCancel(callback)
                }
              }
            }
            done()
          }
        }
      })
    },
    handleEditFinish() {
      this.$dialog.confirm({
        title: '提示',
        message: '是否保存更改?',
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        messageAlign: 'left',
        beforeClose: (action, done, e) => {
          if (action === 'confirm') {
            try {
              updateDealerContacts([...this.contactList, ...this.deleteRow])
                .then(res => {
                  if (res.code === 200) {
                    done() // 关
                    this.$toast.success('修改成功')
                    this.handleEditCancel()
                  } else {
                    this.$toast(res.msg)
                    done() // 关
                  }
                })
                .catch(() => {
                  done()
                })
            } catch (error) {
              done()
            } finally {
              // done()
            }
          } else {
            done()
          }
        }
      })
    },
    handleAdd() {
      //数组添加一条为add的
      this.contactList.unshift({
        contactId: 'Add' + this.index,
        dealerId: this.dealerInfo.rocheDealerId,
        contactName: '',
        phonenumber: '',
        email: '',
        position: '',
        contactGroups: [],
        userAccount: '',
        status: 0,
        processFlag: 'add'
      })
      this.index++
    },
    handleEditCancel(link) {
      this.editMode = false
      console.log('editMode', this.editMode)
      this.$store.commit('SET_EDITING', false)
      if (typeof link === 'string') {
        console.log('跳转', link)
        console.log('store editing', this.$store.state.user.editing)
        this.$router.push({ path: link })
      }
      this.onRefresh()
    },
    changeEditMode() {
      this.editMode = true
      this.$store.commit('SET_EDITING', true)
      this.queryParams = {
        pageNum: 0,
        pageSize: 9999,
        orderByColumn: 'updateTime',
        isAsc: 'desc'
      }
      this.total = 0
      this.contactList = []
      this.refreshing = true
      this.loading = true
      this.onLoad()
    },
    closeshowContactDetailPop() {
      this.showContactDetailPop = false
    },
    closeShowDetailPop(type) {
      this.showDetailPop = false
      if (type == 'init') {
        this.onRefresh()
      }
    },
    openDealerInfoDetail() {
      if (this.dealerInfoDetail.isHaveAppeal) {
        return
      }
      this.showDetailPop = true
      this.info = _.cloneDeep(this.dealerInfoDetail)
    },
    getDealerDetail() {
      getDealerCompanyInfo().then(res => {
        if (res.data) {
          this.dealerInfoDetail = res.data
          this.dealerInfoDetail.dealerName = this.dealerInfo.rocheDealerName
        } else {
          this.dealerInfoDetail.state = 'noData'
        }
      })
    },
    parseTime(time) {
      if (time) {
        return dayjs(time).format('YYYY-MM-DD')
      } else return null
    },
    getCheckPermi(value) {
      return checkPermi([value])
    },
    handleDelete(row) {
      let param = {
        contactId: row.contactId,
        dealerId: row.dealerId
      }
      checkBinding(param).then(res => {
        let msgStr = null
        if (res.data.isBindFlag) {
          msgStr =
            '该经销商联络人账号已绑定过经销商账号，如果删除该账号，会将已绑定的经销商账号进行解绑操作，请确认是否继续删除该账号？'
        } else {
          msgStr = '是否确认删除 ' + row.contactName + ' 这个联络人？'
        }
        this.$dialog
          .confirm({
            message: msgStr
          })
          .then(() => {
            delDealerContact([row.contactId]).then(({ code, msg }) => {
              if (code != 200) {
                this.$toast.fail({
                  message: msg,
                  className: 'fail'
                })
                return
              }
              this.$toast.success('删除成功')
              this.onRefresh()
            })
          })
          .catch(() => {})
      })
    },
    openInfo() {
      this.dealerInfoLoading = true
      getDealerDetail(this.dealerInfo.rocheDealerId)
        .then(res => {
          this.dealerInfoLoading = false
          this.dealerInfoDetail = { ...res.data }
        })
        .catch(() => {
          this.dealerInfoLoading = false
        })
    },
    onSelect(data) {
      if (data.text === '新增') {
        this.goToInfo('new')
      }
      if (data.text === '导出') {
        this.download('/bff-dp-mobile/dealer-contacts/export', {}, `联络人信息_${new Date().getTime()}.xlsx`, {
          method: 'get',
          params: {
            ...this.queryParams,
            dealerId: this.dealerInfo.rocheDealerId
          }
        })
      }
    },
    //新增\修改
    goToInfo(item) {
      if (!this.editMode) return
      if (item.notEdit) {
        this.$toast.fail('公司负责人不可编辑，需提交变更申请')
        return
      }
      this.showContactDetailPop = true
      this.contactInfoDetail = _.cloneDeep(item)
    },
    //申请更改
    goToChange(obj) {
      this.$dialog
        .alert({
          title: '提醒',
          message: `根据经销协议条款18.4的内容：如经销商的送达地址、受送达人、联系电话等送达方式发生变动，应立即书面通知罗氏。</br></br>通知方式为：</br>
          ① 通过原经销商协议上约定的邮箱将变更的信息发送至china.ctm@roche.com；</br>
          ② 若原经销商协议上约定的邮箱目前已无法发送邮件，可提供说明函，说明发生的变更，并将加盖公章的说明函扫描件发送至china.ctm@roche.com。`,
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true,
          messageAlign: 'left'
        })
        .then(() => {
          dealerChagne(obj).then(() => {
            this.$toast.success('变更申请成功')
            this.showDealerInfoDetail = false
          })
        })
        .catch(() => {})
    },
    goToChangeDealer() {
      this.$dialog
        .alert({
          title: '提醒',
          message: `根据经销协议条款18.4的内容：如经销商的送达地址、受送达人、联系电话等送达方式发生变动，应立即书面通知罗氏。</br></br>通知方式为：</br>
          ① 通过原经销商协议上约定的邮箱将变更的信息发送至china.ctm@roche.com；</br>
          ② 若原经销商协议上约定的邮箱目前已无法发送邮件，可提供说明函，说明发生的变更，并将加盖公章的说明函扫描件发送至china.ctm@roche.com。`,
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true,
          messageAlign: 'left'
        })
        .then(() => {
          let params = {
            dealerId: this.dealerInfo.rocheDealerId,
            email: this.dealerInfoDetail.email,
            phonenumber: this.dealerInfoDetail.phonenumber,
            address: this.dealerInfoDetail.address
          }
          editDealer(params).then(() => {
            this.$toast.success('变更申请成功')
          })
        })
        .catch(() => {})
    },
    //随机颜色
    getRandomRGBColor() {
      const r = Math.floor(Math.random() * 256)
      const g = Math.floor(Math.random() * 256)
      const b = Math.floor(Math.random() * 256)
      return `rgb(${r}, ${g}, ${b})`
    },
    onLoad() {
      let _this = this
      let queryParams = _this.queryParams
      _this.loading = true
      // 设置定时器做节流处理，避免滚动时频繁触发加载事件
      if (!_this.timer) {
        _this.timer = setTimeout(() => {
          _this.queryParams.pageNum++
          _this.getList(queryParams)
          // 清除定时器，释放内存
          _this.timer = null
        }, 1000)
      }
    },
    handleDeleteRow() {
      this.contactList.forEach(element => {
        if (this.editChecked.includes(element.contactId)) {
          if (element.processFlag != 'add') {
            element.processFlag = 'delete'
            this.deleteRow.push(element)
          }
        }
      })
      this.contactList = this.contactList.filter(item => {
        return !this.editChecked.includes(item.contactId)
      })
      this.editChecked = []
    },
    getList(queryParams) {
      this.queryParams = queryParams
      listContacts({ ...queryParams })
        .then(res => {
          if (res.code !== 200) {
            this.loading = false
            this.refreshing = false
            this.finished = true
            return
          }
          this.loading = false
          this.refreshing = false
          this.total = res.total
          this.contactList = [
            ...this.contactList,
            ...res.rows.map(item => {
              return {
                ...item,
                contactGroups: item.contactGroups || [],
                notEdit: item.contactGroups.includes('0') ? true : false
              }
            })
          ]
          if (this.contactList.length >= this.total) {
            this.finished = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams = {
        pageNum: 0,
        pageSize: 10,
        orderByColumn: 'updateTime',
        isAsc: 'desc'
      }
      this.total = 0
      this.contactList = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    onRefreshBySearch() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams.pageNum = 0
      console.log('onRefreshBySearch', this.queryParams)
      this.total = 0
      this.contactList = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    tabChange() {
      this.editMode = false
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams = {
        pageNum: 0,
        pageSize: 10,
        orderByColumn: 'updateTime',
        isAsc: 'desc'
      }
      this.total = 0
      this.contactList = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    divClick(index) {
      this.options[index].checked = !this.options[index].checked
      console.log(index)
    },
    onConfirm(val) {
      this.startDate = dayjs(val).format('YYYY-MM')
      this.showPicker = false
    },
    onConfirm2(val) {
      this.endDate = dayjs(val).format('YYYY-MM')
      this.showPicker2 = false
    },
    showDetail(id) {
      this.$router.push({
        path: '/notice/detail',
        query: { id: id, from: this.active === 'announcement' ? 'announcement' : 'notice' }
      })
    },
    onConfirmFunctionList(data) {
      this.functionLabel = data.dictLabel
      this.queryParams.contactGroup = data.dictValue
      this.showPickerFunction = false
    },
    filterData() {
      this.show = true
    },
    cancel() {
      this.show = false
      // this.reset()
    },
    reset() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'updateTime',
        isAsc: 'desc',
        dealerId: this.dealerInfo.rocheDealerId
      }
      this.functionLabel = ''
    },
    /** 确定按钮操作 */
    handleQuery() {
      this.onRefreshBySearch()
      this.show = false
    },
    getAccountList() {
      getUnbindAccount({}).then(res => {
        this.accountList = res.data
        this.accountList = res.data.map(item => {
          return {
            ...item,
            nameLabel: item.nickName + '（' + item.userName + '）'
          }
        })
      })
    },
    goAuth(obj) {
      if (obj.status == 1) {
        this.$toast('停用的联络人不能绑定账号！')
        return false
      }
      this.selectRow = obj
      this.getAccountList()
      this.authShow = true
    },
    goUnbind(obj) {
      this.$dialog
        .confirm({
          title: '提示',
          message: '确认解绑 ' + obj.contactName + ' 的相关账号信息？'
        })
        .then(() => {
          let params = {
            contactId: obj.contactId,
            dealerId: obj.dealerId
          }
          unbindAccount(params).then(res => {
            this.$toast.success('操作成功')
            obj.userAccount = ''
          })
        })
        .catch(() => {})
    },
    cancel2() {
      this.authShow = false
      this.$refs.authForm.resetValidation()
      this.reset2()
    },
    reset2() {
      this.authInfo = {}
      this.accountLabel = ''
      this.accountNickName = ''
    },
    onConfirmAccount(data) {
      console.log(data)
      this.accountLabel = data.nameLabel
      this.accountNickName = data.nickName
      this.authInfo.userId = data.userId
      this.showPickerAccount = false
    },
    handleAuth() {
      this.$refs.authForm
        .validate()
        .then(() => {
          let param = {
            contactId: this.selectRow.contactId,
            userId: this.authInfo.userId
          }
          checkBeforeAuth(param).then(res => {
            if (!res.data.isEmailFlag) {
              this.$dialog
                .confirm({
                  title: '提示',
                  message: '当前联络人的邮箱与绑定的账户邮箱不同, 请确认是否允许覆盖当前邮箱后再绑定！'
                })
                .then(() => {
                  let params = []
                  this.selectRow.contactGroups.forEach(item => {
                    let obj = {
                      contactId: this.selectRow.contactId,
                      userId: this.authInfo.userId,
                      contactGroupCode: item
                    }
                    params.push(obj)
                  })
                  authAccount(params).then(res => {
                    this.$toast.success('操作成功')
                    this.authShow = false
                    this.selectRow.userAccount = this.accountNickName
                    // this.onRefresh()
                  })
                })
                .catch(() => {
                  this.authShow = false
                })
            } else {
              let params = []
              this.selectRow.contactGroups.forEach(item => {
                let obj = {
                  contactId: this.selectRow.contactId,
                  userId: this.authInfo.userId,
                  contactGroupCode: item
                }
                params.push(obj)
              })
              authAccount(params).then(res => {
                this.$toast.success('操作成功')
                this.authShow = false
                this.selectRow.userAccount = this.accountNickName
                // this.onRefresh()
              })
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
@import 'index';
.list-bg {
  min-height: calc(100vh - 99px - 55px) !important;
  background: linear-gradient(268deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
  padding: 20px 16px;
}
.edit {
  height: calc(100vh - 99px - 55px - 60px);
  min-height: auto !important;
  overflow: scroll;
  overflow-x: hidden;
}
.edit-btn {
  position: fixed;
  bottom: 60px;
  width: calc(100% - 40px);
  left: 50%;
  display: flex;
  justify-content: space-between;
  transform: translateX(-50%);
}
.btn {
  width: 22%;
  padding: 0;
  box-shadow: 0px 0px 4vmin 0.533333vmin #0b41cd3d;
  border: none;
  color: #656464;
}
.btn-finish {
  background: #0b41cd;
  color: #fff;
}
.popover,
.tab-right-btn {
  color: #0b41cd;
  font-size: 16px;
  position: absolute;
  height: 44px;
  right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}

.dotClass {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: block;
  margin-top: 8px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.notice-type {
  background-color: #ced9f5;
  color: #0b41cd;
  font-size: 12px;
  padding: 0px 5px;
  border-radius: 4px;
  min-width: 50px;
}

.notice-title {
  padding: 0px 10px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #0b41cd;
}

.divNormal {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #8c8c8c;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

.divActive {
  border-radius: 4px;
  background-color: #0b41cd;
  border: 1px solid #0b41cd;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

/deep/.van-tabs__nav {
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}

/deep/.van-collapse-item__title .van-cell__title {
  width: calc(100% - 20px);
}
.text-hide1 {
  /*单行文本溢出*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header {
  padding: 0 2px 0 2px;
  .total {
    font-size: 14px;
    line-height: 24px;
    color: #969799;
  }
  .filter {
    color: #0b41cd;
    font-size: 14px;
  }
}

.popup-box {
  overflow-y: hidden;
  height: 450px;
  .group-box {
    margin-top: 40px;
    height: 340px;
    overflow-y: auto;
    z-index: 10;
  }
  .popup-foot-btn {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    text-align: center;
    height: 36px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    .btn {
      width: 80px;
    }
  }
}
.popup-box2 {
  overflow-y: hidden;
  height: 200px;
  .group-box {
    margin-top: 40px;
    height: 90px;
    overflow-y: auto;
    z-index: 10;
  }
  .popup-foot-btn {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    text-align: center;
    height: 36px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    .btn {
      width: 80px;
    }
  }
}
.margin-lf-10 {
  margin-left: 10px;
}
.collapse {
  padding: 2px;
  .van-collapse-item {
    // overflow: hidden;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe) !important;
    /deep/.van-collapse-item__content {
      border-radius: 0 0 8px 8px;
    }
    .icon {
      font-size: 20px;
    }
    .card-text {
      color: #8c8c8c;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        // margin-bottom: 2px;
      }
      .email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
      .status {
        // margin-bottom: 2px;
        font-size: 12px;
        .table-status {
          display: flex;
          justify-content: right;
          align-items: center;
          .dotClass {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
      .phone {
        font-size: 12px;
        text-align: end;
      }
    }
    /deep/.van-cell {
      align-items: center;
      padding: 10px 8px;
    }
    .diy-cell-value {
      color: #969799;
      text-align: right;
    }

    .collapse-content {
      /deep/.van-cell {
        align-items: normal;
        font-size: 12px;
        line-height: 14px;
        padding-left: 0;
      }

      .table-mark {
        margin-left: 8px;
        padding: 0.2vw 0.8vw;
        border-radius: 12px;
      }
      .mark-del {
        background-color: #ebeef5;
      }
    }
    .foot-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 8px;
      color: #0b41cd;
      border-top: 0.5px solid #ebedf0;
      .vertical-line {
        margin: 0 8px;
      }
    }
    .disable-button {
      color: #a0cfff;
    }
  }
}
.bar {
  position: relative;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.warning-icon {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.font-dis {
  color: #ff4a4a;
  font-size: 12px;
}

/deep/.dealerInfoDetail .van-collapse-item__title .van-cell__title {
  width: 75%;
  flex: 0 0 auto;
}
</style>
