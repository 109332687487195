import request from '@/utils/request'

//获取信息核对进度
export function getFeedbackProcess(query) {
  return request({
    url: '/bff-dp-mobile/all-channel-data/data/process',
    method: 'get',
    params: query
  })
}

//查询分销商信息
export function getDistributor(query) {
  return request({
    url: '/bff-dp-mobile/all-channel-data/distributor-list',
    method: 'get',
    params: query
  })
}

//新增分销商
export function addDistributor(data) {
  return request({
    url: '/bff-dp-mobile/all-channel-data/distributor',
    method: 'post',
    data: data
  })
}

//编辑分销商
export function editDistributor(data) {
  return request({
    url: '/bff-dp-mobile/all-channel-data/distributor',
    method: 'put',
    data: data
  })
}

//删除分销商
export function deleteDistributor(data) {
  return request({
    url: '/bff-dp-mobile/all-channel-data/distributor/' + data.id,
    method: 'delete'
  })
}

//删除全渠道链数据
export function deleteChainData(data) {
  return request({
    url: '/bff-dp-mobile/all-channel-data/delete-data',
    method: 'post',
    data: data
  })
}

//更新全渠道链数据状态
export function editChainData(data) {
  return request({
    url: '/bff-dp-mobile/all-channel-data/update-status',
    method: 'put',
    data: data
  })
}

//批量提交全渠道链数据
export function submitChainData(data) {
  return request({
    url: '/bff-dp-mobile/all-channel-data/batch-confirm',
    method: 'put',
    data: data
  })
}

//新增全渠道链数据
export function addChainData(data) {
  return request({
    url: '/bff-dp-mobile/all-channel-data/add-data',
    method: 'post',
    data: data
  })
}
