<template>
  <div>
    <router-view v-if="$route.path === '/dataCenter/instrument/changeLog'"></router-view>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      :btnData="btnData"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
      :is-need-operate="true"
      :more-column="true"
      :more-search="true"
      :isCardDisabled="false"
      :isCheckbox="false"
      :hide-btn-with-empty-data="true"
      @on-search="handleData"
    >
      <template slot="custom-title">
        <van-row style="margin-bottom: 10px">
          <van-col :span="12">
            <div style="font-size: 12px">允许经销商发起反馈时间段：</div>
          </van-col>
          <van-col :span="12">
            <div style="font-size: 12px; color: #595959">{{ appealTime }}</div>
          </van-col>
        </van-row>
        <van-row style="margin-bottom: 10px; font-size: 14px">
          <van-radio-group v-model="searchType" :icon-size="14" direction="horizontal" @change="changeFn">
            <van-radio name="1" @click="clickFn">近30天新增仪器</van-radio>
            <van-radio name="2" @click="clickFn" style="margin-left: 50px">已拆除仪器</van-radio>
            <van-radio name="3" @click="clickFn">发货到仓库</van-radio>
          </van-radio-group>
        </van-row>
      </template>
      <template #column-item="scope">
        <div>
          <van-row class="item">
            <van-col :span="20">
              <div class="name">
                <van-icon name="info-o" color="red" size="14" v-if="scope.row['isExpire']" />
                {{ scope.row['equipmentNum'] }}<br />{{ scope.row['instrLocName'] }}
              </div>
            </van-col>
            <van-col :span="4" class="year">
              <div>{{ scope.row['instrLocKey'] }}</div>
            </van-col>
          </van-row>
        </div>
      </template>
      <template #column-item-form="scope">
        <template v-if="scope.item.prop === 'moduleStatusSupplement'">
          <van-cell :title="scope.item.label" label-class="titleClass" style="font-size: 12px">
            <span>{{ scope.row[scope.item.prop] || '-' }}</span>
            <van-popover v-model="scope.row.showPopover" trigger="click" theme="dark">
              <div class="instrumentPopover">
                <span style="font-size: 12px">{{ scope.row.moduleStatusDescription }}</span>
              </div>
              <template #reference>
                <van-icon name="info-o" color="red" size="14" v-if="scope.row.moduleStatusDescription" />
              </template>
            </van-popover>
          </van-cell>
        </template>
        <template v-else>
          <van-cell
            :title="scope.item.label"
            :value="scope.row[scope.item.prop]"
            label-class="titleClass"
            style="font-size: 12px"
          ></van-cell>
        </template>
      </template>
      <template slot="table-btn" slot-scope="data">
        <span @click="setMoreColumn(data.row)">{{ data.row.isShowMoreColumn ? '收起' : '展示' }}更多字段</span>
        <span style="padding: 0 5px">|</span>
        <span @click="viewChangeLog(data.row)">变更日志</span>
        <span style="padding: 0 5px" v-if="data.row.appealType">|</span>
        <span v-if="data.row.appealType" @click="showDetail(data.row)">反馈详情</span>
      </template>
    </form-table>
  </div>
</template>

<script>
import { getDicts } from '@/api/dict/data'
import { getChangeLog, getAppealDate, getInstrumentAppealDetail } from '@/api/dataCenter/instrument'
import dayjs from 'dayjs'

export default {
  name: 'DemoList',
  data() {
    return {
      btnData: [{ text: '导出' }],
      searchFormData: [
        {
          props: 'contractNumber',
          label: '合同号',
          type: 'input'
        },
        {
          props: 'equipmentNum',
          label: '设备号',
          type: 'input',
          isMore: true
        },
        {
          props: 'materialNumber',
          label: '物料号',
          type: 'input',
          isMore: true
        },
        {
          props: 'materialDesc',
          label: '物料名称',
          type: 'input'
        },
        {
          props: 'instrLocKey',
          label: '终端代码',
          type: 'input',
          isMore: true
        },
        {
          props: 'instrLocName',
          label: '终端名称',
          type: 'input'
        },
        {
          props: 'provinceNameZh',
          label: '终端省份',
          type: 'input'
        },
        {
          props: 'iBaseCate',
          label: '仪器类型',
          type: 'DictSelect',
          dictCode: 'sys_instrument_type',
          isMore: true
        },
        {
          props: 'serialNumber',
          label: '仪器序列号',
          type: 'input'
        }
        // {
        //   props: 'contractMaxEndYear',
        //   label: '服务合同到期年份',
        //   type: 'date',
        //   placeholder: '请选择年份',
        //   valueFormat: 'yyyy',
        //   dateFormat: 'yyyy',
        //   dateType: 'year',
        //   isMore: true
        // }
      ],
      tableOptions: [
        {
          type: 'fileName',
          hideFileIcon: true
        },
        {
          prop: 'contractNumber',
          label: '合同号'
        },
        {
          prop: 'instrumentAge',
          label: '机龄'
        },
        {
          prop: 'materialNumber',
          label: '物料号',
          isMore: true
        },
        {
          prop: 'materialDesc',
          label: '物料名称'
        },
        {
          prop: 'provinceNameZh',
          label: '终端省份'
        },
        {
          prop: 'iBaseCate',
          label: '仪器类型',
          isMore: true
        },
        {
          prop: 'serialNumber',
          label: '仪器序列号'
        },
        {
          prop: 'moduleStatus',
          label: '仪器使用状态'
        },
        {
          prop: 'moduleStatusSupplement',
          label: '状态补充说明',
          isEdit: true
        },
        {
          prop: 'salesTeam',
          label: '业务部门',
          isMore: true
        },
        {
          prop: 'businessLine',
          label: '产品线',
          isMore: true
        },
        {
          prop: 'firstInstallDate',
          label: '仪器模块首次启用时间',
          isMore: true
        },
        {
          prop: 'contractMaxEndDate',
          label: '服务合同到期日',
          isMore: true
        },
        {
          prop: 'movementType',
          label: '最终处置方案',
          isMore: true
        },
        {
          prop: 'movementStatus',
          label: '处置方案状态',
          isMore: true
        }
      ],
      headerTitle: '仪器清单',
      queryUrl: '/bff-dp-mobile/instrument-data/data/list',
      appealTime: '',
      searchType: undefined,
      checkRadioFlag: false
    }
  },
  created() {
    this.getDateInfo()
    getDicts('sys_instrument_type').then(({ data }) => {
      this.searchFormData[2].options = data
    })
  },
  watch: {
    searchType(value) {
      this.$refs.baseForm.queryParams = { ...this.$refs.baseForm.queryParams, searchType: value }
      this.$refs.baseForm.handleQuery()
    }
  },
  methods: {
    getDateInfo() {
      let params = {}
      getAppealDate(params)
        .then(res => {
          this.appealTime =
            dayjs(res.data.startAppealTime).format('YYYY-MM-DD') +
            ' ~ ' +
            dayjs(res.data.endAppealTime).format('YYYY-MM-DD')
        })
        .catch(err => {
          console.log(err)
        })
    },
    clickFn() {
      if (this.checkRadioFlag) {
        this.searchType = null
      }
      this.checkRadioFlag = true
    },
    changeFn() {
      this.checkRadioFlag = false
    },
    onSelect(_, searchParams, selectedData) {
      const selectedFileIdList = [...selectedData].map(item => {
        return item.equipmentNum
      })
      const requestParams = {
        ...searchParams,
        ids: selectedFileIdList.join(','),
        searchType: this.searchType,
        pageNum: undefined,
        pageSize: undefined
      }
      this.download('/bff-dp-mobile/instrument-data/data/export', {}, `仪器清单_${new Date().getTime()}.xlsx`, {
        method: 'get',
        params: requestParams
      })
    },
    handleData(data) {
      data.forEach(item => {
        this.$set(item, 'showPopover', false)
      })
    },
    viewChangeLog(data) {
      if (!data.isHaveLog) {
        this.$toast.fail('暂无变更日志')
        return false
      }
      let params = {
        equipmentNum: data.equipmentNum
      }
      getChangeLog(params)
        .then(res => {
          if (!res.data) {
            this.$toast.fail('暂无变更日志')
            return false
          } else {
            let obj = res.data
            let arr = []
            if (obj.instrLocKey !== data.instrLocKey) {
              arr.push({
                fieldName: '终端代码',
                beforeChangeValue: obj.instrLocKey,
                afterChangeValue: data.instrLocKey,
                updateTime: obj.updateTime
              })
            }
            if (obj.instrLocName !== data.instrLocName) {
              arr.push({
                fieldName: '终端名称',
                beforeChangeValue: obj.instrLocName,
                afterChangeValue: data.instrLocName,
                updateTime: obj.updateTime
              })
            }
            if (obj.serialNumber !== data.serialNumber) {
              arr.push({
                fieldName: '仪器序列号',
                beforeChangeValue: obj.serialNumber,
                afterChangeValue: data.serialNumber,
                updateTime: obj.updateTime
              })
            }
            if (obj.moduleStatus !== data.moduleStatus) {
              arr.push({
                fieldName: '仪器使用状态',
                beforeChangeValue: obj.moduleStatus,
                afterChangeValue: data.moduleStatus,
                updateTime: obj.updateTime
              })
            }
            this.$router.push({
              path: '/dataCenter/instrument/changeLog',
              query: { data: JSON.stringify(arr) }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    showDetail(data) {
      let params = {
        processId: data.processId,
        taskId: data.taskId
      }
      getInstrumentAppealDetail(params)
        .then(res => {
          if (res.data) {
            this.$router.push({
              path: '/dataCenter/instrument/appealDetail',
              query: { initData: res.data }
            })
          } else {
            this.$toast.fail('暂未查到反馈详情数据！')
            return false
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    setMoreColumn(data) {
      const isShowMoreColumn = data.isShowMoreColumn ? !data.isShowMoreColumn : true
      this.$set(data, 'isShowMoreColumn', isShowMoreColumn)
    }
  }
}
</script>

<style lang="css" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    color: #0b41cd;
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .year {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(20px) translateY(10px);
  }
}

/deep/ .titleClass {
  color: #8c8c8c !important;
  font-size: 12px;
}

.instrumentPopover {
  line-height: 20px !important;
  padding: 5px 10px !important;
}
</style>
