import request from '@/utils/request'

// 查询学员列表
export function getTrainers(query) {
  return request({
    url: '/bff-dp-mobile/trainers',
    method: 'get',
    params: query
  })
}

// 新增学员
export function addTrainers(data) {
  return request({
    url: '/bff-dp-mobile/trainers',
    method: 'post',
    data: data
  })
}

// 更新学员
export function updateTrainers(data) {
  return request({
    url: '/bff-dp-mobile/trainers',
    method: 'put',
    data: data
  })
}

// 删除学员
export function delTrainers(trainerIds) {
  return request({
    url: `/bff-dp-mobile/trainers/delete?trainerIds=${trainerIds}`,
    method: 'delete'
  })
}

// 查询联络人信息
export function getTrainContactInformation(query) {
  return request({
    url: `/bff-dp-mobile/trainers/contact`,
    method: 'get',
    params: query
  })
}
