<template>
  <van-row class="main-container">
    <van-row class="title">
      <van-col>待办任务</van-col>
    </van-row>
    <van-row class="line" v-if="taskData.length > 0">
      <div class="item" v-for="(item, index) in taskData" :key="index" @click="resolveTask(item)">
        <div class="imgBg">
          <van-icon name="todo-list-o" color="#0b41cd" size="18" />
        </div>
        <div class="desc">{{ item.processDefinitionName }}-{{ item.taskDescription }}</div>
        <div class="number">处理 ></div>
      </div>
    </van-row>
    <van-row class="line" v-if="taskData.length === 0 && loadingFLag">
      <div class="no-data">暂无数据</div>
    </van-row>
    <Details ref="detailComp"></Details>
  </van-row>
</template>
<script>
import { getTodoTask } from '@/api/home'
import Details from '@/views/task/details.vue'

export default {
  name: 'TaskCard',
  components: { Details },
  props: {},
  data() {
    return {
      taskData: [],
      loadingFLag: false
    }
  },
  created() {
    this.getTaskList()
  },
  methods: {
    getTaskList() {
      let params = {
        pageNum: 1,
        pageSize: 5
      }
      getTodoTask(params)
        .then(res => {
          this.loadingFLag = true
          this.taskData = res.rows
        })
        .catch(err => {
          this.loadingFLag = true
          console.log(err)
        })
    },
    resolveTask(data) {
      this.$refs.detailComp.init(data)
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  z-index: 1;
  overflow: hidden;
  .title {
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
    border-radius: 0 0 0 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
  }
}
.line {
  padding: 16px 13px 23px;
  background: #fff;
  .item {
    width: 100%;
    display: flex;
    min-height: 50px;
    align-items: center;
    .imgBg {
      width: 25px;
      height: 25px;
      max-width: 25px;
      max-height: 25px;
      border-radius: 50%;
      background: #ecf0fb;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .desc {
      width: 250px;
      font-weight: bold;
      font-size: 12px;
      color: #262626;
    }
    .number {
      font-weight: 400;
      font-size: 12px;
      color: #0b41cd;
      text-align: right;
      width: 80px;
    }
  }
  .no-data {
    display: flex;
    min-height: 50px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}
</style>
