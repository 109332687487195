<template>
  <div>
    <van-row class="main-container">
      <van-popover v-model="showPopoverLogout" trigger="click" :offset="offset">
        <div class="popover-name" @click="logout">
          <div class="user-name" style="font-weight: bold">{{ name }}</div>
          <div class="role-name" style="font-weight: bold" v-if="orgId != 1002 && orgId != 1003">{{ dealerName }}</div>
          <div class="role-name">{{ roleName }}</div>
          <div style="font-size: 14px; font-weight: bold; margin-top: 10px; color: #0b41cd">退出登录</div>
        </div>
        <template #reference>
          <van-col class="person">
            <van-image class="person-image" :src="require('@/assets/images/avatar.svg')" />
            <div class="name">
              <div class="user-name">{{ name }}</div>
              <div class="role-name">{{ roleName }}</div>
            </div>
          </van-col>
        </template>
      </van-popover>

      <van-col style="margin-left: auto">
        <van-icon name="search" class="btn-search popover-item" @click="goToSearch()" />
        <van-popover v-model="showPopover.credit" trigger="click" class="popover-item" placement="bottom">
          <div class="popover-name">
            <van-row class="credit" v-if="creditData.block === 'X'">
              <van-col span="24">
                <div class="header" style="color: red">
                  <van-icon name="info-o" color="red" size="18" style="position: relative; top: 1px" />信用账号被锁
                </div>
              </van-col>
            </van-row>
            <van-row class="credit">
              <van-col span="12">
                <div class="header" v-if="creditData.showBalance">信用额度(元)</div>
                <div class="header" v-if="!creditData.showBalance">服务费缺口(元)</div>
              </van-col>
              <van-col span="12">
                <div class="title text-right">截止昨日</div>
              </van-col>
            </van-row>
            <van-row class="credit" v-if="creditData.showBalance">
              <van-col span="12">
                <div class="title">总额度</div>
              </van-col>
              <van-col span="12">
                <div class="content text-right">{{ creditData.totalBalance | numberFormat }}</div>
              </van-col>
            </van-row>
            <van-row class="credit" v-if="creditData.showBalance">
              <van-col span="12">
                <div class="title">剩余额度</div>
              </van-col>
              <van-col span="12">
                <div class="content text-right">{{ creditData.remainingBalance | numberFormat }}</div>
              </van-col>
            </van-row>
            <van-row class="credit" v-if="!creditData.showBalance">
              <van-col span="12">
                <div class="title">服务费缺口</div>
              </van-col>
              <van-col span="12">
                <div class="content text-right">{{ creditData.serviceFeeGap | numberFormat }}</div>
              </van-col>
            </van-row>
          </div>
          <template #reference>
            <van-image
              class="btn-credit"
              v-show="showPopover.credit"
              :src="actions.credit.active"
              v-has-permission="['dashboard:credit:data']"
            ></van-image>
            <van-image
              class="btn-credit"
              v-show="!showPopover.credit"
              :src="actions.credit.normal"
              v-has-permission="['dashboard:credit:data']"
            ></van-image>
          </template>
        </van-popover>
        <van-popover
          v-model="showPopover.notice"
          trigger="click"
          class="popover-item"
          :offset="[85, 0]"
          placement="bottom-end"
        >
          <div class="notice-container" type="flex" justify="space-between" aligh="center">
            <div class="header">
              <van-col>通知</van-col>
              <van-image
                @click="goNoticeList"
                class="notice-arrow-icon"
                :src="require('@/assets/icons/icon-home-notice-arrow.svg')"
              />
            </div>
            <van-row v-for="i in noticeData" class="item" :key="i.noticeId">
              <van-image class="notice-icon" :src="require('@/assets/images/home-notice1.png')" />
              <van-col @click="showNoticeDetail(i)">{{ i.noticeTitle }}</van-col>
              <van-col class="date"> {{ i.sendTime | noticeDateFormat }}</van-col>
            </van-row>
            <van-row v-if="noticeData.length === 0">
              <van-col span="24">
                <div style="text-align: center; padding: 10px 0px; font-size: 14px">暂无未读通知</div>
              </van-col>
            </van-row>
          </div>
          <template #reference v-if="orgId != 1002">
            <van-image
              class="btn-notice"
              v-show="noticeData.length > 0 && showPopover.notice"
              :src="actions.notice.active"
            ></van-image>
            <van-image
              class="btn-notice"
              v-show="noticeData.length > 0 && !showPopover.notice"
              :src="actions.notice.normal"
            ></van-image>
            <van-image
              class="btn-notice"
              v-show="noticeData.length === 0 && showPopover.notice"
              :src="actions.noticeEmpty.active"
            ></van-image>
            <van-image
              class="btn-notice"
              v-show="noticeData.length === 0 && !showPopover.notice"
              :src="actions.noticeEmpty.normal"
            ></van-image>
          </template>
        </van-popover>
        <van-popover
          v-model="showPopover.phone"
          trigger="click"
          class="popover-item"
          :offset="[20, 0]"
          placement="bottom-end"
        >
          <div class="phone-container">
            <van-row v-for="i in hotlineData" class="item" :key="i.title">
              <van-row class="title"> {{ i.title }} </van-row>
              <van-row class="line phone-item">
                <van-image class="phone-icon" :src="require('@/assets/icons/icon-home-popup-phone.svg')" />
                <van-col>{{ i.phone || '--' }}</van-col>
              </van-row>
              <van-row class="line phone-item">
                <van-image class="phone-icon" :src="require('@/assets/icons/icon-home-email.svg')" />
                <span class="email">{{ i.email || '--' }}</span>
              </van-row>
            </van-row>
          </div>
          <template #reference>
            <van-image class="btn-phone" v-show="showPopover.phone" :src="actions.phone.active"></van-image>
            <van-image class="btn-phone" v-show="!showPopover.phone" :src="actions.phone.normal"></van-image>
          </template>
        </van-popover>
        <van-popover
          v-model="showPopover.manual"
          trigger="click"
          class="popover-item"
          :offset="[-10, 0]"
          placement="bottom"
        >
          <div style="font-size: 14px; font-weight: bold; color: #0b41cd; padding: 10px" @click="openManual">
            使用手册
          </div>
          <template #reference>
            <van-image class="btn-book" :src="actions.manual.normal"></van-image>
          </template>
        </van-popover>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import IconHomeApp from '@/assets/icons/icon-home-app.svg'
import IconHomeAppActive from '@/assets/icons/icon-home-app-active.svg'
import IconHomeNotice from '@/assets/icons/icon-home-notice.svg'
import IconHomeNoticeActive from '@/assets/icons/icon-home-notice-active.svg'
import IconHomeNoticeEmpty from '@/assets/icons/icon-home-notice-empty.svg'
import IconHomeNoticeEmptyActive from '@/assets/icons/icon-home-notice-empty-active.svg'
import IconHomePhone from '@/assets/icons/icon-home-phone.svg'
import IconHomePhoneActive from '@/assets/icons/icon-home-phone-active.svg'
import IconHomeManual from '@/assets/images/icon-manual.svg'
import IconHomeCreditActive from '@/assets/icons/icon-home-credit-active.svg'
import IconHomeCredit from '@/assets/icons/icon-home-credit.svg'
import { receiveNoticeList, getCreditData } from '@/api/home'
import { formatTargetDate } from '@/utils/common'
import {
  goSSO,
  setToken,
  setIsNeedChangeDealer,
  setSelectedDealer,
  removeToken,
  removeRefreshToken, setRefreshToken
} from '@/utils/auth'
import { ssoLogout } from '@/api/login'
import { getConfigKey } from '@/api/dict/data'
import { previewUserManual } from '@/api/file'
import request from '@/utils/request'
import { getDicts } from '@/api/dict/data'

export default {
  name: 'Header',
  dicts: ['menu_hotline'],
  data() {
    return {
      manualUrl: '',
      fileParam: {},
      showPopoverLogout: false,
      actionsLogout: [{ text: '退出登录' }],
      noticeData: [],
      hotlineData: [],
      dictHotLineData: [],
      showPopover: {
        notice: false,
        app: false,
        phone: false,
        credit: false
      },
      actions: {
        notice: {
          normal: IconHomeNotice,
          active: IconHomeNoticeActive
        },
        noticeEmpty: {
          normal: IconHomeNoticeEmpty,
          active: IconHomeNoticeEmptyActive
        },
        app: {
          normal: IconHomeApp,
          active: IconHomeAppActive
        },
        phone: {
          normal: IconHomePhone,
          active: IconHomePhoneActive
        },
        credit: {
          normal: IconHomeCredit,
          active: IconHomeCreditActive
        },
        manual: {
          normal: IconHomeManual
        }
      },
      offset: [30, 8],
      creditData: {}
    }
  },
  created() {
    this.getNoticeList()
    // this.hotlineData = hotline.listData
    this.getManualUrl()
    this.getDictHotLineData()
    this.getCreditData()
  },
  computed: {
    name() {
      return this.$store.state.user.name
    },
    roleName() {
      return this.$store.state.user.userInfo.roles.map(item => item.roleName).join('、')
    },
    dealerName() {
      return this.$store.state.user.userInfo.org.orgName
    },
    orgId() {
      return this.$store.state.user.userInfo.orgId
    }
  },
  methods: {
    async getDictHotLineData() {
      const options = await getDicts('menu_hotline').then(res => {
        let list = []
        if (res.code === 200 && res.data.length > 0) {
          list = res.data
        }
        return list
      })
      this.dictHotLineData = options
      this.getMenuHotLine()
    },
    getMenuHotLine() {
      //通过数据字典获取热线列表
      const dataArray = this.dictHotLineData.map(item => item.dictValue)
      const promisesArray = dataArray.map(value => this.getDetailsByHotline(value))

      //当所有接口返回结果后，将结果赋值给hotlineData
      Promise.all(promisesArray)
        .then(results => {
          this.hotlineData = results.filter(item => item.show !== 'false')
        })
        .catch(error => {
          console.error(error)
        })
    },
    getDetailsByHotline(value) {
      //通过热线编号获取热线详情
      return request
        .get(`/user/dict/data/type/${value.toLowerCase()}`)
        .then(({ data }) => {
          let resultObject = {}
          data.forEach(item => {
            resultObject[item.dictValue] = item.dictLabel
          })
          return resultObject
        })
        .catch(error => {
          console.error(error)
          throw error
        })
    },
    onSelect(action) {
      this.logout()
    },
    // 退出登录
    logout() {
      // 登录了

      this.$dialog
        .confirm({
          title: '提示',
          message: '确定退出系统吗？'
        })
        .then(() => {
          // 清除token
          ssoLogout().finally(() => {
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            this.$store.commit('SET_TOKEN', '')
            this.$store.commit('SET_REFRESH_TOKEN', '')
            this.$store.commit('SET_NAME', '')
            this.$store.commit('SET_BASEINFO', '')
            this.$store.commit('SET_IsNeedChangeDealer', false)
            setToken('')
            setRefreshToken('')
            setIsNeedChangeDealer(false)
            setSelectedDealer('')
            removeToken()
            removeRefreshToken()
            this.$store.state.user.name = ''
            // 退出到登出页
            goSSO()
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    handleGoToApp() {
      window.open(process.env['VUE_APP_HEADER_LINK'])
    },
    formatTargetDate,
    goToSearch() {
      this.$router.push({
        path: '/search'
      })
    },
    getNoticeList() {
      let params = {
        pageNum: 1,
        pageSize: 5,
        stationType: 'NOTICE',
        readStatus: 'N'
      }
      receiveNoticeList(params)
        .then(res => {
          this.noticeData = res.rows
          this.total = res.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCreditData() {
      let params = {}
      getCreditData(params)
        .then(res => {
          if (res.data && res.data.length > 0) {
            this.creditData = res.data[0]
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    goNoticeList() {
      this.$router.push({
        path: '/notice'
      })
    },
    showNoticeDetail(obj) {
      this.$router.push({
        path: '/notice/detail',
        query: { from: 'NOTICE', noticeId: obj.noticeReceiveId }
      })
    },
    getManualUrl() {
      getConfigKey('sys.user.distributor.manualUrl').then(response => {
        this.manualUrl = response.msg
        let arr = this.manualUrl.split('&')
        arr.forEach((item, index) => {
          let str = item.replaceAll('fileName=', '').replaceAll('filePath=', '')
          arr.splice(index, 1, str)
        })
        this.fileParam = {
          fileName: arr[0],
          filePath: arr[1]
        }
      })
    },
    openManual() {
      let fileName = this.fileParam.filePath.split('/')[2]
      previewUserManual(this.fileParam, fileName)
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.person {
  display: flex;
  align-items: center;
}
.person-image {
  width: 28px;
  height: 28px;
}

.list-bg {
  min-height: 100vh;
  padding: 0 0.4rem 50px;
  background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
}

.btn-notice {
  width: 22px;
  height: 22px;
}

.btn-app {
  width: 28px;
  height: 24px;
}
.btn-book {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
.btn-phone {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.btn-search {
  font-size: 22px;
  margin-right: 5px;
  transform: translateY(-1px);
}
.btn-credit {
  width: 30px;
  height: 30px;
  transform: translateY(3px);
}
.notice-container {
  width: calc(100vw - 40px);
  .header {
    height: 48px;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
    font-weight: 600;
    font-size: 14px;
    color: #262626;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 25px;
  }
  .item {
    height: 48px;
    font-weight: 500;
    font-size: 12px;
    color: #262626;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 23px;
    border-bottom: 0.5px solid #f0f0f0;
    .notice-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
    .date {
      margin-left: auto;
    }
  }
}
.phone-container {
  max-height: 50vh;
  overflow-y: auto;
  padding: 16px;
  width: 257px;
  background: linear-gradient(
    276deg,
    rgba(217, 235, 251, 0.75),
    rgba(217, 235, 251, 0.75),
    rgba(242, 242, 247, 0.75),
    rgba(254, 249, 247, 0.75),
    rgba(255, 254, 254, 0.75)
  );
  //backdrop-filter: blur(122px);
  .item {
    border: 0.5px solid #d9d9d9;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #fff;
    color: #000;
    padding: 16px;
    font-size: 12px;
    .title {
      font-weight: 500;
    }
    .line {
      margin-top: 8px;
      display: flex;
      align-items: center;
      color: #595959;
      font-weight: 400;
      .phone-icon {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        margin-right: 10px;
      }
      .email {
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
}
.app-container {
  width: 174px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0.5px solid #d0d0d0;
  padding: 16px;
  background: linear-gradient(
    276deg,
    rgba(217, 235, 251, 0.75),
    rgba(217, 235, 251, 0.75),
    rgba(242, 242, 247, 0.75),
    rgba(254, 249, 247, 0.75),
    rgba(255, 254, 254, 0.75)
  );
  backdrop-filter: blur(22px);
  .inner-container {
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    .title {
      color: #000;
      font-weight: 500;
      font-size: 12px;
    }
    .item {
      background: linear-gradient(
        276deg,
        rgba(225, 234, 255, 0.1755),
        rgba(224, 233, 255, 0.65),
        rgba(191, 209, 255, 0.104)
      );
      margin-top: 24px;
      position: relative;
      border: 2px solid rgba(78, 129, 255, 0.32);
      height: 32px;
      border-radius: 108px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #595959;
      font-size: 16px;
      font-weight: 400;
      padding-left: 18px;
      .app-icon {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 13px;
      }
    }
  }
}
.popover-item {
  margin-left: 12px;
}
.popover-item2 {
  margin-left: 30px;
}
.name {
  margin-left: 12px;
  width: 100px;
  .user-name {
    font-size: 14px;
    color: #8c8c8c;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .role-name {
    font-size: 12px;
    color: #8c8c8c;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.popover-name {
  width: 200px;
  padding: 5px 10px;
  .user-name {
    min-height: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    font-weight: 500;
  }
  .role-name {
    min-height: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    font-weight: 500;
  }
}

/deep/.van-popover__action-text {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #8c8c8c !important;
}
.popoverColor {
  color: #8c8c8c !important;
}

.credit {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  .header {
    color: #262626;
    font-size: 14px;
    font-weight: bold;
  }
  .title {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 400;
  }
  .content {
    color: #262626;
    font-size: 14px;
    font-weight: bold;
  }
  .text-right {
    text-align: right;
  }
}
</style>
