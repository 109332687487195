<template>
  <div class="tabs-wrap">
    <template v-if="$slots.default">
      <slot />
    </template>
    <template v-else>
      <div
        class="tab-item"
        :class="{
          active: value === tab[filedKey]
        }"
        v-for="tab in tabs"
        :key="tab[filedKey]"
        @click.stop="() => onChange(tab)"
      >
        {{ tab[fieldLabel] }}
      </div>
    </template>
  </div>
</template>
<script>
export default {
  selector: 'performance-appraisal-tabs',
  props: {
    value: {
      default: false
    },
    tabs: {
      type: Array,
      default: () => []
    },
    fieldLabel: {
      type: String,
      default: 'label'
    },
    filedKey: {
      type: String,
      default: 'value'
    }
  },
  methods: {
    onChange(tab) {
      this.$emit('input', tab[this.filedKey])
    }
  }
}
</script>
<style lang="less">
.tabs-wrap {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(228, 231, 237, 1);
    visibility: visible;
  }
  .tab-item {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    padding: 9px 20px 9px 20px;
    border-top: 1px solid rgba(228, 231, 237, 1);
    border-right: 1px solid rgba(228, 231, 237, 1);
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(228, 231, 237, 1);
      visibility: visible;
    }
    &:first-child {
      border-left: 1px solid rgba(228, 231, 237, 1);
    }
    &.active {
      color: rgba(11, 65, 205, 1);
      &::after {
        background-color: #fff;
        z-index: 1;
      }
    }
  }
}
</style>
