<template>
  <div class="list-bg">
    <van-popover
      v-if="getCheckPermi('studentsEdit-btn')"
      class="popover"
      placement="bottom-end"
      v-model="showPopover"
      trigger="click"
      :actions="actions"
      @select="onSelect"
    >
      <template #reference>
        <van-icon name="ellipsis" />
      </template>
    </van-popover>
    <van-row v-else class="tab-right-btn" @click="onSelect({ text: '导出' })">
      <van-col class="text">导出</van-col>
    </van-row>
    <van-row class="header" type="flex" justify="space-between">
      <van-col class="total">
        <span>共{{ total }}条数据</span>
      </van-col>
      <van-col class="filter" @click="filterData"
        ><span>筛选</span><van-icon name="filter-o" color="#0B41CD"
      /></van-col>
    </van-row>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-collapse v-model="activeNames" class="collapse">
          <van-collapse-item :name="item.trainerId" v-for="item in trainersList" :key="item.trainerId">
            <template #title>
              <van-row type="flex" align="center" class="card">
                <van-col span="4" style="color: coral"><van-icon name="manager" class="icon" /></van-col>
                <van-col span="11" class="card-text">
                  <van-row class="name">{{ item.trainerName }} </van-row>
                  <van-row class="email"><van-icon name="envelop-o" class="theme-color" />{{ item.email }} </van-row>
                </van-col>
                <van-col span="9" class="card-text">
                  <van-row class="status">
                    <!-- <van-cell>
                      <template #right-icon> -->
                    <van-row class="diy-cell-value">
                      <span class="table-status" v-if="item.status == 0">
                        <i class="dotClass" style="background-color: springgreen"></i
                        ><span class="custom-title">{{ statusLabel(item.status) }}</span>
                      </span>
                      <span class="table-status" v-if="item.status == 1">
                        <i class="dotClass" style="background-color: yellow"></i
                        ><span class="custom-title">{{ statusLabel(item.status) }}</span>
                      </span>
                      <span class="table-status" v-if="item.status == 2">
                        <i class="dotClass" style="background-color: red"></i
                        ><span class="custom-title">{{ statusLabel(item.status) }}</span>
                      </span>
                    </van-row>
                    <!-- </template>
                    </van-cell> -->
                  </van-row>
                  <van-row class="phone">
                    <van-icon name="phone-o" class="theme-color" />{{ item.mobilePhone | mobileFormat }}
                  </van-row>
                  <!-- <span>{{ genderLabel(item.gender) }}</span> | <span>{{ item.age + '岁' }}</span> -->
                </van-col>
              </van-row>
            </template>
            <div class="collapse-content">
              <van-row class="divider">
                <van-col span="24"><van-cell title="员工职能" :value="item.functionName || '-'" /></van-col>
                <van-col span="24"><van-cell title="培训账号" :value="item.trainingAccount || '-'" /></van-col>
                <van-col span="24"><van-cell title="驳回原因" :value="item.rejectionReason || '-'" /></van-col>
                <van-col span="24"><van-cell title="员工信息备注" :value="item.employeeMemo || '-'" /></van-col>

                <!-- <van-col span="12"><van-cell title="手机" :value="item.mobilePhone" /></van-col>
                <van-col span="12"><van-cell title="座机" :value="item.telephone" /></van-col>
                <van-col span="12"><van-cell title="微信昵称" :value="item.wechatNickname" /></van-col>
                <van-col span="12"><van-cell title="入职时间" :value="item.entryTime" /></van-col> -->
              </van-row>
              <!-- <van-row class="divider">
                <van-col span="12"><van-cell title="最高学历" :value="educationLabel(item.education)" /></van-col>
                <van-col span="12"><van-cell title="专业" :value="item.major" /></van-col>
              </van-row> -->

              <van-row class="divider">
                <van-col span="24"><van-cell title="培训联络人" :value="''" class="training-contact" /></van-col>
                <van-col span="24"><van-cell title="姓名" :value="item.trainingContactName" /></van-col>
                <van-col span="24"><van-cell title="手机" :value="item.trainingContactPhone | mobileFormat" /></van-col>
                <van-col span="24"><van-cell title="邮箱" :value="item.trainingContactEmail" /></van-col>
              </van-row>
              <van-row class="divider">
                <van-col span="24"><van-cell title="变更人" :value="item.updatedUser || '-'" /></van-col>
                <van-col span="24">
                  <van-cell title="变更日期">
                    <template #right-icon>
                      <van-row class="van-cell__value">
                        <van-row class="">
                          <span>{{ parseTime(item.updatedDate) || '-' }}</span></van-row
                        >
                        <van-row>
                          <span v-show="item.delMark" class="table-mark mark-del">{{ item.delMark }}</span>
                        </van-row>
                      </van-row>
                    </template>
                  </van-cell>
                </van-col>
                <!-- <van-row>
                  <van-col span="12"><van-cell title="创建人" :value="item.createdUser" /> </van-col>
                  <van-col span="12">
                    <van-cell title="创建日期">
                      <template #right-icon>
                        <van-row class="van-cell__value">
                          <van-row class="">
                            <span>{{ parseTime(item.createdDate) }}</span></van-row
                          >
                        </van-row>
                      </template>
                    </van-cell>
                  </van-col>
                </van-row> -->
                <!-- <van-col span="12">
                  <van-cell title="状态">
                    <template #right-icon>
                      <van-row class="diy-cell-value">
                        <span class="table-status" v-if="item.status == 0">
                          <i class="dotClass" style="background-color: springgreen"></i
                          ><span class="custom-title">{{ statusLabel(item.status) }}</span>
                        </span>
                        <span class="table-status" v-if="item.status == 1">
                          <i class="dotClass" style="background-color: yellow"></i
                          ><span class="custom-title">{{ statusLabel(item.status) }}</span>
                        </span>
                        <span class="table-status" v-if="item.status == 2">
                          <i class="dotClass" style="background-color: red"></i
                          ><span class="custom-title">{{ statusLabel(item.status) }}</span>
                        </span>
                      </van-row>
                    </template>
                  </van-cell>
                </van-col> -->
              </van-row>
              <van-row class="foot-btn">
                <span @click="viewChangeLog(item)">变更日志</span>
                <span v-has-permission="['student-operate-btn']" :class="{ 'disable-button': item.delMark === '删除' }">
                  <span class="vertical-line">|</span>
                  <span @click="handleUpdate(item)">编辑</span>
                  <span class="vertical-line">|</span>
                  <span @click="handleDelete(item)">删除</span>
                </span>
              </van-row>
            </div>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="show" class="popup-box" closeable round position="bottom" @close="cancel">
      <van-cell-group class="group-box">
        <van-field
          :maxlength="defaultMaxLength"
          readonly
          clickable
          name="function"
          :value="functionLabel"
          label="员工职能"
          placeholder="点击选择"
          @click="showPickerFunction = true"
        />
        <van-popup v-model="showPickerFunction" position="bottom">
          <van-picker
            show-toolbar
            value-key="dictLabel"
            :columns="sys_trainer_function"
            @confirm="onConfirmFunctionList"
            @cancel="showPickerFunction = false"
          />
        </van-popup>
        <van-field
          :maxlength="defaultMaxLength"
          v-model="queryParams.trainerName"
          label="员工姓名"
          placeholder="请输入"
        />
        <van-field :maxlength="defaultMaxLength" v-model="queryParams.mobilePhone" label="手机" placeholder="请输入" />
        <van-field :maxlength="defaultMaxLength" v-model="queryParams.email" label="邮箱" placeholder="请输入" />
      </van-cell-group>
      <van-row class="popup-foot-btn" gutter="20">
        <van-button class="btn margin-lf-10" type="default" @click="reset">重置</van-button>
        <van-button class="btn margin-lf-10" type="info" @click="handleQuery">确定</van-button>
      </van-row>
    </van-popup>
  </div>
</template>

<script>
import { getTrainers, delTrainers, getTrainContactInformation } from '@/api/userCenter/students'
import { getDicts } from '@/api/dict/data'
import dayjs from 'dayjs'
import { checkPermi } from '@/utils/permission'
import { delDealerUser } from '@/api/userCenter/dealer'
export default {
  name: 'Students',
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      showPopover: false,
      actions: [{ text: '新增' }, { text: '导出' }],
      showPickerFunction: false,
      activeNames: [1],
      show: false,
      sys_trainer_function: [],
      sys_trainer_status: [],
      functionLabel: '',
      trainersList: [],
      //联络人信息
      trainingContactData: [],
      queryParams: {
        delFlag: 1,
        pageNum: 0,
        pageSize: 10
      },
      total: 0,
      isDAoverdue: false,
      extraParams: {}
    }
  },
  computed: {
    dealerInfo() {
      return this.$store.state.user.baseInfo
    },
    setPageSize() {
      return this.queryParams.pageNum * this.queryParams.pageSize
    }
  },
  created() {
    if (this.$route.query?.id) {
      this.extraParams.id = this.$route.query?.id
    }
    // this.queryParams.dealerIds = this.dealerInfo.rocheDealerId
    // this.onLoad()

    getDicts('sys_trainer_function').then(({ data }) => {
      this.sys_trainer_function = data
    })
    getDicts('sys_trainer_status').then(({ data }) => {
      this.sys_trainer_status = data
    })
    // this.getList()
    this.getTrainingContact()

    //da合同是否过期
    this.isDAoverdue = this.$store.state.user.userInfo.isDealerExpire
  },
  methods: {
    checkIsDAoverdue() {
      if (this.isDAoverdue) {
        this.$toast.fail({
          message: '当前经销商DA已过期，无法操作！',
          className: 'fail'
        })
        return true
      } else {
        return false
      }
    },
    getCheckPermi(value) {
      return checkPermi([value])
    },
    onLoad() {
      this.loading = true
      // 设置定时器做节流处理，避免滚动时频繁触发加载事件
      if (!this.timer) {
        // if (this.refreshing) {
        //   this.trainersList = []
        //   this.refreshing = false
        // }
        this.timer = setTimeout(() => {
          this.queryParams.pageNum++
          this.getList('load')
          // 清除定时器，释放内存
          this.timer = null
        }, 1000)
      }
    },
    getList(type) {
      this.loading = true
      let requestParams = {
        ...this.extraParams,
        ...this.queryParams
      }
      getTrainers(requestParams)
        .then(response => {
          console.log(response)
          this.total = response.total || 0
          this.loading = false
          this.refreshing = false
          if (type == 'load') {
            this.trainersList = [
              ...this.trainersList,
              ...response.rows.map(item => {
                return {
                  ...item
                }
              })
            ]
          } else {
            this.trainersList = response.rows || []
          }
          if (this.trainersList.length >= this.total) {
            this.finished = true
          }
        })
        .catch(() => {
          this.finished = true
        })
    },

    statusLabel(data) {
      return this.sys_trainer_status.filter(itemA => data?.toString() === itemA.dictValue.toString())[0]?.dictLabel
    },
    viewChangeLog(data) {
      if (!data.trainer_history || data.trainer_history.length === 0) {
        this.$toast.fail('该数据无变更日志')
        return
      }
      this.$router.push({
        path: '/userCenter/students/changeLog',
        query: { data: JSON.stringify(data.trainer_history), isUpdatedUser: true, routeFromName: JSON.stringify('ST') }
      })
    },
    getTrainingContact() {
      this.loading = true
      // const dealerId = { dealerId: this.queryParams.dealerIds }
      getTrainContactInformation()
        .then(response => {
          if (response.code === 200) {
            this.trainingContactData = [...response.data]
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 编辑按钮操作 */
    handleUpdate(row) {
      if (this.checkIsDAoverdue()) {
        return
      }
      if (this.trainingContactData.length === 0) {
        this.$toast.fail('当前经销商没有培训联络人，请在经销商联络人管理中新增1名培训联络人！')
        return
      } else if (this.trainingContactData.length > 1) {
        this.$dialog
          .confirm({
            title: '提示',
            message: '请确保只有一个培训联络人，如果存在多个培训联络人，建议删除只保留一个培训联络人！',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            showCancelButton: false
          })
          .then(() => {})
          .catch(() => {})
      } else {
        if (row.delMark === '删除') return
        this.$router.push({
          name: 'StudentsDetail',
          query: row
        })
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      if (this.checkIsDAoverdue()) {
        return
      }
      if (row.delMark === '删除') return
      this.$dialog
        .alert({
          message: `是否确认删除该学员信息？`,
          showCancelButton: true
        })
        .then(() => {
          this.loading = true
          delTrainers(row.trainerId).then(res => {
            if (res.code === 200) {
              this.$toast.success('删除成功')
              this.getList()
            } else {
              this.$toast.fail(res.msg)
            }
            this.loading = false
          })
        })
        .catch(() => {})
    },
    onSelect(data) {
      if (data.text === '新增') {
        if (this.checkIsDAoverdue()) {
          return
        }
        if (this.trainingContactData.length === 0) {
          this.$toast.fail('当前经销商没有培训联络人，请在经销商联络人管理中新增1名培训联络人！')
          return
        } else if (this.trainingContactData.length > 1) {
          this.$dialog
            .confirm({
              title: '提示',
              message: '请确保只有一个培训联络人，如果存在多个培训联络人，建议删除只保留一个培训联络人！',
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              showCancelButton: false
            })
            .then(() => {})
            .catch(() => {})
        } else {
          this.$router.push({
            name: 'StudentsDetail',
            query: { dealerId: this.dealerInfo.rocheDealerId, ...this.trainingContactData[0] }
          })
        }
      }
      if (data.text === '导出') {
        this.download('/bff-dp-mobile/trainers/export', {}, `学员信息_${new Date().getTime()}.xlsx`, {
          method: 'get',
          params: { ...this.queryParams, pageNum: 1, pageSize: this.setPageSize }
        })
      }
    },
    onConfirmFunctionList(data) {
      console.log(data)
      this.functionLabel = data.dictLabel
      this.queryParams.function = data.dictValue
      this.showPickerFunction = false
    },
    filterData() {
      this.show = true
    },
    cancel() {
      this.show = false
      // this.reset()
    },
    reset() {
      this.queryParams = {
        delFlag: 1,
        pageNum: 1,
        pageSize: 10
      }
      this.functionLabel = ''
    },
    /** 确定按钮操作 */
    handleQuery() {
      this.extraParams = {}
      const { path } = this.$route
      const query = { ...this.$route.query }
      delete query.id
      this.$router.replace({ path, query })
      this.onRefreshBySearch()
      // this.getList()
      this.cancel()
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams = {
        delFlag: 1,
        pageNum: 1,
        pageSize: 10
      }
      this.total = 0
      this.trainersList = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.getList()
    },
    onRefreshBySearch() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.queryParams.pageNum = 0
      console.log('onRefreshBySearch', this.queryParams)
      this.total = 0
      this.trainersList = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    parseTime(time) {
      if (time) {
        return dayjs(time).format('YYYY-MM-DD')
      } else return null
    }
  }
}
</script>

<style lang="less" scoped>
.van-cell .van-cell__title {
  flex: none;
  width: 35%;
}
.margin-lf-10 {
  margin-left: 10px;
}
.list-bg {
  min-height: 100vh;
  padding: 20px 16px;
  //background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
}
.theme-color {
  color: @blue;
}
.van-icon {
  margin-right: 5px;
}
.popover,
.tab-right-btn {
  color: #0b41cd;
  font-size: 16px;
  position: absolute;
  height: 44px;
  right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
.header {
  padding: 0 2px 12px 2px;
  .total {
    font-size: 14px;
    line-height: 24px;
    color: #969799;
  }
  .filter {
    color: #0b41cd;
    font-size: 14px;
  }
}
.popup-box {
  overflow-y: hidden;
  height: 450px;
  .group-box {
    margin-top: 40px;
    height: 340px;
    overflow-y: auto;
    z-index: 10;
  }
  .popup-foot-btn {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    text-align: center;
    height: 36px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    .btn {
      width: 80px;
    }
  }
}
.collapse {
  padding: 2px;
  .van-collapse-item {
    // overflow: hidden;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe) !important;
    /deep/.van-collapse-item__content {
      border-radius: 0 0 8px 8px;
    }
    .icon {
      font-size: 48px;
    }
    .card-text {
      color: #8c8c8c;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        // margin-bottom: 2px;
      }
      .email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
      .status {
        // margin-bottom: 2px;
        font-size: 12px;
        .table-status {
          display: flex;
          justify-content: right;
          align-items: center;
          .dotClass {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
      .phone {
        font-size: 12px;
        text-align: end;
      }
    }
    .training-contact {
      .van-cell__title {
        span {
          font-weight: 600;
          font-size: 13px;
        }
      }
    }
    /deep/.van-cell {
      align-items: center;
      padding: 10px 8px;
    }
    .diy-cell-value {
      color: #969799;
      text-align: right;
    }

    .collapse-content {
      /deep/.van-cell {
        align-items: normal;
        font-size: 12px;
        line-height: 14px;
        padding-left: 0;
      }

      .table-mark {
        margin-left: 8px;
        padding: 0.2vw 0.8vw;
        border-radius: 12px;
      }
      .mark-del {
        background-color: #ebeef5;
      }
    }
    .foot-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      color: #0b41cd;
      .vertical-line {
        margin: 0 8px;
      }
    }
    .disable-button {
      color: #a0cfff;
    }
  }
}

.divider {
  border-bottom: 0.5px solid #ebedf0;
}
</style>
