// 导入axios
import axios from 'axios'
import errorCode from '@/utils/errorCode'
import {
  getToken,
  goSSO,
  setToken,
  setIsNeedChangeDealer,
  setSelectedDealer,
  setRefreshToken,
  removeToken,
  removeRefreshToken
} from '@/utils/auth'
import { saveAs } from 'file-saver'
import Vue from 'vue'
import store from '@/store'
import { ssoLogout, refreshToken } from '@/api/login'

// 是否显示重新登录
export let isRelogin = { show: false }
// 使用自定义配置新建一个axios 实例，对axios 做一些基础配置
const baseURL = process.env.NODE_ENV === 'development' ? '/dev-api' : 'http://139.129.23.128/:9206'
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 30 * 10000,
  headers: { 'X-Custom-Header': 'foobar' }
})

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    const isToken = (config.headers || {}).isToken === false
    //请求之前执行该函数， 一般在该处设置token
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params)
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }

    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

//响应拦截器
//标志当前是否正在刷新token
var isRefreshing = false
//请求队列
var requests = []
instance.interceptors.response.use(
  response => {
    // 二进制数据则直接返回
    if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
      return response
    }
    const code = response.data.code || 200
    // console.log('请求接口：' + response.config.url + '，请求响应code：' + code)
    // 获取当前请求信息
    const config = response.config
    if (code === 401) {
      //尝试刷新token
      if (!isRefreshing) {
        isRefreshing = true
        return refreshToken()
          .then(resp => {
            if (resp.data) {
              console.log('token刷新成功')
              const token = resp.data.access_token
              //获取到最新的token，替换已过期的token
              setToken(token)
              config.headers['Authorization'] = `Bearer ${token}`
              // 已经刷新了token，将所有队列中的请求进行重试
              requests.forEach(cb => cb(token))
              requests = []
              return instance(config)
            }
          })
          .catch(error => {
            console.log('token刷新异常')
            if (!isRelogin.show) {
              isRelogin.show = true
              Vue.prototype.$dialog
                .alert({
                  title: '系统提示',
                  message: `登录状态刷新失败，请重新登录`,
                  confirmButtonText: '重新登录',
                  showCancelButton: false
                })
                .then(() => {
                  ssoLogout().finally(() => {
                    isRelogin.show = false
                    store.dispatch('LogOut').then(() => {
                      goSSO()
                    })
                  })
                })
                .catch(() => {
                  isRelogin.show = false
                  store.dispatch('LogOut').then(() => {
                    goSSO()
                  })
                })
            }
            return Promise.reject('登录状态刷新失败，请重新登录。')
          })
          .finally(() => {
            console.log('token刷新最终执行')
            isRefreshing = false
          })
      } else {
        console.log('token正在刷新，pending')
        // 正在刷新token，将返回一个未执行resolve的promise
        // 保存请求，等刷新完成后再一个一个调用
        return new Promise(resolve => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push(token => {
            config.headers['Authorization'] = `Bearer ${token}`
            resolve(instance(config))
          })
        })
      }
    } else if (code === 511) {
      console.log('token刷新失败，refreshToken已经过期，需要重新登录')
      if (!isRelogin.show) {
        isRelogin.show = true
        Vue.prototype.$dialog
          .alert({
            title: '系统提示',
            message: `登录状态已过期，请重新登录`,
            confirmButtonText: '重新登录',
            showCancelButton: false
          })
          .then(() => {
            ssoLogout().finally(() => {
              isRelogin.show = false
              store.dispatch('LogOut').then(() => {
                goSSO()
              })
            })
          })
          .catch(() => {
            isRelogin.show = false
            store.dispatch('LogOut').then(() => {
              goSSO()
            })
          })
      }
      return Promise.reject('登录状态已过期，请重新登录。')
    } else if (code === 500) {
      Vue.prototype.$toast.fail(response.data.msg)
      return Promise.reject(response.data.msg)
    }
    return response.data
  },
  error => {
    console.log('err' + error)
    let { message } = error
    if (message.indexOf('Network Error') > -1) {
      message = '当前网络异常，请检查网络设置'
    } else if (message.includes('timeout')) {
      message = '当前网络异常，请检查网络设置'
    } else if (message.includes('Request failed with status code')) {
      message = '当前网络异常，请检查网络设置'
    }
    Vue.prototype.$toast.fail(message)
    return Promise.reject(message)
  }
)
// 通用下载方法
export function download(url, params, filename, config) {
  Vue.prototype.$loading.show()
  return instance
    .request({
      url,
      method: 'POST',
      transformRequest: [
        () => {
          return ''
        }
      ],
      data: params,

      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob',
      ...config
    })
    .then(async res => {
      console.log(res)
      const data = res.data
      const isBlob = blobValidate(data)
      if (isBlob) {
        const blob = new Blob([data])
        let fileN = undefined
        if (!filename) {
          fileN = decodeURIComponent(res.headers['content-disposition'].split('=')[1])?.split('+')?.join(' ')
          console.log(fileN)
        }
        saveAs(blob, fileN || filename)
      } else {
        const resText = await data.text()
        const rspObj = JSON.parse(resText)
        const errMsg = rspObj.msg
        Vue.prototype.$toast(errMsg)
      }
      Vue.prototype.$loading.hide()
    })
    .catch(err => {
      Vue.prototype.$loading.hide()
      Vue.prototype.$toast('下载文件出现错误，请联系管理员！')
    })
}

export function preview(url, params, filename, config) {
  Vue.prototype.$loading.show()
  return instance
    .request({
      url,
      method: 'POST',
      transformRequest: [
        () => {
          return ''
        }
      ],
      data: params,

      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob',
      ...config
    })
    .then(async res => {
      console.log(res)
      let fileN = undefined
      if (!filename) {
        fileN = decodeURIComponent(res.headers['content-disposition'].split('=')[1])?.split('+')?.join(' ')
        console.log(fileN)
      }
      const data = res.data
      const isBlob = blobValidate(data)
      if (isBlob) {
        if (filename?.toUpperCase().indexOf('.PDF') > -1) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          let a = document.createElement('a')
          a.setAttribute('href', url)
          document.body.appendChild(a)
          a.click()
          a.remove()
          //   window.open(url)
        } else if (
          filename?.toUpperCase().indexOf('.PNG') > -1 ||
          filename?.toUpperCase().indexOf('.JPG') > -1 ||
          filename?.toUpperCase().indexOf('.JPEG') > -1
        ) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'image/jpeg' }))
          let a = document.createElement('a')
          a.setAttribute('href', url)
          document.body.appendChild(a)
          a.click()
          a.remove()
        } else {
          const blob = new Blob([data])
          saveAs(blob, filename)
        }
      } else {
        const resText = await data.text()
        const rspObj = JSON.parse(resText)
        const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
        Vue.prototype.$toast(errMsg)
      }
      Vue.prototype.$loading.hide()
    })
    .catch(r => {
      console.log(r)
      Vue.prototype.$toast('查询文件出现错误，请联系管理员！')
      Vue.prototype.$loading.hide()
    })
}

function IsPhone() {
  //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
  var info = navigator.userAgent
  console.log(info)
  //通过正则表达式的test方法判断是否包含“Mobile”字符串
  var isPhone = /mobile/i.test(info)
  console.log(isPhone)
  //如果包含“Mobile”（是手机设备）则返回true
  return isPhone
}

// 验证是否为blob格式
function blobValidate(data) {
  return data.type !== 'application/json'
}

export function mockRequest(res) {
  return Promise.resolve(res)
}

function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    var part = encodeURIComponent(propName) + '='
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== '' && typeof value[key] !== 'undefined') {
            let params = propName + '[' + key + ']'
            var subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    }
  }
  return result
}

export default instance
