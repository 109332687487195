import request, { download } from '@/utils/request'
import Vue from 'vue'

function deleteApi(url, obj) {
  return request({
    url: url,
    method: 'post',
    data: {
      ...obj
    }
  })
}

export const dataCenter = {
  delete: function Delete(apiUrl, obj, baseUrl = 'dealer') {
    const url = `/${baseUrl}${apiUrl}`
    console.log(apiUrl)
    deleteApi(url, obj)
  },
  downloadFile: (list, filedName = 'fileId', waterMarkFlag = true) => {
    if (list.length === 0) {
      Vue.prototype.$toast('请先勾选要操作的记录')
      return false
    }
    const selectedList = [...list]
    const selectedFileIdList = selectedList.map(item => {
      return item[filedName]
    })
    const fileIds = selectedFileIdList.join(',')
    download('/file/multi-download', {}, null, {
      method: 'POST',
      params: {
        watermarkFlag: waterMarkFlag,
        fileIds
      }
    })
  }
}
