<template>
  <van-row class="main-container">
    <van-row class="title">
      <van-col>重要提醒</van-col>
    </van-row>
    <van-row class="line" v-if="noticeData.length > 0">
      <div class="item" v-for="(item, index) in noticeData" :key="index" @click="goDetail(item)">
        <van-image class="image" :src="require('@/assets/images/home-notice3.png')" />
        <div class="desc">{{ item.noticeTitle }}</div>
        <div class="number">{{ item.sendTime | noticeDateFormat }}</div>
      </div>
    </van-row>
    <van-row class="line" v-if="noticeData.length === 0 && loadingFLag">
      <div class="no-data">暂无数据</div>
    </van-row>
  </van-row>
</template>
<script>
import { receiveNoticeList } from '@/api/notice'

export default {
  name: 'ReminderCard',
  props: {},
  data() {
    return {
      noticeData: [],
      loadingFLag: false
    }
  },
  created() {
    this.getUrgentNoticeList()
  },
  methods: {
    getUrgentNoticeList() {
      let params = {
        pageNum: 1,
        pageSize: 5,
        stationType: 'NOTICE',
        urgentFlag: 'Y'
      }
      receiveNoticeList(params)
        .then(res => {
          this.loadingFLag = true
          this.noticeData = res.rows
        })
        .catch(err => {
          this.loadingFLag = true
          console.log(err)
        })
    },
    goDetail(obj) {
      this.$router.push({
        path: '/notice/detail',
        query: { from: 'INDEX', noticeId: obj.noticeReceiveId }
      })
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  z-index: 1;
  overflow: hidden;
  .title {
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
    border-radius: 0 0 0 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
  }
}
.line {
  padding: 16px 13px 23px;
  background: #fff;
  .item {
    width: 100%;
    display: flex;
    min-height: 50px;
    align-items: center;
    .image {
      width: 25px;
      height: 25px;
      max-width: 25px;
      max-height: 25px;
      margin-right: 5px;
    }
    .desc {
      width: 250px;
      font-weight: bold;
      font-size: 12px;
      color: #262626;
    }
    .number {
      font-weight: 400;
      font-size: 12px;
      color: #8c8c8c;
      text-align: right;
      width: 80px;
    }
  }
  .no-data {
    display: flex;
    min-height: 50px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}
</style>
