<!-- 我的 -->
<template>
  <div class="user-box">
    <div class="list-bg" />
    <van-nav-bar title="变更日志" left-arrow @click-left="goBack"> </van-nav-bar>
    <van-list style="padding: 0 0.4rem 50px">
      <van-list>
        <van-collapse v-model="activeNames" class="collapse" disabled>
          <van-collapse-item name="index" v-for="item in list" :key="item.title" :is-link="false" disabled>
            <template #title>
              <van-row type="flex" align="center" class="card">
                <van-col
                  ><van-icon class="iconfont menu-icon" class-prefix="icon" name="menu-yiqi" size="24"
                /></van-col>
                <van-row class="name"><div v-html="item.fieldName"></div></van-row>
                <!--                <van-col span="10" class="card-text">{{-->
                <!--                  dayjs(item.updateTime).format('YYYY-MM-DD HH:mm:ss')-->
                <!--                }}</van-col>-->
              </van-row>
            </template>
            <div class="collapse-content">
              <van-row span="24" class="card-line">
                <van-col class="label">变更前</van-col>
                <van-col class="value">{{ item.beforeChangeValue }} </van-col>
              </van-row>
              <van-row class="card-line">
                <van-col class="label">变更后</van-col>
                <van-col class="value">{{ item.afterChangeValue }} </van-col>
              </van-row>
              <van-row class="card-line">
                <van-col class="label">变更时间</van-col>
                <van-col class="value">{{ item.updateTime }} </van-col>
              </van-row>
              <van-row v-if="isUpdatedUser" class="card-line">
                <van-col class="label">变更人</van-col>
                <van-col class="value">{{ item.updatedUser }} </van-col>
              </van-row>
            </div>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-list>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'VadInstrumentChangeLog',
  data() {
    return {
      activeNames: ['index'],
      list: [],
      isUpdatedUser: false,
      routeFromName: ''
    }
  },
  created() {
    console.log(this.$route.query.data)
    this.list = JSON.parse(this.$route.query.data)
    this.isUpdatedUser = this.$route.query.isUpdatedUser ? JSON.parse(this.$route.query.isUpdatedUser) : false
    this.routeFromName = this.$route.query.routeFromName ? JSON.parse(this.$route.query.routeFromName) : ''
  },
  methods: {
    dayjs,
    handleChange() {
      this.activeNames = ['index']
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
}
.collapse {
  .van-collapse-item {
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe) !important;
    /deep/.van-collapse-item__content {
      border-radius: 0 0 8px 8px;
    }
    .card-line {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 16px;
      display: flex;
      .label {
        color: #8c8c8c;
        width: 60px;
        min-width: 60px;
      }
      .text {
        color: #262626;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    /deep/.van-cell--clickable:active {
      background-color: transparent;
    }
  }
}

.name {
  font-size: 14px;
  font-weight: 500;
  color: #262626 !important;
}
.card-text {
  margin-left: auto;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
}

.menu-icon {
  color: #0b41cd;
  margin-right: 8px;
}
</style>
