<script>
export default {
  name: 'Empty'
}
</script>

<template>
  <div class="empty">暂无数据</div>
</template>

<style scoped>
.empty {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
}
</style>
