<template>
  <van-row class="main-container">
    <van-row class="title">
      <van-col>授权</van-col>
    </van-row>
    <van-row class="line">
      <div class="item" v-for="(item, index) in loaResult" :key="index" @click="() => goToDetail(item)">
        <div class="right-item">
          <div class="desc">{{ item.productLine }}-终端数量</div>
          <div class="num">{{ item.customerCount }}</div>
        </div>
      </div>
      <div class="item" v-if="loaResult.length === 0" @click="goToDetail">
        <div class="right-item">
          <div class="desc">终端数量</div>
          <div class="num">0</div>
        </div>
      </div>
    </van-row>
    <van-image class="bg-img" :src="require('@/assets/images/home-credit.svg')" />
  </van-row>
</template>
<script>
import { getLOAResult } from '@/api/home'
import Empty from '@/views/home/components/Empty.vue'

export default {
  name: 'Auth',
  components: { Empty },
  props: {},
  data() {
    return {
      loaResult: [],
      currentTime: new Date().getFullYear() + '年 ' + (new Date().getMonth() + 1) + '月'
    }
  },
  created() {
    this.getLOAResult()
  },
  methods: {
    getLOAResult() {
      let params = {}
      getLOAResult(params)
        .then(res => {
          this.loaResult = [...res.data]
        })
        .catch(err => {
          console.log(err)
        })
    },
    goToDetail(obj) {
      this.$router.push({
        path: '/dataCenter/daAndLoa/loa',
        query: { overviewFlag: 'Y', productLine: obj.productLine != '' ? obj.productLine : undefined }
      })
    }
  }
}
</script>

<style scoped lang="less">
.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  background: linear-gradient(180deg, rgba(249, 248, 255, 0.3) 0%, #f1ecff 100%), #fff;
  z-index: 1;
  overflow: hidden;
  min-height: 180px;
  .title {
    border-radius: 0 0 100px 0;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    background: linear-gradient(90deg, #dfd4fd 0%, rgba(243, 239, 255, 0.55) 100%);
    border-bottom: 1px solid #dfd4fd;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    .date {
      color: #595959;
      font-size: 12px;
      font-weight: 400;
      margin-left: auto;
    }
  }
}
.line {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px 10px 10px 15px;
  .item {
    width: 33%;
    display: flex;
    line-height: 1;
    height: 50px;
    //justify-content: center;
    align-items: center;
    margin-top: 5px;
    .image {
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }
    .right-item {
      font-size: 28px;
      color: #262626;
      font-weight: 400;
      .num {
        font-size: 18px;
        margin-top: 10px;
      }
      .desc {
        font-size: 12px;
        color: #8c8c8c;
        font-weight: 400;
        //margin-top: 8px;
      }
    }
  }
}
.empty-container {
  height: 130px;
  padding-top: 10px;
}
.bg-img {
  width: 75px;
  height: 75px;
  opacity: 0.4;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
</style>
