export const queryUrl = '/bff-web/kpi-assessmentResult/getListByDealer'
export const listTableOptions = [
  // {
  //   prop: 'title',
  //   label: '文件名'
  //   // type: 'fileName'
  // },
  {
    prop: 'periodValue',
    label: '周期',
    type: 'fileName',
    hideFileIcon: true
  },
  // {
  //   prop: 'baValue',
  //   label: 'KPI类型',
  //   dictCode: 'ba_type',
  //   options: []
  // },
  // {
  //   props: 'productLineValue',
  //   label: '产品线'
  // },
  {
    prop: 'appealDeadline',
    label: '申诉截止日期'
  },
  {
    prop: 'createTime',
    label: '上传时间',
    type: 'createTime'
  }
]

export const searchFormData = [
  {
    props: 'year',
    label: '年份',
    type: 'date',
    valueFormat: 'yyyy',
    dateFormat: 'yyyy',
    dateType: 'year'
  },
  {
    props: 'baValue',
    label: 'KPI类型',
    type: 'DictSelect',
    dictCode: 'ba_type',
    multiple: false,
    customChange: data => {
      if (data.baValue == 2 || data.baValue === '运营') {
        data.productLineValue = null
      }
      return data
    },
    showRule: data => {
      if (data.dictValue == 1) {
        return false
      } else {
        return true
      }
    }
  },
  {
    props: 'productLineValue',
    label: '产品线',
    type: 'DictSelect',
    dictCode: 'sys_product_line',
    multiple: false,
    disableRule: data => {
      return data.baValue == '2' || data.baValue === '运营'
    }
  }
]
