<template>
  <div>
    <form-table
      ref="baseForm"
      :queryOptions="searchFormData"
      :tableOptions="tableOptions"
      :headerTitle="headerTitle"
      :queryUrl="queryUrl"
      @clickBtn="onSelect"
      :query-permission="['system:notice:query']"
      :is-need-operate="false"
      :forcePDF="true"
    >
      <template #column-item="scope">
        <div class="item" @click="onCardClick(scope.row)">
          <div class="name">{{ scope.row.performanceYear }}_{{ scope.row.performancePeriod }}_VAD绩效</div>
        </div>
      </template>
    </form-table>
  </div>
</template>
<script>
import { getDicts } from '@/api/dict/data'
import { tableOptions, queryUrl, searchFormData } from './config'
import { dataCenter } from '@/utils/dataCenter'
export default {
  data() {
    return {
      headerTitle: '绩效',
      queryUrl: queryUrl,
      tableOptions: tableOptions,
      searchFormData: searchFormData,
      periodOptions: []
    }
  },
  created() {
    this.initDict()
  },
  methods: {
    async initDict() {
      this.periodOptions = await getDicts('period_value').then(({ data }) => {
        return data.filter(item => item.dictValue !== 'annual')
      })
      this.searchFormData.forEach(element => {
        if (element.props === 'performancePeriod') {
          element.options = [...this.periodOptions]
        }
      })
    },
    onCardClick(obj) {
      let fileName = `${obj.performanceYear}_${obj.performancePeriod}_VAD绩效`
      this.$router.push({
        path: '/dataCenter/customerService/score/dataDetail',
        query: {
          id: obj.mergeId,
          title: encodeURIComponent(fileName)
        }
      })
    },
    onSelect(text, queryParams, list) {
      dataCenter.downloadFile(list)
    }
  }
}
</script>
<style scoped lang="less">
.item {
  color: #0b41cd;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  justify-content: space-between;
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.name {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
