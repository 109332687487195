<template>
  <div class="list-bg">
    <van-search v-model="searchResult" placeholder="请输入搜索关键词" @search="onSearch" show-action :clearable="false">
      <template #right-icon>
        <van-icon name="clear" v-if="searchResult != ''" @click="onClear" />
      </template>
      <template #action>
        <div @click="onSearch(searchResult)">搜索</div>
      </template>
    </van-search>
    <div class="noSearch" v-if="NoSearchResult">
      {{ tips }}
    </div>

    <van-list v-else class="padding">
      <van-collapse v-model="activeNames" class="collapse" accordion>
        <van-collapse-item :name="tab.documentType" v-for="tab in tabs" :key="tab.documentType">
          <template #title>
            <span class="name"> {{ filterTitle(tab.documentType) }}</span>
            <van-badge :content="tab.count" color="#0b41cd"> </van-badge>
          </template>
          <div class="collapse-content">
            <!-- <van-pull-refresh v-model="tab.refreshing" @refresh="onRefresh(tab)"> -->
            <van-list v-model="tab.listLoading" :finished="tab.finished" finished-text="没有更多了" @load="onLoad(tab)">
              <ul class="ul">
                <li v-for="(result, i) in tab.results" :key="i">
                  <div class="wrapper">
                    <div>
                      <img
                        class="icon"
                        :src="require('@/assets/images/pdf.svg')"
                        v-if="result.fileName && getFileType(result.fileName) === '.PDF'"
                      />
                      <img
                        class="icon"
                        :src="require('@/assets/images/excel.svg')"
                        v-else-if="
                          result.fileName &&
                          (getFileType(result.fileName) === '.XLS' || getFileType(result.fileName) === '.XLSX')
                        "
                      />
                      <img
                        class="icon"
                        :src="require('@/assets/images/word.svg')"
                        v-else-if="
                          result.fileName &&
                          (getFileType(result.fileName) === '.DOC' || getFileType(result.fileName) === '.DOCX')
                        "
                      />
                      <img
                        class="icon"
                        :src="require('@/assets/images/png.svg')"
                        v-else-if="result.fileName && getFileType(result.fileName) === '.PNG'"
                      />
                      <img
                        class="icon"
                        :src="require('@/assets/images/jpg.svg')"
                        v-else-if="
                          result.fileName &&
                          (getFileType(result.fileName) === '.JPG' || getFileType(result.fileName) === '.JPEG')
                        "
                      />
                      <img class="icon" :src="require('@/assets/images/file.svg')" v-else />
                    </div>
                    <div class="details">
                      <div v-html="highlighted(result.fileName)" @click="goToPage(result)" class="title"></div>
                      <div class="content" v-html="result.content ? '...' + result.content : ''"></div>
                    </div>
                  </div>
                </li>
              </ul>
            </van-list>
            <!-- </van-pull-refresh> -->
          </div>
        </van-collapse-item>
      </van-collapse>
    </van-list>
    <!-- <van-tabs v-model="active" class="tab-new" @change="tabChange" v-else>
      <van-tab :title="tab.documentType" :name="tab.documentType" v-for="tab in tabs" :key="tab.documentType">
        <template #title>
          {{ TYPE_MAP[tab.documentType] ? TYPE_MAP[tab.documentType].title : '其他' }}
          <van-badge :content="tab.count" color="#0b41cd"> </van-badge>
        </template>
        <van-row>
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
              <ul class="ul">
                <li v-for="(result, i) in results" :key="i">
                  <div class="wrapper">
                    <div>
                      <img
                        class="icon"
                        :src="require('@/assets/images/pdf.svg')"
                        v-if="result && result.fileName.indexOf('.pdf') > -1"
                      />
                      <img
                        class="icon"
                        :src="require('@/assets/images/excel.svg')"
                        v-else-if="
                          (result && result.fileName.indexOf('.xlsx') > -1) ||
                          (result && result.fileName.indexOf('.xls') > -1)
                        "
                      />
                      <img class="icon" :src="require('@/assets/images/file.svg')" v-else />
                    </div>
                    <div class="details text-hide1">
                      <div v-html="highlighted(result.fileName)" class="text-hide1"></div>
                      <div style="cursor: pointer" @click="goToPage(result)">查看详情 ></div>
                    </div>
                  </div>
                </li>
              </ul>
            </van-list>
          </van-pull-refresh>
        </van-row>
      </van-tab>
    </van-tabs> -->
  </div>
</template>

<script>
import { TYPE_MAP } from './map'
import { getSearchTypeAndCount, getListByType } from '@/api/search'
export default {
  name: 'Search',
  data() {
    return {
      active: 'dealerContact',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      NoSearchResult: true,
      timer: null,
      results: [],
      TYPE_MAP: TYPE_MAP,
      tabs: [],
      activeName: '加载中ing...',
      activeNames: [],

      searchResult: '',
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      hasSearch: false
    }
  },
  computed: {
    tips() {
      let str = ''
      if (this.loading) {
        str = '搜索中...'
        return str
      }
      if (!this.hasSearch) {
        str = '请在上方搜索栏，输入关键字查询相关数据'
      }
      if (this.NoSearchResult && this.searchResult && this.hasSearch) {
        str = '暂无数据'
      }
      return str
    },
    allRoutes() {
      return this.$store.state.user.allRoutes
    }
  },
  created() {
    this.getTypeAndCount(this.searchResult)
  },
  methods: {
    filterTitle(documentType) {
      let title = ''
      if (this.TYPE_MAP[documentType]) {
        this.allRoutes.forEach(route => {
          if (route.path == this.TYPE_MAP[documentType].url) {
            title = route.menuName
          }
        })
        title = title || this.TYPE_MAP[documentType].title
      } else {
        title = '其他'
      }
      return title
    },
    highlighted(originalString) {
      const searchTerm = this.searchResult
      const regex = new RegExp('(' + searchTerm + ')', 'gi') // 使用捕获组
      return originalString.replace(regex, '<span class="highlighted">$1</span>') // 使用 $1 引用捕获的搜索词
    },
    onCancel() {
      this.$router.go(-1)
    },
    onClear() {
      this.searchResult = ''
      this.hasSearch = false
      this.NoSearchResult = true
    },
    onSearch(val) {
      if (!val) return
      this.hasSearch = true
      console.log(val)
      this.searchResult = val
      this.getTypeAndCount(this.searchResult)
    },
    getTypeAndCount(search) {
      if (!search) return
      this.NoSearchResult = true
      this.loading = true
      this.tabs = []
      getSearchTypeAndCount(encodeURIComponent(search)).then(({ data, code, msg }) => {
        this.loading = false
        if (code == 200) {
          let sumCount = 0
          this.tabs = data?.list.map(item => {
            sumCount += item.count
            return {
              ...item,
              queryParams: {
                pageNum: 0,
                pageSize: 10
              },
              total: 0,
              results: [],
              listLoading: false,
              refreshing: false,
              finished: false
            }
          })
          this.tabs = this.tabs.filter(item => item.documentType != null)
          console.log(sumCount)
          if (data?.list.length == 0) {
            this.NoSearchResult = true
          } else {
            this.NoSearchResult = false
          }
        } else {
          this.$message.error(msg)
        }
      })
    },
    getFileType(fileName) {
      const fileType = fileName.substr(fileName.lastIndexOf('.'), fileName.length - 1).toUpperCase()
      return fileType
    },
    getList(tab) {
      console.log('getList')
      let _tab = this.tabs.filter(item => item.documentType == tab.documentType)[0]
      _tab.listLoading = true
      if (!this.activeNames) {
        return
      }
      _tab.queryParams = {
        ..._tab.queryParams,
        type: this.activeNames,
        fileName: encodeURIComponent(this.searchResult)
      }
      _tab.total = 0
      getListByType(_tab.queryParams)
        .then(({ rows, code, msg, total }) => {
          _tab.listLoading = false
          if (code == 200) {
            _tab.results = [
              ..._tab.results,
              ...rows.map(item => {
                return {
                  ...item
                }
              })
            ]
            console.log(_tab.results.length)
            _tab.total = total
            if (_tab.results.length >= _tab.total) {
              _tab.finished = true
            }
          } else {
            this.$message.error(msg)
          }
        })
        .catch(() => {
          _tab.listLoading = false
        })
    },
    goToPage(item) {
      if (!TYPE_MAP[item.documentType]) return
      let queryInfo = { id: item.id }
      if (item?.channelDataType == 'Submission') {
        queryInfo = { id: item.id, channelType: 'Submission' }
      }
      if (item?.channelDataType == 'Inventory') {
        queryInfo = { id: item.id, channelType: 'Inventory' }
      }
      if (item?.channelDataType == 'Account') {
        queryInfo = { id: item.id, channelType: 'Account' }
      }
      if (item?.channelDataType == 'Student') {
        queryInfo = { id: item.id, channelType: 'Student' }
      }
      if (item?.documentType == 'ALL_CHANNEL_DATA') {
        queryInfo = { id: item.id, title: '渠道数据' }
      }
      this.$router.push({
        path: TYPE_MAP[item.documentType].url,
        query: queryInfo
      })
    },
    onRefresh(tab) {
      // 清空列表数据
      tab.finished = false
      // 重新加载数据
      tab.queryParams = {
        pageNum: 1,
        pageSize: 10
      }
      tab.total = 0
      tab.results = []
      // 将 loading 设置为 true，表示处于加载状态
      tab.loading = true
      this.onLoad(tab)
    },
    onLoad(tab) {
      console.log('onLoad')
      if (!this.activeNames) return
      tab.loading = true
      // 设置定时器做节流处理，避免滚动时频繁触发加载事件
      if (!this.timer) {
        this.timer = setTimeout(() => {
          tab.queryParams.pageNum++
          this.getList(tab)
          // 清除定时器，释放内存
          this.timer = null
        }, 1000)
      }
    },
    tabChange(name) {
      this.activeName = name
      this.queryParams.pageNum = 1
      // let filteredTabs = this.tabs.filter(item => item.documentType == this.activeName)
      // this.skeletonRows = filteredTabs.length > 0 ? filteredTabs[0].count * 3 : 4
      this.onRefresh()
    }
  }
}
</script>

<style lang="less" scoped>
.list-bg {
  min-height: calc(100vh - 55px);
  padding-bottom: 30px;
  background: linear-gradient(268deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}

.dotClass {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: block;
  margin-top: 8px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.notice-type {
  background-color: #ced9f5;
  color: #0b41cd;
  font-size: 12px;
  padding: 0px 5px;
  border-radius: 4px;
  min-width: 50px;
}

.notice-title {
  padding: 0px 10px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #0b41cd;
}

.divNormal {
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #8c8c8c;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

.divActive {
  border-radius: 4px;
  background-color: #0b41cd;
  border: 1px solid #0b41cd;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
}

/deep/.van-tabs__nav {
  background: transparent;
}
/deep/.van-tabs--line .van-tabs__wrap {
  width: 100%;
}
/deep/.van-tab__text--ellipsis {
  line-height: 1.75;
}
/deep/.van-tab {
  font-size: 14px;
  // width: 80px;
  &.van-tab--active {
    span {
      font-weight: bold;
    }
  }
}

/deep/ ul {
  word-spacing: normal;
}

.icon {
  width: 30px;
  height: 30px;
  margin-top: 5px;
}
.wrapper {
  display: flex;
  line-height: 1.75;
}
.details {
  margin-left: 15px;
  font-size: 14px;
}
/deep/ .highlighted {
  color: #0b41cd;
  font-weight: bold;
}
.noSearch {
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title2 {
  font-size: 16px;
  display: inline-block;
  margin-left: 10px;
}
li {
  padding: 10px;
}

.text-hide1 {
  /*单行文本溢出*/
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.padding {
  padding: 20px 0.4rem 20px;
}
.collapse {
  .van-collapse-item {
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
    /deep/.van-collapse-item__content {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0 0 8px 8px;
    }
    .collapse-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-height: 70vh;
      overflow-y: scroll;
      min-height: 70px;
      width: 100%;
      /deep/.van-list {
        width: 100%;
      }
    }
    /deep/.van-cell--clickable:active {
      background-color: transparent;
    }
  }
}

.menu-button {
  min-width: 48%;
  margin-bottom: 12px;
}

.menu-icon {
  color: #0b41cd;
  margin-right: 8px;
}
.name {
  color: #0b41cd;
  font-size: 14px;
  font-weight: 500;
}
.head-title {
  font-size: 24px;
  font-weight: 600;
  color: #0b41cd;
  padding-bottom: 27px;
  padding-top: 17px;
}

/deep/.van-collapse-item__title .van-cell__right-icon::before {
  color: #0b41cd;
  transform: rotate(0deg);
}

/deep/.van-collapse-item__title--expanded .van-cell__right-icon::before {
  transform: rotate(90deg);
}

.under {
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  color: #0b41cd;
}
.name {
  color: #0b41cd;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-right: 5px;
}
.details {
  margin-left: 5px;
  .title {
    color: #868686;
    word-break: break-all;
    cursor: pointer;
    font-size: 12px;
    line-height: 2;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
  .content {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
