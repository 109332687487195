<template>
  <div class="list-bg">
    <van-nav-bar title="通知详情" left-arrow @click-left="goBack">
      <!--      <template #right>-->
      <!--        <van-popover v-model="showPopover" trigger="click" placement="bottom-end" :actions="actions" @select="onSelect">-->
      <!--          <template #reference>-->
      <!--            <van-image width="20" height="20" :src="require('@/assets/images/ellipsis.svg')" />-->
      <!--          </template>-->
      <!--        </van-popover>-->
      <!--      </template>-->
    </van-nav-bar>
    <van-row style="padding: 10px 10px">
      <van-col span="24" style="margin-bottom: 10px">
        <div style="font-size: 16px; color: #262626; font-weight: bold">
          {{ detail.noticeTitle }}
          <img :src="require('@/assets/images/attachment.svg')" v-if="fileData.length > 0" />
        </div>
        <div style="color: #8c8c8c; text-align: right; font-size: 12px">
          {{ detail.sendTime }}
        </div>
      </van-col>
      <van-col span="24">
        <div style="background: #e6eefc; min-height: 300px; padding: 10px 10px">
          <div
            style="
              min-height: 300px;
              background: linear-gradient(180deg, rgba(233, 242, 253, 0.15) 0%, rgba(206, 228, 255, 0.3) 100%), #fff;
              border-radius: 10px;
            "
          >
            <div class="ql-snow ql-editor" v-html="detail.noticeContent" style="font-size: 14px"></div>
          </div>
        </div>
      </van-col>
    </van-row>

    <van-row style="padding: 10px 10px">
      <van-col span="24" class="cell-group">
        <van-row style="height: 40px; line-height: 40px; padding: 5px 10px">
          <van-col span="24" style="text-align: left; font-weight: bold; font-size: 14px"> 发件人信息 </van-col>
        </van-row>
        <van-cell-group class="cell-bg">
          <van-cell>
            <van-row>
              <van-col span="24">
                <div>发件人：ChannelManagement@roche.com(sent by {{ detail.senderEmail }})</div>
              </van-col>
            </van-row>
          </van-cell>
        </van-cell-group>
      </van-col>
    </van-row>

    <van-row style="padding: 10px 10px" v-if="fileData.length > 0">
      <van-col span="24" class="cell-group">
        <van-row style="height: 40px; line-height: 40px; padding: 5px 10px">
          <van-col span="12" style="text-align: left; font-weight: bold; font-size: 14px"> 附件 </van-col>
          <van-col span="12" style="text-align: right; font-size: 14px; color: #0b41cd" @click="downloadAll">
            下载全部
          </van-col>
        </van-row>
        <van-cell-group class="cell-bg" v-for="(item, index) in fileData" :key="index">
          <van-cell @click="downloadOne(item)">
            <van-row>
              <van-col span="24">
                <van-col span="3" style="margin-top: 2px">
                  <van-image
                    v-if="getFileType(item.fileName) === '.PDF'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/pdf.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.ZIP'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/zip.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.XLS' || getFileType(item.fileName) === '.XLSX'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/excel.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.DOC' || getFileType(item.fileName) === '.DOCX'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/word.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.PNG'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/png.svg')"
                  />
                  <van-image
                    v-else-if="getFileType(item.fileName) === '.JPG' || getFileType(item.fileName) === '.JPEG'"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/jpg.svg')"
                  />
                  <van-image v-else width="20" height="20" :src="require('@/assets/images/file.svg')" />
                </van-col>
                <van-col span="21">
                  <div style="color: #0b41cd">{{ item.fileName }}</div>
                </van-col>
              </van-col>
            </van-row>
          </van-cell>
        </van-cell-group>
        <!--        <div v-if="fileData.length === 0" style="text-align: center; color: #8c8c8c; font-size: 14px">暂无附件数据</div>-->
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { readNotice } from '@/api/notice'
import { download } from '@/utils/request'

export default {
  name: 'NoticeDetail',
  data() {
    return {
      noticeId: null,
      detail: {},
      showPopover: false,
      actions: [{ text: '下载' }, { text: '查看附件' }],
      fileData: []
    }
  },
  created() {
    this.noticeId = this.$route.query.noticeId
    this.onLoad()
  },
  methods: {
    onLoad() {
      let params = {
        noticeId: this.$route.query.noticeId
      }
      readNotice(params)
        .then(res => {
          this.detail = res.data
          this.fileData = res.data.noticeFileList ? res.data.noticeFileList : []
        })
        .catch(err => {
          console.log(err)
        })
    },
    goBack() {
      // this.$router.push({
      //   path: '/notice'
      // })
      this.$router.go(-1)
    },
    onSelect(action) {
      console.log(action.text)
      if (action.text === '下载') {
        if (this.fileData.length === 0) {
          this.$toast.fail('没有可下载的附件信息')
          return false
        } else {
          this.downloadAll()
        }
      } else {
        this.$router.push({
          path: '/notice/file',
          query: { noticeId: this.noticeId, from: this.$route.query.from }
        })
      }
    },
    downloadAll() {
      const fileList = this.fileData.map(item => {
        return item.fileId
      })
      const fileIds = fileList.join(',')
      download('/file/multi-download', {}, null, {
        method: 'POST',
        params: {
          regCertFlag: this.detail.noticeType === 'PRODUCT_REG_CERTIFICATE' ? true : undefined,
          watermarkFlag: true,
          fileIds
        }
      })
    },
    downloadOne(obj) {
      const fileIds = obj.fileId
      download('/file/multi-download', {}, null, {
        method: 'POST',
        params: {
          regCertFlag: this.detail.noticeType === 'PRODUCT_REG_CERTIFICATE' ? true : undefined,
          watermarkFlag: true,
          fileIds
        }
      })
    },
    getFileType(fileName) {
      if (!fileName) {
        return null
      }
      const fileType = fileName.substr(fileName.lastIndexOf('.'), fileName.length - 1).toUpperCase()
      return fileType
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/quill.core.css';
@import '../../assets/css/quill.snow.css';
@import '../../assets/css/quill.bubble.css';
@import '../../assets/css/quill-better-table.css';

.list-bg {
  min-height: 100vh;
  padding-bottom: 50px;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}

.cell-bg {
  background: #ffffff;
}

.cell-group {
  //border-radius: 8px;
  //border: 0.5px solid var(--neutrals-grey-5-divider, #d9d9d9);
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
  box-shadow: 0px 0px 10px 0px rgba(11, 65, 205, 0.15);
  margin-bottom: 10px;
}

.html-css {
  font-size: 16px;
}

::v-deep .ql-editor > .ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}

::v-deep .ql-snow a {
  color: #06c !important;
}

::v-deep {
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    //li {
    //  list-style: decimal;
    //}
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li {
      //list-style: disc;
      padding-left: 0 !important;
    }
  }

  ol ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;

    li {
      list-style: circle;
    }
  }

  ul ol {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  ul ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
    li {
      list-style: circle;
    }
  }

  ol ol {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  ul ul ul {
    list-style-type: square;
    li {
      list-style: square;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
