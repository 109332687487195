<template>
  <div class="warpper">
    <div class="list-bg" />
    <van-nav-bar
      title="变更申请"
      right-text="提交"
      :class="loading ? 'btn-loading' : ''"
      left-arrow
      @click-left="goToBack"
      @click-right="onSubmit"
    >
      <template #right>
        <span class="header-btn" :class="loading ? 'btn-loading' : ''" @click="onSubmit">提交</span>
      </template>
    </van-nav-bar>

    <van-form validate-first ref="form">
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.phonenumber"
        name="phonenumber"
        label="电话"
        required
        placeholder="请输入电话"
        :rules="[{ required: true, message: '请输入电话' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.address"
        name="address"
        label="经销协议地址（即DA地址）"
        required
        placeholder="请输入经销协议地址（即DA地址）"
        :rules="[{ required: true, message: '请输入经销协议地址（即DA地址）' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.postCode"
        name="postCode"
        label="经销协议地址所在地邮编"
        required
        placeholder="请输入经销协议地址所在地邮编"
        :rules="[{ required: true, message: '请输入经销协议地址所在地邮编' }]"
      />
      <van-field
        :maxlength="defaultMaxLength"
        input-align="right"
        v-model="form.mailingAddress"
        name="mailingAddress"
        label="协议寄件地址"
        required
        placeholder="请输入协议寄件地址"
        :rules="[{ required: true, message: '请输入协议寄件地址' }]"
      />
    </van-form>
  </div>
</template>

<script>
import { updateDealerContact } from '@/api/userCenter/dealerContact'
import { checkBinding, createAppealForCompanyInfo } from '@/api/userCenter/dealer'
import { getDicts } from '@/api/dict/data'
import _ from 'lodash'

export default {
  name: 'info-detail',
  props: {
    dealerInfoDetail: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      title: '',
      lengthPattern: /^.{1,10}$/,
      phonePattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      emailPattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      columns: [],
      showPicker: false,
      selectedOptions: [],
      form: {
        postCode: '',
        phonenumber: '',
        mailingAddress: '',
        address: ''
      },
      formClone: {},
      contactGroupsClone: [],
      loading: false,
      beforeEditInfo: {}
    }
  },
  watch: {
    dealerInfoDetail: {
      handler(value) {
        this.init(value)
      },
      deep: true
    }
  },
  created() {
    getDicts('sys_contacts_group').then(({ data }) => {
      this.columns = data
    })
    // this.onLoad()
  },
  mounted() {
    this.init(this.dealerInfoDetail)
  },
  methods: {
    init(obj) {
      this.form = _.cloneDeep(obj)
      this.formClone = _.cloneDeep(obj)
    },
    onConfirm() {
      this.form.contactGroups = _.cloneDeep(this.contactGroupsClone)
      this.showPicker = false
    },
    goToBack() {
      this.$emit('close')
    },
    goToBackWithRefresh() {
      this.$router.push({
        name: 'UserCenter',
        params: {
          page: 'Contact'
        }
      })
    },
    goToChangeDealer() {
      console.log(this.form)
      this.$dialog
        .alert({
          title: '提醒',
          message: `根据经销协议条款18.4的内容：如经销商的送达地址、受送达人、联系电话等送达方式发生变动，应立即书面通知罗氏。</br></br>通知方式为：</br>
          ① 通过原经销商协议上约定的邮箱将变更的信息发送至china.ctm@roche.com；</br>
          ② 若原经销商协议上约定的邮箱目前已无法发送邮件，可提供说明函，说明发生的变更，并将加盖公章的说明函扫描件发送至china.ctm@roche.com。`,
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true,
          messageAlign: 'left'
        })
        .then(() => {
          try {
            let params = {
              dealerId: this.form.dealerId,
              phonenumber: this.formClone.phonenumber,
              address: this.formClone.address,
              mailingAddress: this.formClone.mailingAddress,
              postCode: this.formClone.postCode,
              appealPhonenumber: this.form.phonenumber,
              appealAddress: this.form.address,
              appealMailingAddress: this.form.mailingAddress,
              appealPostCode: this.form.postCode
            }
            createAppealForCompanyInfo(params).then(() => {
              this.$toast.success('变更申请成功')
              this.loading = false
              this.$emit('success')
              this.goToBack()
            })
          } catch (error) {
            console.error('操作失败:', error)
            this.$dialog.msgError('操作失败，请重试')
          } finally {
          }
        })
        .catch(() => {})
      // this.$dialog.confirm({
      //   title: '提醒',
      //   message: `根据经销协议条款18.4的内容：如经销商的送达地址、受送达人、联系电话等送达方式发生变动，应立即书面通知罗氏。</br></br>通知方式为：</br>
      //     ① 通过原经销商协议上约定的邮箱将变更的信息发送至china.ctm@roche.com；</br>
      //     ② 若原经销商协议上约定的邮箱目前已无法发送邮件，可提供说明函，说明发生的变更，并将加盖公章的说明函扫描件发送至china.ctm@roche.com。`,
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   dangerouslyUseHTMLString: true,
      //   messageAlign: 'left',
      //   beforeClose: (action, done) => {
      //     if (action === 'confirm') {
      //       try {
      //         updateDealerCompanyInfo(this.form).then(() => {
      //           done() // 关
      //           this.$toast.success('变更申请成功')
      //           this.loading = false
      //         })
      //       } catch (error) {
      //         console.error('操作失败:', error)
      //         this.$dialog.msgError('操作失败，请重试')
      //       } finally {
      //       }
      //     } else {
      //       console.log('用户点击了取消按钮')
      //       done()
      //       this.loading = false
      //     }
      //   }
      // })
    },
    changeStatus(val) {
      let param = {
        contactId: this.form.contactId,
        dealerId: this.form.dealerId
      }
      checkBinding(param).then(res => {
        let msgStr = null
        let text = val ? '启用' : '停用'
        let status = val ? '0' : '1'
        if (res.data.isBindFlag) {
          if (text === '停用') {
            msgStr =
              '该经销商联络人账号已绑定过经销商账号，如果停用该账号，会将已绑定的经销商账号进行解绑操作，请确认是否继续停用该账号？'
          } else {
            msgStr = '确认要' + text + ' ' + this.form.contactName + ' 这个联络人吗？'
          }
        } else {
          msgStr = '确认要' + text + ' ' + this.form.contactName + ' 这个联络人吗？'
        }
        this.$dialog
          .confirm({
            message: msgStr
          })
          .then(() => {
            updateDealerContact({ ...this.form, status: status }).then(({ code, msg }) => {
              if (code != 200) {
                this.$toast.fail({
                  message: msg,
                  className: 'fail'
                })
                return
              }
              this.$toast.success('操作成功')
              this.form.status = val
            })
          })
          .catch(() => {})
      })
    },
    onSubmit() {
      this.$refs.form
        .validate()
        .then(() => {
          if (this.loading) {
            return
          }
          this.loading = true
          this.goToChangeDealer()
        })
        .catch(() => {
          // this.$toast.fail('提交失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb 0%, #d9ebfb 0%, #f2f2f7 19.29%, #fef9f7 45.26%, #fffefe 100%);
}
/deep/.van-cell {
  border-bottom: 1px solid #ebedf0;
}
/deep/ .van-form {
  background: #fff;
}
/deep/ .van-toast--fail {
  width: 300px;
}
/deep/ .van-nav-bar__title {
  font-weight: bold !important;
}
/deep/ .van-field__label {
  color: #000;
}
/deep/.van-field__error-message {
  text-align: right;
}
.header-btn {
  color: #0b41cd;
}
.warpper {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
