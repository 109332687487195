<template>
  <div class="login">
    <div class="login-container">
      <img class="login-logo" src="../../assets/logo/logo.png" />
      <img class="title" src="../../assets/images/login-name.png" />
      <!--      <van-button class="login-btn btn" type="info" @click="handleSSO">登录</van-button>-->
    </div>
    <video autoplay muted loop playsinline disablePictureInPicture>
      <source src="../../assets/videos/login.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script>
import { goSSO } from '@/utils/auth'
export default {
  name: 'Login',
  data() {
    return {}
  },
  created() {
    if (this.$route.fullPath.indexOf('errorCode') > -1 && this.$route.fullPath.indexOf('403002') > -1) {
      this.$router.push({ path: '/expire', query: { code: '403002' } })
    }
  },
  methods: {
    handleSSO() {
      goSSO()
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.login-form {
  margin: 0 auto;
  position: relative;
  top: 20vh;
  z-index: 100;
  border-radius: 6px;
  width: 100%;
  padding: 25px 0 5px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .login-btn {
    width: 200px;
  }
}
.logo {
  width: 160px;
  margin-bottom: 50px;
}
.login-btn {
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px !important;
}
.login-container {
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 327px;
  height: 267px;
  border: 0.5px solid #d0d0d0;
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  .login-logo {
    width: 120px;
    margin: 48px auto;
  }
  .title {
    width: 300px;
    margin-bottom: 48px;
  }
  .login-btn {
    width: 180px;
  }
}
video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>
