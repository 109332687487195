<template>
  <van-row class="main-container">
    <van-row class="header" :class="theme.headerClass">
      <van-row class="title">
        <van-col>信用额度</van-col>
        <van-col class="date" v-if="cibResult.length > 0">{{ currentTime }}</van-col>
      </van-row>
    </van-row>
    <van-row class="result">
      <van-col class="item">
        <van-col class="title">总额度</van-col>
        <van-col class="price">{{ credit.totalAvailableAmount | numberFormat }}</van-col>
      </van-col>
      <van-col class="item">
        <van-col class="title">剩余额度</van-col>
        <van-col class="price">{{ credit.remainingAvailableAmount | numberFormat }}</van-col>
      </van-col>
      <van-col class="item">
        <van-col class="title">服务费缺口</van-col>
        <van-col class="price">{{ credit.totalAvailableAmount | numberFormat }}</van-col>
      </van-col>
    </van-row>
    <div class="unit">单位:元</div>
    <div class="bg-color" :class="theme.bgClass" />
    <van-image class="bg-img" :src="require('@/assets/images/home-credit.svg')" />
  </van-row>
</template>
<script>
import { getCibResult } from '@/api/home'
import { getDicts } from '@/api/dict/data'

const THEME_CONFIG = {
  bgClass: 'bg-purple',
  headerClass: 'header-purple'
}

export default {
  name: 'CreditCard',
  props: {
    themeType: {
      type: String,
      default: 'purple'
    }
  },
  data() {
    return {
      type: 'CD',
      theme: THEME_CONFIG,
      currentProduct: {},
      currentTime: '',
      cibResult: [],
      productLineOptions: [],
      credit: {
        totalAvailableAmount: 1000,
        remainingAvailableAmount: 500
      }
    }
  },
  created() {
    this.getCibResult()
    this.getDicts()
  },
  methods: {
    getDicts() {
      getDicts('sys_product_line').then(res => {
        this.productLineOptions = res.data
      })
    },
    changeShowType(currentProduct) {
      this.currentProduct = currentProduct
    },
    getCibResult() {
      let params = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      }
      getCibResult(params)
        .then(res => {
          this.cibResult = [...res.data]
          this.currentTime = this.cibResult[0] ? this.cibResult[0].year + '年' + this.cibResult[0].month + '月' : ''
          this.currentProduct = this.cibResult[0] || {
            productLine: ' ',
            totalAvailableAmount: ' ',
            lastMonthReceivedAmount: ' ',
            remainingAvailableAmount: ' ',
            releaseRunRate: ' '
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    formatNumberWithCommas(number) {
      // 将数字转换成字符串
      let numberStr = number.toString()

      // 使用正则表达式在逗号前插入逗号
      numberStr = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      return numberStr
    }
  },
  mounted() {}
}
</script>

<style scoped lang="less">
.main-container {
  position: relative;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
  border: 1px solid #bfbfbf;
  z-index: 1;
  overflow: hidden;
  .header {
    height: 46px;
    box-sizing: border-box;
    border-radius: 0 0 100px 0;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    padding: 15px 16px;
    position: relative;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date {
        color: #595959;
        font-size: 12px;
        font-weight: 400;
        margin-left: auto;
      }
    }
    .action {
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      line-height: 1;
      max-width: 250px;
      overflow-x: auto;
      .item {
        padding: 0 12px;
        color: #595959;
        font-size: 14px;
        font-weight: 500;
        margin-right: 4px;
        border-radius: 6px 6px 0 0;
        background: #fff7f2;
        min-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
      }
      .active {
        color: #ff7d2a;
        padding: 0 12px;
        min-height: 30px;
      }
    }
  }
  .desc {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 400;
    margin-top: 27px;
    margin-left: 16px;
  }
}
.bg-img {
  width: 75px;
  height: 75px;
  opacity: 0.4;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.bg-color {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
}

.header-purple {
  background: linear-gradient(90deg, #dfd4fd 0%, rgba(243, 239, 255, 0.55) 100%);
  border-bottom: 1px solid #dfd4fd;
}

.bg-purple {
  background: linear-gradient(180deg, rgba(249, 248, 255, 0.3) 0%, #f1ecff 100%), #fff;
}
.result {
  padding-left: 16px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  margin-top: 27px;
  line-height: 1;
  .item {
    .title {
      color: #8c8c8c;
      font-size: 12px;
      font-weight: 400;
    }
    .price {
      color: #262626;
      font-size: 18px;
      font-weight: 400;
      margin-top: 16px;
      min-height: 22px;
    }
    .price-mini {
      font-size: 14px;
      min-height: 22px;
    }
  }
}
.unit {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.empty-container {
  height: 130px;
}
.result {
  display: flex;
  justify-content: space-between;
  .item {
    display: flex;
    flex-direction: column;
  }
}
</style>
