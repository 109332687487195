!(function (n, e) {
  var t = n.documentElement,
    i = function () {
      var isLandscape = Math.abs(window.orientation) === 90 // 判断是否是横屏模式
      var n = t.clientWidth
      if (n) {
        if (isLandscape) {
          t.style.fontSize = n >= 750 ? '100px' : (n / 750) * 150 + 'px' // 竖屏模式下的字体大小
        } else {
          t.style.fontSize = n >= 750 ? '100px' : (n / 375) * 75 + 'px'
        }
      }
    }

  n.addEventListener && (e.addEventListener('orientationchange', i, !1), n.addEventListener('DOMContentLoaded', i, !1))
})(document, window)
