<template>
  <div class="main-container">
    <div class="list-bg" />
    <van-row class="header" type="flex" justify="space-between">
      <van-col class="head-arrow" @click="goToBack"><van-icon name="arrow-left" color="#0b41cd" /></van-col>
      <van-col class="head-title">{{ headerTitle }}</van-col>
      <van-col class="head-btn" @click="onSelect(headBtnText)" v-if="queryParams.titleCategory !== 'Sales Team'">{{
        headBtnText
      }}</van-col>
    </van-row>
    <!--    <van-row>-->
    <!--      <van-col span="24">-->
    <!--        <div style="color: red; font-size: 12px" v-if="showTag">-->
    <!--          <van-icon name="warning-o" color="red" />-->
    <!--          选择月份和产品线后，将显示Scorecard具体内容。-->
    <!--        </div>-->
    <!--      </van-col>-->
    <!--    </van-row>-->
    <van-row class="title" type="flex" justify="space-between">
      <van-col class="total">
        <span>共 {{ total }} 条数据</span><span v-if="lastMonth && lastMonth !== ''">，{{ lastMonth }}月更新</span>
      </van-col>
      <van-col class="filter" @click="filterData"
        ><span>筛选</span><van-icon name="filter-o" color="#0B41CD"
      /></van-col>
    </van-row>
    <van-row class="title" v-if="Object.keys(pickerParams).length > 0">
      <van-col class="total">
        <span>产品线：{{ pickerParams.productLine }}，</span>
        <span>月份：{{ pickerParams.month }}</span>
      </van-col>
    </van-row>
    <van-row style="padding: 5px 0px" v-if="titleList.length > 0 && !noData">
      <van-col v-for="(item, index) in titleList" :key="index">
        <div :class="item.checked ? 'divActive' : 'divNormal'" @click="divClick(item, index)">{{ item.label }}</div>
      </van-col>
    </van-row>
    <van-row class="title" v-if="titleList.length > 0 && !noData">
      <van-col class="total">
        <span>金额：千元，不含税</span>
      </van-col>
    </van-row>
    <van-row v-if="!showTag && !noData">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="onLoad"
        >
          <van-collapse v-model="activeNames" class="collapse">
            <van-collapse-item v-for="(item, index) in tableData" :key="index">
              <template #title>
                <div style="color: #0b41cd">{{ item.header }}</div>
              </template>
              <div class="collapse-content">
                <van-row class="divider">
                  <van-col span="24" v-for="(content, index2) in item.targetList" :key="index2">
                    <van-cell v-if="item.isMore || index2 < 3" :title="content.label" :value="content.value">
                      <template>
                        <span v-if="content.value && content.value.indexOf('%') > -1">{{ content.value }}</span>
                        <span v-else>{{ content.value | numberFormat }}</span>
                      </template>
                    </van-cell>
                  </van-col>
                </van-row>
                <van-row class="foot-btn" v-if="targetList.length > 3">
                  <span @click="showMore(item, index)">{{ !item.isMore ? '展示更多字段' : '收起更多字段' }}</span>
                </van-row>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-list>
      </van-pull-refresh>
    </van-row>
    <van-row v-if="noData">
      <van-col :span="24" style="text-align: center" v-if="!noProductLineData">
        <div style="color: #aaaaaa; margin-top: 50px; font-size: 14px">暂无数据</div>
      </van-col>
      <van-col :span="24" style="text-align: center" v-else>
        <div style="margin-top: 50px; font-size: 14px">请点击右上角下载按钮，下载文件</div>
      </van-col>
    </van-row>
    <van-popup v-model="show" class="popup-box" closeable round get-container="body" position="bottom" @close="cancel">
      <van-cell-group class="group-box">
        <van-col span="24" v-for="(item, index) in searchFormData" :key="index" class="form-item">
          <van-field
            v-if="item.type === 'input'"
            v-model="queryParams[item.props]"
            :label="item.label"
            placeholder="请输入"
            :disabled="item.disabled"
            required
          />
          <van-field
            v-if="item.type === 'DictSelect'"
            readonly
            clickable
            :name="item.props"
            :value="pickerParams[item.props]"
            :label="item.label"
            placeholder="点击选择"
            @click="openPicker(item)"
            :disabled="item.disabled"
            required
          />
          <van-popup v-model="showPicker" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              value-key="dictLabel"
              :columns="options"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
        </van-col>
      </van-cell-group>
      <van-row class="popup-foot-btn" gutter="20">
        <!--        <van-button class="btn margin-lf-10" type="default" @click="reset">重置</van-button>-->
        <van-button class="btn margin-lf-10" type="info" :disabled="searchFlag" @click="handleQuery">确定</van-button>
      </van-row>
    </van-popup>
  </div>
</template>

<script>
import { getRCRDataview, downloadTemplate } from '@/api/file'
import { getDicts } from '@/api/dict/data'
import dayjs from 'dayjs'

export default {
  name: 'RCR_Scorecard_Dataview',
  data() {
    return {
      headerTitle: 'RCR Scorecard',
      btnData: [{ text: '下载' }],
      headBtnText: '下载',
      titleList: [],
      options: [],
      searchFormData: [
        { props: 'month', label: '月份(必选)', type: 'DictSelect', options: [] },
        { props: 'productLine', label: '产品线(必选)', type: 'DictSelect', options: [] }
      ],
      tableData: [],
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      queryParams: {
        month: undefined,
        productLine: undefined,
        titleCategory: undefined
      },
      show: false,
      showPicker: false,
      pickerParams: {},
      selectedItem: {},
      activeNames: [],
      showTag: true,
      noData: false,
      noProductLineData: false,
      fileInfo: {},
      lastMonth: '',
      tabFlag: false,
      basicParams: {
        month: undefined,
        productLine: undefined,
        titleCategory: undefined
      },
      targetList: [],
      extraParams: {}
    }
  },
  filters: {
    parseTime(time) {
      return dayjs(time).format('YYYY-MM-DD')
    }
  },
  computed: {
    searchFlag() {
      return !this.queryParams.month || !this.queryParams.productLine
    }
  },
  created() {
    this.setProductLine()
    getDicts('score_card_month').then(({ data }) => {
      this.searchFormData[0].options = data
    })
    if (this.$route.query?.id) {
      this.extraParams.id = this.$route.query?.id
      this.search()
    }
  },
  methods: {
    goToBack() {
      if (this.$route.query.title) {
        this.$router.push({ name: 'DataCenter', params: { title: this.$route.query.title } })
      } else {
        this.$router.go(-1)
      }
    },
    onSelect(text) {
      if (text === '下载') {
        let flag = !this.queryParams.month || !this.queryParams.productLine
        if ((flag || this.noData) && !this.noProductLineData) {
          this.$toast.fail('请先筛选出数据再下载')
          return false
        }
        this.download()
      }
    },
    download() {
      downloadTemplate(this.fileInfo, this.fileInfo.fileName)
    },
    filterData() {
      this.show = true
    },
    openPicker(item) {
      this.showPicker = true
      this.options = item.options
      this.selectedItem = item
    },
    onConfirm(value) {
      const data = this.selectedItem
      this.queryParams[data.props] = data.dictValueFormat === 'dictLabel' ? value.dictLabel : value.dictValue
      this.queryParams = data.customChange ? data.customChange(this.queryParams) : this.queryParams
      this.pickerParams[data.props] = value.dictLabel
      this.pickerParams = data.customChange ? data.customChange(this.pickerParams) : this.pickerParams
      this.showPicker = false
    },
    reset() {
      this.queryParams = {
        month: undefined,
        productLine: undefined
      }
      this.searchFormData.forEach(item => {
        if (Object.hasOwn(item, 'defaultValue')) {
          this.queryParams[item.props] = item.defaultValue
        }
      })
      this.pickerParams = {}
    },
    handleQuery() {
      this.basicParams = { ...this.queryParams }
      if (this.extraParams.id) {
        this.showTag = true
        this.extraParams = {}
        const { path } = this.$route
        const query = { ...this.$route.query }
        delete query.id
        delete query.channelType
        this.$router.replace({ path, query })
      }
      this.getList()
      this.cancel()
    },
    cancel() {
      let arr1 = this.searchFormData[0].options.filter(item => item.dictValue === this.basicParams.month)
      let arr2 = this.searchFormData[1].options.filter(item => item.dictValue === this.basicParams.productLine)
      this.pickerParams['month'] = arr1 ? arr1[0].dictLabel : ''
      this.pickerParams['productLine'] = arr2 ? arr2[0].dictLabel : ''
      this.queryParams = { ...this.basicParams }
      this.show = false
    },
    divClick(obj, index) {
      this.titleList[index].checked = !this.titleList[index].checked
      let boo = this.titleList[index].checked
      this.titleList.forEach(item => {
        if (obj.value !== item.value && boo) {
          item.checked = false
        }
      })
      let filterData = this.titleList.filter(item => item.checked == true)
      this.queryParams.titleCategory = filterData[0].value
      //重新查询数据
      this.tabFlag = true
      this.getList()
    },
    async setProductLine() {
      const productLine = await getDicts('sys_product_line').then(({ data }) => {
        return data
      })
      const productSpecial = await getDicts('sys_product_special').then(({ data }) => {
        return data
      })
      this.searchFormData[1].options = [...productLine, ...productSpecial]
    },
    onLoad() {
      this.loading = true
      this.getList()
    },
    getList() {
      let params = {
        ...this.extraParams,
        ...this.queryParams,
        titleCategory: this.tabFlag ? this.queryParams.titleCategory : undefined,
        month: undefined
      }
      this.activeNames = []
      this.$loading.show()
      getRCRDataview(params)
        .then(res => {
          this.loading = false
          this.refreshing = false
          this.tabFlag = false
          if (res.data) {
            //title信息获取
            this.titleList = res.data.titleCategories.map(item => {
              return {
                value: item.dictValue,
                label: item.dictLabel,
                checked: res.data.defaultCategory === item.dictValue ? true : false
              }
            })
            //只有“销售团队”这一个tab时做特殊处理
            if (this.titleList.length === 1 && this.titleList[0].value === 'Sales Team') {
              let dataObj = {
                value: undefined,
                label: '默认',
                checked: res.data.defaultCategory ? false : true
              }
              this.titleList.unshift(dataObj)
            }
            this.queryParams.titleCategory = res.data.defaultCategory ? res.data.defaultCategory : undefined
            // 筛选表格数据
            let data = res.data.data
            let filterData = data.filter(item => item.rowDate === this.queryParams.month)
            let dataByMonth = filterData[0]
            this.tableData = []
            let headers = res.data.headers
            let subHeaders = res.data.subHeaders
            this.targetList = res.data.subHeaders
            headers.forEach(header => {
              let obj = {}
              obj.header = header
              let arr = []
              subHeaders.forEach(item => {
                let target = {}
                target.label = item
                target.value = dataByMonth[header][item]
                arr.push(target)
              })
              obj.targetList = arr
              obj.isMore = false
              this.tableData.push(obj)
            })
            if (res.data.headers.length === 0 || res.data.subHeaders.length === 0) {
              this.noData = true
              this.noProductLineData = true
            } else {
              this.noData = false
              this.noProductLineData = false
            }
            this.total = this.tableData.length
            this.fileInfo = {
              fileName: res.data.fileName,
              filePath: res.data.filePath
            }
            this.lastMonth = res.data.month
            this.showTag = false
          } else {
            this.tableData = []
            this.total = 0
            this.noData = true
            this.noProductLineData = false
            this.lastMonth = ''
          }
          this.$loading.hide()
          this.finished = true
        })
        .catch(err => {
          this.loading = false
          this.refreshing = false
          this.$loading.hide()
          console.log(err)
        })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.total = 0
      this.tableData = []
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.getList()
    },
    showMore(obj, index) {
      obj.isMore = !obj.isMore
      this.$set(this.tableData, index, obj)
    }
  }
}
</script>

<style lang="less" scoped>
.main-container {
  padding: 0 16px;
}

.list-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe);
}

.header {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;

  .popover {
    color: #0b41cd;
    font-size: 16px;
    position: absolute;
    height: 52px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
  }

  .head-arrow {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    left: 16px;
  }

  .head-title {
    font-weight: 600;
  }

  .head-btn {
    height: 52px;
    align-items: center;
    display: flex;
    position: absolute;
    right: 16px;
    font-size: 16px;
    color: #0b41cd;
  }
}

.title {
  padding: 6px 0 12px;

  .total {
    font-size: 14px;
    line-height: 24px;
    color: #969799;
  }

  .filter {
    color: #0b41cd;
    font-size: 14px;
  }
}

.popup-box {
  overflow-y: hidden;
  height: 450px;

  .van-cell {
    font-size: 14px;
    line-height: 20px;
  }

  .group-box {
    margin-top: 40px;
    height: 100%;
    overflow-y: scroll;
  }

  .popup-foot-btn {
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    box-sizing: border-box;

    .btn {
      width: 80px;
    }

    .btn-120 {
      width: 120px;
    }
  }
}

.margin-lf-10 {
  margin-left: 10px;
}

.divNormal {
  min-width: 50px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: #8c8c8c;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  padding: 0 5px;
}

.divActive {
  min-width: 50px;
  border-radius: 4px;
  background-color: #0b41cd;
  border: 1px solid #0b41cd;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  padding: 0 5px;
}

.collapse {
  padding: 2px;

  .van-collapse-item {
    overflow: hidden;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px #ebedf0, 2px -2px 2px #ebedf0, -2px 2px 2px #ebedf0, -2px -2px 2px #ebedf0;
    background: linear-gradient(276deg, #d9ebfb, #f2f2f7, #fef9f7, #fffefe) !important;

    .card {
      height: 52px;

      .icon {
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-right: 10px;
      }

      .file-name {
        flex: 1;
      }

      .card-right {
        text-align: right;
        padding-left: 10px;
        font-size: 12px;

        // line-height: 14px;
        .right-top {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          // display: flex;
          // justify-content: end;
          .right {
            // margin-left: 2px;
          }
        }

        .right-bottom {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // color: #0b41cd;
        }
      }

      .card-title-right {
        text-align: right;
        padding-left: 10px;
        margin-top: -25px;
      }

      // .year {
      //   text-align: end;
      //   padding-left: 10px;
      // }
    }

    .collapse-checkbox {
      margin-left: 5px;
    }

    .name {
      color: #0b41cd;
      font-size: 12px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    .collapse-content {
      .line {
        border-bottom: 0.5px solid #ebedf0;
      }
    }

    .foot-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      color: #0b41cd;
      border-top: 1px solid #ebedf0;
      padding: 10px 16px;

      .vertical-line {
        margin: 0 8px;
      }
    }
  }
}

/deep/ .van-cell__right-icon {
  color: #0b41cd !important;
}
</style>
