import request from '@/utils/request'
// 查询收到通知列表
export function receiveNoticeList(query) {
  return request({
    url: '/bff-dp-mobile/station-notice/receive-list',
    method: 'get',
    params: query
  })
}

// KPI绩效考核结果
export function getKpiResult(query) {
  return request({
    url: '/dealer/kpi-assessmentResult/getViewForDealerPortal',
    method: 'get',
    params: query
  })
}

// CIB金额
export function getCibResult(query) {
  return request({
    url: '/bff-dp-mobile/dashboard-data/cib-amount',
    method: 'get',
    params: query
  })
}

// Scorecard
export function getScorecardResult(query) {
  return request({
    url: '/bff-dp-mobile/score-card-data/data',
    method: 'get',
    params: query
  })
}

export function getScorecardSAP(query) {
  return request({
    url: '/bff-dp-mobile/score-card-data/dash-board/sap',
    method: 'get',
    params: query
  })
}

export function getScorecardRCR(query) {
  return request({
    url: '/bff-dp-mobile/score-card-data/dash-board/rcr',
    method: 'get',
    params: query
  })
}

// LOA授权
export function getLOAResult(query) {
  return request({
    url: '/dealer/loa-auth/product-customer-count',
    method: 'get',
    params: query
  })
}

// 通知
export function getNoticeResult(query) {
  return request({
    url: '/bff-dp-mobile/station-notice/remind-count',
    method: 'get',
    params: query
  })
}

// 待办任务
export function getTodoTask(query) {
  return request({
    url: '/bff-dp-mobile/task-flow/dealer-todo-list',
    method: 'get',
    params: query
  })
}

// 重点机型
export function getInstrumentInfo(query) {
  return request({
    url: '/bff-dp-mobile/score-card-data/dash-board/instrument',
    method: 'get',
    params: query
  })
}

// 渠道库存
export function getInventoryData(query) {
  return request({
    url: '/bff-dp-mobile/dashboard-data/inventory',
    method: 'get',
    params: query
  })
}

// 信用额度
export function getCreditData(query) {
  return request({
    url: '/bff-dp-mobile/dashboard-data/credit-limit',
    method: 'get',
    params: query
  })
}
