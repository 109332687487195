import { login, logout, getInfo, getRouters, getAllRouters, ssoLogout, refreshToken } from '@/api/login'
import Vue from 'vue'
import { getDealer } from '@/api/userCenter/dealer'
import {
  getToken,
  setToken,
  removeToken,
  parsingJWT,
  setIsNeedChangeDealer,
  setRefreshToken,
  removeRefreshToken
} from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    isNeedChangeDealer: false,
    baseInfo: {},
    userInfo: {},
    routes: [],
    allRoutes: [],
    noRoutes: false,
    authRouters: [],
    refreshToken: ''
  },

  mutations: {
    SET_EDITING(state, value) {
      state.editing = value
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_BASEINFO: (state, baseInfo) => {
      state.baseInfo = baseInfo
    },
    SET_IsNeedChangeDealer: (state, isNeedChangeDealer) => {
      state.isNeedChangeDealer = isNeedChangeDealer
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_ROUTES: (state, routes) => {
      state.routes = routes
    },
    SET_ALL_ROUTERS: (state, routes) => {
      state.allRoutes = routes
    },
    SET_NO_ROUTER: (state, routes) => {
      state.noRoutes = routes
    },
    SET_AUTH_ROUTER: (state, routes) => {
      state.authRouters = routes
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        // const loginInfo = loginData['loginInfo']
        // setToken(loginInfo.token)
        // commit('SET_TOKEN', loginInfo.token)
        // resolve()
        login(username, password, code, uuid)
          .then(res => {
            if (res.code != 200) {
              Vue.prototype.$toast(res.msg)
            }
            setToken(res.data.access_token)
            setRefreshToken(res.data.refresh_token)
            commit('SET_TOKEN', res.data.access_token)
            commit('SET_REFRESH_TOKEN', res.data.refresh_token)
            //如果是区域经理，可以选择经销商，来查看数据
            const { org_id } = parsingJWT(res.data.access_token)
            console.log('登录信息', parsingJWT(res.data.access_token))
            if (org_id == '1003' || org_id == '1002') {
              setIsNeedChangeDealer(org_id)
              commit('SET_IsNeedChangeDealer', org_id)
            } else {
              setIsNeedChangeDealer(false)
              commit('SET_IsNeedChangeDealer', false)
            }

            resolve(res.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then(res => {
            const user = res.user
            const avatar = ''
            // const avatar =
            //   user.avatar == '' || user.avatar == null
            //     ? require('@/assets/images/profile.jpg')
            //     : process.env.VUE_APP_BASE_API + user.avatar
            if (res.roles && res.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', res.roles)
              commit('SET_PERMISSIONS', res.permissions)
            } else {
              commit('SET_ROLES', ['ROLE_DEFAULT'])
            }
            commit('SET_NAME', user.nickName)
            commit('SET_AVATAR', avatar)
            commit('SET_USERINFO', user)
            state.orgId = res.user.org.orgId
            resolve(res.user)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    //获取菜单路由
    GenerateRoutes({ commit }) {
      return new Promise(resolve => {
        // 向后端请求路由数据
        getRouters().then(res => {
          const titles = []
          commit('SET_NO_ROUTER', res.data.length == 0)
          commit('SET_AUTH_ROUTER', res.data)
          res.data.forEach(item => {
            titles.push(item?.meta?.title)
            if (item.children) {
              item.children.forEach(child => {
                titles.push(child?.meta?.title)
              })
            }
          })
          commit('SET_ROUTES', titles)
          resolve(titles)
        })
      })
    },

    getAllRoutes({ commit }) {
      return new Promise(resolve => {
        // 向后端请求路由数据
        getAllRouters().then(res => {
          console.log(res)
          commit('SET_ALL_ROUTERS', res.data)
          resolve()
        })
      })
    },

    //详情
    GetDetailInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getDealer(state.orgId)
          .then(res => {
            commit('SET_BASEINFO', res[0])
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        ssoLogout().finally(() => {
          commit('SET_TOKEN', '')
          commit('SET_REFRESH_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          commit('SET_IsNeedChangeDealer', false)
          commit('SET_USERINFO', {})
          removeToken()
          removeRefreshToken()
          resolve()
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_REFRESH_TOKEN', '')
        commit('SET_IsNeedChangeDealer', false)
        removeToken()
        removeRefreshToken()
        resolve()
      })
    },
    // SSO登录
    ssoLogin({ commit }, token) {
      setToken(token)
      commit('SET_TOKEN', token)
      //如果是区域经理，可以选择经销商，来查看数据
      const { org_id } = parsingJWT(token)
      console.log('登录信息', parsingJWT(token))
      if (org_id == '1003' || org_id == '1002') {
        setIsNeedChangeDealer(org_id)
        commit('SET_IsNeedChangeDealer', org_id)
      } else {
        setIsNeedChangeDealer(false)
        commit('SET_IsNeedChangeDealer', false)
      }
    },
    ssoLoginRefresh({ commit }, refreshToken) {
      setRefreshToken(refreshToken)
      commit('SET_REFRESH_TOKEN', refreshToken)
    },
    // 刷新token
    RefreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken()
          .then(res => {
            setToken(res.data.access_token)
            commit('SET_TOKEN', res.data.access_token)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}

export default user
