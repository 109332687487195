export const queryUrl = '/bff-dp-mobile/task-flow/dealer-finished-list'
export const tableOptions = [
  {
    prop: 'processInstanceName',
    label: '流程名称',
    type: 'fileName',
    hideFileIcon: true
  },
  {
    prop: 'taskDescription',
    label: '任务描述',
    'show-overflow-tooltip': true
  },
  {
    prop: 'startTime',
    label: '发起时间',
    'show-overflow-tooltip': true
  },
  {
    prop: 'endTime',
    label: '完成时间',
    'show-overflow-tooltip': true
  },
  {
    prop: 'reason',
    'show-overflow-tooltip': true,
    label: '备注'
  }
]

export const searchFormData = [
  { props: 'processInstanceId', label: '流程ID', type: 'input', placeholder: '请输入完整的流程ID' },
  {
    props: 'processInstanceName',
    label: '流程名称',
    type: 'input'
  },
  { props: 'taskDescription', label: '任务描述', type: 'input' },
  {
    props: 'result',
    label: '状态',
    type: 'DictSelect',
    dictCode: 'task_result_status',
    multiple: false
  },
  {
    props: 'startTime',
    label: '发起时间',
    width: '120px',
    type: 'dateTime'
  },
  {
    props: 'endTime',
    label: '完成时间',
    width: '120px',
    type: 'dateTime'
  }
]
