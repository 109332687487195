export const queryUrl = '/bff-dp-mobile/task-flow/dealer-todo-list'
export const tableOptions = [
  {
    prop: 'processInstanceName',
    label: '流程名称',
    type: 'fileName',
    hideFileIcon: true
  },
  {
    prop: 'taskDescription',
    label: '任务描述',
    'show-overflow-tooltip': true
  },
  {
    prop: 'startTime',
    label: '发起时间',
    'show-overflow-tooltip': true
  },

  {
    prop: 'reason',
    'show-overflow-tooltip': true,
    label: '备注'
  }
]

export const searchFormData = [
  { props: 'processInstanceId', label: '流程ID', type: 'input', placeholder: '请输入完整的流程ID' },
  {
    props: 'processInstanceName',
    label: '流程名称',
    type: 'input'
  },
  { props: 'taskDescription', label: '任务描述', type: 'input' },
  // {
  //   props: 'status',
  //   label: '状态',
  //   // type: 'select',
  //   // options: [],
  //   type: 'DictSelect',
  //   dictCode: 'process_instance_status',
  //   multiple: false,
  //   showRule: data => {
  //     if (data.dictValue == 1 || data.dictValue == 2) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   }
  // },
  {
    props: 'startTime',
    label: '发起时间',
    width: '120px',
    type: 'dateTime'
  }
]
