<template>
  <div class="performance-appraisal-detail">
    <version-old v-if="version == 1" />
    <version-operation v-else-if="version == 2 && baValue == 2" />
    <version-marketing v-else-if="version == 2 && baValue == 3" />
    <version-achievement v-else-if="version == 2 && baValue == 4" />
    <version-old v-else />
  </div>
</template>
<script>
import get from 'lodash/get'
import VersionOld from './template/versionOld.vue'
import VersionOperation from './template/versionOperation.vue'
import VersionMarketing from './template/versionMarketing.vue'
import VersionAchievement from './template/versionAchievement.vue'
export default {
  components: {
    VersionOld,
    VersionAchievement,
    VersionMarketing,
    VersionOperation
  },
  data() {
    return {
      activeNamesMap: {},
      refreshing: false,
      loading: false,
      finished: false,
      active: '',
      baTabs: [],
      tableData: []
    }
  },
  computed: {
    detailID() {
      return get(this.$route, 'params.id', '')
    },
    title() {
      return decodeURIComponent(get(this.$route, 'query.title', ''))
    },
    period() {
      return decodeURIComponent(get(this.$route, 'query.period', ''))
    },
    baValue() {
      return decodeURIComponent(get(this.$route, 'query.baValue', '') || '')
    },
    version() {
      return decodeURIComponent(get(this.$route, 'query.version', '') || '')
    }
  },
  created() {},
  methods: {}
}
</script>
<style scoped lang="less">
.performance-appraisal-detail {
  background: linear-gradient(296.57deg, #d9ebfb 0%, #d9ebfb 5.27%, #f2f2f7 35.59%, #fef9f7 65.39%, #fffefe 83.33%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(100vh);
  .detail-tabs {
    ::v-deep .van-tab {
      flex: unset;
    }
  }
  .performance-appraisal-nav {
    ::v-deep .van-nav-bar {
      background-color: #fff;
      .van-nav-bar__content {
        .van-nav-bar__left {
          .van-icon {
            color: rgba(38, 38, 38, 1);
          }
        }
      }
    }
  }
  .right-btn-link {
    color: rgba(11, 65, 205, 1);
    border-right: 1px solid rgba(206, 217, 245, 1);
    margin: 10px 0;
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
  .content {
    // flex: 1;
    //overflow-y: auto;
    padding: 0 16px;
  }
  .total-score {
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    color: rgba(9, 52, 164, 1);
    justify-content: flex-end;
    padding-bottom: 20px;
    padding-right: 16px;
    .score {
      font-size: 32px;
      font-weight: 600;
    }
  }
  .collapse {
    padding-top: 12px;
    .van-collapse-item {
      align-items: center;
      margin-bottom: 12px;
      border-radius: 12px;
      box-shadow: 0 8px 12px #ebedf0;
      overflow: hidden;
      ::v-deep .van-cell {
        align-items: center;
      }
      ::v-deep .van-collapse-item__title {
        background: linear-gradient(
          296.57deg,
          #d9ebfb 0%,
          #d9ebfb 5.27%,
          #f2f2f7 35.59%,
          #fef9f7 65.39%,
          #fffefe 83.33%
        );
      }
      .collapse-content {
        .content-item {
          padding-bottom: 12px;
          &:not(:last-child) {
            border-bottom: 1px solid rgba(235, 237, 240, 1);
            margin-bottom: 12px;
          }
          .sub-title {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            font-size: 14px;
            margin-bottom: 10px;
            color: rgba(20, 20, 20, 1);
            .label {
              padding-right: 10px;
            }
            .content {
              flex: 1;
              display: flex;
              > .text {
                flex: 1;
                color: rgba(9, 52, 164, 1);
                display: flex;
                justify-content: flex-end;
              }
            }
          }
          .assessment-item {
            .van-icon.van-icon-info-o {
              margin-left: 16px;
            }
          }
        }
        ::v-deep .van-cell {
          align-items: normal;
          font-size: 12px;
          line-height: 14px;
          padding: 10px 0;
          &.high-light {
            .van-cell__value {
              font-size: 16px;
              color: rgba(9, 52, 164, 1);
            }
          }
        }
      }
    }
    .card-title {
      font-size: 16px;
      b {
        font-weight: 600;
        margin-right: 10px;
      }
    }
  }
}
</style>
