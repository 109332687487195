<template>
  <div class="confirmation">
    <van-nav-bar
      :title="filename"
      left-arrow
      class="confirmation-nav"
      fixed
      placeholder
      safe-area-inset-top
      @click-left="$router.back()"
    >
      <template #right>
        <span class="right-btn-link" @click.prevent="onDownload">下载</span>
      </template>
    </van-nav-bar>
    <div class="content">
      <canvas ref="pdfContainer"></canvas>
    </div>
  </div>
</template>
<script>
import get from 'lodash/get'
import { getCIBConfirmationByDealer } from '@/api/dataCenter/cib/confirmation'
import errorCode from '@/utils/errorCode'
import * as pdfjsLib from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
export default {
  data() {
    return {
      src: ''
    }
  },
  computed: {
    detailID() {
      return get(this.$route, 'params.id', '')
    },
    filename() {
      return decodeURIComponent(get(this.$route, 'query.filename', '确认函'))
    }
  },
  created() {
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker
    this.initDetailData()
  },
  methods: {
    initDetailData() {
      return this.queryDetail(this.detailID).then(src => {
        // this.src = src + '#view=FitH,top&scrollbars=0&toolbar=0&statusbar=0'
      })
    },
    queryDetail() {
      return getCIBConfirmationByDealer(this.detailID).then(async res => {
        this.loading = false
        const isBlob = res.data.type !== 'application/json'
        if (isBlob) {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const pdfUrl = window.URL.createObjectURL(blob)
          let loadingTask = pdfjsLib.getDocument(pdfUrl)
          loadingTask.promise.then(
            pdf => {
              let pageNum = pdf.numPages
              for (let i = 1; i <= pageNum; i++) {
                pdf.getPage(i).then(page => {
                  let winW = document.documentElement.clientWidth
                  let viewport = page.getViewport({ scale: 1 })
                  let scale = (winW / viewport.width).toFixed(2)
                  let scaledViewport = page.getViewport({ scale })
                  let canvas = this.$refs.pdfContainer
                  let context = canvas.getContext('2d')
                  canvas.height = scaledViewport.height
                  canvas.width = scaledViewport.width
                  let renderContext = {
                    canvasContext: context,
                    viewport: scaledViewport
                  }
                  let renderTask = page.render(renderContext)
                  renderTask.promise.then(() => {})
                })
              }
            },
            function (reason) {
              console.error(reason)
            }
          )
        } else {
          const resText = await res.data.text()
          const rspObj = JSON.parse(resText)
          const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
          this.$toast(errMsg)
          return ''
        }
      })
    },
    onDownload() {
      this.download('/file/multi-download', {}, `${this.filename}.pdf`, {
        method: 'POST',
        params: {
          watermarkFlag: true,
          fileIds: this.detailID
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.confirmation {
  background: linear-gradient(296.57deg, #d9ebfb 0%, #d9ebfb 5.27%, #f2f2f7 35.59%, #fef9f7 65.39%, #fffefe 83.33%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  .right-btn-link {
    color: rgba(11, 65, 205, 1);
    border-right: 1px solid rgba(206, 217, 245, 1);
    margin: 10px 0;
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
  .confirmation-nav {
    ::v-deep .van-nav-bar {
      background-color: #fff;
      .van-nav-bar__content {
        .van-nav-bar__left {
          .van-icon {
            color: rgba(38, 38, 38, 1);
          }
        }
      }
    }
  }
  .content {
    flex: 1;
    .iframe {
      border: 0;
    }
  }
}
</style>
